import React from 'react';
import type { ButtonProps} from '@Components/button';
import {Button, Type} from '@Components/button';

export interface PanelFooterPrimaryDefaultActionProps extends ButtonProps {
  className?: string;
}

export function PanelFooterPrimaryDefaultAction({className = '', type = Type.PRIMARY, ...props}: PanelFooterPrimaryDefaultActionProps) {
  return <Button {...props} type={type} customClasses={className} />;
}
