import React from 'react';
import PropTypes from 'prop-types';
import './campaign-metric-outcome-summary.scss';
import {connect} from 'react-redux';
import {CampaignMetricOutcomeStatus} from '../campaign-metric-outcome-status';
import {METRIC_TYPES} from '../../../../libraries/email-marketing-campaigns-library';

/**
 * Shows the summary for an outcome (positive or negative) for a metric for email marketing campaigns
 * @author Basil <basil@250mils.com>
 */
class CampaignMetricOutcomeSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isNonInteractable,
    };
  }

  render() {
    const expandableSection = this.props.outcomeExpandableSection,
      isOutcomeExpandable = expandableSection !== null,
      metricDetails = this.props.campaignMetrics[this.props.campaignId][this.props.metricType],
      metricTotal = metricDetails.total;

    return (
      <>
        {this.props.isNonInteractable
          ? this.#getNonInteractableOutcomeSummary(metricDetails, metricTotal)
          : this.#getInteractableOutcomeSummary(metricDetails, metricTotal, isOutcomeExpandable)}
        {isOutcomeExpandable && this.state.isOpen ? expandableSection : null}
      </>
    );
  }

  /**
   * @return {JSX.Element}
   */
  #getNonInteractableOutcomeSummary = (metricDetails, metricTotal) => {
    return (
      <div className={'campaign-metric-outcome-summary -non-interactable flexbox'}>
        <div className={'column flex-column-justify-between'}>
          <div>
            <p className={'metric-label'}>{metricDetails.positiveOutcomeLabel}</p>
            <div className={'flexbox flex-items-center flex-justify-between'}>
              <CampaignMetricOutcomeStatus hideTooltip outcomePercentage={metricDetails.positiveOutcomePercentage} isPositiveOutcome={true} />
              {this.#getRatioForOutcome(metricDetails.positiveOutcomeTotal, metricTotal)}
            </div>
          </div>
          <div>
            <p className={'metric-label expand-details flex-row-align-center'}>{metricDetails.negativeOutcomeLabel}</p>
            <div className={'flexbox flex-items-center flex-justify-between'}>
              <CampaignMetricOutcomeStatus
                outcomePercentage={metricDetails.negativeOutcomePercentage}
                isPositiveOutcome={false}
                hideStatus={this.props.metricType !== METRIC_TYPES.CLICK}
                hideTooltip
              />
              {this.#getRatioForOutcome(metricDetails.negativeOutcomeTotal, metricTotal)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  /**
   * @return {JSX.Element}
   */
  #getInteractableOutcomeSummary = (metricDetails, metricTotal, isOutcomeExpandable) => {
    return (
      <div className={'campaign-metric-outcome-summary flexbox'}>
        <div className={'column flex-column-justify-between'}>
          <p className={'metric-label'}>{metricDetails.positiveOutcomeLabel}</p>
          <p className={'metric-label' + (isOutcomeExpandable ? ' expand-details flex-row-align-center' : '')} onClick={this.#onToggleExpandableSection}>
            {metricDetails.negativeOutcomeLabel}
            {isOutcomeExpandable ? <i className={'icon ' + (this.state.isOpen ? 'icon-caret-up' : 'icon-caret-down')} /> : null}
          </p>
        </div>
        <div className={'flexbox'}>
          <div className={'column flex-column-justify-between'}>
            <CampaignMetricOutcomeStatus outcomePercentage={metricDetails.positiveOutcomePercentage} isPositiveOutcome={true} />
            <CampaignMetricOutcomeStatus
              outcomePercentage={metricDetails.negativeOutcomePercentage}
              isPositiveOutcome={false}
              hideStatus={this.props.metricType !== METRIC_TYPES.CLICK}
            />
          </div>

          <div className={'column flex-column-justify-between'}>
            {this.#getRatioForOutcome(metricDetails.positiveOutcomeTotal, metricTotal)}
            {this.#getRatioForOutcome(metricDetails.negativeOutcomeTotal, metricTotal)}
          </div>
        </div>
      </div>
    );
  };

  /**
   * @param {number} outcomeTotal
   * @param {number} metricTotal
   * @return {JSX.Element}
   */
  #getRatioForOutcome = (outcomeTotal, metricTotal) => {
    return (
      <p className={'ratio'}>
        <strong>{outcomeTotal}</strong>
        <span className={'denominator'}> / {metricTotal}</span>
      </p>
    );
  };

  #onToggleExpandableSection = () => {
    this.setState((prevState) => ({isOpen: !prevState.isOpen}));
  };
}

CampaignMetricOutcomeSummary.propTypes = {
  /**
   * The type of metric, one of the METRIC_TYPES.* constants
   */
  metricType: PropTypes.string.isRequired,

  /**
   * The ID of the relevant campaign
   */
  campaignId: PropTypes.string.isRequired,

  /**
   * If this is the positive outcome of the relevant metric
   */
  isPositiveOutcome: PropTypes.bool,

  /**
   * An optional expandable section for this outcome of the relevant metric
   */
  outcomeExpandableSection: PropTypes.object,
  /**
   * Uncollapses the expandable section on first load
   */
  isNonInteractable: PropTypes.bool,
};

CampaignMetricOutcomeSummary.defaultProps = {
  isPositiveOutcome: false,
  outcomeExpandableSection: null,
  isNonInteractable: false,
};

const mapCampaignMetricOutcomeSummaryStateToProps = (state) => {
  return {
    campaignMetrics: state.emailMarketingCampaignsPerformance.campaignMetrics,
  };
};

export default connect(mapCampaignMetricOutcomeSummaryStateToProps, null)(CampaignMetricOutcomeSummary);
