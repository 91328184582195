import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './brand-colors-section.scss';
import {Button, Size, Type} from '@Components/button';
import {isBrandContentTypeLoading, BRAND_ASSETS, fetchBrandsResourceIfNotLoaded, getActiveBrandId} from '@Libraries/brands-library';
import {BRANDS_COLOR_CATEGORY, EMPTY_SELECTABLE_COLORS_STATE, MAX_SELECTION_NUMBER_OF_COLORS, SELECTABLE_COLOR_EMPTY} from '@Components/mystuff-brands/brand-kits.types';
import {cloneDeep} from 'lodash';
import {noop} from '@Utils/general.util';
import {fetchBrandColors, getContentForBrandContentType} from '../../brands-thunk';
import {setSelectedColorsInSelectableState} from '@Components/mystuff-brands/brands-reducer';
import {GridSection} from '../../../grid-section';
import {DottedIconButton} from '../../../dotted-icon-button';
import {BrandSectionLoader} from '../brand-section-loader';
import {BrandColorGridItem} from '../brand-color-grid-item';

/**
 * Section responsible for displaying brand colors
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
class BrandColorsSection extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        void fetchBrandsResourceIfNotLoaded(this.props.loadingState, this.#getBrandColors);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        void fetchBrandsResourceIfNotLoaded(this.props.loadingState, this.#getBrandColors);
    }

    render() {
        const gridItems = this.#getBrandColorGridItems();
        return (
            <>
                {this.#getNonGridAddBtn()}
                <GridSection
                    gridItems={gridItems}
                    sectionHeading={this.#getSectionHeadingText()}
                    headingClasses={'body-xs-bold hidden-phone'}
                    sectionClasses={'brands-section brand-colors -bottom-margin'}
                    gridClasses={gridItems.length === 1 ? '-single-column' : ''}
                    isLoading={isBrandContentTypeLoading(this.props.loadingState)}
                    loaderComponent={<BrandSectionLoader/>}
                    hideHeading={gridItems.length === 0 || this.props.hideHeading}
                />
            </>
        );
    }

    /**
     * @returns {JSX.Element | null}
     */
    #getNonGridAddBtn = () => {
        if (!this.props.showAddButtonOutsideGrid) {
            return null;
        }

        const clickHandler = this.#shouldShowAddBtnInReadOnlyMode() ? this.props.onAddColors : this.#openBrandColorModal;
        return <Button type={Type.PRIMARY} onClick={clickHandler} isLoading={isBrandContentTypeLoading(this.props.loadingState)} size={Size.SMALL} customClasses="spacing-m-b-5" text={i18next.t('pmwjs_add_a_color')} isFullWidth/>
    }

    #getNewBrandColorButtonMarkup = () => {
        return (
            <li className={'brand-grid-item -small'} key={'btn-new-color'}>
                <Button type={Type.DASHED} customClasses="_full-width _full-height" icon="icon-plus" iconClassName="size-icon-24" onClick={this.#openBrandColorModal}/>
            </li>
        );
    };

    #getSectionHeadingText = () => {
        if (this.props.sectionHeading) {
            return this.props.sectionHeading;
        }

        return window.i18next.t('pmwjs_colors');
    };

  #getDefaultColorButtonMarkup = () => {
    return (
        <li key={'btn-default-select'} className={`brand-grid-item btn-upload-logo ${this.#isDefaultColorSelected() ? '-selected' : ''}`}>
          <Button
              type={Type.SECONDARY}
              icon={'icon-color-palette'}
              text={`${window.i18next.t('pmwjs_default')}`}
              customClasses={`btn-default-selection  ${this.#isDefaultColorSelected() ? '-selected' : ''}`}
              iconClassName={'logo-default-selection'}
              textClasses={'default brand-asset-name'}
              onClick={this.#onDefaultColorSelect}
          />
        </li>
    );
  };

    #getAddColorsButtonMarkup = () => {
        return (
            <li className={'brand-grid-item'} key={'btn-add-colors'}>
                <DottedIconButton btnText={i18next.t('pmwjs_add_colors')} btnIcon={'icon-plus'} onClick={this.props.onAddColors}/>
            </li>
        );
    };

    /**
     * @return {BRANDS_COLOR_CATEGORY.PRIMARY | BRANDS_COLOR_CATEGORY.SECONDARY | BRANDS_COLOR_CATEGORY.TERTIARY | null}
     * */
    #findOrderOfColorByIdFromSelectableState = (selectedColorId) => {
        if (this.props.selectedColorsIds?.Primary === selectedColorId) {
            return BRANDS_COLOR_CATEGORY.PRIMARY;
        }

        if (this.props.selectedColorsIds?.Secondary === selectedColorId) {
            return BRANDS_COLOR_CATEGORY.SECONDARY;
        }

        if (this.props.selectedColorsIds?.Tertiary === selectedColorId) {
            return BRANDS_COLOR_CATEGORY.TERTIARY;
        }

        return null;
    };

    // Helper function to find the next missing order number
    #findNextMissingOrder = () => {
        if (!this.props.selectedColorsIds) {
            return;
        }

        if (this.props.selectedColorsIds.Primary === SELECTABLE_COLOR_EMPTY) {
            return BRANDS_COLOR_CATEGORY.PRIMARY;
        }

        if (this.props.selectedColorsIds.Secondary === SELECTABLE_COLOR_EMPTY) {
            return BRANDS_COLOR_CATEGORY.SECONDARY;
        }

        if (this.props.selectedColorsIds.Tertiary === SELECTABLE_COLOR_EMPTY) {
            return BRANDS_COLOR_CATEGORY.TERTIARY;
        }

        return null;
    };

    #colorInSelectedColors = (id) => {
        return !!this.#findOrderOfColorByIdFromSelectableState(id);
    };

    #getIndexOfSelectedColor = (id) => {
        switch (this.#findOrderOfColorByIdFromSelectableState(id)) {
            case BRANDS_COLOR_CATEGORY.PRIMARY:
                return 1;
            case BRANDS_COLOR_CATEGORY.SECONDARY:
                return 2;
            case BRANDS_COLOR_CATEGORY.TERTIARY:
                return 3;
            default:
                return 0;
        }
    };

    #noColorsSelected(selectedColorIds) {
        return Object.values(selectedColorIds).every((colorId) => colorId === SELECTABLE_COLOR_EMPTY);
    }

    #totalColorsSelected(selectedColorIds) {
        return Object.values(selectedColorIds).filter((colorId) => colorId !== SELECTABLE_COLOR_EMPTY).length;
    }

    #onSelectableColorClick = (id) => {
        if (!this.props.isSelectable) {
            return;
        }

        const newSelection = cloneDeep(this.props.selectedColorsIds);
        const order = this.#findOrderOfColorByIdFromSelectableState(id);

        if (order) {
            newSelection[order] = SELECTABLE_COLOR_EMPTY;
        } else {
            const nextMissingOrder = this.#findNextMissingOrder(this.props.selectedColorsIds);
            if (nextMissingOrder) newSelection[nextMissingOrder] = id;
        }

        if (this.#noColorsSelected(newSelection)) {
            return this.#onDefaultColorSelect();
        }

        const allSelectionsAlreadyMade = this.#totalColorsSelected(newSelection) >= MAX_SELECTION_NUMBER_OF_COLORS && !this.#findNextMissingOrder(this.props.selectedColorsIds);

        if (allSelectionsAlreadyMade) {
            newSelection[BRANDS_COLOR_CATEGORY.TERTIARY] = id;
        }

        this.props.setSelectedColors({
            ids: newSelection,
            defaultSelected: false,
        });
    };

    #onDefaultColorSelect = () => {
        this.props.setSelectedColors({
            ids: EMPTY_SELECTABLE_COLORS_STATE,
            defaultSelected: true,
        });
    };

    #isDefaultColorSelected = () => {
        return this.props.defaultColorSelected;
    };

    #getBrandColorGridItems = () => {
        let firstGridItem, lastGridItem;
        const colorsForBrand = this.props.colorIds.map((idbrandColor) => this.props.colors[idbrandColor]);

        const gridItems = colorsForBrand.map((brandColor) => this.#mapBrandColorInfoToGridItem(brandColor));

        if (this.props.isReadOnly) {
            if (this.props.isSelectable) {
                firstGridItem = this.#getDefaultColorButtonMarkup;

                if (this.props.colorIds.length < 1 && !this.props.showAddButtonOutsideGrid) {
                    lastGridItem = this.#getAddColorsButtonMarkup;
                }
            }
        } else if (!this.props.showAddButtonOutsideGrid) {
            firstGridItem = this.#getNewBrandColorButtonMarkup;
        }

        if (firstGridItem) {
            gridItems.unshift(firstGridItem());
        }

        if (lastGridItem) {
            gridItems.push(lastGridItem());
        }

        return gridItems;
    };

    #openBrandColorModal = () => {
        PMW.openBrandColorsModal({
            brandId: this.props.brandId,
        });
    };

    #getBrandColors = async () => {
        await fetchBrandColors(this.props.brandId);
    };

    /**
     * @param {Object} [brandColorInfo]
     * @param {number} [brandColorInfo.idbrandColor]
     * @param {string} [brandColorInfo.brandId]
     * @param {string} [brandColorInfo.hexCode]
     * @param {number} [brandColorInfo.type]
     * @returns {JSX.Element}
     */
    #mapBrandColorInfoToGridItem = (brandColorInfo) => {
        return (
            <BrandColorGridItem
                key={`brand-color-${brandColorInfo.idbrandColor}`}
                idbrandColor={brandColorInfo.idbrandColor}
                brandId={brandColorInfo.brandId}
                hexCode={brandColorInfo.hexCode}
                type={brandColorInfo.type}
                isReadOnly={this.props.isReadOnly}
                isSelectable={this.props.isSelectable}
                isSelected={this.#colorInSelectedColors(brandColorInfo.idbrandColor)}
                onClick={() => this.#onSelectableColorClick(brandColorInfo.idbrandColor)}
                selectionIndex={this.#getIndexOfSelectedColor(brandColorInfo.idbrandColor)}
            />
        );
    };

    /**
     *
     * @return {boolean}
     */
    #shouldShowAddBtnInReadOnlyMode = () => {
        return this.props.isReadOnly && this.props.colorIds.length < 1;
    }
}

const mapBrandColorsStateToProps = (state) => {
    const activeBrandId = getActiveBrandId(state.brands),
        activeBrandContent = state.brands.brandContent[activeBrandId];

    return {
        brandId: activeBrandId,
        loadingState: activeBrandContent.colors.loaded,
        colorIds: activeBrandContent.colors.ids,
        colors: state.brands.colors,
        selectedColorsIds: state.brands.selectableState.selectedColors.ids,
        defaultColorSelected: state.brands.selectableState.selectedColors.defaultSelected,
    };
};

const mapBrandColorsDispatchToProps = (dispatch) => {
    return {
        getBrandColors: (brandId) => dispatch(getContentForBrandContentType({brandId: brandId, brandContentType: BRAND_ASSETS.BRAND_COLORS})),
        setSelectedColors: (selectedColorIds) => dispatch(setSelectedColorsInSelectableState(selectedColorIds)),
    };
};

BrandColorsSection.propTypes = {
    /**
     * the ID of the brand who's color assets are being displayed by this section
     */
    brandId: PropTypes.string,
    /**
     * the loading state of colors in the section. see BRANDS_LOADING_STATES for all loading state types
     */
    loadingState: PropTypes.number,
    /**
     * An array of Ids for this brand's color assets
     */
    colorIds: PropTypes.array,
    /**
     * The object containing brand colors and their information (serialized versions of BrandColorVO)
     */
    colors: PropTypes.object,
    /**
     * Disables all actions on click for every grid item
     */
    isReadOnly: PropTypes.bool,
    /**
     * If 'true', makes all grid item selectable and adds 1 extra grid item for default selection
     */
    isSelectable: PropTypes.bool,
    /**
     * Heading for the brands logos section - defaults to 'Brand Colors'
     */
    sectionHeading: PropTypes.string,
    /**
     * an array of selected colors in selectable state
     */
    selectedColorsIds: PropTypes.shape({Primary: PropTypes.number, Secondary: PropTypes.number, Tertiary: PropTypes.number}),
    /**
     * true if the default is selected instead of any color options
     */
    defaultColorSelected: PropTypes.bool,
    /**
     * sets the selected colors in the redux store
     */
    setSelectedColors: PropTypes.func,
    /**
     * An 'Add Colors' button is  added to the grid if the component is selectable and no colors are
     * present in the grid, this function is executed when that is clicked.
     */
    onAddColors: PropTypes.func,
    /**
     * whether we want to show the add button separately above the grid instead of it being part of the grid
     */
    showAddButtonOutsideGrid: PropTypes.bool,
    /**
     * whether to hide heading
     */
    hideHeading: PropTypes.bool
};

BrandColorsSection.defaultProps = {
    isSelectable: false,
    isReadOnly: false,
    sectionHeading: '',
    onAddColors: noop,
    showAddButtonOutsideGrid: false,
    hideHeading: false
};

export default connect(mapBrandColorsStateToProps, mapBrandColorsDispatchToProps)(BrandColorsSection);
