import type {ControlledListItemType1Props} from '@Components/controlled-list/components/controlled-list-item-type-1';
import type {ControlledListItemType3Props} from '@Components/controlled-list/components/controlled-list-item-type-3';
import type {ControlledListItemType2Props} from '@Components/controlled-list/components/controlled-list-item-type-2';
import type {ControlledListItemType4Props} from '@Components/controlled-list/components/controlled-list-item-type-4';
import type {ControlledListItemType5Props} from '@Components/controlled-list/components/controlled-list-item-type-5';
import type {ControlledListHeadingItemProps} from '@Components/controlled-list/components/controlled-list-heading-item';
import type {ControlledListItemType7Props} from '@Components/controlled-list/components/controlled-list-item-type-7';
import type {ControlledListDividerItemProps} from '@Components/controlled-list/components/controlled-list-divider-item';
import type {ControlledListItemTypeButtonProps} from '@Components/controlled-list/components/controlled-list-item-type-button';
import type {IconProps} from '@Components/icon-v2';
import type React from 'react';
import type {ControlledListItemType6Props} from '@Components/controlled-list/components/controlled-list-item-type-6';
import type {ControlledListItemType8Props} from '@Components/controlled-list/components/controlled-list-item-type-8';

export interface BaseControlledListItem {
  id: string;
  className?: string;
  text: string;
  isSelected?: boolean;

  onClick?(id?: string, event?: React.MouseEvent<HTMLElement>): void;

  searchTerms?: Array<string>;
  listSize?: ControlledListItemSize;
}

export enum ControlledListItemSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum CONTROLLED_LIST_ITEM_TYPE {
  HEADING = 'heading',
  DIVIDER = 'divider',
  DEFAULT_1 = 'default_type_1',
  DEFAULT_2 = 'default_type_2',
  DEFAULT_3 = 'default_type_3',
  DEFAULT_4 = 'default_type_4',
  DEFAULT_5 = 'default_type_5',
  DEFAULT_6 = 'default_type_6',
  DEFAULT_7 = 'default_type_7',
  DEFAULT_8 = 'default_type_8',
  BUTTON = 'button',
}

export interface ControlledListItem1 extends ControlledListItemType1Props {
  type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_1;
}

export interface ControlledListItem2 extends ControlledListItemType2Props {
  type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2;
}

export interface ControlledListItem3 extends ControlledListItemType3Props {
  type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_3;
}

export interface ControlledListItem4 extends ControlledListItemType4Props {
  type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_4;
}

export interface ControlledListItem5 extends ControlledListItemType5Props {
  type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_5;
}

export interface ControlledListHeadingItem extends ControlledListHeadingItemProps {
  type: CONTROLLED_LIST_ITEM_TYPE.HEADING;
}

export interface ControlledListDividerItem extends ControlledListDividerItemProps {
  type: CONTROLLED_LIST_ITEM_TYPE.DIVIDER;
}

export interface ControlledListItem7 extends ControlledListItemType7Props {
  type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7;
}

export interface ControlledListItem6 extends ControlledListItemType6Props {
  type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_6;
}

export interface ControlledListItem8 extends ControlledListItemType8Props {
  type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_8;
}

export interface ControlledListItemButton extends ControlledListItemTypeButtonProps {
  type: CONTROLLED_LIST_ITEM_TYPE.BUTTON;
}

export type ControlledListItemType =
  | ControlledListItem1
  | ControlledListItem2
  | ControlledListItem3
  | ControlledListItem4
  | ControlledListItem5
  | ControlledListItem6
  | ControlledListItem7
  | ControlledListItem8
  | ControlledListHeadingItem
  | ControlledListDividerItem
  | ControlledListItemButton;

export interface ControlledListItem3ActionProps extends IconProps {
  containerClasses?: string;
  isUsedAsButton?: true;
}
