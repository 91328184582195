import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type * as Fabric from '@postermywall/fabricjs-2';

interface StateProps {
  openPopup?: boolean;
  beforeOpen?: boolean;
  target: any | null;
}

const initialState: StateProps = {
  openPopup: false,
  beforeOpen: false,
  target: null,
};

const imageItemPopUpMenu = createSlice({
  name: 'ImageItemPopUpMenu',
  initialState,
  reducers: {
    updateImagePopUpState: (state: StateProps, action: PayloadAction<StateProps>) => {
      state.openPopup = action.payload.openPopup;
      state.beforeOpen = action.payload.beforeOpen;
      state.target = action.payload.target as Fabric.Image;
    },
  },
});

export const {updateImagePopUpState} = imageItemPopUpMenu.actions;
export const imageItemPopUpMenuReducer = imageItemPopUpMenu.reducer;
