import type {MouseEventHandler} from 'react';
import React from 'react';
import './modal-header-action.scss';
import PropTypes from 'prop-types';

interface ModalHeaderActionProps {
  /**
   * Text to show
   */
  label?: string;
  /**
   * Class to give to the icon to render the appropiate icon
   */
  iconClass?: string;
  /**
   * Additional classes to give to the button
   */
  customClasses?: string;
  /**
   * Handler for when the button is clicked
   */
  onClick?: MouseEventHandler;
  /**
   * Title to appear on hover
   */
  title?: string;
}

export function ModalHeaderAction({title = '', label = '', ...props}: ModalHeaderActionProps) {
  const titleVal = title || label;

  return (
    <a onClick={props.onClick} href="#" className={`modal-header-action ${props.customClasses}`} aria-label={label}>
      {props.iconClass && <i className={`icon ${props.iconClass} action-icon`} title={titleVal || undefined} />}
      {label && <span className="action-label">{label}</span>}
    </a>
  );
}
