import type {ReactElement} from 'react';
import React from 'react';
import {PanelHeader} from '@Components/panel';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import type {CustomEventsPanelInnerContentProps} from '@Components/content-planner/content-planner.types';
import styles from './create-event-header.module.scss';

export function CreateEventHeader({isInModal, onClose}: CustomEventsPanelInnerContentProps): ReactElement | null {
  const className = `${styles.container} _no-border-bottom flex-row-justify-between flex-items-center spacing-p-l-5 spacing-p-r-2`;

  return !isInModal ? (
    <div className={className}>
      <Text val={window.i18next.t('pmwjs_new_event')} className="content-heading" size={TextSize.MEDIUM} bold />
      <Icon icon="icon-close" className="content-body" type={IconType.GHOST_UNCOLORED} shape={IconShape.SQUARE} onClick={onClose} size={IconSize.SIZE_ICON_20} />
    </div>
  ) : (
    <PanelHeader title={window.i18next.t('pmwjs_new_event')} onClose={onClose} centerAlignTitle={false} />
  );
}
