import type {LoadingStates} from '@Utils/loading.util';
import type {
  RoyaltyFilters,
  DesignerEarningsData,
  DesignersLazyData,
  DesignerPayouts,
  DesignerRoyalties,
  DesignerTemplateLifetimeEarnings,
  TemplateLifetimeEarningLazyData,
} from '@Libraries/my-stuff/designer-dashboard-library';
import type {Ref} from 'react';

export interface DesignerEarningsReducerState {
  earningsSummaryLoadingState: LoadingStates;
  royaltyFilters: RoyaltyFilters;
  payouts: DesignersLazyData;
  royalties: DesignersLazyData;
  templateLifetimeEarnings: TemplateLifetimeEarningLazyData;
  filteredRoyalties: DesignersLazyData;
  allPayouts: DesignerPayouts;
  allRoyalties: DesignerRoyalties;
  allLifetimeTemplateEarnings: DesignerTemplateLifetimeEarnings;
  designerEarnings?: DesignerEarningsData;
  messageBannersState: MessageBannerStateForEarningType;
}

export enum EarningType {
  PAYOUTS = 'royalty-payouts',
  ROYALTIES = 'designer-royalties',
  TEMPLATE_LIFETIME_EARNINGS = 'template-lifetime-earnings',
}

export enum MessageBannerState {
  VISIBLE,
  HIDING,
  HIDDEN,
}

export type MessageBannerStateForEarningType = {
  [earningType in EarningType]: MessageBannerState;
};

export interface DesignerEarningsReportContainerQuery {
  isSmallScreen?: boolean;
  isMediumScreen?: boolean;
  isLargeScreen?: boolean;
}

export interface DesignerPayoutsFromStore {
  payouts: DesignersLazyData;
  allPayouts: DesignerPayouts;
  isLoading: boolean;
  fetchPayouts: () => void;
}

export interface DesignerRoyaltiesFromStore {
  royalties: DesignersLazyData;
  allRoyalties: DesignerRoyalties;
  isLoading: boolean;
  fetchRoyalties: () => void;
  lastProcessedDate: string;
}

export interface DesignerTemplateEarningsFromStore {
  templateLifetimeEarnings: DesignersLazyData;
  allTemplateLifetimeEarnings: DesignerTemplateLifetimeEarnings;
  isLoading: boolean;
  fetchTemplateEarnings: () => void;
}

export interface ContainerQueryParamsFromHook {
  large?: boolean;
  medium?: boolean;
  small?: boolean;
}
export interface ContainerQueryParams {
  containerQueryParamProps: DesignerEarningsReportContainerQuery;
  containerRef: Ref<HTMLDivElement>;
  hasContainerQueryInitialized: boolean;
}

export interface SetMessageBannerStatePayload {
  earningType: EarningType;
  state: MessageBannerState;
}

export enum LifetimeEarningsTableColumnIds {
  DESIGN_ID = 'designId',
  PUBLISHED_ON = 'publishedOn',
  LIFETIME_EARNING = 'lifetimeEarning',
}

export type SetRoyaltiesReducerPayload = Pick<DesignerEarningsReducerState, 'allRoyalties' | 'royalties'>;
export type SetTemplateLifetimeEarningsReducerPayload = Pick<DesignerEarningsReducerState, 'templateLifetimeEarnings' | 'allLifetimeTemplateEarnings'>;
export type ResetTemplateEarningsReducerPayload = Pick<TemplateLifetimeEarningLazyData, 'sortDirection' | 'sortColumnId'>;
