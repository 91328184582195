import type {ReactElement, ReactNode} from 'react';
import React from 'react';
import type {CustomEventsPanelInnerContentProps} from '@Components/content-planner/content-planner.types';
import {PanelHeader} from '@Components/panel';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import type {EditEventPanelInnerContentProps} from '@Panels/edit-content-planner-event-panel/edit-content-planner-event-panel.types';
import styles from './edit-event-header.module.scss';

interface EditEventHeaderProps extends CustomEventsPanelInnerContentProps, EditEventPanelInnerContentProps {
  onConfirmEdit: () => void;
  onCancelEdit: () => void;
}

export function EditEventHeader({
  isInEditMode,
  isInDeleteMode,
  switchToEditMode,
  switchToDeleteMode,
  onConfirmEdit,
  onCancelEdit,
  isInModal,
  onClose,
}: EditEventHeaderProps): ReactElement {
  const getDefaultModeIcons = (): ReactElement => {
    return (
      <>
        <Icon
          onClick={switchToDeleteMode}
          icon="icon-delete"
          type={IconType.GHOST_UNCOLORED}
          shape={IconShape.SQUARE}
          size={IconSize.SIZE_ICON_20}
          className="spacing-m-r-2 content-body"
        />
        <Icon
          onClick={switchToEditMode}
          icon="icon-pencil"
          type={IconType.GHOST_UNCOLORED}
          shape={IconShape.SQUARE}
          size={IconSize.SIZE_ICON_20}
          className="content-body"
        />
      </>
    );
  };

  const getIconsInEditMode = (): ReactElement => {
    return (
      <>
        <Icon
          onClick={onCancelEdit}
          icon="icon-close"
          type={IconType.GHOST_UNCOLORED}
          shape={IconShape.SQUARE}
          size={IconSize.SIZE_ICON_20}
          className="spacing-m-r-2 content-body"
        />
        <Icon onClick={onConfirmEdit} icon="icon-check" type={IconType.GHOST_UNCOLORED} shape={IconShape.SQUARE} size={IconSize.SIZE_ICON_20} className="content-body" />
      </>
    );
  };

  const getIcons = (): ReactNode => {
    if (isInDeleteMode) {
      return null;
    }

    return (
      <>
        {isInEditMode ? getIconsInEditMode() : getDefaultModeIcons()}
        <span className={`border-s-dark _no-border-right _no-border-top _no-border-bottom spacing-m-r-3 spacing-m-l-3 ${styles.divider}`} />
      </>
    );
  };

  return isInModal ? (
    <PanelHeader title={window.i18next.t('pmwjs_custom_event')} onClose={onClose} centerAlignTitle={false} />
  ) : (
    <div className={`${styles.container} flex-row-align-center flex-content-end spacing-p-1`}>
      <div className="flex-row-align-center">
        {getIcons()}
        <Icon onClick={onClose} className="content-body" icon="icon-close" type={IconType.GHOST_UNCOLORED} shape={IconShape.SQUARE} size={IconSize.SIZE_ICON_20} />
      </div>
    </div>
  );
}
