import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './brand-logos-section.scss';
import '../../brands.scss';
import {Button, Size, Type} from '@Components/button';
import {GridSection} from '../../../grid-section';
import {DottedIconButton} from '../../../dotted-icon-button';
import {BrandLogoGridItem} from '../brand-logo-grid-item';
import {BrandSectionLoader} from '../brand-section-loader';
import {isBrandContentTypeLoading, fetchBrandsResourceIfNotLoaded, isBrandLogoPrimary, getActiveBrandId} from '@Libraries/brands-library';
import {repoImageThumbURL} from '@Libraries/graphic-item-image-library';
import {fetchBrandLogos} from '../../brands-thunk';
import {default as Emitter, EVENTS} from '../../../../services/event';
import {initBrandLogoUpload} from '@Libraries/brands-upload-library';
import {setSelectedLogoInSelectableState} from '@Components/mystuff-brands/brands-reducer';

/**
 * Section responsible for displaying brand Logos
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
class BrandLogosSection extends React.Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.state = {
            areBrandLogosUploading: false,
        };
    }

    async componentDidMount() {
        Emitter.on(EVENTS.BRAND_LOGO_UPLOAD_START, this.#onBrandLogoUploadStart);
        Emitter.on(EVENTS.BRAND_LOGO_UPLOAD_FAIL, this.#onBrandLogoUploadDone);
        Emitter.on(EVENTS.BRAND_LOGO_UPLOAD_SUCCESS, this.#onBrandLogoUploadDone);
        initBrandLogoUpload($('#content .js-upload-brand-logo'));
        await fetchBrandsResourceIfNotLoaded(this.props.loadingState, this.#getBrandLogos);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        await fetchBrandsResourceIfNotLoaded(this.props.loadingState, this.#getBrandLogos);
    }

    componentWillUnmount() {
        Emitter.off(EVENTS.BRAND_LOGO_UPLOAD_START);
        Emitter.off(EVENTS.BRAND_LOGO_UPLOAD_FAIL);
        Emitter.off(EVENTS.BRAND_LOGO_UPLOAD_SUCCESS);
    }

    render() {
        const gridItems = this.#getBrandLogoGridItems();
        return (
            <>
                <div className={'_hidden'}>
                    <input type="file" className="hidden js-upload-brand-logo" name="brandLogoData" accept={'image/*'} aria-label="Upload" ref={this.fileInputRef}/>
                </div>
                {this.#getNonGridAddBtn()}
                <GridSection
                    gridItems={gridItems}
                    sectionHeading={this.#getSectionHeadingText()}
                    headingClasses={'body-xs-bold'}
                    sectionClasses={'brands-section brand-logos -bottom-margin'}
                    gridClasses={gridItems.length === 1 ? '-single-column' : ''}
                    isLoading={isBrandContentTypeLoading(this.props.loadingState)}
                    loaderComponent={<BrandSectionLoader/>}
                    hideHeading={gridItems.length === 0 || this.props.hideHeading}
                />
            </>
        );
    }


    /**
     * @returns {JSX.Element | null}
     */
    #getNonGridAddBtn = () => {
        if (!this.props.showAddButtonOutsideGrid) {
            return null;
        }

        const clickHandler = this.#shouldShowAddBtnInReadOnlyMode() ? this.props.onAddLogo : this.#onUploadBtnClick;
        return <Button type={Type.PRIMARY} onClick={clickHandler} isLoading={this.state.areBrandLogosUploading} size={Size.SMALL} customClasses="spacing-m-b-5" text={i18next.t('pmwjs_add_a_logo')} isFullWidth />
    }

    /**
     *
     * @returns {JSX.Element}
     */
    #getUploadBtnHtml = () => {
        return (
            <li key={'btn-upload'} className={'brand-grid-item -small btn-upload-logo'}>
                <Button type={Type.DASHED} customClasses="_full-width _full-height" icon="icon-plus" iconClassName="size-icon-24" isLoading={this.state.areBrandLogosUploading} onClick={this.#onUploadBtnClick}/>
            </li>
        );
    };

    #getAddLogoButtonMarkup = () => {
        return (
            <li className={'brand-grid-item'} key={'btn-add-logo'}>
                <DottedIconButton btnText={i18next.t('pmwjs_add_a_logo')} btnIcon={'icon-plus'} onClick={this.props.onAddLogo}/>
            </li>
        );
    };

  #getDefaultSelectionBtnHtml = () => {
    return (
        <li key={'btn-default-select'} className={`brand-grid-item btn-upload-logo ${this.props.defaultLogoSelected ? '-selected' : ''}`}>
          <Button
              type={Type.SECONDARY}
              icon={'icon-ban'}
              text={`${window.i18next.t('pmwjs_no_logo')}`}
              customClasses={`btn-default-selection flex ${this.props.defaultLogoSelected ? '-selected' : ''}`}
              textClasses={'default brand-asset-name'}
              iconClassName={'logo-default-selection'}
              onClick={this.#onNoLogoSelect}
              className="flex-column-align-center flex-column-justify-center"
          />
        </li>
    );
  };

    /**
     *
     * @returns {string}
     */
    #getSectionHeadingText = () => {
        if (this.props.sectionHeading) {
            return this.props.sectionHeading;
        }

        return window.i18next.t('pmwjs_logos');
    };

    /**
     *
     * @returns {string}
     */
    #getUploadButtonText = () => {
        return this.state.areBrandLogosUploading ? i18next.t('pmwjs_uploading') : i18next.t('pmwjs_upload_a_logo');
    };

    /**
     *
     * @return {boolean}
     */
    #shouldShowAddBtnInReadOnlyMode = () => {
        return this.props.isReadOnly && this.props.logosIds.length < 1;
    }

    /**
     *
     * @returns {JSX.Element[]}
     */
    #getBrandLogoGridItems = () => {
        let firstGridItem;
        let lastGridItem;

        const logosForBrand = this.props.logosIds.map((idbrandImage) => this.props.logos[idbrandImage]);

        const gridItems = logosForBrand.map((brandLogo) => this.#mapBrandLogoInfoToGridItem(brandLogo));

        if (this.props.isReadOnly) {
            if (this.props.isSelectable) {
                firstGridItem = this.#getDefaultSelectionBtnHtml;
            }

            if (this.props.logosIds.length < 1 && !this.props.showAddButtonOutsideGrid) {
                lastGridItem = this.#getAddLogoButtonMarkup;
            }
        } else if (!this.props.showAddButtonOutsideGrid) {
            firstGridItem = this.#getUploadBtnHtml;
        }

        if (firstGridItem) {
            gridItems.unshift(firstGridItem());
        }

        if (lastGridItem) {
            gridItems.push(lastGridItem());
        }

        return gridItems ?? [];
    };

    #getBrandLogos = async () => {
        await fetchBrandLogos(this.props.brandId);
    };

    /**
     *
     * @param {Object} [brandLogoInfo]
     * @param {number} [brandLogoInfo.idbrandImage]
     * @param {string} [brandLogoInfo.brandId]
     * @param {string} [brandLogoInfo.filename]
     * @param {string} [brandLogoInfo.ext]
     * @param {number} [brandLogoInfo.brandImageType]
     * @returns {JSX.Element}
     */
    #mapBrandLogoInfoToGridItem = (brandLogoInfo) => {
        return (
            <BrandLogoGridItem
                key={brandLogoInfo.idbrandImage}
                logoId={brandLogoInfo.idbrandImage}
                imgURL={repoImageThumbURL(brandLogoInfo.filename, brandLogoInfo.ext)}
                brandId={brandLogoInfo.brandId}
                isPrimary={isBrandLogoPrimary(brandLogoInfo.brandImageType)}
                isReadOnly={this.props.isReadOnly}
                isSelectable={this.props.isSelectable}
                isSelected={this.props.isSelectable && this.props.selectedLogoId === brandLogoInfo.idbrandImage}
                onClick={() => this.#onSelectableLogoClick(brandLogoInfo.idbrandImage)}
            />
        );
    };

    /**
     * handler for when the 'Upload logo' button is clicked
     */
    #onUploadBtnClick = () => {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.click();
        }
    };

    #onSelectableLogoClick = (id) => {
        if (!this.props.isSelectable) {
            return;
        }

        this.props.setSelectedLogo({
            id: id,
            defaultSelected: false,
        });
    };

    #onNoLogoSelect = () => {
        this.props.setSelectedLogo({
            id: -1,
            defaultSelected: true,
        });
    };

    /**
     * event handler for when brand logo upload has started.
     * If brand logo is being uploaded from mobile flow, then don't do anything.
     */
    #onBrandLogoUploadStart = () => {
        if (!this.#isBrandLogoBeingUploadedInMobileFlow()) {
            this.#showBrandLogoUploadingState();
        }
    };

    /**
     * event handler for when brand logo upload operation has completed
     */
    #onBrandLogoUploadDone = () => {
        this.#hideBrandLogoUploadingState();
    };

    /**
     *
     * @returns {boolean}
     */
    #isBrandLogoBeingUploadedInMobileFlow = () => {
        return document.getElementById('empty-brand-mobile-wizard') !== null;
    };

    #showBrandLogoUploadingState = () => {
        this.setState({
            areBrandLogosUploading: true,
        });
    };

    #hideBrandLogoUploadingState = () => {
        this.setState({
            areBrandLogosUploading: false,
        });
    };
}

BrandLogosSection.propTypes = {
    /**
     * the ID of the brand for which logos in this section are being shown
     */
    brandId: PropTypes.string,
    /**
     * the current loading state for the section
     */
    loadingState: PropTypes.number,
    /**
     * the IDs of the logos that need to be displayed for this section
     */
    logosIds: PropTypes.array,
    /**
     * An object containing brand logos for potentially multiple brands
     */
    logos: PropTypes.object,
    /**
     * Disables all actions on click for every grid item
     */
    isReadOnly: PropTypes.bool,
    /**
     * If 'true', makes all grid item selectable and adds 1 extra grid item for default selection
     */
    isSelectable: PropTypes.bool,
    /**
     * Heading for the brands logos section - defaults to 'Brand Logos'
     */
    sectionHeading: PropTypes.string,
    /**
     * Heading for the brands logos section - defaults to 'Brand Logos'
     */
    selectedLogoId: PropTypes.number,
    /**
     * sets the selected logo in the redux store
     */
    setSelectedLogo: PropTypes.func,
    /**
     * An 'Add a Logo' button is  added to the grid if the component is selectable and no colors are
     * present in the grid, this function is executed when that is clicked.
     */
    onAddLogo: PropTypes.func,
    /**
     * whether we want to show the add button separately above the grid instead of it being part of the grid
     */
    showAddButtonOutsideGrid: PropTypes.bool,
    /**
     * whether to hide heading
     */
    hideHeading: PropTypes.bool
};

BrandLogosSection.defaultProps = {
    isReadOnly: false,
    isSelectable: false,
    sectionHeading: '',
    showAddButtonOutsideGrid: false,
    hideHeading: false
};

const mapStateToPropsForBrandLogos = (state) => {
    const activeBrandId = getActiveBrandId(state.brands),
        activeBrandContent = state.brands.brandContent[activeBrandId];

    return {
        brandId: activeBrandId,
        loadingState: activeBrandContent.logos.loaded,
        logosIds: activeBrandContent.logos.ids,
        logos: state.brands.logos,
        selectedLogoId: state.brands.selectableState.selectedLogo.id,
        defaultLogoSelected: state.brands.selectableState.selectedLogo.defaultSelected,
    };
};

const mapDispatchToPropsForBrandLogos = (dispatch) => {
    return {
        setSelectedLogo: (selectedLogo) => dispatch(setSelectedLogoInSelectableState(selectedLogo)),
    };
};

export default connect(mapStateToPropsForBrandLogos, mapDispatchToPropsForBrandLogos)(BrandLogosSection);
