import {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {CellData, DateData, TimeData} from '@/components/table/table.types';

export const USER_SCHEDULE_PANEl_MIN_HEIGHT = 620;
export const USER_SCHEDULE_PANEL_FIXED_CONTENT_HEIGHT = 340;
export const USER_SCHEDULE_PANEL_CELL_HEIGHT = 80;

export const USER_SCHEDULE_MODAL_TABS_ID = 'user-schedule-modal-tabs';

export enum TABS {
  STYLE = 'style',
  DATA = 'data',
}

export type ScheduleDataType = DateData | TimeData | CellData;

export enum LayoutData {
  TABLE_LAYOUT = 'layout-1',
  BAND_LAYOUT_LEFT_ALIGN = 'layout-2',
  BAND_LAYOUT_RIGHT_ALIGN = 'layout-6',
  BAND_LAYOUT_CENTER_ALIGN = 'layout-7',
  SPORTS_LAYOUT = 'layout-3',
  SLANTED_SPORTS_LAYOUT = 'layout-4',
  STRAIGHT_SPORTS_LAYOUT = 'layout-8',
  OFFSET_SPORTS_LAYOUT = 'layout-9',
}

export const FIXED_DATE_LAYOUTS = [LayoutData.SLANTED_SPORTS_LAYOUT, LayoutData.STRAIGHT_SPORTS_LAYOUT, LayoutData.SPORTS_LAYOUT];

export const ALL_SCHEDULE_COLUMNS = [CellType.DATE, CellType.TIME, CellType.TEAM, CellType.VENUE];

export const COLUMNS_DATA = {
  [LayoutData.TABLE_LAYOUT]: [CellType.DATE, CellType.TIME, CellType.TEAM, CellType.VENUE],
  [LayoutData.BAND_LAYOUT_LEFT_ALIGN]: [CellType.DATE, CellType.VENUE],
  [LayoutData.BAND_LAYOUT_RIGHT_ALIGN]: [CellType.DATE, CellType.VENUE],
  [LayoutData.BAND_LAYOUT_CENTER_ALIGN]: [CellType.DATE, CellType.VENUE],
  [LayoutData.SPORTS_LAYOUT]: [CellType.DATE, CellType.TEAM, CellType.VENUE],
  [LayoutData.SLANTED_SPORTS_LAYOUT]: [CellType.DATE, CellType.TIME, CellType.TEAM, CellType.VENUE],
  [LayoutData.STRAIGHT_SPORTS_LAYOUT]: [CellType.DATE, CellType.TIME, CellType.TEAM, CellType.VENUE],
  [LayoutData.OFFSET_SPORTS_LAYOUT]: [CellType.DATE, CellType.VENUE, CellType.TEAM],
};

export interface ScheduleData {
  rows: number;
  columns: number;
  tableData: Array<Array<ScheduleDataType>>;
  columnsData: Array<CellType>;
  layoutStyle: LayoutData;
  highlightedRows: Array<number>;
  unusedData: Array<Array<ScheduleDataType>>;
}
