import type {MutableRefObject, ReactElement} from 'react';
import React from 'react';
import CampaignPerformance from '@Components/email-marketing-campaign-performance/components/campaign-performance/campaign-performance';
import {useSelector} from 'react-redux';
import {DEFAULT_CAMPAIGN_METRIC_PDF_GRAPH_COLORS, getFirstSelectedEmailMarketingCampaign} from '@Libraries/email-marketing-campaigns-library';
import './download-pdf-report-preview-details.scss';
import type {BrandsAppState, SelectableColorHexes} from '@Components/mystuff-brands/brand-kits.types';
import {SELECTABLE_COLOR_EMPTY} from '@Components/mystuff-brands/brand-kits.types';
import {repoImageThumbURL} from '@Libraries/graphic-item-image-library';
import {getBrandName, getBrandsFromStore} from '@Libraries/brands-library';
import {generateIncrementalShadedColors} from '@Utils/color.util';
import {getAssetUrl} from '@Utils/s3.util';
import {formatShortUSDate} from '@Utils/date.util';
import {isUserPremiumPlus} from '@Libraries/user.library';
import type {EmailMarketingCampaign} from '@Components/email-marketing-campaign-performance/email-marketing-campaign-performance.types';
import type {RootState} from '@/store';
import styles from './download-pdf-report-preview.module.scss';

interface DownloadPDFReportPreviewProps {
  pdfRef?: MutableRefObject<any>;
  isPrintable?: boolean;
}

/**
 * Customizable PDF preview generated for email/mailing-list campaign metrics with cuztomizable colors
 * @author Moeed Ahmad <moeeed@250mils.com>
 */
export function DownloadPDFReportPreview({...props}: DownloadPDFReportPreviewProps): ReactElement {
  const brands: BrandsAppState = useSelector((state: RootState) => {
    return state.brands;
  });

  const firstSelectedCampaign = getFirstSelectedEmailMarketingCampaign() as EmailMarketingCampaign;
  const campaignName: string = firstSelectedCampaign.name;
  const publishOnDate: string = firstSelectedCampaign.publishOn;
  const selectedLogo = brands.logos[brands.selectableState.selectedLogo.id];
  const selectedColorIds = brands.selectableState.selectedColors.ids;

  const getColorsForGraphs = (): SelectableColorHexes => {
    if (!selectedColorIds) {
      return DEFAULT_CAMPAIGN_METRIC_PDF_GRAPH_COLORS;
    }

    const colorsForGraphs: SelectableColorHexes = {
      Primary: DEFAULT_CAMPAIGN_METRIC_PDF_GRAPH_COLORS.Primary,
      Secondary: DEFAULT_CAMPAIGN_METRIC_PDF_GRAPH_COLORS.Secondary,
      Tertiary: DEFAULT_CAMPAIGN_METRIC_PDF_GRAPH_COLORS.Tertiary,
    };

    let generatedShades;

    if (selectedColorIds.Primary && selectedColorIds.Primary !== SELECTABLE_COLOR_EMPTY) {
      generatedShades = generateIncrementalShadedColors(`#${brands.colors[selectedColorIds.Primary].hexCode}`);
      [colorsForGraphs.Primary, colorsForGraphs.Secondary, colorsForGraphs.Tertiary] = generatedShades;
    }

    if (selectedColorIds.Secondary && selectedColorIds.Secondary !== SELECTABLE_COLOR_EMPTY) {
      colorsForGraphs.Secondary = `#${brands.colors[selectedColorIds.Secondary]?.hexCode}`;
    }

    if (selectedColorIds.Tertiary && selectedColorIds.Tertiary !== SELECTABLE_COLOR_EMPTY) {
      colorsForGraphs.Tertiary = `#${brands.colors[selectedColorIds.Tertiary]?.hexCode}`;
    } else if (colorsForGraphs.Primary && colorsForGraphs.Secondary) {
      if (generatedShades) {
        [, , colorsForGraphs.Tertiary] = generatedShades;
      } else {
        colorsForGraphs.Tertiary = DEFAULT_CAMPAIGN_METRIC_PDF_GRAPH_COLORS.Tertiary;
      }
    } else {
      colorsForGraphs.Tertiary = DEFAULT_CAMPAIGN_METRIC_PDF_GRAPH_COLORS.Tertiary;
    }

    return colorsForGraphs;
  };

  const getAltTextForLogo = (): string => {
    if (!brands.activeBrandId) return '';

    const brandName = getBrandName(brands.activeBrandId, getBrandsFromStore() as NonNullable<[]>);
    return window.i18next.t('pmwjs_brand_logo_belongs_to_x_brand', {brandName});
  };

  const getReportLogo = (): ReactElement | null => {
    if (!selectedLogo) {
      return null;
    }

    return (
      <div className={styles.logoContainer}>
        <img src={repoImageThumbURL(selectedLogo?.filename, selectedLogo?.ext)} className={styles.logoImage} width={selectedLogo?.width} alt={getAltTextForLogo()} />
      </div>
    );
  };

  const getPMWWatermark = (): ReactElement | null => {
    if (isUserPremiumPlus()) {
      return null;
    }

    return (
      <div className={styles.watermark}>
        <div className={styles.text}>{window.i18next.t('pmwjs_powered_by')}</div>
        <div className={styles.imageContainer}>
          <img src={getAssetUrl('logo-on-white.png')} alt="PosterMyWall" />
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.preview} ${props.isPrintable ? styles.printCopy : ''}`} ref={props.pdfRef}>
      <div className={styles.logoSection}>
        {getReportLogo()}
        <div className="flexbox flex-h-row flex-content-between">
          <div>
            <div className={styles.subtitle}>{window.i18next.t('pmwjs_campaign')}</div>
            <div className={styles.campaignName}>{campaignName}</div>
          </div>
          <div className="text-right">
            <div className={styles.campaignmeta}>
              {window.i18next.t('pmwjs_campaign_sent_on')} <span className={styles.date}>{formatShortUSDate(new Date(publishOnDate))}</span>
            </div>
            <div className={styles.campaignmeta}>
              {window.i18next.t('pmwjs_report_generated_on')} <span className={styles.date}>{formatShortUSDate(new Date())}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.campaignPerformaceContainer} pdf-preview-details ${props.isPrintable ? 'print-copy' : ''}`}>
        <CampaignPerformance disableDetails disableHeader isNonInteractable colorsForGraphs={getColorsForGraphs()} />
      </div>
      {getPMWWatermark()}
    </div>
  );
}
