const READ_BUCKET: string = 'd1csarkz8obe9u.cloudfront.net';
const WRITE_BUCKET_LOCAL: string = 'd3rka4syouiwkp.cloudfront.net';
const WRITE_BUCKET_PROD: string = 'd1csarkz8obe9u.cloudfront.net';
const LOCAL_ASSETS_URL: string = `${window.PMW.BASE_URL}repo/assets/`;
const BETA_TESTING_ASSETS_URL: string = 'https://s3.amazonaws.com/dev.assets.postermywall.com/assets/';
const ASSETS_FOLDER: string = 'assets';

/**
 * The name of the bucket that contains read-only files on S3.
 * @return {string}
 */
export function getReadBucket(): string {
  return READ_BUCKET;
}

/**
 * The name of the bucket that contains writable files on S3.
 * @return {string}
 */
export function getWriteBucket(): string {
  return window.PMW.isLocal() ? WRITE_BUCKET_LOCAL : WRITE_BUCKET_PROD;
}

export function getReadDistributionUrl(uri: string = ''): string {
  return `https://${getReadBucket()}/${uri}`;
}

export const getWriteDistributionUrl = (uri: string = ''): string => {
  return `https://${getWriteBucket()}/${uri}`;
};

/**
 * If a page is currently in progress, will get the asset URL depending on the environment.
 * Else, will use the prod bucket's URL.
 *
 * @param {string} filename
 * @returns {string}
 */
export const getAssetUrl = (filename: string): string => {
  const bodyElement: JQuery = $('body');

  if (doGetAssetsFromLocal(bodyElement)) {
    return `${LOCAL_ASSETS_URL}${filename}`;
  }

  if (doGetAssetsFromBeta(bodyElement)) {
    return `${BETA_TESTING_ASSETS_URL}${filename}`;
  }

  return getReadDistributionUrl(`${ASSETS_FOLDER}/${filename}`);
};

/**
 * Returns true if the environment is local for the work in progress page.
 *
 * @param {JQuery} bodyElement
 * @returns {boolean}
 */
const doGetAssetsFromLocal = (bodyElement: JQuery): boolean => {
  return bodyElement.hasClass('js-assets-local');
};

/**
 * Returns true if the environment is beta for the work in progress page.
 *
 * @param {JQuery} bodyElement
 * @returns {boolean}
 */
const doGetAssetsFromBeta = (bodyElement: JQuery): boolean => {
  return bodyElement.hasClass('js-assets-beta');
};
