import React from 'react';
import type { ButtonProps} from '@Components/button';
import {Button, Type} from '@Components/button';

export interface PanelFooterSecondaryDefaultActionProps extends ButtonProps {
  key: string;
  className?: string;
}

export function PanelFooterSecondaryDefaultAction({className = '', type = Type.SECONDARY, ...props}: PanelFooterSecondaryDefaultActionProps) {
  return <Button {...props} type={type} customClasses={className} />;
}
