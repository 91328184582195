import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './brand-fonts-section.scss';
import {GridSection} from '../../../grid-section';
import {BrandFontGridItem} from '../brand-font-grid-item';
import {BrandSectionLoader} from '../brand-section-loader';
import {
  isBrandContentTypeLoading,
  BRAND_ASSETS,
  fetchBrandsResourceIfNotLoaded,
  showAddOrUpdateFontLoading,
  hideAddOrUpdateFontLoading,
  getActiveBrandId,
} from '@Libraries/brands-library';
import {fetchBrandFonts, getContentForBrandContentType} from '../../brands-thunk';
import {Button, Size, Type} from "@Components/button";

/**
 * Section responsible for displaying all fonts for a particular brand
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
class BrandFontsSection extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    void fetchBrandsResourceIfNotLoaded(this.props.loadingState, this.#getBrandFonts);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    void fetchBrandsResourceIfNotLoaded(this.props.loadingState, this.#getBrandFonts);
  }

  render() {
    const gridItems = this.#getGridItems();

    return (
      <>
        {this.#getNonGridAddBtn()}
        <GridSection
            gridItems={gridItems}
            sectionHeading={this.#getSectionHeadingText()}
            headingClasses={'body-xs-bold'}
            sectionClasses={'brands-section brand-fonts -bottom-margin'}
            gridClasses={gridItems.length === 1 ? '-single-column' : ''}
            isLoading={isBrandContentTypeLoading(this.props.loadingState)}
            loaderComponent={<BrandSectionLoader />}
            hideHeading={gridItems.length === 0 || this.props.hideHeading}
        />
      </>
    );
  }

  /**
   * @returns {JSX.Element | null}
   */
  #getNonGridAddBtn = () => {
    if (!this.props.showAddButtonOutsideGrid) {
      return null;
    }

    return <Button type={Type.PRIMARY} onClick={this.#openAddFontModal} isLoading={isBrandContentTypeLoading(this.props.loadingState)} size={Size.SMALL} customClasses="spacing-m-b-5" text={i18next.t('pmwjs_add_a_font')} isFullWidth />
  }

  #getSectionHeadingText = () => {
    if (this.props.sectionHeading) {
      return this.props.sectionHeading;
    }

    return window.i18next.t('pmwjs_fonts');
  };

  #getAddFontButton = () => {
    return (
      <li key={'btn-add-brand-font'} className={'brand-grid-item -small'}>
        <Button type={Type.DASHED} customClasses="_full-width _full-height" icon="icon-plus" iconClassName="size-icon-24" onClick={this.#openAddFontModal} />
      </li>
    );
  };

  /**
   * @return {Array.<JSX.Element>}
   */
  #getGridItems = () => {
    const gridItems = [];

    if (!this.props.showAddButtonOutsideGrid) {
      gridItems.push(this.#getAddFontButton());
    }

    return gridItems.concat(this.#getFontGridItems());;
  }

  #getFontGridItems = () => {
    const fontsForBrand = this.props.fontIds.map((idbrandFont) => this.props.fonts[idbrandFont]);
    return fontsForBrand.map((brandFont) => this.#mapBrandFontInfoToGridItem(brandFont));
  };

  #getBrandFonts = async () => {
    await fetchBrandFonts(this.props.brandId);
  };

  /**
   * @param {Object} [brandFontInfo]
   * @param {number} [brandFontInfo.idbrandFont]
   * @param {string} [brandFontInfo.brandId]
   * @param {number} [brandFontInfo.idfont]
   * @param {boolean} [brandFontInfo.isInternalFont]
   * @param {number} [brandFontInfo.fontType]
   * @param {string} [brandFontInfo.brandFontName]
   * @param {number} [brandFontInfo.fontSize]
   * @param {string} [brandFontInfo.fontFamily]
   * @param {string} [brandFontInfo.defaultColor]
   * @returns {JSX.Element}
   */
  #mapBrandFontInfoToGridItem = (brandFontInfo) => {
    return (
      <BrandFontGridItem
        key={brandFontInfo.idbrandFont}
        brandId={brandFontInfo.brandId}
        idfont={brandFontInfo.idfont}
        idbrandFont={brandFontInfo.idbrandFont}
        isInternalFont={brandFontInfo.isInternalFont}
        fontType={brandFontInfo.fontType}
        fontName={brandFontInfo.brandFontName}
        fontSize={brandFontInfo.fontSize}
        fontFamily={brandFontInfo.fontFamily}
        defaultColorCode={brandFontInfo.defaultColor}
      />
    );
  };

  #openAddFontModal = () => {
    PMW.openAddBrandFontModal({
      brandId: this.props.brandId,
      beforeBrandFontAddedOrUpdated: showAddOrUpdateFontLoading.bind(null, i18next.t('pmwjs_adding_brand_font')),
      onBrandFontAddedOrUpdated: hideAddOrUpdateFontLoading,
      onBrandFontAddedOrUpdatedFailure: hideAddOrUpdateFontLoading,
    });
  };

  /**
   *
   * @return {boolean}
   */
  #shouldShowAddBtnInReadOnlyMode = () => {
    return this.props.isReadOnly && this.props.fontIds.length < 1;
  }
}

BrandFontsSection.propTypes = {
  /**
   * the ID of the active brand in My Stuff
   */
  brandId: PropTypes.string,
  /**
   * the current loading state for fonts for the currently active My Stuff brand
   */
  loadingState: PropTypes.number,
  /**
   * the IDs of the fonts associated with the currently active brand
   */
  fontIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * all the fonts in the redux store
   */
  fonts: PropTypes.object,
  /**
   * If provided, will overwrite the deafult section heading
   */
  sectionHeading: PropTypes.string,
  /**
   * whether we want to show the add button separately above the grid instead of it being part of the grid
   */
  showAddButtonOutsideGrid: PropTypes.bool,
  /**
   * whether to hide heading
   */
  hideHeading: PropTypes.bool
};

BrandFontsSection.defaultProps = {
  sectionHeading: '',
  showAddButtonOutsideGrid: false,
  hideHeading: false
};

//selfCodeReviewShahrukhLater: what about using connect() for each individual grid item or useSelector and getting the stuffs khud hee in the component? just pass the ID to the component and let connect do its thing
const mapBrandFontsStateToProps = (state) => {
  const activeBrandId = getActiveBrandId(state.brands),
    activeBrandContent = state.brands.brandContent[activeBrandId];

  return {
    brandId: activeBrandId,
    loadingState: activeBrandContent.fonts.loaded,
    fontIds: activeBrandContent.fonts.ids,
    fonts: state.brands.fonts,
  };
};

const mapBrandFontsDispatchToProps = (dispatch) => {
  return {
    getBrandFonts: (brandId) => dispatch(getContentForBrandContentType({brandId: brandId, brandContentType: BRAND_ASSETS.BRAND_FONTS})),
  };
};

export default connect(mapBrandFontsStateToProps, mapBrandFontsDispatchToProps)(BrandFontsSection);
