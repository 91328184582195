/**
 * A generic error handler that opens an error modal with a generic error message and sends the error log to the server.
 * @param error - The error object
 * @param message
 */
export const onAjaxError = <T>(error: T, message?: string): void => {
  window.PMW.openErrorModal({
    message: message ?? window.i18next.t('pmwjs_error_message'),
  });
  console.error(error);
  window.PMW.log(error);
};

export interface AjaxSuccessResponse {
  status: 'success' | 'fail' | 'error';
  data: any;
}

export interface AjaxResponse {
  error?: AjaxError;
  data?: any;
  meta?: {
    requestId: string;
  };
}

export interface AjaxError {
  error: {
    name: string;
    message: string;
    stack: string;
  };
}
