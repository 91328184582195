import React from "react";
import './modal-footer-primary-actions.scss';
import PropTypes from "prop-types";

export default class ModalFooterPrimaryActions extends React.Component {
    render = () => {
        if (this.props.actions.length) {
            return <div className={`primary-actions ${this.props.className}`}>{this.props.actions}</div>;
        }
        return null;
    }
}


ModalFooterPrimaryActions.propTypes = {
    actions: PropTypes.array,
    className: PropTypes.string,
};

ModalFooterPrimaryActions.defaultProps = {
    actions: [],
    className: '',
};
