/**
 * Enums
 */
import type {ReactElement} from 'react';

export enum ALIGNMENT_TYPE {
  TOP_START = 'top-start',
  TOP = 'top',
  TOP_END = 'top-end',
  RIGHT_START = 'right-start',
  RIGHT = 'right',
  RIGHT_END = 'right-end',
  BOTTOM_START = 'bottom-start',
  BOTTOM = 'bottom',
  BOTTOM_END = 'bottom-end',
  LEFT_START = 'left-start',
  LEFT = 'left',
  LEFT_END = 'left-end',
}

export enum DROPDOWN_SIZE {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export enum DROPDOWN_POSITION {
  ABSOLUTE = 'ABSOLUTE',
  /**
   * recommended for modals
   */
  FIXED = 'FIXED',
}

/**
 * Interfaces
 */

export interface BaseDropdownSelectBox extends BaseDropdownSelectBoxProperties {
  icon?: string;
  /**
   * can use svg instead of icon but icon gets preference over it
   */
  svg?: ReactElement;
  text?: string;
  isBold?: boolean;
  textClassName?: string;
}

export interface BaseDropdownSelectBoxProperties {
  id?: string;
  size?: DROPDOWN_SIZE;
  isOpened?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;

  onClick?(id?: string): void;
}

export interface DropdownPopupProperties {
  popupSpacingClass?: string;
  popupClassName?: string;
  popupOffset?: number;
  popUpHasCustomWidth?: boolean;
  popUpHasCustomHeight?: boolean;
  /**
   * please note that this needs to be DROPDOWN_POSITION.FIXED for modals
   */
  position?: DROPDOWN_POSITION;
  leftOffset?: number;
  topOffset?: number;
  maxHeight?: number;
  arrowOffset?: number;
}
