import type {ReactElement} from 'react';
import React, {useState} from 'react';
import {Text, TextSize} from '@Components/text';
import {AuthInput} from '@Components/auth-input';
import {EMAIL_PLACEHOLDER} from '@Libraries/login-signup-library';
import {INPUT_FIELD_TYPE} from '@Components/input-field';
import {Button, Type} from '@Components/button';
import {Checkbox} from '@Components/checkbox';
import {IconSize} from '@Components/icon-v2/icon.types';
import type {VerifyUserEmailStateResponse} from '@Panels/connect-sso-account-panel/connect-sso-account-panel.types';
import {closePanel} from '@Components/panel/panel-reducer';
import {CONNECT_SSO_ACCOUNT_PANEL_ID} from '@Panels/connect-sso-account-panel';
import {useAppDispatch} from '@/hooks';

interface ConnectAccountEmailRequiredFormProps {
  onConnectCallback: (email: string) => void;
  showSubscribeToEmailsCheckbox?: boolean;
}

export function ConnectAccountEmailRequiredForm({onConnectCallback, showSubscribeToEmailsCheckbox = false}: ConnectAccountEmailRequiredFormProps): ReactElement {
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = (): void => {
    if (email.length === 0) {
      setIsEmailInvalid(true);
      return;
    }

    void verifyUserEmailState();
  };

  const verifyUserEmailState = async (): Promise<void> => {
    setIsEmailInvalid(false);
    setIsLoading(true);

    try {
      const response = (await window.PMW.writeLocal('authenticate/verifyUserEmailState', {
        email,
        allowEmailMarketing: isChecked ? 1 : 0,
      })) as VerifyUserEmailStateResponse;

      if (response.state === 'allow') {
        onConnectCallback(email);
        dispatch(closePanel(CONNECT_SSO_ACCOUNT_PANEL_ID));
      } else {
        setIsEmailInvalid(true);
      }
    } catch (e) {
      setIsEmailInvalid(true);
    }

    setIsLoading(false);
  };

  const handleEmailInputChange = React.useCallback((txt: string) => {
    setEmail(txt.trim());
  }, []);

  const getEmailRequiredPrompt = (): ReactElement => {
    return (
      <div className="spacing-m-b-2">
        <Text val={window.i18next.t('pmwjs_enter_email_to_complete_signup')} size={TextSize.SMALL} className="content-body" />
      </div>
    );
  };

  const getEmailInputField = (): ReactElement => {
    return (
      <AuthInput
        label={window.i18next.t('pmwjs_email')}
        placeholder={EMAIL_PLACEHOLDER}
        type={INPUT_FIELD_TYPE.EMAIL}
        handleInput={handleEmailInputChange}
        showError={isEmailInvalid}
        onInputSubmit={onSubmit}
        errorText={window.i18next.t('pmwjs_valid_email_address')}
      />
    );
  };

  const getFormSubmitButton = (): ReactElement => {
    return (
      <div className="spacing-m-t-4">
        <Button text={window.i18next.t('pmwjs_complete_signup_with_space')} type={Type.PRIMARY} onClick={onSubmit} customClasses="_full-width" isLoading={isLoading} />
      </div>
    );
  };

  const onCheckboxClick = (): void => {
    setIsChecked(!isChecked);
  };

  const getFreebieNotificationCheckbox = (): ReactElement | null => {
    return showSubscribeToEmailsCheckbox ? (
      <div className="flex-center spacing-m-t-4">
        <Checkbox className="spacing-m-r-2" isChecked={isChecked} onClick={onCheckboxClick} />
        <Text val={window.i18next.t('pmwjs_notify_freebies_discounts')} size={TextSize.XSMALL} className="_cursor-pointer content-sub-text" onClick={onCheckboxClick} />
      </div>
    ) : null;
  };

  const getEmailRequiredForm = (): ReactElement | null => {
    return (
      <div className="spacing-m-t-6">
        {getEmailInputField()}
        {getFormSubmitButton()}
      </div>
    );
  };

  return (
    <div className="spacing-m-t-9 spacing-m-l-14 spacing-m-r-14 spacing-m-b-14">
      {getEmailRequiredPrompt()}
      {getEmailRequiredForm()}
      {getFreebieNotificationCheckbox()}
    </div>
  );
}
