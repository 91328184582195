import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {PopupMenu} from '@Components/popup-menu';
import {PopupMenuItem, PopupToggleButton} from '@Components/popup-menu/components';
import {PopupMenuItemIconSize, PopupMenuItemTypeInModal} from '@Components/popup-menu/components/popup-menu-item/popup-menu-item';
import {areAllEmailsLoadedForMailingList, downloadCSV, getAudienceUrl, GRID_ID} from '@Libraries/email-marketing-campaigns-audience-library';
import {executeThunk} from '@Utils/thunk.util';
import {deleteMailingList, duplicateMailingList} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-thunk';
import type {GridAudienceItemStorage} from '@Components/email-marketing-campaign-audience/components/audience-grid';
import type {GridItemStorage} from '@Components/base-grid/components/grid-item';
import {GRID_ITEM_TYPE} from '@Components/base-grid/components/grid-item';
import {addItemsToGrid} from '@Components/base-grid';
import type {Audience} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience.types';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {useUserCustomFieldsMetaMap} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience.hooks';
import {useAppDispatch, useAppSelector} from '@/hooks';

interface AudienceGridItemOptionsProps {
  gridItem: GridAudienceItemStorage;
  isSpam?: boolean;
  isInMobileView?: boolean;
}

export function AudienceGridItemOptions({isSpam, gridItem, isInMobileView = false}: AudienceGridItemOptionsProps): ReactElement {
  const mailingListId = parseInt(gridItem.id, 10);
  const dispatch = useAppDispatch();
  const userCustomFieldMetaMap = useUserCustomFieldsMetaMap() ?? {};

  const mailingListData = useAppSelector((state) => {
    return state.emailMarketingCampaignAudience.mailingLists[mailingListId];
  });

  const audiences = useAppSelector((state) => {
    return state.emailMarketingCampaignAudience.audiences;
  });

  const confirmDeletingMailingList = (): void => {
    window.PMW.openConfirmDeleteModal({
      title: window.i18next.t('pmwjs_mailing_list_delete'),
      text: window.i18next.t('pmwjs_audience_delete_confirmation_message', {name: gridItem.name}),
      ctaText: window.i18next.t('pmwjs_delete'),
      onDeleteConfirmation: onDeleteMailingList,
    });
  };

  const onDeleteMailingList = async (): Promise<void> => {
    await executeThunk(() => {
      return dispatch(
        deleteMailingList({
          idMailingList: mailingListId,
        })
      );
    });
  };

  const getDataForDuplicateGridItem = (payload: GridItemStorage): GridAudienceItemStorage => {
    return {
      type: GRID_ITEM_TYPE.EMAIL_CAMPAIGN_AUDIENCE,
      id: payload.id,
      name: gridItem.name,
      audiences: gridItem.audiences,
      numAudiences: gridItem.numAudiences,
      lastViewed: gridItem.lastViewed,
      numNewAudiences: 0,
    };
  };

  const onClickDuplicateMailingList = async (): Promise<void> => {
    await executeThunk(
      () => {
        return dispatch(
          duplicateMailingList({
            idMailingList: gridItem.id,
          })
        );
      },
      (payload: GridItemStorage) => {
        dispatch(
          addItemsToGrid({
            id: GRID_ID,
            items: [getDataForDuplicateGridItem(payload)],
            index: 1,
          })
        );
      }
    );
  };

  const fetchAllAudiences = async (): Promise<Audience[]> => {
    const data = (await window.PMW.readLocal('emailmarketing/getEmailsForAudience', {
      idMailingList: gridItem.id,
    })) as {audiences: Audience[]};

    return data?.audiences ?? [];
  };

  const getAllAudiencesFromState = (): Audience[] => {
    return mailingListData.emailIds.map((emailId: number) => {
      return audiences[emailId];
    });
  };

  const onClickDownloadCSV = async (): Promise<void> => {
    let audienceData: Audience[];

    if (areAllEmailsLoadedForMailingList(mailingListData)) {
      audienceData = getAllAudiencesFromState();
    } else {
      showLoading('fetching-emails');
      audienceData = await fetchAllAudiences();
      hideLoading('fetching-emails');
    }

    downloadCSV(audienceData, userCustomFieldMetaMap, gridItem.name);
  };

  const getItemsForSpam = (): ReactElement => {
    return (
      <Icon
        icon="icon-delete color-danger"
        size={IconSize.SIZE_ICON_16}
        className="spacing-m-l-3"
        type={IconType.GHOST_UNCOLORED}
        shape={IconShape.SQUARE}
        onClick={confirmDeletingMailingList}
      />
    );
  };

  const getItemsForNonSpam = (): ReactElement => {
    return (
      <PopupMenu
        openModalOnPhone
        modalTitle={window.i18next.t('pmwjs_audience_options')}
        menuToggleButton={<PopupToggleButton className={`flexbox ${isInMobileView ? 'spacing-m-l-1' : 'spacing-m-l-3'} -neutral -smaller-icon`} />}
      >
        <PopupMenuItem
          itemClasses="-smaller radius-4 -more-margin-horizontal"
          text={window.i18next.t('pmwjs_edit_item')}
          textColorClasses="content-body"
          textClasses="spacing-m-l-2"
          iconSize={PopupMenuItemIconSize.SMALL}
          iconClasses="icon icon-pencil"
          href={getAudienceUrl(gridItem.id)}
          horizontalAlignStart
          popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START}
        />
        <PopupMenuItem
          itemClasses="-smaller radius-4 -more-margin-horizontal"
          text={window.i18next.t('pmwjs_duplicate')}
          textColorClasses="content-body"
          textClasses="spacing-m-l-2"
          iconSize={PopupMenuItemIconSize.SMALL}
          iconClasses="icon icon-duplicate"
          clickHandler={onClickDuplicateMailingList}
          horizontalAlignStart
          popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START}
        />
        <PopupMenuItem
          itemClasses="-smaller radius-4 -more-margin-horizontal"
          text={window.i18next.t('pmwjs_download_csv')}
          textColorClasses="content-body"
          textClasses="spacing-m-l-2"
          iconSize={PopupMenuItemIconSize.SMALL}
          iconClasses="icon icon-download"
          clickHandler={onClickDownloadCSV}
          horizontalAlignStart
          popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START}
        />
        <PopupMenuItem
          itemClasses="-smaller radius-4 -more-margin-horizontal -danger"
          text={window.i18next.t('pmwjs_delete')}
          textColorClasses="content-body"
          textClasses="spacing-m-l-2"
          iconSize={PopupMenuItemIconSize.SMALL}
          iconClasses="icon icon-delete"
          clickHandler={confirmDeletingMailingList}
          horizontalAlignStart
          popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START}
        />
      </PopupMenu>
    );
  };

  return isSpam ? getItemsForSpam() : getItemsForNonSpam();
}
