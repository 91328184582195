/**
 * This file contains the extra reducers that will be attached to the fontSelector slice
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
import {getPMWFontsVersion, getUserFacingFonts, getUserUploadedFonts} from "./font-selector-thunk.js";
import {alphabeticallySortFonts, setDefaultSelectedFont, setPublicFontsInState} from "@Libraries/font-library.ts";

export const fontSelectorExtraReducers = {
    [getPMWFontsVersion.fulfilled]: (state, {payload}) => {
        state.pmwFontsVersion = payload.version
    },

    [getUserUploadedFonts.fulfilled]: (state, {payload}) => {
        state.userFonts = payload.userFonts.sort(alphabeticallySortFonts);
    },

    [getUserFacingFonts.fulfilled]: (state, {payload}) => {
        setPublicFontsInState(state, payload.pmwTextFonts, payload.fancyTextFonts);
        setDefaultSelectedFont(state, state.pmwFonts);
        state.pmwFontsVersion = payload.version;
    }
}
