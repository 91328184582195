export const getEnumFromValue = <T>(value: string, enumType: T): T[keyof T] | null => {
  const lowercasedValue = value.toLowerCase();
  let enumObject: T[keyof T];

  if (Object.values(enumType as ArrayLike<any>).includes(lowercasedValue)) {
    enumObject = enumType[lowercasedValue.toUpperCase() as keyof T];
    return enumObject;
  }

  return null;
};
