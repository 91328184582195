import React from 'react';
import {connect} from 'react-redux';
import './campaign-performance.scss';
import PropTypes, {bool, string} from 'prop-types';
import {cloneDeep} from 'lodash';
import {EmailCampaignMetricSummary} from '../email-campaign-metric-summary';
import {DEFAULT_CAMPAIGN_METRIC_GRAPH_COLORS, getFirstSelectedItemId, METRIC_TYPES} from '../../../../libraries/email-marketing-campaigns-library';
import {CampaignDeliveryNegativeOutcomeDetails} from '../campaign-delivery-negative-outcome-details';
import {SectionDivider} from '../section-divider';
import {DetailedList} from '../detailed-list';
import EmailMarketingCampaignPerformanceHeader from '../email-marketing-campaign-performance-header/email-marketing-campaign-performance-header.jsx';
import {EmptyState} from '../empty-state';
/**
 * This component shows metrics for a single email marketing campaign.
 * @author Basil <basil@250mils.com>
 */
class CampaignPerformance extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.#isEmptyState() ? <EmptyState /> : this.#getContent();
  }

  #getContent = () => {
    const firstSelectedItemId = getFirstSelectedItemId(this.props.selectedItemIds);
    const metrics = this.props.campaignMetrics[firstSelectedItemId];

    return (
      <section className="metrics-summary-section">
        {this.#getHeader()}
        <div className="metrics-summary-container flexbox flex-items-start">
          <EmailCampaignMetricSummary
            metricType={METRIC_TYPES.DELIVERY}
            campaignId={firstSelectedItemId}
            negativeOutcomeExpandableSection={
              <CampaignDeliveryNegativeOutcomeDetails
                negativeOutcomeDetails={metrics[METRIC_TYPES.DELIVERY].negativeOutcomeDetails}
                onRemoveInvalidEmails={this.#onRemoveInvalidEmails}
              />
            }
            isNonInteractable={this.props.isNonInteractable}
            graphColor={this.props.colorsForGraphs.Primary}
          />
          <SectionDivider containerClass="section-divider" iconClass="content-body" iconName="icon-back" hasDividerLine />
          <EmailCampaignMetricSummary
            metricType={METRIC_TYPES.OPEN}
            campaignId={firstSelectedItemId}
            isNonInteractable={this.props.isNonInteractable}
            graphColor={this.props.colorsForGraphs.Secondary}
          />
          <SectionDivider containerClass="section-divider" iconClass="content-body" iconName="icon-back" hasDividerLine />
          <EmailCampaignMetricSummary
            metricType={METRIC_TYPES.CLICK}
            campaignId={firstSelectedItemId}
            isNonInteractable={this.props.isNonInteractable}
            graphColor={this.props.colorsForGraphs.Tertiary}
          />
        </div>
        {this.#getDetailedList(metrics)}
      </section>
    );
  };

  /**
   * @return {Element | null}
   * */
  #getHeader = () => {
    if (this.props.disableHeader) {
      return null;
    }

    return <EmailMarketingCampaignPerformanceHeader />;
  };

  /**
   * @return {Element | null}
   * */
  #getDetailedList = (metrics) => {
    if (this.props.disableDetails) {
      return null;
    }

    return <DetailedList itemType={this.props.itemType} data={metrics} />;
  };

  #isEmptyState = () => {
    return this.#isFirstCampaignNotSelected() || this.#isDraftCampaignSelected() || this.#isDataBeingCollectedForCampaign() || this.#isNoCampaignCreatedOrPublished();
  };

  #isFirstCampaignNotSelected = () => {
    return getFirstSelectedItemId(this.props.selectedItemIds) === null;
  };

  #isDraftCampaignSelected = () => {
    return this.props.campaigns[getFirstSelectedItemId(this.props.selectedItemIds)].isDraft;
  };

  #isScheduledCampaignSelected = () => {
    return this.props.campaigns[getFirstSelectedItemId(this.props.selectedItemIds)].isScheduled;
  };

  #isDataBeingCollectedForCampaign = () => {
    return this.props.isCollectingData && this.props.campaignsWithMetricsAvailable[getFirstSelectedItemId(this.props.selectedItemIds)] === undefined;
  };

  #isNoCampaignCreatedOrPublished = () => {
    return this.props.isNoCampaignCreated || this.props.isNoCampaignPublished;
  };

  #onRemoveInvalidEmails = () => {
    const firstSelectedItemId = getFirstSelectedItemId(this.props.selectedItemIds);
    PMW.openRemoveInvalidEmailsModal({data: Object.values(this.props.campaignMetrics[firstSelectedItemId].details)});
  };
}

const mapEmailMarketingCampaignPerformanceStateToProps = (state) => {
  return {
    itemType: state.emailMarketingCampaignsPerformance.itemType,
    campaigns: state.emailMarketingCampaignsPerformance.campaigns,
    campaignMetrics: state.emailMarketingCampaignsPerformance.campaignMetrics,
    selectedItemIds: state.emailMarketingCampaignsPerformance.selectedItemIds,
    campaignsWithMetricsAvailable: state.emailMarketingCampaignsPerformance.campaignsWithMetricsAvailable,
    isNoCampaignCreated: state.emailMarketingCampaignsPerformance.isNoCampaignCreated,
    isNoCampaignPublished: state.emailMarketingCampaignsPerformance.isNoCampaignPublished,
    isCollectingData: state.emailMarketingCampaignsPerformance.isCollectingData,
  };
};

CampaignPerformance.propTypes = {
  /**
   * hides the header from the output
   */
  disableHeader: bool,
  /**
   * hides the details list on the bottom from the output
   */
  disableDetails: bool,
  /**
   * provides a read only copy of the output with all details unhidden
   */
  isNonInteractable: bool,
  /**
   * provides a read only copy of the output with all details unhidden
   */
  colorsForGraphs: PropTypes.shape({Primary: string, Secondary: string, Tertiary: string}),
};

CampaignPerformance.defaultProps = {
  disableHeader: false,
  disableDetails: false,
  isNonInteractable: false,
  colorsForGraphs: cloneDeep(DEFAULT_CAMPAIGN_METRIC_GRAPH_COLORS),
};

export default connect(mapEmailMarketingCampaignPerformanceStateToProps, null)(CampaignPerformance);
