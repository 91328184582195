import React from 'react';
import PropTypes from 'prop-types';
import './brand-logo-grid-item.scss';
import {connect} from 'react-redux';
import {BRAND_ASSETS, getBrandName, getBrandsFromStore} from '@Libraries/brands-library';
import {PopupMenuItem, PopupToggleButton} from '../../../popup-menu/components';
import {PopupMenu} from '../../../popup-menu';
import {changePrimaryLogo, deleteBrandAsset} from '../../brands-thunk';
import {executeThunk} from '@Utils/thunk.util';
import {PopupMenuItemIconSize, PopupMenuItemTypeInModal} from "@Components/popup-menu/components/popup-menu-item/popup-menu-item";

/**
 * Single brand logo grid item displayed in brand logos section
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
class BrandLogoGridItem extends React.Component {
    render() {
        return (
            <li
                className={`brand-grid-item brand-logo-grid-item brand-asset -with-background ${this.props.isSelectable ? '-has-cursor' : ''} ${this.props.isSelected ? '-selected' : ''} `}
                onClick={this.props.onClick}
            >
                <img src={this.props.imgURL} alt={this.#getAltTextForLogo()} className={'brand-logo-img hover-transition-all'}/>

                {this.props.isPrimary ? <div className={'primary-label body-xxs-bold'}> {i18next.t('pmwjs_primary')} </div> : null}

                {this.#getPopupMenuMarkup()}
            </li>
        );
    }

    #getAltTextForLogo = () => {
        const brandName = getBrandName(this.props.brandId, getBrandsFromStore());
        return i18next.t('pmwjs_brand_logo_belongs_to_x_brand', {brandName: brandName});
    };

    #getPopupMenuMarkup = () => {
        if (this.props.isReadOnly) {
            return null;
        }

        return (
            <PopupMenu
                openModalOnPhone={true}
                modalTitle={i18next.t('pmwjs_logo_options')}
                menuToggleButton={<PopupToggleButton className={'border-s-dark floating-options-toggle -neutral -smaller-icon'}/>}
            >
                <PopupMenuItem
                    itemClasses="-smaller-height radius-4 -more-margin-horizontal"
                    text={i18next.t('pmwjs_make_primary')}
                    iconClasses={`icon icon-logo popup-menu-item-icon-size-small ${this.props.isPrimary ? 'content-disabled' : ''}`}
                    textColorClasses={this.props.isPrimary ? 'color-extra-light' : 'content-body'}
                    textClasses="spacing-m-l-2"
                    disabled={this.props.isPrimary}
                    clickHandler={this.#makeLogoPrimary}
                    iconSize={PopupMenuItemIconSize.SMALL}
                    horizontalAlignStart
                    popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START}
                />
                <PopupMenuItem itemClasses="-smaller-height -danger radius-4 -more-margin-horizontal" textColorClasses="content-body" text={i18next.t('pmwjs_delete')} iconClasses={'icon icon-delete'} textClasses="spacing-m-l-2"
                               clickHandler={this.#onDeleteBrandLogo} iconSize={PopupMenuItemIconSize.SMALL} horizontalAlignStart popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START}/>
            </PopupMenu>
        );
    };

    #onDeleteBrandLogo = () => {
        PMW.openConfirmDeleteModal({
            title: i18next.t('pmwjs_are_you_sure'),
            text: i18next.t('pmwjs_delete_brand_logo_confirmation_message'),
            onDeleteConfirmation: this.#deleteBrandLogo,
        });
    };

    #deleteBrandLogo = async () => {
        await executeThunk(() => this.props.deleteBrandLogo(this.props.logoId, this.props.brandId));
    };

    #makeLogoPrimary = async () => {
        await executeThunk(() => this.props.changePrimaryLogo(this.props.logoId, this.props.brandId));
    };
}

BrandLogoGridItem.propTypes = {
    /**
     * the URL of the image preview to display
     */
    imgURL: PropTypes.string.isRequired,
    /**
     * the ID of the logo (from DB)
     */
    logoId: PropTypes.number.isRequired,
    /**
     * The id of the brand the logo belongs to
     */
    brandId: PropTypes.string.isRequired,
    /**
     * whether the logo is the primary logo for a brand or not
     */
    isPrimary: PropTypes.bool,
    /**
     * Disables all actions on click for every grid item
     */
    isReadOnly: PropTypes.bool,
    /**
     * changes the cursor to a pointer on hover
     */
    isSelectable: PropTypes.bool,
    /**
     * If 'true', adds a blue outline around the grid item
     */
    isSelected: PropTypes.bool,
    /**
     * onClickHandler for selectable butons
     */
    onClick: PropTypes.func,
};

BrandLogoGridItem.defaultProps = {
    isPrimary: false,
    isReadOnly: false,
    isSelectable: false,
    isSelected: false,
};

const mapLogoGridItemDispatchToProps = (dispatch) => {
    return {
        deleteBrandLogo: (idbrandLogo, brandId) => dispatch(deleteBrandAsset({brandAssetType: BRAND_ASSETS.BRAND_LOGOS, idbrandAsset: idbrandLogo, brandId: brandId})),
        changePrimaryLogo: (idbrandLogo, brandId) => dispatch(changePrimaryLogo({idbrandLogo, brandId})),
    };
};

export default connect(null, mapLogoGridItemDispatchToProps)(BrandLogoGridItem);
