import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {
  ImageAndVideoRecorderStatusStateSetterProps,
  MediaTypeStateSetterProps,
  SelectedCameraStateSetterProps,
} from '@Panels/record-image-and-video-panel/record-image-and-video.types';
import {ImageAndVideoRecorderStatus, RecorderMediaType} from '@Panels/record-image-and-video-panel/record-image-and-video.types';

interface State {
  imageAndVideoRecorderStatus: ImageAndVideoRecorderStatus;
  mediaUrl: string | undefined;
  selectedCamera: string | undefined;
  mediaType: RecorderMediaType;
  permission: boolean;
}

const initialState: State = {
  imageAndVideoRecorderStatus: ImageAndVideoRecorderStatus.LOADING_RECORDER,
  mediaUrl: undefined,
  selectedCamera: undefined,
  mediaType: RecorderMediaType.IMAGE,
  permission: false,
};

export const recordImageAndVideoPanelSlice = createSlice({
  name: 'recordImageAndVideoPanel',
  initialState,
  reducers: {
    initState(state, action: PayloadAction<MediaTypeStateSetterProps>) {
      state.imageAndVideoRecorderStatus = ImageAndVideoRecorderStatus.LOADING_RECORDER;
      state.mediaType = action.payload.mediaType;
      state.mediaUrl = undefined;
      state.selectedCamera = undefined;
    },
    setImageAndVideoRecorderStatus(state, action: PayloadAction<ImageAndVideoRecorderStatusStateSetterProps>) {
      state.imageAndVideoRecorderStatus = action.payload.imageAndVideoRecorderStatus;
    },
    setMediaUrl(state, action: PayloadAction<string | undefined>) {
      state.mediaUrl = action.payload;
    },
    setSelectedCamera(state, action: PayloadAction<SelectedCameraStateSetterProps>) {
      state.selectedCamera = action.payload.selectedCamera;
    },
    setMediaType(state, action: PayloadAction<MediaTypeStateSetterProps>) {
      state.mediaType = action.payload.mediaType;
    },
    setPermission(state, action: PayloadAction<boolean>) {
      state.permission = action.payload;
    },
  },
});

export const {initState, setImageAndVideoRecorderStatus, setMediaUrl, setSelectedCamera, setMediaType, setPermission} = recordImageAndVideoPanelSlice.actions;
export const recordImageAndVideoReducer = recordImageAndVideoPanelSlice.reducer;
