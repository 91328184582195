import type {ActionReducerMapBuilder} from '@reduxjs/toolkit/src/mapBuilders';
import type {NoInfer} from '@reduxjs/toolkit/src/tsHelpers';
import {getSocialMediaAccountsForUser, getSocialMediaPostsForUser} from '@Components/social-media/social-media-thunk';
import type {SocialPost} from '@Components/social-media/post.vo';
import type {
    SocialMediaGridsState} from '@Components/social-media/social-media-reducer';
import {
    updateAccountsHaveLoaded,
    doUpdateDoRefetchGridForNewSorting,
    updateMorePostsOnServer,
    updatePostsHaveLoaded,
    updateSocialAccounts,
    updateSocialPosts,
} from '@Components/social-media/social-media-reducer';
import type {SocialAccount} from '@Components/social-media/account.vo';
import _ from 'lodash';

export interface SocialMediaPostsResponse {
    hasMore: boolean;
    jsonPosts: SocialPost[];
    hasAccounts: boolean;
}

export interface SocialMediaAccountsResponse {
    jsonAccounts: SocialAccount[];
}

export const socialMediaGridExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<SocialMediaGridsState>>): void => {
    builder.addCase(getSocialMediaPostsForUser.rejected, (state) => {
        updatePostsHaveLoaded(state, false);
    });

    builder.addCase(getSocialMediaPostsForUser.fulfilled, (state, action) => {
        const {payload, meta} = action;
        const {getDeleted} = meta.arg;
        if (getDeleted !== state.getDeletedPosts) {
            return;
        }
        const doConcatenate = !state.doRefetchGridForNewSorting;
        doUpdateDoRefetchGridForNewSorting(state, false);
        let updatedPosts: SocialPost[];

        if (doConcatenate) {
            updatedPosts = state.posts.concat(payload.jsonPosts);
        } else {
            updatedPosts = payload.jsonPosts;
        }

        updatePostsHaveLoaded(state, true);
        updateMorePostsOnServer(state, payload.hasMore);
        updateSocialPosts(state, updatedPosts);
    });

    builder.addCase(getSocialMediaAccountsForUser.rejected, (state) => {
        updateAccountsHaveLoaded(state, false);
    });

    builder.addCase(getSocialMediaAccountsForUser.fulfilled, (state, action) => {
        const {payload} = action;
        updateAccountsHaveLoaded(state, true);
        const updatedAccounts: SocialAccount[] = state.accounts.concat(payload.jsonAccounts).reverse();

        const uniqueAccounts = _.uniqWith(updatedAccounts, (a, b): boolean => {
            return a.id === b.id;
        });

        updateSocialAccounts(state, uniqueAccounts);
    });
};
