import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {RGB} from '@Utils/color.util';
import {rgbToHexString} from '@Utils/color.util';
import type * as Fabric from '@postermywall/fabricjs-2';
import {Shadow} from '@postermywall/fabricjs-2';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {DEFAULT_TEXT_SHADOW_BLUR} from '@PosterWhiteboard/items/text-item/text-item.types';

const DEFAULT_SHADOW_DISTANCE = 15;
const DEFAULT_SHADOW_BLUR = 5;

export enum AuraType {
  NONE = 0,
  LIGHT_SHADOW = 1,
  STRONG_SHADOW = 2,
  CUSTOM_SHADOW = 3,
  LIGHT_GLOW = 4,
  STRONG_GLOW = 5,
}

export interface ItemAuraObject {
  type: number;
  dropShadowColor: RGB;
  dropShadowAlpha: number;
  dropShadowAngle: number;
  dropShadowBlur: number;
  dropShadowDistance: number;
  glowColor: RGB;
}

export interface ItemShadowProperties {
  type: AuraType;
  dropShadowAlpha?: number;
  dropShadowAngle?: number;
  dropShadowColor?: RGB;
  dropShadowDistance?: number;
  dropShadowBlur?: number;
}

export class ItemAura extends CommonMethods {
  public type: AuraType = AuraType.NONE;
  public glowColor: RGB = [255, 255, 255];

  public dropShadowColor: RGB = [0, 0, 0];
  public dropShadowDistance;
  public dropShadowBlur;
  public dropShadowAlpha = 0.25;
  public dropShadowAngle = 45;

  public constructor(defaultDistance = DEFAULT_SHADOW_DISTANCE, defaultBlur = DEFAULT_SHADOW_BLUR) {
    super();
    this.dropShadowDistance = defaultDistance;
    this.dropShadowBlur = defaultBlur;
  }

  public toObject(): ItemAuraObject {
    return {
      type: this.type,
      dropShadowColor: this.dropShadowColor,
      dropShadowAlpha: this.dropShadowAlpha,
      dropShadowAngle: this.dropShadowAngle,
      dropShadowBlur: this.dropShadowBlur,
      dropShadowDistance: this.dropShadowDistance,
      glowColor: this.glowColor,
    };
  }

  public getItemAura(scaleShadow: number): Fabric.Shadow | null {
    if (this.isShadow()) {
      const colorHex = rgbToHexString(this.dropShadowColor, this.dropShadowAlpha);
      const dx = Math.round(this.dropShadowDistance * Math.cos((this.dropShadowAngle * Math.PI) / 180));
      const dy = Math.round(this.dropShadowDistance * Math.sin((this.dropShadowAngle * Math.PI) / 180));

      return new Shadow({
        color: colorHex,
        blur: this.dropShadowBlur * scaleShadow,
        offsetX: dx * scaleShadow,
        offsetY: dy * scaleShadow,
        affectStroke: true,
      });
    }

    if (this.isGlow()) {
      const alpha = this.type === AuraType.LIGHT_GLOW ? 0.8 : 1;
      const defaultGlowVal = this.type === AuraType.LIGHT_GLOW ? 8 : 10;
      const co = rgbToHexString(this.glowColor, alpha);
      return new Shadow(`0px 0px ${defaultGlowVal}px ${co}`);
    }

    return null;
  }

  public isShadow(): boolean {
    return this.type === AuraType.LIGHT_SHADOW || this.type === AuraType.STRONG_SHADOW || this.type === AuraType.CUSTOM_SHADOW;
  }

  public isGlow(): boolean {
    return this.type === AuraType.LIGHT_GLOW || this.type === AuraType.STRONG_GLOW;
  }

  public hasAura(): boolean {
    return this.type !== AuraType.NONE;
  }

  public isCustomShadow(): boolean {
    return this.type === AuraType.CUSTOM_SHADOW;
  }
}

export const isAuraTypeGlow = (auraType: AuraType): boolean => {
  return auraType === AuraType.LIGHT_GLOW || auraType === AuraType.STRONG_GLOW;
};

export const getShadowObjectFromAuraType = (type: AuraType, itemType: ITEM_TYPE): ItemShadowProperties => {
  const defaultBlur = getDefaultBlurForItemType(itemType);
  switch (type) {
    case AuraType.CUSTOM_SHADOW:
      return {
        type: AuraType.CUSTOM_SHADOW,
        dropShadowAlpha: 0.5,
        dropShadowAngle: 45,
      };
    case AuraType.STRONG_SHADOW:
      return {
        type: AuraType.STRONG_SHADOW,
        dropShadowAlpha: 0.5,
        dropShadowAngle: 45,
        dropShadowBlur: defaultBlur,
        dropShadowColor: [0, 0, 0],
      };
    case AuraType.LIGHT_SHADOW:
      return {
        type: AuraType.LIGHT_SHADOW,
        dropShadowAlpha: 0.25,
        dropShadowAngle: 45,
        dropShadowBlur: defaultBlur,
        dropShadowColor: [0, 0, 0],
      };
    case AuraType.NONE:
      return {
        type: AuraType.NONE,
      };
    default:
      throw new Error('This function only supports shadow aura types.');
  }
};

export const getDefaultBlurForItemType = (itemType: ITEM_TYPE): number => {
  switch (itemType) {
    case ITEM_TYPE.TEXT:
    case ITEM_TYPE.TEXTSLIDE:
      return DEFAULT_TEXT_SHADOW_BLUR;
    default:
      return DEFAULT_SHADOW_BLUR;
  }
};
