import type {ForwardedRef, ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import {getDateMonthDayFormat} from '@Utils/date.util';
import {ClickableDiv} from '@Components/clickable-div';
import styles from './content-planner-date-time-tile.module.scss';

export interface ContentPlannerDateTimeTileProps {
  date: Date;
  onClick: () => void;
  showEditIcon?: boolean;
  className?: string;
  btnSpacingClassName?: string;
  showHover?: boolean;
  showExtraSmallText?: boolean;
}

function ContentPlannerDateTimeTile(
  {date, onClick, showEditIcon = true, className = '', btnSpacingClassName = '', showHover = false, showExtraSmallText = false}: ContentPlannerDateTimeTileProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const buttonSpacingClass = btnSpacingClassName !== '' ? btnSpacingClassName : 'spacing-p-2 spacing-m-r-5';
  const btnClasses = `${styles.btn} radius-4 flex-row-align-center ${buttonSpacingClass} ${showHover ? styles.hasHover : ''} flex-1`;

  return (
    <ClickableDiv onClick={onClick} ref={ref} className={`flex-row-justify-between ${className} ${!showEditIcon ? '_fit-width' : ''}`}>
      <button type="button" className={btnClasses}>
        <Icon icon="icon-calendar" size={IconSize.SIZE_ICON_20} className="spacing-m-r-2 content-body" />
        <Text val={getDateMonthDayFormat(date)} className={`content-body ${styles.dateText}`} size={showExtraSmallText ? TextSize.XSMALL : TextSize.SMALL} />
      </button>

      {showEditIcon ? <Icon className="content-heading" icon="icon-pencil" size={IconSize.SIZE_ICON_20} type={IconType.GHOST_UNCOLORED} shape={IconShape.SQUARE} /> : null}
    </ClickableDiv>
  );
}

export default React.forwardRef(ContentPlannerDateTimeTile);
