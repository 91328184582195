import type {ReactElement} from 'react';
import React from 'react';
import type {GRID_ITEM_TYPE, GridItemBaseStorage, GridItemProps} from '@Components/base-grid/components/grid-item';
import {GridItem} from '@Components/base-grid/components/grid-item';
import type {ButtonProps} from '@Components/button';
import {Button} from '@Components/button';

export interface GridGenericButtonItemStorage extends GridItemBaseStorage {
  buttonProps: Omit<ButtonProps, 'children'>;
  width?: number;
  height?: number;
  type: GRID_ITEM_TYPE.GENERIC_BUTTON;
}

interface GridGenericButtonItemProps extends GridItemProps {
  buttonProps: Omit<ButtonProps, 'children'>;
  width?: number;
  height?: number;
}

export function GridGenericButtonItem({...props}: GridGenericButtonItemProps): ReactElement {
  return (
    <GridItem {...props} onClicked={props.onClicked} className={`flex-center grid-item ${props.containerClassName ?? ''}`} showHoverOverlay={false}>
      <Button {...props.buttonProps} />
    </GridItem>
  );
}
