import React from 'react';
import PropTypes from 'prop-types';
import './campaign-metric-graph.scss';

/**
 * A simple bar chart that shows the positive and negative outcome of an email campaign metric
 * @author Basil <basil@250mils.com>
 */
class CampaignMetricGraph extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={'metric-graph-container'}>
        <div className={'metric-graph flexbox -' + this.props.metricType}>
          <div
            style={{backgroundColor: `${this.props.graphColor}`}}
            className={'metric-outcome-bar ' + this.#getMetricOutcomeBarHeightClass(this.props.positiveOutcomePercentage)}
          />
          <div className={'metric-outcome-bar ' + this.#getMetricOutcomeBarHeightClass(this.props.negativeOutcomePercentage)} />
        </div>
        <div className={'metric-graph-labels flexbox spacing-p-3'}>
          <span className={this.props.hasVerticalLabels ? 'flex-v-row flex-column-justify-center text-center flex-column-align-center' : ''}>
            <strong className={this.props.hasVerticalLabels ? '_d-block' : ''}>
              {this.props.positiveOutcomeLabel} {this.props.hasVerticalLabels ? '' : '·'}{' '}
            </strong>
            <span className={this.props.hasVerticalLabels ? '_d-block' : ''}>{this.props.positiveOutcomePercentage}%</span>
          </span>
          <span className={this.props.hasVerticalLabels ? 'flex-v-row flex-column-justify-center text-center flex-column-align-center' : ''}>
            <strong className={this.props.hasVerticalLabels ? '_d-block' : ''}>
              {this.props.negativeOutcomeLabel} {this.props.hasVerticalLabels ? '' : '·'}{' '}
            </strong>
            <span className={this.props.hasVerticalLabels ? '_d-block' : ''}>{this.props.negativeOutcomePercentage}%</span>
          </span>
        </div>
      </div>
    );
  }

  /**
   * @param {number} outcomePercentage
   * @return {string}
   */
  #getMetricOutcomeBarHeightClass = (outcomePercentage) => {
    if (outcomePercentage === 0) {
      return '-zero';
    } else if (outcomePercentage <= 10) {
      return '-ten';
    } else if (outcomePercentage <= 20) {
      return '-twenty';
    } else if (outcomePercentage <= 30) {
      return '-thirty';
    } else if (outcomePercentage <= 40) {
      return '-forty';
    } else if (outcomePercentage <= 50) {
      return '-fifty';
    } else if (outcomePercentage <= 60) {
      return '-sixty';
    } else if (outcomePercentage <= 70) {
      return '-seventy';
    } else if (outcomePercentage <= 80) {
      return '-eighty';
    } else if (outcomePercentage <= 90) {
      return '-ninety';
    } else {
      return '-hundred';
    }
  };
}

CampaignMetricGraph.propTypes = {
  /**
   * The type of metric for which this graph is to be rendered
   */
  metricType: PropTypes.string.isRequired,

  /**
   * Label for the positive outcome of this metric
   */
  positiveOutcomeLabel: PropTypes.string.isRequired,

  /**
   * The count of the positive outcome of this metric, expressed as a percentage of the total count for all outcomes of this metric
   */
  positiveOutcomePercentage: PropTypes.number.isRequired,

  /**
   * Label for the negative outcome of this metric
   */
  negativeOutcomeLabel: PropTypes.string.isRequired,

  /**
   * The count of the negative outcome of this metric, expressed as a percentage of the total count for all outcomes of this metric
   */
  negativeOutcomePercentage: PropTypes.number.isRequired,

  /**
   * If set true, changes the graph caption to vertical column instead of horizontal
   */
  hasVerticalLabels: PropTypes.bool,
  /**
   * Sets the color of the first (left-side) bar in the graph
   */
  graphColor: PropTypes.string,
};

CampaignMetricGraph.defaultProps = {
  hasVerticalLabels: false,
};

export default CampaignMetricGraph;
