import type {ReactElement} from 'react';
import React from 'react';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import styles from './email-campaign-dropdown-item.module.scss';

export interface EmailCampaignDropdownItemProps {
  item: string;
  isSelected: boolean;
}

export function EmailCampaignDropdownItem({item, isSelected}: EmailCampaignDropdownItemProps): ReactElement {
  return (
      <div className="_full-width flex-row-align-center flex-justify-between">
        <Text val={item} className={`${styles.emailMetricsDropdownText} `} size={TextSize.XSMALL} bold={isSelected} />
        {isSelected ? <Icon icon="icon icon-check" size={IconSize.SIZE_ICON_16} className="dropdown-icon" />: null}
      </div>
  );
}
