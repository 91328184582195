import type {ReactElement} from 'react';
import React from 'react';
import {findGridItem, SIGNUP_GRID_ID} from '@Libraries/email-signup-form-library';
import {EmailSignupFormGridItemPreview} from '@Components/base-grid/components/grid-email-signup-form-item/components/email-signup-form-grid-item-preview';
import {EmailSignupFormGridItemFooter} from '@Components/base-grid/components/grid-email-signup-form-item/components/email-signup-form-grid-item-footer';
import styles from './grid-email-signup-form-item.module.scss';
import {useAppSelector} from '@/hooks';
import {MobileSignupFormGridItem} from "@Components/base-grid/components/grid-email-signup-form-item/components/mobile-signup-form-grid-item";

interface GridEmailSignupFormItemProps {
  id: string;
}

export interface GridEmailSignupFormData {
  name: string;
  id: string;
  imageUrl: string;
  subscribedEmailsCount: number;
  areNotificationsEnabled: boolean;
}

export function GridEmailSignupFormItem({...props}: GridEmailSignupFormItemProps): ReactElement {
  const grid = useAppSelector((state) => {
    return state.grids.gridsHashmap[SIGNUP_GRID_ID];
  });
  const gridItem = findGridItem(grid, props.id);

  return (
    <li className="flexbox _unpadded">
      <div className={`_full-width signup-form-grid-item ${styles.signupFormGridItem} flex-v-row hidden-phone`}>
        <EmailSignupFormGridItemPreview gridItem={gridItem} />
        <EmailSignupFormGridItemFooter gridItem={gridItem} />
      </div>
      <div className="_full-width visible-phone flexbox">
        <MobileSignupFormGridItem gridItem={gridItem} />
      </div>
    </li>
  );
}
