import type {ForwardedRef, HTMLAttributes, KeyboardEvent, MouseEvent, MouseEventHandler, ReactElement, ReactNode} from 'react';
import React from 'react';
import {EventKeys} from '@Utils/dom.util';
import type {ElementTestId} from '@Tests/tests.types';
import styles from './clickable-div.module.scss';

interface ClickableDivProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  onClick: MouseEventHandler<HTMLDivElement>;
  enableFocusOutline?: boolean;
  'data-testid'?: ElementTestId;
}

function ClickableDiv({children, onClick, enableFocusOutline = true, className = '', ...props}: ClickableDivProps, ref: ForwardedRef<HTMLDivElement>): ReactElement {
  const onKeyUp = (e: KeyboardEvent): void => {
    if (e.key === EventKeys.ENTER_KEY) {
      onClick(e as unknown as MouseEvent<HTMLDivElement>);
    }
  };

  return (
    <div
      className={`${className} ${enableFocusOutline ? '' : styles.disableFocusOutline}`}
      role="button"
      tabIndex={0}
      {...props}
      onClick={onClick}
      onKeyUp={onKeyUp}
      ref={ref}
      data-testid={props['data-testid']}
    >
      {children}
    </div>
  );
}

export default React.forwardRef(ClickableDiv);
