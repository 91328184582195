import React from 'react';
import {IconSize} from '@Components/icon-v2/icon.types';
import styles from './auth-input-error-notification.module.scss';
import {Icon} from '../../../icon-v2';
import {Text, TextSize} from '../../../text';

interface AuthInputErrorNotificationProps {
  errorText: string;
}

function AuthInputErrorNotification({errorText}: AuthInputErrorNotificationProps) {
  return (
    <div className={`flexbox flex-items-center content-danger ${styles.errorMessage}`}>
      <Icon icon="icon-exclamation-triangle" size={IconSize.SIZE_ICON_16} />
      <div className="spacing-m-l-1">
        <Text size={TextSize.XXSMALL} val={errorText} />
      </div>
    </div>
  );
}

export default React.memo(AuthInputErrorNotification);
