import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {StockGetterOptions} from '@Libraries/pmw-stock-media-library';

interface StateProps {
  areFiltersVisible: boolean;
  stockGetterOptions: StockGetterOptions;
  isRecentStockAvailable: boolean;
}

const initialState: StateProps = {
  areFiltersVisible: false,
  stockGetterOptions: {},
  isRecentStockAvailable: false,
};

export const stockPanelSlice = createSlice({
  name: 'stockPanel',
  initialState,
  reducers: {
    toggleFiltersVisibility(state) {
      state.areFiltersVisible = !state.areFiltersVisible;
    },
    setStockGetterOptions(state, action: PayloadAction<StockGetterOptions>) {
      state.stockGetterOptions = {...action.payload};
    },
    setRecentStockAvailability(state, action: PayloadAction<boolean>) {
      if (state.isRecentStockAvailable !== action.payload) {
        state.isRecentStockAvailable = action.payload;
      }
    },
  },
});

export const {toggleFiltersVisibility, setStockGetterOptions, setRecentStockAvailability} = stockPanelSlice.actions;
export const stockReducer = stockPanelSlice.reducer;
