export interface RawAudience {
  email: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  birthday?: Date | string | object;
  hasYear?: boolean;
  customFields?: AudienceCustomFieldData;

  // backwards compatibility for full name
  fullName?: string;
}

export type RawAudienceFieldNames = keyof RawAudience;

export enum AudienceEmailState {
  EMAIL_STATE_VALID = 0,
  EMAIL_STATE_INVALID = 1,
  EMAIL_STATE_UNSUBSCRIBED = 3,
  EMAIL_STATE_COMPLAINT = 6,
  EMAIL_STATE_BOUNCE = 7,
}

export interface Audience extends RawAudience {
  id: number;
  idMailingList: number;
  state: AudienceEmailState;
  displayableBounceReason: string;
  subscribedOn?: number;
}

export type AudienceCustomFieldValue = string;

export interface AudienceCustomFieldData {
  [idUserHasCustomField: number]: AudienceCustomFieldValue;
}

export interface UserCustomFieldMeta {
  idUserHasCustomField: number;
  label: string;
  order: number;
  format?: string;
}

export interface UserCustomFieldMetaMap {
  [idUserHasCustomField: string]: UserCustomFieldMeta;
}
