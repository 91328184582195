import React, {useMemo} from "react";
import PropTypes from 'prop-types';
import './progress-bar-stop.scss';
import {ProgressBarStopLabel} from "../progress-bar-stop-label";


/**
 * One individual 'stop' in the progress bar displayed as a circle inside the progress bar
 * Labels can also be optionally provided to the stop to show what this stop is associated with
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
const ProgressBarStop = ({label, isStepComplete, haveAllStepsCompleted, classes}) => {

    const getCompletedStateClass = () => {
        if (haveAllStepsCompleted) {
            return '-completed';
        }

        if (isStepComplete) {
            return '-filled';
        }

        return '';
    }

    const getLabelColorClass = () => {
        if (haveAllStepsCompleted) {
            return 'color-success';
        }

        if (isStepComplete) {
            return 'content-link';
        }

        return 'content-disabled';
    }

    const completedStateClass = useMemo(getCompletedStateClass, [isStepComplete, haveAllStepsCompleted]),
        labelColorClass = useMemo(getLabelColorClass, [isStepComplete, haveAllStepsCompleted]);

    return (
        <div className={`progress-bar-stop flex-v-row ${completedStateClass} ${classes} js-progress-bar-stop`} >
            <span className={`progress-bar-stop-circle`} />
            {label ? <ProgressBarStopLabel label={label} colorClass={labelColorClass} /> : null}
        </div>
    )
}

ProgressBarStop.propTypes = {
    /**
     * any lavel to associate with the stop
     */
    label: PropTypes.string,
    /**
     * whether the stop is complete or not
     */
    isStepComplete: PropTypes.bool,

    /**
     * whether all the steps have been completed in which case, apply other classes to reflect on the UI
     */
    haveAllStepsCompleted: PropTypes.bool,

    /**
     * extra classes to give
     */
    classes: PropTypes.string
}

ProgressBarStop.defaultProps = {
    classes: ''
}

export default React.memo(ProgressBarStop);