import type {ReactElement} from 'react';
import React, { useState} from 'react';
import type {GridItemProps} from '../grid-item';
import type { GridMediaItemStorage} from '../grid-media-item';
import {GridMediaItem} from '../grid-media-item';

export interface GridShapeItemStorage extends GridMediaItemStorage {
  hashedFilename: string;
}

export interface GridShapeItemBackEndData {
  id: string;
  isHidden: boolean;
}

interface GridShapeItemProps extends GridItemProps {
  thumbSrc: string;
}

export function GridShapeItem({...props}: GridShapeItemProps): ReactElement {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <GridMediaItem
      {...props}
      isImageLoaded={isImageLoaded}
      thumb={
        <img
          onLoad={(): void => {
            return setIsImageLoaded(true);
          }}
          src={props.thumbSrc}
          className="radius-4"
          alt={window.i18next.t('pmwjs_image')}
          draggable="false"
          loading="lazy"
        />
      }
    />
  );
}
