import type {ReactElement} from 'react';
import React, { useState} from 'react';
import './calendar-date-time-picker.module.scss';
import {noop} from '@Utils/general.util';
import {CalendarDatePicker} from '../calendar-date-picker';
import {CalendarTimePicker} from '../calendar-time-picker';
import type {CalendarBase, CalendarItemBaseStorage} from './calendar.types';

/**
 * @author < Haseeb Chaudhry haseebch250@mils.com >
 */
const DEFAULT_MINUTES_VALUE = 0;
const TWELVE_HOUR = 12;

export interface CalendarDateTimePickerProps extends CalendarBase {
  onSubmit?: (submittedDate: Date) => void;
  prefilledDate?: Date;
  timePickerCtaText?: string;
  skipDateSelector?: boolean;
  onTimeChange?: (newTime: Date) => void;
  onDateChange?: (newTime: Date) => void;
  showScheduleButton?: boolean;
  customControl?: boolean;
}

export function CalendarDateTimePicker({
  onSubmit = noop,
  minDate = new Date(),
  timePickerCtaText = window.i18next.t('pmwjs_schedule'),
  prefilledDate = new Date(),
  skipDateSelector = false,
  className = '',
  showBoxShadow = true,
  isFullWidth = false,
  onTimeChange = noop,
  onDateChange = noop,
  showScheduleButton = true,
  customControl = false,
}: CalendarDateTimePickerProps): ReactElement | null {
  if (!prefilledDate) {
    // eslint-disable-next-line no-param-reassign
    prefilledDate = new Date();
  }

  const [showTimePicker, setShowTimePicker] = useState(skipDateSelector);
  const [date, setDateState] = useState(prefilledDate);

  const hourValue = (date?.getHours() ?? 0) + 1;

  const getHourValue = (): number => {
    if (hourValue > TWELVE_HOUR) {
      return hourValue - 12;
    }
    return hourValue;
  };

  const [time, setTime] = useState<CalendarItemBaseStorage>({
    hours: getHourValue(),
    minutes: DEFAULT_MINUTES_VALUE,
    isPMChecked: hourValue >= TWELVE_HOUR,
  });

  const changeDate = (newDate: Date): void => {
    setDateState(newDate);
    onDateChange(newDate);
  };

  const onSubmitDate = (newDate: Date): void => {
    changeDate(newDate);

    if (!customControl) {
      setShowTimePicker(true);
    }
  };

  const onBackFromTime = (newTime: CalendarItemBaseStorage): void => {
    if (!customControl) {
      setShowTimePicker(false);
    }

    setTime(newTime);
  };

  const incrementDate = (): void => {
    const newDateState = new Date(date);
    newDateState.setDate(date.getDate() + 1);
    setDateState(newDateState);
  };

  const decrementDate = (): void => {
    const newDateState = new Date(date);
    newDateState.setDate(date.getDate() - 1);
    setDateState(newDateState);
  };

  const getHours = (timeValue: CalendarItemBaseStorage): number => {
    let {hours} = timeValue;

    if (hours === 12) {
      hours = 0;
    }

    if (timeValue.isPMChecked) {
      hours += 12;
    }

    return hours;
  };

  const onChangeDateTime = (timeValue: CalendarItemBaseStorage): void => {
    const submittedDate = new Date(date);
    const hours = getHours(timeValue);
    submittedDate.setHours(hours, timeValue.minutes);
    onTimeChange(submittedDate);
  };

  const onSubmitDateTime = (timeValue: CalendarItemBaseStorage): void => {
    const submittedDate = new Date(date);
    const hours = getHours(timeValue);
    submittedDate.setHours(hours, timeValue.minutes);
    onSubmit(submittedDate);
  };

  return showTimePicker || (customControl && skipDateSelector) ? (
    <CalendarTimePicker
      hours={time.hours}
      minutes={0}
      isPMChecked={time.isPMChecked}
      onBackCalendarView={onBackFromTime}
      dateState={date}
      incrementDate={incrementDate}
      decrementDate={decrementDate}
      onSubmitButton={onSubmitDateTime}
      minDate={minDate}
      submitButtonText={timePickerCtaText}
      className={className}
      showBoxShadow={showBoxShadow}
      isFullWidth={isFullWidth}
      onChangeTime={onChangeDateTime}
      showScheduleButton={showScheduleButton}
    />
  ) : (
    <CalendarDatePicker
      onDateSelected={onSubmitDate}
      selectedDate={date}
      onDateChange={changeDate}
      minDate={minDate}
      className={className}
      showBoxShadow={showBoxShadow}
      isFullWidth={isFullWidth}
    />
  );
}
