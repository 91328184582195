import React from 'react';
import PropTypes from 'prop-types';
import './dropdown-item.scss';

export const DROPDOWN_ITEM_VARIANTS = {
    DEFAULT: '-default'
};

/**
 * Dropdown Item wrapper component that wraps a list item's content inside the dropdown menu
 * @author Muhammad Shahrukh
 */
class DropdownItem extends React.Component {

    render() {
        return (
            <li className={`${this.props.dropdownItemClasses} dropdown-item ${this.props.isSelected ? 'js-selected selected' : ''} ${this.props.disabled ? '-disabled' : ''} ${this.props.isLink ? '-has-link' : ''} ${this.props.enableShowMore ? this.#getShowMoreClasses() : ''} `}
                onClick={this.props.onDropdownItemClick} data-value={this.props.dataValue} title={this.props.title}>
                {this.#buildDropdownItemChild()}
            </li>
        );
    }

    #getShowMoreClasses = () => {
        if (this.props.currentIndex === this.props.totalItems - 1) {
            return `show-more`;
        }
        return ``;
    }

    /**
     * Builds the dropdown item children depending on whether it's a link or a regular item
     * @returns {JSX.Element|*}
     */
    #buildDropdownItemChild = () => {
        if (this.props.isLink) {
            return (
                <a className={'dropdown-anchor'} href={this.props.href}>
                    {this.props.label}
                    <span className={`dropdown-notification ${(!this.props.showNotification || this.props.isSelected) ? 'hidden' : ''}`}></span>
                </a>
            );
        } else {
            return this.props.label;
        }
    }
}

DropdownItem.propTypes = {
    /**
     * the value assigned to the data-value attribute given to each list item
     */
    dataValue: PropTypes.any.isRequired,

    /**
     * The dropdown item's text/label
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /**
     * boolean determining whether the item is currently selected or not
     */
    isSelected: PropTypes.bool.isRequired,

    /**
     * click handler for when a dropdown item gets clicked
     */
    onDropdownItemClick: PropTypes.func.isRequired,

    /**
     * any custom classes to give to the dropdown item
     */
    dropdownItemClasses: PropTypes.string,

    /**
     * optional prop to pass if the item is a link
     */
    isLink: PropTypes.bool,
    /**
     * the url associated with the item if it is a link
     */
    href: PropTypes.string,
    /**
     * whether the item is disabled or not
     */
    disabled: PropTypes.bool,
    /**
     * the title attribute for this dropdown item
     */
    title: PropTypes.string,
    /**
     * Flag whether to show a notification or not
     */
    showNotification: PropTypes.bool
}

DropdownItem.defaultProps = {
    isSelected: false,
    dropdownItemClasses: '',
    dataValue: '',
    label: '',
    isLink: false,
    href: '',
    disabled: false,
    title: '',
    showNotification: false,
    enableShowMore: false,
}


export default DropdownItem;