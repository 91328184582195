/**
 * Returns the stack trace to this function call
 * @return {string}
 */
export function getStackTrace(): string {
  let stack: string = new Error().stack || '';
  stack = stack
    .split('\n')
    .map((line: string): string => {
      return line.trim();
    })
    .join('\n');
  return stack
    .split('\n')
    .splice(stack[0] === 'Error' ? 2 : 1)
    .join('\n');
}
