import React from 'react';
import './grid-button-item.scss';
import {FLEX_DIRECTION} from '@Utils/dom.util';
import {IconSize} from '@Components/icon-v2/icon.types';
import {Icon} from '../../../icon-v2';
import {Text, TextSize} from '../../../text';
import type { GridItemBaseStorage, GridItemProps} from '../grid-item';
import {GridItem} from '../grid-item';

export interface GridButtonItemStorage extends GridItemBaseStorage {
  icon: string;
  text: string;
  className: string;
  onClicked?: Function;
  iconSize?: IconSize;
  textSize?: TextSize;
  flexDirection?: FLEX_DIRECTION;
  applyCustomStyles?: boolean;
  containerClassName?: string;
}

interface GridButtonItemProps extends GridItemProps {
  icon: string;
  text: string;
  iconSize?: IconSize;
  textSize?: TextSize;
  flexDirection?: FLEX_DIRECTION;
  applyCustomStyles?: boolean;
}

export function GridButtonItem({
  flexDirection = FLEX_DIRECTION.COLUMN,
  className = '',
  iconSize = IconSize.SIZE_ICON_24,
  textSize = TextSize.XSMALL,
  applyCustomStyles = true,
  style,
  ...props
}: GridButtonItemProps) {
  return (
    <GridItem
      {...props}
      style={applyCustomStyles ? style : undefined}
      className={`${className} dotted-icon-button grid-item ${flexDirection === FLEX_DIRECTION.COLUMN ? '-vertical' : '-horizontal'}`}
    >
      {props.icon ? <Icon icon={props.icon} size={iconSize} /> : null}
      {props.text ? <Text val={props.text} size={textSize} className="dotted-icon-btn-text" /> : null}
    </GridItem>
  );
}
