import type {ReactElement} from 'react';
import React from 'react';
import {Text, TextSize} from '@Components/text';
import type {GridEmailSignupFormItemStorage} from "@Components/email-signup-form-grid/components/email-signup-form-grid";
import {SignupFormGridItemOptions} from "@Components/base-grid/components/grid-email-signup-form-item/components/signup-form-grid-item-options";
import styles from './email-signup-form-grid-item-footer.module.scss';

interface SignupFormGridItemFooterProps {
  gridItem: GridEmailSignupFormItemStorage;
}

export function EmailSignupFormGridItemFooter({gridItem}: SignupFormGridItemFooterProps): ReactElement {

  return (
    <div className={`flex-row-align-center _unpadded spacing-m-t-2 ${styles.footer}`}>
      <Text val={gridItem.name} size={TextSize.XSMALL} className="-truncated-text content-body flex-items-start flex-1" />
      <SignupFormGridItemOptions gridItem={gridItem} />
    </div>
  );
}
