import React from "react";
import PropTypes from 'prop-types';
import './brands-progress-bar.scss';
import {ProgressBarWithStops} from "../../../progress-bar-with-stops";
import {useSelector} from "react-redux";
import {doesBrandHaveFonts, doesBrandHaveLogos, doesBrandHavePrimaryOrSecondaryColor, getActiveBrand} from "../../../../libraries/brands-library";
import {useActiveBrandId} from "../../../../hooks/brands/useActiveBrandId";

const PROGRESS_BAR_STARTING_STEP = 1,
    PROGRESS_BAR_STARTING_STEP_PARTIALLY_FILLED = 1.1;
/**
 * The Progress bar shown inside the Brands page showing the user their progress in creating their brand
 * (filling it up with different brand assets)
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 * @return {JSX.Element}
 */
const BrandsProgressBar = ({labelsForSteps, isEmptyProgressBarPartiallyFilled, ...progressBarProps}) => {
    const activeBrandId = useActiveBrandId(),
        activeBrand = useSelector(({brands}) => getActiveBrand(brands)),
        doesActiveBrandHaveLogos = useSelector(({brands}) => doesBrandHaveLogos(activeBrandId, brands)),
        doesActiveBrandHaveColors = useSelector(({brands}) => doesBrandHavePrimaryOrSecondaryColor(activeBrandId, brands)),
        doesActiveBrandHaveFonts = useSelector(({brands}) => doesBrandHaveFonts(activeBrandId, brands));

    const getExtraClassesForProgressBar = () => {
        return activeBrand.doesBrandHaveAllAssetTypes ? '-hidden' : '';
    }

    const getCurrentStepForProgressBar = () => {
        let currentStep = isEmptyProgressBarPartiallyFilled ? PROGRESS_BAR_STARTING_STEP_PARTIALLY_FILLED : PROGRESS_BAR_STARTING_STEP;

        if (doesActiveBrandHaveLogos) {
            currentStep++;
        }

        if (doesActiveBrandHaveColors) {
            currentStep++;
        }

        if (doesActiveBrandHaveFonts) {
            currentStep++;
        }

        return currentStep;
    }

    return <ProgressBarWithStops key={`brand-progress-bar-${activeBrandId}`} numSteps={3} currentStep={getCurrentStepForProgressBar()} iconForFinalStep={'icon-brands'} labelsForSteps={labelsForSteps} {...progressBarProps}
                                 extraClasses={getExtraClassesForProgressBar()}/>;
}

BrandsProgressBar.propTypes = {
    /**
     * the labels to give to steps for the progress bar
     */
    labelsForSteps: PropTypes.arrayOf(PropTypes.string),
    /**
     * pass in true if we want to show a partially filled bar instead of an empty one for an incomplete step
     */
    isEmptyProgressBarPartiallyFilled: PropTypes.bool,

    /**
     * Prop used in progress-bar-with-stops, to customise the appearance of the progress bar used inside that component
     */
    progressBarClasses: PropTypes.string,
    /**
     * Prop used in progress-bar-with-stops, to customise the appearance of the circles between the bars used inside that component
     */
    progressBarStopClasses: PropTypes.string,
    /**
     * Prop used in progress-bar-with-stops, to customise the appearance of the final circle used inside that component
     */
    progressBarFinalStopClasses: PropTypes.string
}

BrandsProgressBar.defaultProps = {
    labelsForSteps: [],
    isEmptyProgressBarPartiallyFilled: false,
    progressBarClasses: '',
    progressBarStopClasses: '',
    progressBarFinalStopClasses: ''
}


export default BrandsProgressBar;