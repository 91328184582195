import React, {useEffect, useRef} from "react";
import PropTypes from 'prop-types';
import './brands-illustration-fonts.scss';
import {useSelector} from "react-redux";
import {colorToHexString, PMW_COLORS_NEUTRAL} from "@Utils/color.util";
import {addFonts} from "../../../../libraries/font-library";
import {useActiveBrandId} from "../../../../hooks/brands/useActiveBrandId";

/**
 *
 * @type {number}
 */
const FIRST_FONT_INDEX = 0,
    SECOND_FONT_INDEX = 1,
    HEADING_TEXT_RIGHT_MARGIN = 20,
    MIN_FONT_SIZE = 18;

const BrandsIllustrationFonts = ({uniqueStringForSvgIds}) => {
    const activeBrandId = useActiveBrandId(),
        activeBrandFontsIds = useSelector(({brands}) => brands.brandContent[activeBrandId].fonts.ids),
        brandFonts = useSelector(({brands}) => brands.fonts),
        headingBrandFont = getBrandFontForListIndex(activeBrandFontsIds, brandFonts, FIRST_FONT_INDEX),
        subHeadingBrandFont = getBrandFontForListIndex(activeBrandFontsIds, brandFonts, SECOND_FONT_INDEX),
        headingFontFamily = getFontFamily(headingBrandFont),
        subHeadingFontFamily = getFontFamily(subHeadingBrandFont),
        mainHeadingRef = useRef(null);

    /**
     *
     * @return {string}
     */
    const getHeadingColorForIllustration = () => {
        return headingBrandFont ? colorToHexString(headingBrandFont.defaultColor) : PMW_COLORS_NEUTRAL.NEUTRAL_18;
    }

    /**
     *
     * @return {string}
     */
    const getSubHeadingColorForIllustration = () => {
        return subHeadingBrandFont ? colorToHexString(subHeadingBrandFont.defaultColor) : PMW_COLORS_NEUTRAL.NEUTRAL_28;
    }

    /**
     *
     * @param {string} fontColor
     * @param {string} fontFamily
     * @return {Object}
     */
    const getFontPreviewStyles = (fontColor, fontFamily) => {
        return {
            color: fontColor,
            fontFamily: formatFontFamilyForElementStyle(fontFamily)
        }
    }

    /**
     *
     * @param {string} fontFamily
     * @return {string}
     */
    const formatFontFamilyForElementStyle = (fontFamily) => {
        return `"${fontFamily}"`;
    }

    /**
     *
     * @param {number} mainContainerWidth
     * @return {boolean}
     */
    const isMainHeadingTextOverflowing = (mainContainerWidth) => {
        return mainHeadingRef.current.getBoundingClientRect().width > (mainContainerWidth - HEADING_TEXT_RIGHT_MARGIN);
    }

    /**
     * recursively sets the font size of the main heading text until it either stops overflowing or reaches the min font size
     */
    const handleHeadingTextOverflow = () => {
        const mainHeadingText = mainHeadingRef.current;
        let mainContainerWidth = mainHeadingText.closest('.js-brands-illustration').querySelector('.js-brands-illustrain-middle-container').getBoundingClientRect().width,
            headingTextCurrentFontSize = parseInt(mainHeadingText.getAttribute('font-size'));

        while (isMainHeadingTextOverflowing(mainContainerWidth) && headingTextCurrentFontSize > MIN_FONT_SIZE) {
            headingTextCurrentFontSize--;
            mainHeadingText.style.fontSize = `${headingTextCurrentFontSize}px`;
        }
    }

    const setDefaultFontSizeForMainHeading = () => {
        mainHeadingRef.current.removeAttribute('style');
    }

    const setHeadingTextDefaults = () => {
        if (mainHeadingRef.current) {
            setDefaultFontSizeForMainHeading();
        }
    }

    /**
     * sets the correct size for the 'Build your own Brand' heading so it doesn't overflow the container.
     */
    const setAppropriateSizeForMainHeading = () => {
        if (mainHeadingRef.current) {
            handleHeadingTextOverflow();
        }
    }

    useEffect(() => {
        setHeadingTextDefaults();
        loadStylesheetForHeadingAndSubHeadingFonts(headingFontFamily, subHeadingFontFamily, setAppropriateSizeForMainHeading);
    }, [headingFontFamily, subHeadingFontFamily]);


    return (
        <>
            {/* The text that says 'Build your own brand'*/}
            <text mask={`url(#mask0_1163_10865-${uniqueStringForSvgIds})`} className={'brands-illustration-text svg-text'} fontWeight={'700'} fontSize={'32px'} x={161} y={91} fill={getHeadingColorForIllustration()} fontFamily={headingFontFamily}
                  ref={mainHeadingRef}> {i18next.t('pmwjs_build_your_own_brand')}</text>
            {/* The text that says 'Stay consistent with brand kits'*/}
            <text mask={`url(#mask0_1163_10865-${uniqueStringForSvgIds})`} className={'svg-text'} fontSize={'18px'} x={161.212} y={122} fill={getSubHeadingColorForIllustration()}> {i18next.t('pmwjs_stay_consistent_with_brand_kits')}</text>
            {/* The font preview text that says 'Font' and has the styles/properties used in the Build your own brand Heading*/}
            <g className={'js-heading-text-smaller-container'} filter={`url(#filter2_d_1163_10865-${uniqueStringForSvgIds})`}>
                <rect x="49.93" y="557.349" height="82.2205" width="143.804" rx="8.89411" fill="white"/>
                <foreignObject x="49.93" y="557.349" height="82.2205" width="143.804" rx="8.89411" fill="white">
                    <div className={'flex-center text-preview-container'} xmlns="http://www.w3.org/1999/xhtml">
                        <span style={getFontPreviewStyles(getHeadingColorForIllustration(), headingFontFamily)} className={'body-l-bold font-preview-text'}>{i18next.t('pmwjs_font')}</span>
                    </div>
                </foreignObject>
            </g>
            {/* The font preview text that says 'Font' and has the styles/properties used in the stay consistent SubHeading'*/}
            <g filter={`url(#filter3_d_1163_10865-${uniqueStringForSvgIds})`}>
                <rect x="212.682" y="557.349" height="82.2205" width="143.016" rx="8.89411" fill="white"/>
                <foreignObject x="212.682" y="557.349" height="82.2205" width="143.016" rx="8.89411" fill="white">
                    <div className={'flex-center text-preview-container'} xmlns="http://www.w3.org/1999/xhtml">
                        <span style={getFontPreviewStyles(getSubHeadingColorForIllustration(), subHeadingFontFamily)} className={'body-l-bold font-preview-text'}>{i18next.t('pmwjs_font')}</span>
                    </div>
                </foreignObject>
            </g>

        </>
    );
}

const getBrandFontForListIndex = (brandFontIds, brandFonts, listIndex) => {
    const idbrandFont = brandFontIds[listIndex];
    return brandFonts[idbrandFont] ?? null;
}


const loadStylesheetForHeadingAndSubHeadingFonts = (headingFontFamily, subHeadingFontFamily, onLoadSuccess) => {
    const fonts = [];

    if (headingFontFamily) {
        fonts.push(headingFontFamily);
    }

    if (subHeadingFontFamily) {
        fonts.push(subHeadingFontFamily);
    }

    addFonts(fonts, onLoadSuccess);
}

/**
 *
 * @param {Object} fontObj
 * @return {string}
 */
const getFontFamily = (fontObj) => {
    return fontObj?.fontFamily ?? '';
}

BrandsIllustrationFonts.propTypes = {
    /**
     * A unique string appended to the ID of all masks and filters which is then needed by all the svg elements that reference those mask/filter elements
     */
    uniqueStringForSvgIds: PropTypes.string
}

export default BrandsIllustrationFonts;