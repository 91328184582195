import {getAssetUrl} from '@Utils/s3.util';
import {getCompatibleImageFileExtension} from '@Utils/image.util';
import {getRandomNumberInRange} from '@Utils/math.util';

export const getDefaultFadeDurationForAudioDuration = (audioDuration: number): number => {
  if (audioDuration < 2) {
    return 0;
  }
  if (audioDuration >= 2 && audioDuration < 3) {
    return 0.5;
  }
  if (audioDuration >= 3 && audioDuration < 9) {
    return 1;
  }
  if (audioDuration >= 9 && audioDuration < 15) {
    return 2;
  }
  if (audioDuration <= 15) {
    return 3;
  }
  return 5;
};

export const getRandomThumbnailImageForAudioItem = (): string => {
  const ext = getCompatibleImageFileExtension('webp');
  return getAssetUrl(`audioitems/audio-${getRandomNumberInRange(1, 7)}.${ext}`);
};
