import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
  EmailCampaign,
  EmailCampaignId,
  FetchEmailCampaignsAjaxParams,
  AjaxResponseWithCampaign,
  ChangeScheduleDateTimeParams,
  DeleteCampaignParams,
} from '@Components/email-marketing-campaigns/email-marketing-campaigns.types';
import {areAllStatusesSelectedInFilter, getSelectedStatusesInFilter} from '@Libraries/email-campaigns-library';

export const fetchEmailCampaigns = createAsyncThunk<EmailCampaign[], FetchEmailCampaignsAjaxParams>('fetchEmailCampaigns', (ajaxParams) => {
  return window.PMW.readLocal('emailmarketing/getEmailCampaignsForUser', {
    offset: ajaxParams.offset,
    sortBy: ajaxParams.sortBy,
    statuses: areAllStatusesSelectedInFilter(ajaxParams.statusFilter) ? [] : getSelectedStatusesInFilter(ajaxParams.statusFilter),
    fetchDeleted: ajaxParams.fetchDeleted,
  });
});

export const sendScheduledCampaignNow = createAsyncThunk<AjaxResponseWithCampaign, EmailCampaignId>('sendScheduledCampaignNow', (campaignId: EmailCampaignId) => {
  return window.PMW.writeLocal('emailmarketing/sendScheduledEmailCampaignNow', {
    idEmailMarketingCampaign: campaignId,
  });
});

export const unscheduleEmailCampaign = createAsyncThunk<AjaxResponseWithCampaign, EmailCampaignId>('unscheduleEmailCampaign', (campaignId) => {
  return window.PMW.writeLocal('emailmarketing/unscheduleEmailMarketingCampaign', {
    idEmailMarketingCampaign: campaignId,
  });
});

export const deleteEmailCampaign = createAsyncThunk('deleteEmailCampaign', (ajaxParams: DeleteCampaignParams): Promise<void> => {
  return window.PMW.writeLocal('emailmarketing/deleteEmailMarketingCampaign', {
    idEmailMarketingCampaign: ajaxParams.campaignId,
    deletePermanently: ajaxParams.deletePermanently ?? false,
  });
});

export const duplicateEmailCampaign = createAsyncThunk<AjaxResponseWithCampaign, EmailCampaignId>('duplicateEmailCampaign', (campaignId: EmailCampaignId) => {
  return window.PMW.writeLocal('emailmarketing/duplicateEmailCampaign', {
    idEmailMarketingCampaign: campaignId,
  });
});

export const updateCampaignScheduleDateTime = createAsyncThunk('updateCampaignScheduleDateTime', (params: ChangeScheduleDateTimeParams) => {
  return window.PMW.writeLocal('emailmarketing/updateEmailMarketingCampaignSchedule', {
    isScheduled: true,
    idEmailMarketingCampaign: params.campaignId,
    scheduledDateTime: params.newDate.toISOString(),
  });
});

export const pauseEmailCampaign = createAsyncThunk('pauseEmailCampaign', (campaignId: EmailCampaignId) => {
  return window.PMW.writeLocal('emailmarketing/haltEmailMarketingCampaign', {
    idEmailMarketingCampaign: campaignId,
  });
});

export const resumeEmailCampaign = createAsyncThunk('resumeEmailCampaign', (campaignId: EmailCampaignId) => {
  return window.PMW.writeLocal('emailmarketing/resumeEmailMarketingCampaign', {
    idEmailMarketingCampaign: campaignId,
  });
});

export const restoreEmailCampaign = createAsyncThunk('restoreEmailCampaign', (campaignId: EmailCampaignId) => {
  return window.PMW.writeLocal('emailmarketing/restoreEmailMarketingCampaign', {
    idEmailMarketingCampaign: campaignId,
  });
});
