import React from 'react';
import {connect} from 'react-redux';
import './email-marketing-campaign-performance-header.scss';
import {getFirstSelectedItemId, getSecondSelectedItemId, ITEM_TYPES} from '@Libraries/email-marketing-campaigns-library';
import {Dropdown} from '@Components/dropdown-v2';
import {Button, Size, Type} from '@Components/button';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {DownloadReportsDropdownOptions} from '@Components/email-marketing-campaign-performance/components/download-reports-dropdown-options';
import {Pill, PILL_BACKGROUND_COLOR, PILL_CONTENT_TYPE, PILL_SIZE} from '@Components/pill';
import {ItemSwitcher} from '../item-switcher';
import {updateFirstId, updateItemType, updateSecondId} from '../../email-marketing-campaign-performance-reducer';
import {ALIGNMENT_TYPE} from "@Components/dropdown-v2/dropdown.types";
/**
 * Parent component for the email campaigns performance view
 * @author Basil <basil@250mils.com>
 */
class EmailMarketingCampaignPerformanceHeader extends React.Component {
  DEFAULT_ITEM_ID = 'none';

  constructor(props) {
    super(props);
    this.firstItemListItems = {};
    this.secondItemListItems = {};
    this.state = {
      isValidComparison: !!getSecondSelectedItemId(this.props.selectedItemIds),
      isDownloadReportDropdownOpen: false,
    };
    if (this.#isCampaignsViewSelected()) {
      this.#setCampaignListItems();
    } else {
      this.#setAudienceListItems();
    }
  }

  render() {
    const {itemType} = this.props;
    return (
      <div className="flexbox flex-content-between campaign-performance-header">
        <div className="header-dropdowns-container flexbox spacing-m-r-2">
          <div className="primary-dropdowns-container flexbox">
            <ItemSwitcher
              itemType={ITEM_TYPES.VIEWS}
              defaultLabel={this.#getViewSwitcherDefaultLabel()}
              defaultValue={itemType}
              clickHandler={this.#onItemTypeSwitch}
              isValidComparison={this.state.isValidComparison}
              showMore={false}
              dropdownClassName="spacing-m-r-2"
            />

            <ItemSwitcher
                listItems={this.#getListItemsForFirstItemSwitcher()}
                itemType={itemType}
                defaultLabel={this.#getFirstItemSwitcherDefaultLabel()}
                defaultValue={this.#getFirstSelectedItemId()}
                clickHandler={this.#onFirstItemSwitch}
                isValidComparison={this.state.isValidComparison}
                labelPrefix={<Icon icon="icon-facebook-group" size={IconSize.SIZE_ICON_16} />}
                dropdownClassName="-large-desktop -main-dropdown"
                showMore
            />
          </div>
        </div>

        <div className="flex-row-align-center">
          <div className="flexbox comparison-container spacing-m-r-2">
            <div className={`compare-with-label comparison-container flexbox flex-align-items-center flex-content-center ${this.state.isValidComparison ? 'comparison-valid ' : ''} spacing-m-r-3`}>
              <Icon icon="icon-envelope" size={IconSize.SIZE_ICON_16} className="spacing-m-r-1 content-sub-text" />
              <Text val={`${window.i18next.t('pmwjs_compare_with')}`} size={TextSize.XSMALL} />
            </div>

            <div className="comparison-dropdown-container">
              <ItemSwitcher
                  listItems={this.#getListItemsForSecondItemSwitcher()}
                  itemType={itemType}
                  defaultLabel={this.#getSecondItemSwitcherDefaultLabel()}
                  defaultValue={this.#getSecondSelectedItemId()}
                  clickHandler={this.#onSecondItemSwitch}
                  isValidComparison={this.state.isValidComparison}
                  labelPrefix={<Icon icon="icon-facebook-group" size={IconSize.SIZE_ICON_16} />}
                  dropdownClassName={this.#isComparisonView() ? '-in-comparison-view': ''}
                  showMore
                  isComparisonDropdown
              />
            </div>
          </div>

          {!this.#reportDownloadShouldBeHidden() && (
              <div className="download-report">
                <Dropdown
                    className="_unmargin _full-width"
                    popupClassName="dropdown zindex-5000"
                    id="export-email-metrics"
                    popUpHasCustomWidth
                    alignment={ALIGNMENT_TYPE.BOTTOM_START}
                    selector={this.#getDropDownSelector()}
                    popup={<DownloadReportsDropdownOptions />}
                    doCloseOnInnerClick={false}
                    popupOffset={8}
                    onOpen={() => {
                      this.setState({isDownloadReportDropdownOpen: true});
                    }}
                    onClose={() => {
                      this.setState({isDownloadReportDropdownOpen: false});
                    }}
                />
              </div>
          )}
        </div>
      </div>
    );
  }

  #getDropDownSelector = () => {
    return (
      <div>
        <div className="download-report-dropdown-button-selector -for-more-h-space">
          <Button
              type={Type.PRIMARY}
              icon="icon-download"
              text={`${window.i18next.t('pmwjs_download_report')}`}
              size={Size.XSMALL}
              disabled={this.#reportDownloadShouldBeDisabled()}
              customClasses={`download-report-dropdown-selector-button ${this.state.isDownloadReportDropdownOpen ? '-active' : ''}`}
          />
        </div>
        <Icon icon="icon-download" type={IconType.SECONDARY} size={IconSize.SIZE_ICON_16} className="download-report-dropdown-button-selector -for-less-h-space" shape={IconShape.SQUARE} isDisabled={this.#reportDownloadShouldBeDisabled()} />
      </div>
    );
  };

  #getFirstSelectedItemId = () => {
    let firstItemId = getFirstSelectedItemId(this.props.selectedItemIds);
    if (firstItemId === null) {
      firstItemId = this.DEFAULT_ITEM_ID;
    }
    return firstItemId;
  };

  #getSecondSelectedItemId = () => {
    let secondItemId = getSecondSelectedItemId(this.props.selectedItemIds);
    if (secondItemId === null) {
      secondItemId = this.DEFAULT_ITEM_ID;
    }
    return secondItemId;
  };

  #isAudiencesViewSelected = () => {
    return this.props.itemType === ITEM_TYPES.AUDIENCES;
  };

  #isCampaignsViewSelected = () => {
    return this.props.itemType === ITEM_TYPES.CAMPAIGNS;
  };

  #getViewSwitcherDefaultLabel = () => {
    let viewsDefaultLabel = i18next.t('pmwjs_campaign');
    if (this.#isAudiencesViewSelected()) {
      viewsDefaultLabel = i18next.t('pmwjs_mailing_list');
    }
    return viewsDefaultLabel;
  };

  #getFirstItemSwitcherDefaultLabel = () => {
    const firstItemId = getFirstSelectedItemId(this.props.selectedItemIds);

    let firstSwitcherDefaultLabel = i18next.t('pmwjs_select_a_campaign');
    if (this.#isAudiencesViewSelected()) {
      firstSwitcherDefaultLabel = i18next.t('pmwjs_select_an_audience');
    }
    if (firstItemId !== null) {
      firstSwitcherDefaultLabel = this.firstItemListItems[firstItemId].name;
    }
    return firstSwitcherDefaultLabel;
  };

  #getSecondItemSwitcherDefaultLabel = () => {
    const secondItemId = getSecondSelectedItemId(this.props.selectedItemIds);

    let secondSwitcherDefaultLabel = i18next.t('pmwjs_none');
    if (secondItemId !== null) {
      secondSwitcherDefaultLabel = this.secondItemListItems[secondItemId].name;
    }
    return secondSwitcherDefaultLabel;
  };

  #getDefaultListItemForSecondSwitcher = () => {
    return {
      id: this.DEFAULT_ITEM_ID,
      name: i18next.t('pmwjs_none'),
    };
  };

  #onItemTypeSwitch = (val) => {
    if (val !== this.props.itemType) {
      this.props.updateItemType({itemType: val});
    }
  };

  #onFirstItemSwitch = (val) => {
    if (val !== getFirstSelectedItemId(this.props.selectedItemIds)) {
      this.props.updateFirstId({firstId: val});
    }
  };

  #onSecondItemSwitch = (val) => {
    if (val !== getSecondSelectedItemId(this.props.selectedItemIds)) {
      this.props.updateSecondId({secondId: val});
    }
  };

  #setCampaignListItems = () => {
    const {campaigns} = this.props;
    const firstItemListItems = {};
    const secondItemListItems = {};

    Object.values(this.props.campaignsWithMetricsAvailable).forEach((campaignID) => {
      firstItemListItems[campaignID] = campaigns[campaignID];
      secondItemListItems[campaignID] = campaigns[campaignID];
    }, this);

    Object.values(this.props.campaignsWithMetricsCollecting).forEach((campaignID) => {
      firstItemListItems[campaignID] = campaigns[campaignID];
    }, this);

    this.#setListItems(firstItemListItems, secondItemListItems);
  };

  #setAudienceListItems = () => {
    const {mailingLists} = this.props;
    const firstItemListItems = {};
    const secondItemListItems = {};

    Object.values(this.props.mailingListsWithAvailableMetrics).forEach((mailingListID) => {
      firstItemListItems[mailingListID] = mailingLists[mailingListID];
      secondItemListItems[mailingListID] = mailingLists[mailingListID];
    }, this);

    Object.values(this.props.mailingListsWithMetricsCollecting).forEach((mailingListID) => {
      firstItemListItems[mailingListID] = mailingLists[mailingListID];
    }, this);

    this.#setListItems(firstItemListItems, secondItemListItems);
  };

  #deleteSelectedItemFromListItems = (listItems, selectedItemId) => {
    if (selectedItemId !== null && listItems.hasOwnProperty(selectedItemId)) {
      delete listItems[selectedItemId];
    }

    return listItems;
  };

  #setListItems = (firstItemListItems, secondItemListItems) => {
    const firstSelectedItemId = this.#getFirstSelectedItemId();
    const secondSelectedItemId = this.#getSecondSelectedItemId();

    this.#deleteSelectedItemFromListItems(firstItemListItems, secondSelectedItemId);
    this.#deleteSelectedItemFromListItems(secondItemListItems, firstSelectedItemId);
    if (secondSelectedItemId !== null) {
      secondItemListItems.none = this.#getDefaultListItemForSecondSwitcher();
    }
    this.firstItemListItems = firstItemListItems;
    this.secondItemListItems = secondItemListItems;
  };

  #getListItemsForFirstItemSwitcher = () => {
    return this.#getListItemsForItemSwitcher(this.firstItemListItems);
  };

  #getListItemsForSecondItemSwitcher = () => {
    return this.#getListItemsForItemSwitcher(this.secondItemListItems);
  };

  #getListItemsForItemSwitcher = (listItems) => {
    listItems = Object.values(listItems);
    const lastItem = listItems.pop();
    if (lastItem.id === this.DEFAULT_ITEM_ID) {
      listItems.unshift(lastItem);
    } else {
      listItems.push(lastItem);
    }

    return listItems;
  };

  #reportDownloadShouldBeHidden = () => {
    if (this.#isComparisonView()) {
      return true;
    }

    return false;
  };

  #reportDownloadShouldBeDisabled = () => {
    if (this.#isAudiencesViewSelected()) {
      return this.#doesSelectedMailingListHaveMetrics();
    }

    return this.#doesSelectedCampaignHaveMetrics();
  };

  #doesSelectedMailingListHaveMetrics = () => {
    return !!this.props.mailingListsWithMetricsNotAvailable[getFirstSelectedItemId(this.props.selectedItemIds)];
  };

  #doesSelectedCampaignHaveMetrics = () => {
    return !!this.props.campaignsWithMetricsNotAvailable[getFirstSelectedItemId(this.props.selectedItemIds)];
  };

  #isComparisonView = () => {
    return this.props.selectedItemIds.length > 1;
  };
}

const mapEmailMarketingCampaignPerformanceDispatchToProps = (dispatch) => {
  return {
    updateItemType: (data) => {
      return dispatch(updateItemType(data));
    },
    updateFirstId: (data) => {
      return dispatch(updateFirstId(data));
    },
    updateSecondId: (data) => {
      return dispatch(updateSecondId(data));
    },
  };
};

const mapEmailMarketingCampaignPerformanceStateToProps = (state) => {
  return {
    itemType: state.emailMarketingCampaignsPerformance.itemType,
    selectedItemIds: state.emailMarketingCampaignsPerformance.selectedItemIds,
    campaigns: state.emailMarketingCampaignsPerformance.campaigns,
    mailingLists: state.emailMarketingCampaignsPerformance.mailingLists,
    campaignsWithMetricsAvailable: state.emailMarketingCampaignsPerformance.campaignsWithMetricsAvailable,
    campaignsWithMetricsNotAvailable: state.emailMarketingCampaignsPerformance.campaignsWithMetricsNotAvailable,
    campaignsWithMetricsCollecting: state.emailMarketingCampaignsPerformance.campaignsWithMetricsCollecting,
    mailingListsWithAvailableMetrics: state.emailMarketingCampaignsPerformance.mailingListsWithAvailableMetrics,
    mailingListsWithMetricsNotAvailable: state.emailMarketingCampaignsPerformance.mailingListsWithMetricsNotAvailable,
    mailingListsWithMetricsCollecting: state.emailMarketingCampaignsPerformance.mailingListsWithMetricsCollecting,
  };
};

export default connect(mapEmailMarketingCampaignPerformanceStateToProps, mapEmailMarketingCampaignPerformanceDispatchToProps)(EmailMarketingCampaignPerformanceHeader);
