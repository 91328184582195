import type {MouseEvent, ReactElement} from 'react';
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import type {Middleware} from '@floating-ui/react';
import {arrow, autoUpdate, computePosition, flip, FloatingArrow, offset, shift, useFloating} from '@floating-ui/react';
import {DropdownDefaultSelectBox} from '@Components/dropdown-v2/components/dropdown-default-select-box';
import {DropdownGhostSelectBox} from '@Components/dropdown-v2/components/dropdown-ghost-select-box';
import {PMW_COLORS_NEUTRAL} from '@Utils/color.util';
import {noop} from '@Utils/general.util';
import {ClickableDiv} from '@Components/clickable-div';
import useWindowSize from '@Hooks/useWindowSize';
import {MOBILE_MODAL_WIDTH_THRESHOLD} from '@Components/modal';
import {openMoreOptionsModal} from '@Modals/more-options-modal';
import type {ElementTestId} from '@Tests/tests.types';
import styles from './dropdown.module.scss';
import type {DropdownPopupProperties} from './dropdown.types';
import {ALIGNMENT_TYPE, DROPDOWN_POSITION} from './dropdown.types';
import useOutsideClick from '../../hooks/useOutsideClick';

export interface DropdownProps extends DropdownPopupProperties {
  id?: string;
  open?: boolean;
  className?: string;
  selectorClassNameOnOpen?: string;
  popupClassName?: string;
  /**
   * the selector can be <DropdownDefaultSelectBox /> or <DropdownGhostSelectBox /> or any custom react element
   */
  selector: ReactElement;
  /**
   * this will be the container that the dropdown opens. It can be any react element (or more inside a div)
   */
  popup: ReactElement;

  /**
   * Please note, for modals only bottom alignments are supported
   */
  alignment?: ALIGNMENT_TYPE;
  doCloseOnInnerClick?: boolean;
  /**
   * details of flip and shift options are available here: https://floating-ui.com/
   */
  shouldFlip?: boolean;
  shouldShift?: boolean;

  useDefaultArrow?: boolean;
  arrowClasses?: string;
  modalTitle?: string;
  modalContent?: ReactElement;
  modalClassName?: string;
  showModalForSmallScreen?: boolean;
  smallScreenWidthThresholdForModal?: number;

  stopClickPropagation?: boolean;
  doesCloseOnOuterClick?: boolean;
  isControlled?: boolean;
  'data-testid'?: ElementTestId;

  /**
   * please use these functions to determine the open and close states.
   * The default selectbox types have isOpened prop by default but for custom select boxes, opened and closed for select box needs to be
   * handled by the parent component and not the dropdown component
   */
  onClick?(id?: string): void;

  onOpen?(): void;

  onClose?(): void;
}

const DROPDOWN_DEFAULT_MAX_HEIGHT = 360;
const DROPDOWN_OFFSET = 12;

export function Dropdown({
  id = '',
  open = false,
  popupClassName = '',
  className = '',
  selectorClassNameOnOpen = '',
  onClick = noop,
  onOpen = noop,
  onClose = noop,
  alignment = ALIGNMENT_TYPE.BOTTOM,
  doCloseOnInnerClick = true,
  popupOffset = DROPDOWN_OFFSET,
  popUpHasCustomWidth = false,
  position = DROPDOWN_POSITION.ABSOLUTE,
  shouldFlip = true,
  shouldShift = true,
  useDefaultArrow = false,
  modalTitle = '',
  modalClassName = '',
  showModalForSmallScreen = false,
  smallScreenWidthThresholdForModal = MOBILE_MODAL_WIDTH_THRESHOLD,
  arrowClasses = '',
  leftOffset = 0,
  topOffset = 0,
  stopClickPropagation = false,
  doesCloseOnOuterClick = true,
  ...props
}: DropdownProps): ReactElement {
  const [isOpened, setIsOpened] = useState(open);
  const initialReferenceX = useRef<number | null>(null);
  const initialReferenceY = useRef<number | null>(null);
  const dropdownContainerRef = useRef<HTMLDivElement | null>(null);
  const dropdownPopupMaxHeight = useRef<number | undefined>(undefined);
  const dropdownPopupWidth = useRef<number | undefined>(undefined);
  const {windowWidth} = useWindowSize();
  const arrowRef = useRef(null);

  useEffect(() => {
    setInitialPositionForSelector();
    setHeightAndWidthForPopup();
  }, []);

  useEffect(() => {
    setIsOpened(open);
  }, [open]);

  const setInitialPositionForSelector = (): void => {
    if (refs.reference && refs.reference.current) {
      const boundingBox = refs.reference.current.getBoundingClientRect();
      if (boundingBox) {
        initialReferenceX.current = boundingBox.x;
        initialReferenceY.current = boundingBox.y;
      }
    }
  };

  const getMiddleware = (): Array<Middleware> => {
    const options = [offset(popupOffset)];
    if (shouldFlip) {
      options.push(flip());
    }
    if (shouldShift) {
      options.push(shift());
    }

    if (useDefaultArrow) {
      options.push(
        arrow({
          element: arrowRef,
        })
      );
    }

    return options;
  };

  const {x, y, refs, context} = useFloating({
    placement: alignment,
    middleware: getMiddleware(),
    whileElementsMounted: autoUpdate,
  });

  const update = useCallback(async (): Promise<void> => {
    if (isOpened && refs.reference.current && refs.floating.current) {
      const {x, y} = await computePosition(refs.reference.current, refs.floating.current, {
        placement: alignment,
        middleware: getMiddleware(),
      });
      if (refs.reference.current) {
        Object.assign(refs.floating.current.style, {
          left: `${x + leftOffset}px`,
          top: `${y + topOffset}px`,
        });
      }
    }
  }, [isOpened, refs.floating, refs.reference]);

  useLayoutEffect(() => {
    if (isOpened && refs.reference.current && refs.floating.current && position === DROPDOWN_POSITION.FIXED) {
      autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [isOpened, update, refs.floating, refs.reference]);

  const getPopupClasses = (): string => {
    const classes = [styles.panel, popupClassName, props.popupSpacingClass ? props.popupSpacingClass : 'spacing-p-2'];
    return classes.join(' ');
  };

  const closeDropdown = (): void => {
    onClose();
    setIsOpened(false);
  };

  const showDropdownSelector = (): ReactElement => {
    if (props.selector.type === DropdownDefaultSelectBox || props.selector.type === DropdownGhostSelectBox) {
      return React.cloneElement(props.selector, {
        isOpened,
        onClick: onClickHandler,
      });
    }

    const existingSelectorClasses = (props.selector.props.className ?? '') as string;
    return React.cloneElement(props.selector, {
      onClick: onClickHandler,
      className: isOpened ? `${existingSelectorClasses} ${selectorClassNameOnOpen}` : existingSelectorClasses,
    });
  };

  const getDropdownContent = (): ReactElement | null => {
    if (isOpened) {
      return (
        <ClickableDiv
          onClick={(): void => {
            doCloseOnInnerClick && setIsOpened(!isOpened);
          }}
          className={`${getPopupClasses()}`}
          style={{
            position: position === DROPDOWN_POSITION.ABSOLUTE ? 'absolute' : 'fixed',
            top: y ? `${y + topOffset}px` : `${topOffset}px`,
            left: x ? x + leftOffset : '',
            width: dropdownPopupWidth.current,
            maxHeight: props.maxHeight ? props.maxHeight : dropdownPopupMaxHeight.current,
          }}
          ref={refs.setFloating}
        >
          {props.popup}
          {useDefaultArrow ? (
            <FloatingArrow
              staticOffset={props.arrowOffset ? `${Math.abs(y ? y + props.arrowOffset : props.arrowOffset)}px` : undefined}
              tipRadius={2}
              className={`${styles.arrow} ${arrowClasses}`}
              ref={arrowRef}
              stroke={PMW_COLORS_NEUTRAL.NEUTRAL_6}
              strokeWidth={0.2}
              context={context}
            />
          ) : null}
        </ClickableDiv>
      );
    }
    return null;
  };

  const onClickHandler = (e: MouseEvent): void => {
    if (stopClickPropagation) {
      e.stopPropagation();
    }
    onClick(id);
    if (showModalForSmallScreen && windowWidth < smallScreenWidthThresholdForModal) {
      openMoreOptionsModal({
        className: modalClassName || 'spacing-p-4',
        headerTitle: modalTitle,
        content: props.modalContent ?? props.popup,
      });
      return;
    }
    if (isOpened) {
      onClose();
    } else {
      onOpen();
    }
    setIsOpened(!isOpened);
  };

  const getWidthForThePopup = (): number | undefined => {
    if (!popUpHasCustomWidth && refs.reference && refs.reference.current) {
      const boundingBox = refs.reference.current.getBoundingClientRect();
      return boundingBox.width;
    }

    return undefined;
  };

  const getHeightForPopup = (): number => {
    if (refs.reference && refs.reference.current && refs.floating && refs.floating.current) {
      const boundingBoxReference = refs.reference.current.getBoundingClientRect();
      const boundingBoxPopup = refs.floating.current.getBoundingClientRect();

      // popup is on right
      if (boundingBoxPopup.x > boundingBoxReference.right) {
        return Math.round(window.innerHeight) - 2 * popupOffset;
      }

      // popup is on left
      if (boundingBoxPopup.right < boundingBoxReference.x) {
        return Math.round(window.innerHeight) - 2 * popupOffset;
      }

      // popup is on top
      if (boundingBoxPopup.y < boundingBoxReference.y) {
        return Math.round(window.innerHeight - boundingBoxReference.y) - 2 * popupOffset;
      }

      // if popup is down
      if (boundingBoxPopup.y > boundingBoxReference.y) {
        return Math.round(window.innerHeight - boundingBoxReference.bottom) - 2 * popupOffset;
      }
    }

    return DROPDOWN_DEFAULT_MAX_HEIGHT;
  };

  const setHeightAndWidthForPopup = (): void => {
    dropdownPopupMaxHeight.current = getHeightForPopup();
    dropdownPopupWidth.current = getWidthForThePopup();
  };

  if (doesCloseOnOuterClick) {
    useOutsideClick(dropdownContainerRef, closeDropdown);
  }

  return (
    <div
      id={id}
      className={`${styles.container} ${className}`}
      data-testid={props['data-testid']}
      ref={(e): void => {
        refs.setReference(e);
        dropdownContainerRef.current = e;
      }}
    >
      {showDropdownSelector()}
      {getDropdownContent()}
    </div>
  );
}
