import type {ActionReducerMapBuilder} from '@reduxjs/toolkit/src/mapBuilders';
import type {NoInfer} from '@reduxjs/toolkit/src/tsHelpers';
import {getPostableSocialMediaAccountsForUser} from '@Components/social-media/social-media-thunk';
import type {SocialMediaWizardState} from '@Components/social-media-wizard/social-media-wizard-reducer';
import { doUpdateAreAccountsLoading, updateWizardSocialAccounts} from '@Components/social-media-wizard/social-media-wizard-reducer';
import type {SocialAccount} from '@Components/social-media/account.vo';
import _ from 'lodash';

export const socialMediaWizardExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<SocialMediaWizardState>>): void => {
  builder.addCase(getPostableSocialMediaAccountsForUser.fulfilled, (state, action) => {
    const {payload} = action;
    const updatedAccounts: SocialAccount[] = payload.jsonAccounts.concat(state.accounts);

    const uniqueAccounts = _.uniqWith(updatedAccounts, (a, b): boolean => {
      return a.id === b.id;
    });
    doUpdateAreAccountsLoading(state, false);
    updateWizardSocialAccounts(state, uniqueAccounts);
  });

  builder.addCase(getPostableSocialMediaAccountsForUser.rejected, (state, action) => {
    doUpdateAreAccountsLoading(state, false);
  });
};
