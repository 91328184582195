import React from "react";
import PropTypes from 'prop-types';
import './metric-insights.scss';
import {PopupMenu} from "../../../popup-menu";
import {PopupMenuItem} from "../../../popup-menu/components";
import {updateWindowLocationHash} from "../../../../libraries/email-marketing-campaigns-library";
import {resetPerformanceLoadingState} from "@Components/email-marketing-campaign-performance/email-marketing-campaign-performance-reducer";
import {copyToClipboard} from "@Utils/clipboard.util";

/**
 * Shows the value, and relevant items, of a certain metric for an email campaign or audience. This is intended to be used as values for certain
 * columns in the table that can be found in the DetailedList component. Also optionally shows a PopupMenu for "more" relevant items.
 * @auhor Basil <basil@250mils.com>
 */
class MetricInsights extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'metric-insights flexbox'}>
                {this.#getValueToShow()}
                {this.#getShowMorePopupMenu()}
            </div>
        );
    }

    #getValueToShow = () => {
        if (this.props.isLink) {
            return <a className={'clicked-link hover-transition-all'} href={this.props.value} target={'_blank'}>{this.props.value}</a>;
        } else {
            return <span>{this.props.value}</span>;
        }
    }

    #getShowMorePopupMenu = () => {
        if (this.props.showMoreLabel !== '') {
            return (
                <PopupMenu menuToggleButton={(<span className={'show-more body-xs-bold radius-4 hover-transition-all spacing-p-2'}>{this.props.showMoreLabel}</span>)}>
                    {this.#getPopupMenuItems()}
                </PopupMenu>
            );
        }

        return null;
    }

    #getPopupMenuItems = () => {
        return this.props.showMorePopupMenuData.map((item, i) => {
            if (this.props.isPopupMenuItemLink) {
                return (
                    <>
                        <li className={'custom-popup-menu-item flexbox flex-items-center'} key={`${i}-${item}`}>
                            <div className={'popup-menu-item hover-transition-all spacing-m-2 spacing-p-3'}>
                                <a className={'popup-menu-item-link flex-row-align-center'} href={item.name} target={'_blank'}>
                                    <p className={'popup-menu-item-text'}>{item.name}</p>
                                </a>
                            </div>
                            <i className={'icon icon-copy size-icon-20 radius-2 hover-transition-all'} onClick={this.#copyToClipboard}/>
                        </li>
                    </>
                );
            }

            return (
                <PopupMenuItem
                    iconClasses={''}
                    text={item.name}
                    target={this.props.isPopupMenuItemLink ? '_blank' : '_self'}
                    clickHandler={this.#popupMenuItemClickHandler.bind(this, item.idEmailMarketingCampaign)}
                    key={`${i}-${item}`}
                />
            );
        });
    }

    /**
     * @param {Object} e Click event data
     */
    #copyToClipboard = (e) => {
        copyToClipboard($(e.currentTarget).siblings('.popup-menu-item').find('.popup-menu-item-text').html()).then();
    }

    /**
     * @param {string} idCampaign
     */
    #popupMenuItemClickHandler = (idCampaign) => {
        updateWindowLocationHash(this.props.itemType, [idCampaign]);
        resetPerformanceLoadingState();
    }
}


MetricInsights.propTypes = {
    /**
     * The value of this metric, can be a number (e.g. number of opens) or string (e.g. a link that was clicked)
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * Whether the value prop above was a link
     */
    isLink: PropTypes.bool.isRequired,

    /**
     * Whether each item in the 'show more' popup menu points to an external link.
     */
    isPopupMenuItemLink: PropTypes.bool.isRequired,

    /**
     * The type of item for which metric insights are to be shown. One of the ITEM_TYPES.* constants.
     */
    itemType: PropTypes.string,

    /**
     * The label for the 'Show More' popup menu to be shown along with the metric value
     */
    showMoreLabel: PropTypes.string,

    /**
     * An array of items to be shown in the 'Show More' popup menu
     */
    showMorePopupMenuData: PropTypes.array
};

MetricInsights.defaultProps = {
    showMoreLabel: '',
    showMorePopupMenuData: []
};

export default MetricInsights;
