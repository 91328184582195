import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {Text, TextSize} from '@Components/text';
import {FlatIconColor, FlatIconColorType, IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {getAudienceUrl, SPAM_SCORE_THRESHOLD} from '@Libraries/email-marketing-campaigns-audience-library';
import type {GridAudienceItemStorage} from '@Components/email-marketing-campaign-audience/components/audience-grid';
import styles from './audience-grid-item-content.module.scss';

interface AudienceGridItemPreviewEmailsProps {
  gridItem: GridAudienceItemStorage;
}

const NUM_AUDIENCES_SHOWN_ON_TRUNCATE = 2;
const AUDIENCES_SHOWN_IN_UI_LIMIT = 3;

export function AudienceGridItemContent({gridItem}: AudienceGridItemPreviewEmailsProps): ReactElement {
  const doTruncateAudiences = (): boolean => {
    return gridItem.numAudiences > AUDIENCES_SHOWN_IN_UI_LIMIT;
  };

  const getNewAudienceSubscriptionIcon = (): ReactElement | null => {
    if (!gridItem.numNewAudiences) {
      return null;
    }

    return <Text size={TextSize.XXSMALL} bold val={`+${gridItem.numNewAudiences}`} className={styles.newAudienceNum} />;
  };

  const getPreviewEmailsDiv = (): ReactElement => {
    const endIndex = doTruncateAudiences() ? NUM_AUDIENCES_SHOWN_ON_TRUNCATE : gridItem.numAudiences;

    return (
      <>
        <Icon
          size={IconSize.SIZE_ICON_16}
          icon="icon-at"
          className={`${styles.iconAt} flex-vertical-center spacing-m-b-3`}
          type={IconType.FLAT}
          shape={IconShape.CIRCLE}
          flatIconType={{color: FlatIconColor.NEUTRAL, type: FlatIconColorType.DEFAULT}}
        />
        {gridItem.audiences.slice(0, endIndex).map((item) => {
          return <Text key={item.id} size={TextSize.XXSMALL} val={item.email} className="-truncated-text spacing-m-b-2" />;
        })}
      </>
    );
  };

  const renderSpamOverlay = (): ReactElement => {
    const spamScorePercentage = `${SPAM_SCORE_THRESHOLD * 100}%`;

    return (
      <a href={getAudienceUrl(gridItem.id.toString())} className={`${styles.overlay} ${styles.spam} flex-v-row`}>
        <Icon className={`hidden-phone hidden-tablet ${styles.spamIcon}`} icon="icon-exclamation-triangle content-danger" shape={IconShape.CIRCLE} />
        <div className={`spacing-p-3 ${styles.spamDesc}`}>
          <Icon className={`spacing-m-b-2 color-danger-100 ${styles.spamIconTop}`} icon="icon-exclamation-triangle" size={IconSize.SIZE_ICON_20} />
          <Text
            dangerouslySetInnerHTML
            val={window.i18next.t('pmwjs_mailing_list_spam_audience', {percentage: spamScorePercentage})}
            size={TextSize.XXSMALL}
            bold
            className={styles.spamText}
          />
        </div>
      </a>
    );
  };

  const renderNonSpamOverlay = (): ReactElement => {
    return (
      <a className={styles.overlay} href={getAudienceUrl(gridItem.id.toString())}>
        <Icon
          icon="icon-pencil"
          isUsedAsButton
          size={IconSize.SIZE_ICON_24}
          shape={IconShape.CIRCLE}
          type={IconType.FLAT}
          flatIconType={{color: FlatIconColor.NEUTRAL, type: FlatIconColorType.DEFAULT}}
        />
      </a>
    );
  };

  const renderTruncatedAudienceDiv = (): ReactElement => {
    return (
      <>
        <div className={`flexbox ${styles.previewEmailTextContainer}`}>
          <Icon icon="icon-menu-dots" size={IconSize.SIZE_ICON_16} className={styles.rotated} />
          <Text
            size={TextSize.XXSMALL}
            className="spacing-m-l-1"
            dangerouslySetInnerHTML
            val={window.i18next.t('pmwjs_email_campaign_audiences_x_more', {num: gridItem.numAudiences - NUM_AUDIENCES_SHOWN_ON_TRUNCATE})}
          />
        </div>
        <div className={styles.truncatedTextOverlay} />
      </>
    );
  };

  return (
    <div className={`${styles.previewEmails} ${gridItem.isSpam ? styles.spam : ''} flex-1 flex-v-row`}>
      {gridItem.isSpam ? renderSpamOverlay() : renderNonSpamOverlay()}
      <div className={`${gridItem.isSpam ? `${styles.spamOpacity} hidden-phone hidden-tablet` : ''} flex-v-row _full-height`}>
        {getPreviewEmailsDiv()}
        {doTruncateAudiences() ? renderTruncatedAudienceDiv() : null}
      </div>
      {getNewAudienceSubscriptionIcon()}
    </div>
  );
}
