export enum USER_TYPES {
  DESIGNER = 'designer',
  TEACHER_DESIGNER = 'teacher-designer',
  TEACHER = 'teacher',
  STUDENT = 'student',
  RESELLER = 'reseller',
  NORMAL = 'normal',
}

export enum USER_PREMIUM_LEVELS {
  PAYG = 0,
  PREMIUM = 1,
  PREMIUM_PLUS = 2,
}

export const isPAYGUser = (userPremiumLevel: USER_PREMIUM_LEVELS): boolean => {
  return userPremiumLevel === USER_PREMIUM_LEVELS.PAYG;
};

export const isPremiumUser = (userPremiumLevel: USER_PREMIUM_LEVELS): boolean => {
  return userPremiumLevel > USER_PREMIUM_LEVELS.PAYG;
};

export const isPremiumPlusUser = (userPremiumLevel: USER_PREMIUM_LEVELS): boolean => {
  return userPremiumLevel === USER_PREMIUM_LEVELS.PREMIUM_PLUS;
};

export const isUserStudent = (userType: USER_TYPES): boolean => {
  return userType === USER_TYPES.STUDENT;
};

export const isUserDesigner = (userType: USER_TYPES): boolean => {
  return userType === USER_TYPES.DESIGNER || userType === USER_TYPES.TEACHER_DESIGNER;
};

export const isUserTeacher = (userType: USER_TYPES): boolean => {
  return userType === USER_TYPES.TEACHER || userType === USER_TYPES.TEACHER_DESIGNER;
};

export const isUserReseller = (userType: USER_TYPES): boolean => {
  return userType === USER_TYPES.RESELLER;
};
