import type {ReactElement} from 'react';
import React from 'react';
import {Text, TextSize} from '@Components/text';
import {Swatches} from '@Components/color-picker-v2/components/swatches';
import styles from './on-my-design-section.module.scss';

interface OnMyDesignSectionProps {
  className?: string;
  colorsOnDesign: Array<string>;

  onColorSelect(color: string): void;
  isMobileVersion?: boolean;
  onClickAdd?(): void;
}

export function OnMyDesignSection({className = '', onClickAdd = (): void => {}, isMobileVersion = false, ...props}: OnMyDesignSectionProps): ReactElement | null {
  return (
    <div className={`flex-v-row ${isMobileVersion ? 'spacing-m-t-0' : 'spacing-m-t-6'} ${className}`}>
      <Text
        size={isMobileVersion ? TextSize.XXSMALL : TextSize.XSMALL}
        bold
        val={window.i18next.t('pmwjs_on_my_design')}
        className={`${isMobileVersion ? '' : styles.onMyDesignHeading} content-body`}
      />
      <Swatches
        onClick={(color: string): void => {
          return props.onColorSelect(color);
        }}
        colors={props.colorsOnDesign}
        showAddButton={isMobileVersion}
        onClickAdd={onClickAdd}
        hasCustomMargins={isMobileVersion}
        className={isMobileVersion ? 'spacing-m-t-2' : ''}
      />
    </div>
  );
}
