import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {GoogleMapReduxState, PlaceDetails} from '@Components/google-map/google-map.types';

const googleMapInitialState: GoogleMapReduxState = {
  place: undefined,
};
const googleMapSlice = createSlice({
  name: 'googleMapSlice',
  initialState: googleMapInitialState,
  reducers: {
    onGoogleMapPlaceChange: (state, action: PayloadAction<PlaceDetails | undefined>): void => {
      state.place = action.payload;
    },
  },
});

export const {onGoogleMapPlaceChange} = googleMapSlice.actions;
export const googleMapReducer = googleMapSlice.reducer;
