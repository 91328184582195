import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {Text, TextSize} from '@Components/text';
import {ClickableDiv} from '@Components/clickable-div';
import {IconSize} from '@Components/icon-v2/icon.types';
import styles from './panel-bottom-pills.module.scss';

export interface PanelBottomPill {
  id: string;
  icon: string;
  text: string;
  isSelected?: boolean;

  onClick(id: string): void;
}

export interface PanelBottomPillsProps {
  className?: string;
  pills: Array<PanelBottomPill>;
  isScrollable?: boolean;
}

export function PanelBottomPills({className = '', ...props}: PanelBottomPillsProps): ReactElement {
  const getIconClasses = (isSelected: boolean | undefined): string => {
    return `spacing-p-t-1 spacing-p-b-1 _full-width
    ${isSelected ? 'content-primary' : 'content-body'}`;
  };

  const getPills = (): Array<ReactElement> => {
    return props.pills.map((pill) => {
      return (
        <ClickableDiv
          className={`flex-column-align-center radius-8 ${styles.pillContainer} ${pill.isSelected ? styles.selectedIcon : ''}  ${props.isScrollable ? styles.smallPill : '_full-width'}`}
          key={pill.id}
          onClick={(): void => {
            pill.onClick(pill.id);
          }}
        >
          <Icon icon={pill.icon} size={IconSize.SIZE_ICON_20} className={getIconClasses(pill.isSelected)} />
          <Text val={pill.text} className={pill.isSelected ? 'content-primary' : 'content-body'} size={TextSize.XXSMALL} bold={pill.isSelected} />
        </ClickableDiv>
      );
    });
  };

  return (
    <div className={`flexbox flex-items-center _full-width spacing-p-2 flex-justify-between ${className} ${styles.pillsContainer} ${props.isScrollable ? styles.scrollable : ''}`}>
      {getPills()}
    </div>
  );
}
