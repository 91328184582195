import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {RootState} from '@/store';

interface StateProps {
  isSpellCheckEnabled: boolean;
  isSpellCheckUpdating: boolean;
}

const initialState: StateProps = {
  isSpellCheckEnabled: true,
  isSpellCheckUpdating: false,
};

const spellCheckSlice = createSlice({
  name: 'spellCheck',
  initialState,
  reducers: {
    updateSpellCheckState(state, action: PayloadAction<boolean>) {
      state.isSpellCheckEnabled = action.payload;
    },

    updateSpellCheckLoadingState(state, action: PayloadAction<boolean>) {
      state.isSpellCheckUpdating = action.payload;
    },
  },
});

export const {updateSpellCheckState, updateSpellCheckLoadingState} = spellCheckSlice.actions;
export const spellCheckReducer = spellCheckSlice.reducer;

export const getIsSpellCheckEnabled = (state: RootState): boolean => {
  return state.spellCheck.isSpellCheckEnabled;
};

export const getIsSpellCheckLoading = (state: RootState): boolean => {
  return state.spellCheck.isSpellCheckUpdating;
};
