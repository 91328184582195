import {createSlice} from '@reduxjs/toolkit';
import {langSelectorReducers} from './language-selector-reducers';
import {langSelectorExtraReducers} from './language-selector-extra-reducers';
import type {StateProps} from './language-selector.types';

const initialState: StateProps = {
  pmwLangs: [],
  pmwLangsVersion: 0,
};

const langSelectorSlice = createSlice({
  name: 'langSelectorReact',
  initialState,
  reducers: langSelectorReducers,
  extraReducers: langSelectorExtraReducers,
});

export const {setPmwLangs} = langSelectorSlice.actions;
export const langSelectorReducer = langSelectorSlice.reducer;
