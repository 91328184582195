import type {ReactElement} from 'react';
import React from 'react';
import styles from './audio-item-wave.module.scss';

const FILL_WITH_BARS_PERCENTAGE = 50;
const TOTAL_BARS = 7;

export interface AudioItemWaveProps {
  isAnimating: boolean;
  width: number;
  height: number;
  color: string;
  className?: string;
}

export default function AudioItemWave({isAnimating, ...props}: AudioItemWaveProps): ReactElement {
  const waveBarWidth = Math.floor((props.width * FILL_WITH_BARS_PERCENTAGE) / 100 / TOTAL_BARS);
  return (
    <div
      style={{height: `${props.height}px`, width: `${props.width}px`}}
      className={`${styles.audioWaveContainer} flex-justify-between flex-h-row flex-items-center ${props.className ?? ''}`}
    >
      <div className={`${styles.waveBar} ${isAnimating ? styles.smallBar : ''}`} style={{height: `50%`, width: `${waveBarWidth}px`, backgroundColor: props.color}} />
      <div className={`${styles.waveBar} ${isAnimating ? styles.bigBar : ''}`} style={{height: `100%`, width: `${waveBarWidth}px`, backgroundColor: props.color}} />
      <div className={`${styles.waveBar} ${isAnimating ? styles.bigBar : ''}`} style={{height: `100%`, width: `${waveBarWidth}px`, backgroundColor: props.color}} />
      <div className={`${styles.waveBar} ${isAnimating ? styles.smallBar : ''}`} style={{height: `50%`, width: `${waveBarWidth}px`, backgroundColor: props.color}} />
      <div className={`${styles.waveBar} ${isAnimating ? styles.bigBar : ''}`} style={{height: `100%`, width: `${waveBarWidth}px`, backgroundColor: props.color}} />
      <div className={`${styles.waveBar} ${isAnimating ? styles.bigBar : ''}`} style={{height: `100%`, width: `${waveBarWidth}px`, backgroundColor: props.color}} />
      <div className={`${styles.waveBar} ${isAnimating ? styles.smallBar : ''}`} style={{height: `50%`, width: `${waveBarWidth}px`, backgroundColor: props.color}} />
    </div>
  );
}
