const READ_BUCKET = 'd1csarkz8obe9u.cloudfront.net';
const READ_BUCKET_LOCAL = 'd3rka4syouiwkp.cloudfront.net';
const WRITE_BUCKET_LOCAL = 'd3rka4syouiwkp.cloudfront.net';
const WRITE_BUCKET_PROD = 'd1csarkz8obe9u.cloudfront.net';
const SCREEN_FOLDER = 'screens';
const THUMB_FOLDER = 'thumbs';

export enum MediaItemSizeType {
  THUMB = 'thumb',
  SCREEN = 'screen',
  HIGH_RES = 'highres',
}

/**
 * Creates a URL to a resource in the S3 repository.
 */
export const repoURL = (filePath: string, bucket: string): string => {
  let url = `https://${bucket}/${filePath}`;
  url += url.indexOf('?') === -1 ? '?' : '&';
  return url;
};

/**
 * The name of the bucket that contains read only files on S3.
 * @return {String}
 */
export function getReadBucket(): string {
  return window.PMW.isLocal() ? READ_BUCKET_LOCAL : READ_BUCKET;
}

/**
 * The name of the bucket that contains writable files on S3.
 * @return {String}
 */
export function getWriteBucket(): string {
  return window.PMW.isLocal() ? WRITE_BUCKET_LOCAL : WRITE_BUCKET_PROD;
}

export const getDirForMediaSizeType = (sizeType: MediaItemSizeType): string => {
  switch (sizeType) {
    case MediaItemSizeType.HIGH_RES:
      return '';
    case MediaItemSizeType.SCREEN:
      return SCREEN_FOLDER;
    case MediaItemSizeType.THUMB:
      return THUMB_FOLDER;
    default:
      throw new Error(`Unknown size type ${sizeType}`);
  }
};
