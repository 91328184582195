import type { GridMediaItemStorage} from '@Components/base-grid/components/grid-media-item';
import {GridMediaItem} from '@Components/base-grid/components/grid-media-item';
import type {GridItemProps, GridStockMediaItemBackendData} from '@Components/base-grid/components/grid-item';
import type {ReactElement} from 'react';
import React, { useState} from 'react';

export interface GridStillStickerItemStorage extends GridMediaItemStorage {
  hashedFilename: string;
}

export interface GridStillStickerItemBackEndData extends GridStockMediaItemBackendData {
  idPMWStockStillSticker: string;
}

interface GridStillStickerItemProps extends GridItemProps {
  thumbSrc: string;
}

export function GridStillStickerItem({...props}: GridStillStickerItemProps): ReactElement {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <GridMediaItem
      {...props}
      isImageLoaded={isImageLoaded}
      thumb={
        <img
          onLoad={(): void => {
            return setIsImageLoaded(true);
          }}
          src={props.thumbSrc}
          className="radius-4"
          alt={window.i18next.t('pmwjs_image')}
          draggable="false"
          loading="lazy"
        />
      }
    />
  );
}
