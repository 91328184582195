import {createAsyncThunk} from '@reduxjs/toolkit';
import type {AuthFormSubmissionParams, LoginSuccessResponse} from '@Components/login-page/login-page.types';
import {AuthFormSubmissionType} from '@Components/login-page/login-page.types';

export const submitAuthForm = createAsyncThunk('webLogin', async (authFormSubmissionParams: AuthFormSubmissionParams, {rejectWithValue}) => {
  try {
    return (await window.PMW.writeLocal(getAjaxRequestUriForAuthFormSubmissionType(authFormSubmissionParams.type), authFormSubmissionParams.requestParams)) as LoginSuccessResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const getAjaxRequestUriForAuthFormSubmissionType = (authFormType: AuthFormSubmissionType) => {
  switch (authFormType) {
    case AuthFormSubmissionType.WEBLOGIN:
    case AuthFormSubmissionType.WEBCONFIRMLOGIN:
      return 'authenticate/weblogin';
    case AuthFormSubmissionType.OAUTHLOGIN:
      return 'authenticate/oAuthLogin';
    case AuthFormSubmissionType.ASYNCLOGIN:
    case AuthFormSubmissionType.ASYNCCONFIRMLOGIN:
      return 'authenticate/asynclogin';
    case AuthFormSubmissionType.ASYNCSTUDENTLOGIN:
      return 'authenticate/studentlogin';
    case AuthFormSubmissionType.WEBSIGNUP:
      return 'authenticate/websignup';
    case AuthFormSubmissionType.ASYNCSIGNUP:
      return 'authenticate/asyncsignup';
    case AuthFormSubmissionType.FORGOTPASSWORD:
      return 'authenticate/emailPasswordReset';
    case AuthFormSubmissionType.LOGGEDINFORGOTPASSWORD:
      return 'authenticate/loggedInUserPasswordReset';
  }
};
