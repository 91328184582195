import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  backgroundRemoved: boolean;
  isBackgroundRemoveDisabled: boolean;
  colorPop: boolean;
  curvedJoints: boolean;
  disableZoomIn: boolean;
  disableZoomOut: boolean;
  disableUndo: boolean;
  disableRedo: boolean;
}

const initialState: StateProps = {
  backgroundRemoved: false,
  isBackgroundRemoveDisabled: false,
  colorPop: false,
  curvedJoints: true,
  disableZoomIn: false,
  disableZoomOut: false,
  disableRedo: true,
  disableUndo: true,
};

const freehandMaskingSlice = createSlice({
  name: 'freehandMasking',
  initialState,
  reducers: {
    toggleIsBackgroundRemoveDisabled(state) {
      state.isBackgroundRemoveDisabled = !state.isBackgroundRemoveDisabled;
    },
    setRemoveBackround(state, action: PayloadAction<boolean>) {
      state.backgroundRemoved = action.payload;
    },
    setColorPop(state, action: PayloadAction<boolean>) {
      state.colorPop = action.payload;
    },
    setCurvedJoints(state, action: PayloadAction<boolean>) {
      state.curvedJoints = action.payload;
    },
    setZoomIn(state, action: PayloadAction<boolean>) {
      state.disableZoomIn = action.payload;
    },
    setZoomOut(state, action: PayloadAction<boolean>) {
      state.disableZoomOut = action.payload;
    },
    setUndo(state, action: PayloadAction<boolean>) {
      state.disableUndo = action.payload;
    },
    setRedo(state, action: PayloadAction<boolean>) {
      state.disableRedo = action.payload;
    },
  },
});

export const {setUndo, setRedo, setZoomIn, setZoomOut, toggleIsBackgroundRemoveDisabled, setRemoveBackround, setColorPop, setCurvedJoints} = freehandMaskingSlice.actions;
export const freehandMaskingReducer = freehandMaskingSlice.reducer;
