import React from 'react';
import PropTypes from 'prop-types';
import './campaign-metric-outcome-status.scss';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

/**
 * Shows the summary for an outcome (positive or negative) for a metric for email marketing campaigns
 * @author Basil <basil@250mils.com>
 */
class CampaignMetricOutcomeStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  OUTCOME_SUMMARY_STATUS = {
    BAD: {
      CLASS: '-bad',
      POSITIVE_OUTCOME_THRESHOLD: {
        LOWER_BOUND: 0,
        UPPER_BOUND: 20,
      },
      NEGATIVE_OUTCOME_THRESHOLD: {
        LOWER_BOUND: 50,
        UPPER_BOUND: 100,
      },
      LABEL: i18next.t('pmwjs_needs_improvement'),
    },
    NEUTRAL: {
      CLASS: '-neutral',
      THRESHOLD: {
        LOWER_BOUND: 20,
        UPPER_BOUND: 50,
      },
      LABEL: i18next.t('pmwjs_moderate_results'),
    },
    GOOD: {
      CLASS: '-good',
      POSITIVE_OUTCOME_THRESHOLD: {
        LOWER_BOUND: 50,
        UPPER_BOUND: 100,
      },
      NEGATIVE_OUTCOME_THRESHOLD: {
        LOWER_BOUND: 0,
        UPPER_BOUND: 20,
      },
      LABEL: i18next.t('pmwjs_good_results'),
    },
  };

  render() {
    if (this.props.hideStatus) {
      return this.#getOutcomeWithoutStatus();
    } else {
      return this.#getOutcomeWithStatus();
    }
  }

  #getOutcomeWithStatus = () => {
    return (
      <OverlayTrigger
        trigger={['hover', 'focus', 'click']}
        delay={{show: 200, hide: 200}}
        overlay={this.#getOutcomeOverlay()}
        defaultShow={undefined}
        flip={undefined}
        onHide={undefined}
        onToggle={undefined}
        placement={undefined}
        popperConfig={undefined}
        show={undefined}
        target={undefined}
      >
        <div className={`campaign-metric-outcome-status flex-row-align-center radius-2 hover-transition-all ${this.#getInteractableClass()} ${this.#getOutcomePercentageClass()}`}>
          <span className={'percentage spacing-p-0'}>{this.props.outcomePercentage}%</span>
          <span className={`status-icon radius-round hover-transition-all`} />
        </div>
      </OverlayTrigger>
    );
  };

  /**
   *
   * @return {PropTypes.ReactElementLike}
   * */
  #getOutcomeOverlay = () => {
    if (this.props.hideTooltip) {
      return <span />;
    }

    return <Tooltip>{this.#getTooltipText()}</Tooltip>;
  };

  #getOutcomeWithoutStatus = () => {
    return (
      <div className={'campaign-metric-outcome-status -no-status flex-center radius-2 hover-transition-all'}>
        <span className={'percentage spacing-p-0'}>{this.props.outcomePercentage}%</span>
      </div>
    );
  };

  #getInteractableClass = () => {
    if (this.props.hideTooltip) {
      return 'non-interactable';
    }

    return '';
  };

  /**
   * @return {string}
   */
  #getTooltipText = () => {
    if (this.#isOutcomePercentageBad()) {
      return this.OUTCOME_SUMMARY_STATUS.BAD.LABEL;
    } else if (this.#isOutcomePercentageNeutral()) {
      return this.OUTCOME_SUMMARY_STATUS.NEUTRAL.LABEL;
    } else {
      return this.OUTCOME_SUMMARY_STATUS.GOOD.LABEL;
    }
  };

  /**
   * @return {string}
   */
  #getOutcomePercentageClass = () => {
    if (this.#isOutcomePercentageBad()) {
      return this.OUTCOME_SUMMARY_STATUS.BAD.CLASS;
    } else if (this.#isOutcomePercentageNeutral()) {
      return this.OUTCOME_SUMMARY_STATUS.NEUTRAL.CLASS;
    } else {
      return this.OUTCOME_SUMMARY_STATUS.GOOD.CLASS;
    }
  };

  /**
   * @return {boolean}
   */
  #isOutcomePercentageBad = () => {
    const value = this.props.outcomePercentage;

    if (this.props.isPositiveOutcome) {
      return value >= this.OUTCOME_SUMMARY_STATUS.BAD.POSITIVE_OUTCOME_THRESHOLD.LOWER_BOUND && value < this.OUTCOME_SUMMARY_STATUS.BAD.POSITIVE_OUTCOME_THRESHOLD.UPPER_BOUND;
    } else {
      return value >= this.OUTCOME_SUMMARY_STATUS.BAD.NEGATIVE_OUTCOME_THRESHOLD.LOWER_BOUND && value < this.OUTCOME_SUMMARY_STATUS.BAD.NEGATIVE_OUTCOME_THRESHOLD.UPPER_BOUND;
    }
  };

  /**
   * @return {boolean}
   */
  #isOutcomePercentageNeutral = () => {
    const value = this.props.outcomePercentage;
    return value >= this.OUTCOME_SUMMARY_STATUS.NEUTRAL.THRESHOLD.LOWER_BOUND && value < this.OUTCOME_SUMMARY_STATUS.NEUTRAL.THRESHOLD.UPPER_BOUND;
  };
}

CampaignMetricOutcomeStatus.propTypes = {
  /**
   * The count of this outcome of this metric, expressed as a percentage of the total count for all outcomes of the relevant metric
   */
  outcomePercentage: PropTypes.number.isRequired,

  /**
   * Whether this is the positive outcome of the relevant metric
   */
  isPositiveOutcome: PropTypes.bool.isRequired,

  /**
   * Whether to hide the status or not (i.e. the icon, colors and tooltip)
   */
  hideStatus: PropTypes.bool,
  /**
   * Whether to hide the tooltip or not;
   */
  hideTooltip: PropTypes.bool,
};

CampaignMetricOutcomeStatus.defaultProps = {
  hideStatus: false,
  hideTooltip: false,
};

export default CampaignMetricOutcomeStatus;
