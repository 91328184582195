import type {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';

export type TableDataType = string | CellData | TimeData | DateData;

export enum DateFormat {
  FORMAT_1 = 'm-d',
  FORMAT_2 = 'd-m',
  FORMAT_3 = 'm-d-y',
  FORMAT_4 = 'm-d-Y',
  FORMAT_5 = 'd-m-y',
  FORMAT_6 = 'M d',
  FORMAT_7 = 'l j F',
  FORMAT_8 = 'l j F Y',
}

export enum TimeFormat {
  STANDARD = '12-h',
  MILITARY = '24-h',
  DISABLE = 'Disable',
}
export const MILLISECONDS_IN_A_DAY = 86400000;
export const TIME_LIST_ITEM_IDS_PREFIX = 'time-format-';
export const DATE_LIST_ITEM_IDS_PREFIX = 'date-format-';
export const TABLE_CONTROLS_LIST = 'User-table-controls';
export const TIME_FORMAT_LIST_ID = 'Schedule-time-format-dropdown-items';
export const DATE_FORMAT_LIST_ID = 'Schedule-date-format-dropdown-items';
export const TABLE_CONTROLS_LIST_IDS = ['table-control-move-forward', 'table-control-move-backward', 'table-control-move-highlight', 'table-control-delete'];
export const TIME_FORMAT_LIST_ITEM_IDS = [
  TIME_LIST_ITEM_IDS_PREFIX + TimeFormat.STANDARD,
  TIME_LIST_ITEM_IDS_PREFIX + TimeFormat.MILITARY,
  TIME_LIST_ITEM_IDS_PREFIX + TimeFormat.DISABLE,
];
export const DATE_FORMAT_LIST_ITEM_IDS = [
  DATE_LIST_ITEM_IDS_PREFIX + DateFormat.FORMAT_1,
  DATE_LIST_ITEM_IDS_PREFIX + DateFormat.FORMAT_2,
  DATE_LIST_ITEM_IDS_PREFIX + DateFormat.FORMAT_3,
  DATE_LIST_ITEM_IDS_PREFIX + DateFormat.FORMAT_4,
  DATE_LIST_ITEM_IDS_PREFIX + DateFormat.FORMAT_5,
  DATE_LIST_ITEM_IDS_PREFIX + DateFormat.FORMAT_6,
  DATE_LIST_ITEM_IDS_PREFIX + DateFormat.FORMAT_7,
  DATE_LIST_ITEM_IDS_PREFIX + DateFormat.FORMAT_8,
];

export interface TimeData {
  hours: string;
  minutes: string;
  meridiem: string;
  timeFormat: TimeFormat;
  type: CellType.TIME;
}

export interface DateData {
  timestamp: string;
  dateFormat: DateFormat;
  type: CellType.DATE;
}

export interface CellData {
  value: string;
  type: CellType.TEAM | CellType.VENUE;
}

export enum TimeDataValue {
  HOURS = 'hours',
  MINUTES = 'minutes',
  MERIDIEM = 'meridiem',
}

export enum TimeMeridiem {
  AM = 'AM',
  PM = 'PM',
}

export enum DateDataValue {
  FORMAT = 'format',
  TIMESTAMP = 'timestamp',
}

export enum CellDirectionType {
  ROW = 'row',
  COLUMN = 'column',
}

export enum DefaultTime {
  HOURS = '12',
  MINUTES = '00',
}

export enum TIME {
  MAX_MINUTES = 59,
  MIN_MINUTES = 0,
  MAX_MILITARY_HOURS = 23,
  MAX_STANDARD_HOURS = 12,
}
