export enum PASSWORD_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  DEFAULT = 'default',
}

export enum PASSWORD_POLICY_ITEM_STATUS {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  IN_PROGRESS = 'in_progress',
  MATCH = 'match',
  MISMATCH = 'mismatch',
}
