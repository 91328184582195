import React from 'react';

export default class ErrorBoundary extends React.Component {
    componentDidCatch = (error, errorInfo) => {
        console.error(error, errorInfo)
        //Enable this after testing it with other react components
        // if(PMW.openErrorModal){
        //     PMW.openErrorModal({
        //         debugDetails: {
        //             error:error,
        //             errorInfo: errorInfo
        //         }
        //     });
        // }
    }

    render = () => {
        return this.props.children;
    }
}
