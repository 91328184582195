import React from "react";
import PropTypes from 'prop-types';
import './progress-bar.scss';


/**
 *
 * @type {number}
 */
const PROGRESS_PERCENTAGE_10 = 10;

/**
 *
 * @type {number}
 */
const PROGRESS_PERCENTAGE_30 = 30;

/**
 *
 * @type {number}
 */
const PROGRESS_PERCENTAGE_60 = 60;

/**
 * Generic progress bar that can be used across the application to show some sort of progress feedback for the user
 * Based on what the percentage is passed to the component, we will show the bar in either an empty state, a 10%, 30%, 60%, or 100% filled state.
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
const ProgressBar = ({progressPercentage, classes = '', isLighter = false, isCustomPercentage = false}) => {

    /**
     * Gets the relevant class to apply to the progress bar based on the progress percentage supplied
     * @returns {string}
     */
    const getProgressClass = () => {
        const percentageRounded = Math.round(progressPercentage);
        if (percentageRounded <= 0) {
            return '-empty';
        }

        if (percentageRounded <= PROGRESS_PERCENTAGE_10) {
            return '-filled-10';
        }

        if (percentageRounded <= PROGRESS_PERCENTAGE_30) {
            return '-filled-30';
        }

        if (percentageRounded <= PROGRESS_PERCENTAGE_60) {
            return '-filled-60';
        }

        if (percentageRounded >= PROGRESS_PERCENTAGE_60) {
            return '-full';
        }

        return '';
    }

    return (
        <div className={`progress-container ${classes} ${isLighter ? '-lighter' : '-default'}`}>
            <span className={`bar-progress  ${!isCustomPercentage ? getProgressClass() : ''}`} style={isCustomPercentage ? {'width': `${progressPercentage}%`} : {}}/>
        </div>
    );
}

ProgressBar.propTypes = {
    /**
     * the current % on this progress bar indicating the progress made thus far
     */
    progressPercentage: PropTypes.number.isRequired,

    /**
     * whether all the steps have been completed in which case, apply other classes to reflect on the UI
     */
    classes: PropTypes.string,

    /**
     * boolean to decide if the background of progress bar will be lighter or not
     */
    isLighter: PropTypes.bool
}

export default ProgressBar;