import type {ReactElement} from 'react';
import React from 'react';
import {AudioItem} from '@Components/base-grid/components/audio-item/audio-item';
import type {GridMediaItemStorage} from '../grid-media-item';
import {GridMediaItem} from '../grid-media-item';
import type {GRID_ITEM_TYPE, GridItemProps} from '../grid-item';

export interface GridStockAudioItemStorage extends GridMediaItemStorage {
  type: GRID_ITEM_TYPE.STOCK_AUDIO;
  audioSrc?: string;
  imgThumbSrc?: string;
  duration?: string;
  name: string;
  data: Record<string, any>;
}

export interface GridStockAudioItemBackEndData {
  [key: string]: any;
}

interface GridStockAudioItemProps extends GridItemProps {
  audioSrc: string;
  imgthumbSrc: string;
  name: string;
  duration: number;
  gridItemWidth?: number;
  gridItemHeight?: number;
  isHidden?: boolean;
  categories?: string[];
}

export function GridStockAudioItem({audioSrc = '', imgthumbSrc = '', ...props}: GridStockAudioItemProps): ReactElement {
  return (
    <GridMediaItem
      {...props}
      paddedThumb={false}
      thumb={
        <AudioItem
          categories={props.categories}
          uid={props.id}
          height={props.gridItemHeight}
          width={props.gridItemWidth}
          title={props.name}
          duration={props.duration}
          source={audioSrc}
        />
      }
    />
  );
}
