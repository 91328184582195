import type {ReactElement} from 'react';
import React from 'react';
import type {GridAudienceItemStorage} from '@Components/email-marketing-campaign-audience/components/audience-grid';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {FlatIconColor, FlatIconColorType, IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {getAudienceUrl, SPAM_SCORE_THRESHOLD} from '@Libraries/email-marketing-campaigns-audience-library';
import {AudienceGridItemOptions} from '../audience-grid-item-options';
import styles from './audience-grid-item-mobile.module.scss';

interface AudienceGridItemMobileProps {
  gridItem: GridAudienceItemStorage;
}

export function AudienceGridItemMobile({gridItem}: AudienceGridItemMobileProps): ReactElement | null {
  const iconColor = gridItem.isSpam ? FlatIconColor.DANGER : FlatIconColor.NEUTRAL;
  const spamScorePercentage = `${SPAM_SCORE_THRESHOLD * 100}%`;

  const getText = (): string => {
    return gridItem.isSpam
      ? window.i18next.t('pmwjs_mailing_list_spam_audience_short', {percentage: spamScorePercentage})
      : window.i18next.t('pmwjs_campaign_x_contacts', {number: gridItem.numAudiences});
  };
  return (
    <div className="_full-width flex-row-align-center">
      <Icon
        icon={gridItem.isSpam ? 'icon-exclamation-triangle' : 'icon-at'}
        className="spacing-m-r-2"
        size={IconSize.SIZE_ICON_24}
        type={IconType.FLAT}
        shape={IconShape.SQUARE}
        flatIconType={{type: FlatIconColorType.DEFAULT, color: iconColor}}
      />

      <a className="flex-v-row flex-1 _full-height _overflow-hidden" href={getAudienceUrl(gridItem.id.toString())} aria-label={gridItem.name}>
        <Text val={gridItem.name} bold className="content-body spacing-m-b-1 -truncated-text" size={TextSize.SMALL} />
        <Text val={getText()} className={gridItem.isSpam ? 'color-danger-150' : 'content-body'} size={TextSize.XXSMALL} dangerouslySetInnerHTML />
      </a>
      <AudienceGridItemOptions gridItem={gridItem} isSpam={!!gridItem.isSpam} isInMobileView />
    </div>
  );
}
