import React from "react";
import './modal-header-close-button.scss';
import {ModalHeaderAction} from "../modal-header-action";

const ModalHeaderCloseButton = (props) => {

    return <ModalHeaderAction
        customClasses="close modal-btn-close"
        iconClass={"icon-close"}
        title={i18next.t('pmwjs_close')}
    />;
}
export default ModalHeaderCloseButton;
