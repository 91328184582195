import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import {InputFieldSize} from '@Components/input-field-v2/input-field.types';
import styles from './input-field-text.module.scss';

interface InputFieldTextProps {
  className?: string;
  text: string;
  icon?: string;
  type: InputFieldTextType;
  size?: InputFieldSize;
  isBold?: boolean;
  isDisabled?: boolean;
}

export enum InputFieldTextType {
  LABEL_TEXT = 'LABEL_TEXT',
  DEFAULT_INFORMATION_TEXT = 'DEFAULT_INFORMATION_TEXT',
  ERROR_INFORMATION_TEXT = 'ERROR_INFORMATION_TEXT',
  SUCCESS_INFORMATION_TEXT = 'SUCCESS_INFORMATION_TEXT',
}

export function InputFieldText({
  type = InputFieldTextType.LABEL_TEXT,
  icon = '',
  size = InputFieldSize.SMALL,
  className = '',
  isBold = false,
  isDisabled = false,
  ...props
}: InputFieldTextProps) {
  const getColorForContainer = () => {
    switch (type) {
      case InputFieldTextType.LABEL_TEXT:
        if (isDisabled) {
          return styles.supplementaryContainerColor;
        }
        return styles.standardContainerColor;
      case InputFieldTextType.DEFAULT_INFORMATION_TEXT:
        return styles.supplementaryContainerColor;
      case InputFieldTextType.ERROR_INFORMATION_TEXT:
        return 'content-danger';
      case InputFieldTextType.SUCCESS_INFORMATION_TEXT:
        return 'content-success';
      default:
        return '';
    }
  };

  const getIcon = () => {
    if (icon) {
      return icon;
    }
    switch (type) {
      case InputFieldTextType.DEFAULT_INFORMATION_TEXT:
        return 'icon-info';
      case InputFieldTextType.ERROR_INFORMATION_TEXT:
        return 'icon-exclamation-triangle';
      case InputFieldTextType.SUCCESS_INFORMATION_TEXT:
        return 'icon-check-circle';
      default:
        return '';
    }
  };

  const getTextSize = () => {
    if (type !== InputFieldTextType.LABEL_TEXT) {
      return TextSize.XXSMALL;
    }
    switch (size) {
      case InputFieldSize.XSMALL:
      case InputFieldSize.SMALL:
        return TextSize.XXSMALL;
      case InputFieldSize.MEDIUM:
        return TextSize.XSMALL;
      default:
        return TextSize.XXSMALL;
    }
  };

  const getContainerClasses = () => {
    const classes = ['flex-row-align-center', getColorForContainer(), className];

    return classes.join(' ');
  };

  return (
    <div className={getContainerClasses()}>
      {type !== InputFieldTextType.LABEL_TEXT ? <Icon icon={getIcon()} size={IconSize.SIZE_ICON_16} className="spacing-m-r-1" /> : null}
      <Text val={props.text} className="" size={getTextSize()} bold={isBold} />
    </div>
  );
}
