import {useEffect} from 'react';
import {closePanel, hidePanel, initPanel, removePanel, triggerPanelClose} from '@Components/panel/panel-reducer';
import {noop} from '@Utils/general.util';
import {useAppDispatch, useAppSelector} from '@/hooks';

const useDefaultPanelClose = (panelId: string, hideOnClose = false, onHide = noop): (() => void) => {
  const dispatch = useAppDispatch();
  const startClosing = useAppSelector((state) => {
    return state.panels.panelHashmap[panelId]?.startClosing ?? false;
  });
  const isHidden = useAppSelector((state) => {
    return state.panels.panelHashmap[panelId]?.isHidden ?? false;
  });

  useEffect(() => {
    dispatch(initPanel({id: panelId, hideOnClose}));

    return (): void => {
      dispatch(removePanel(panelId));
    };
  }, [panelId]);

  useEffect(() => {
    if (startClosing) {
      onClosed();
    }
  }, [startClosing]);

  useEffect(() => {
    if (isHidden) {
      onHide();
    }
  }, [isHidden]);

  const onClosed = (): void => {
    dispatch(closePanel(panelId));
  };

  return (): void => {
    if (hideOnClose) {
      dispatch(hidePanel(panelId));
    } else {
      dispatch(triggerPanelClose(panelId));
    }
  };
};

export default useDefaultPanelClose;
