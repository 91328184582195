import React, {useEffect, useRef} from "react";
import PropTypes from 'prop-types';
import './progress-bar-stop-label.scss';

/**
 * the vertical space between the circle and the label line
 * @type {number}
 */
const VERTICAL_SPACING_OFFSET_FOR_LABEL_LINE = 2;
/**
 * the vertical space offset between the label line and the label
 * @type {number}
 */
const VERTICAL_SPACING_OFFSET_FOR_LABEL = 2;

const ProgressBarStopLabel = ({label, colorClass}) => {
    const labelLineRef = useRef(null),
        labelRef = useRef(null);

    /**
     * @returns {number}
     */
    const getCenteredTextHorizontalPosition = () => {
        return labelLineRef.current.offsetLeft + labelLineRef.current.offsetWidth  - (labelRef.current.offsetWidth / 2);
    }

    const getVerticalPositionOffsetForLabelLine = () => {
        const parentHeight = labelLineRef.current.closest('.js-progress-bar-stop').getBoundingClientRect().height;
        return parentHeight + VERTICAL_SPACING_OFFSET_FOR_LABEL_LINE;
    }

    const getVerticalPositionOffsetForLabel = () => {
        const parentHeight = labelRef.current.closest('.js-progress-bar-stop').getBoundingClientRect().height,
            labelLineHeight = labelLineRef.current.getBoundingClientRect().height;

        return parentHeight + labelLineHeight + VERTICAL_SPACING_OFFSET_FOR_LABEL_LINE + VERTICAL_SPACING_OFFSET_FOR_LABEL;
    }

    const positionLabelAndLabelLine = () => {
        const labelElement = labelRef.current,
            labelLineElement = labelLineRef.current;

        if (labelElement && labelLineElement) {
            labelLineElement.style.top = `${getVerticalPositionOffsetForLabelLine()}px`;
            labelElement.style.left = `${getCenteredTextHorizontalPosition()}px`;
            labelElement.style.top = `${getVerticalPositionOffsetForLabel()}px`;
        }
    }

    useEffect(() => {
        positionLabelAndLabelLine();
    }, [label])

    return (
        <>
            <span className={'progress-bar-stop-label-line'} ref={labelLineRef} />
            <span className={`progress-bar-stop-label caption-xxs ${colorClass}`} ref={labelRef} >{label}</span>
        </>
    );
}

ProgressBarStopLabel.propTypes = {
    /**
     * any lavel to associate with the step
     */
    label: PropTypes.string.isRequired,
    /**
     * the color class to give to the text
     */
    colorClass: PropTypes.string
}

ProgressBarStopLabel.defaultProps = {
    colorClass: 'content-disabled'
}

export default ProgressBarStopLabel;
