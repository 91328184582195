import type {ReactElement} from 'react';
import React from 'react';
import type { PanelFooterProps, PanelHeaderProps} from '@Components/panel';
import {Panel, PanelContent} from '@Components/panel';
import {CircularProgressLoader, LOADER_SIZE} from '@Components/circular-progress-loader';
import {Text, TextSize} from '@Components/text';
import styles from './loading-panel.module.scss';

interface LoadingPanelProps {
  text?: string;
  textClassName?: string;
  header?: ReactElement<PanelHeaderProps> | null;
  footer?: ReactElement<PanelFooterProps> | null;
  loaderSize?: LOADER_SIZE;
}

function LoadingPanel({text = '', textClassName = '', header = null, footer = null, loaderSize = LOADER_SIZE.DEFAULT}: LoadingPanelProps) {
  const getContent = () => {
    return (
      <PanelContent className="flex-center flex-v-row spacing-p-t-7 spacing-p-b-7 spacing-p-l-12 spacing-p-r-12">
        <CircularProgressLoader classes="_unmargin" size={loaderSize} />
        <Text size={TextSize.SMALL} className={`${textClassName} ${styles.message} spacing-m-t-6`} val={text} />
      </PanelContent>
    );
  };

  return <Panel panelContent={getContent()} panelHeader={header} panelFooter={footer} />;
}

export default LoadingPanel;
