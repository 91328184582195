import {useEffect, useState} from 'react';

const DEFAULT_DEBOUNCE_TIMEOUT = 50;

/**
 * Reusable hook that returns the current window width and height
 * The hook is debounced by default at 50ms but the timeout can be changed as per needs
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 * @param {number} debounceTimeout
 *
 */
const useWindowSize = (debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT) => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  });

  useEffect(() => {
    const resizeHandler = window.PMW.debounce(() => {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }, debounceTimeout);

    window.addEventListener('resize', resizeHandler, {passive: true});

    return () => {
      resizeHandler.clear();
      window.removeEventListener('resize', resizeHandler);
    };
  });

  return windowSize;
};

export default useWindowSize;
