import type {ReactElement} from 'react';
import React, { useState} from 'react';
import {Text, TextSize} from '@Components/text';
import {AuthInput} from '@Components/auth-input';
import {INPUT_FIELD_TYPE} from '@Components/input-field';
import {AUTH_TYPE, PASSWORD_PLACEHOLDER} from '@Libraries/login-signup-library';
import {Button, Type} from '@Components/button';
import {ClickableDiv} from '@Components/clickable-div';
import {setIsConnectSSOAccountModal} from '@Components/login-page/login-page-reducer';
import type {VerifyUserEmailAndPasswordResponse} from '@Panels/connect-sso-account-panel/connect-sso-account-panel.types';
import {useUpdateAuthFormTypes} from '@Hooks/login-signup/useUpdateAuthFormTypes';
import {CONNECT_SSO_ACCOUNT_PANEL_ID} from '@Panels/connect-sso-account-panel';
import {closePanel} from '@Components/panel/panel-reducer';
import styles from './connect-account-login-form.module.scss';
import {useAppDispatch} from '@/hooks';

interface ConnectAccountLoginFormProps {
  email: string;
  errorMessage: string;
  onConnectCallback: (email: string, password: string) => void;
}

export function ConnectAccountLoginForm({...props}: ConnectAccountLoginFormProps): ReactElement {
  const [password, setPassword] = useState('');
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(props.errorMessage);
  const [isLoading, setIsLoading] = useState(false);

  const {updateAuthTypes} = useUpdateAuthFormTypes();
  const dispatch = useAppDispatch();

  const isLoginPasswordInvalid = (): boolean => {
    return password.length === 0;
  };

  const handlePasswordInputChange = React.useCallback((txt: string) => {
    setPassword(txt.trim());
  }, []);

  const onSubmit = (): void => {
    setIsPasswordInvalid(isLoginPasswordInvalid());

    if (isLoginPasswordInvalid()) {
      return;
    }

    void verifyUserCredentials();
  };

  const verifyUserCredentials = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = (await window.PMW.writeLocal('authenticate/verifyUserEmailAndPassword', {
        email: props.email,
        password,
      })) as VerifyUserEmailAndPasswordResponse;

      if (response.match) {
        props.onConnectCallback(props.email, password);
        dispatch(closePanel(CONNECT_SSO_ACCOUNT_PANEL_ID));
      } else {
        setIsPasswordInvalid(true);
      }
    } catch (e) {
      setErrorMessage(window.i18next.t('pmwjs_something_wrong_contact_support', {supportLink: window.PMW.util.site_url('user/feedbackform')}));
    }
    setIsLoading(false);
  };

  const onForgotPasswordClick = (): void => {
    updateAuthTypes(AUTH_TYPE.FORGOT_PASSWORD);
    dispatch(setIsConnectSSOAccountModal());
  };

  const getErrorMessage = (): ReactElement => {
    return (
      <div className={`${styles.errorContainer} flex-center spacing-p-t-3 spacing-p-b-3 spacing-p-l-5 spacing-p-r-5`}>
        <Text size={TextSize.XSMALL} val={errorMessage} className={`${styles.errorText}`} dangerouslySetInnerHTML />
      </div>
    );
  };

  const getEmailInputField = (): ReactElement => {
    return <AuthInput label={window.i18next.t('pmwjs_email')} disabled placeholder={props.email} type={INPUT_FIELD_TYPE.EMAIL} handleInput={() => {}} onInputSubmit={() => {}} />;
  };

  const getPasswordInputField = (): ReactElement => {
    return (
      <div className={styles.passwordContainer}>
        <AuthInput
          label={window.i18next.t('pmwjs_password')}
          type={INPUT_FIELD_TYPE.PASSWORD}
          placeholder={PASSWORD_PLACEHOLDER}
          handleInput={handlePasswordInputChange}
          showError={isPasswordInvalid}
          onInputSubmit={onSubmit}
          errorText={window.i18next.t('pmwjs_incorrect_password')}
        />
      </div>
    );
  };

  const getForgotPasswordOption = (): ReactElement => {
    return (
      <ClickableDiv className={`${styles.forgotPassword} spacing-m-t-2 spacing-m-b-8`} onClick={onForgotPasswordClick}>
        <Text val={window.i18next.t('pmwjs_login_forgot_password')} className={`${styles.text} ${styles.link} hover-transition-all`} />
      </ClickableDiv>
    );
  };

  const getFormSubmitButton = (): ReactElement => {
    return (
      <Button
        text={window.i18next.t('pmwjs_separated_login_with_email')}
        type={Type.PRIMARY}
        onClick={onSubmit}
        customClasses="_full-width"
        icon="icon-envelope"
        isLoading={isLoading}
      />
    );
  };

  return (
    <>
      {getErrorMessage()}
      <div className={`${styles.formContainer} spacing-m-t-9 spacing-m-b-13 spacing-m-l-14 spacing-m-r-14`}>
        {getEmailInputField()}
        {getPasswordInputField()}
        {getForgotPasswordOption()}
        {getFormSubmitButton()}
      </div>
    </>
  );
}
