import type {ReactElement} from 'react';
import React, { useState} from 'react';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {isUserPremium} from '@Libraries/user.library';
import {Icon} from '@Components/icon-v2';
import {noop} from '@Utils/general.util';
import styles from './calendar-time-picker.module.scss';
import {Button, Size, Type} from '../button';
import {ToggleButton} from '../toggle-button';
import type {CalendarBase, CalendarCallback, CalendarCallbackWithTime, CalendarItemBaseStorage} from '../calendar-date-time-picker/calendar.types';
import {Text} from '../text';
import './calendar-time-picker.scss';

/**
 * @author < Haseeb Chaudhry haseebch@250mils.com >
 */
const DEFAULT_HOUR_VALUE = 10;
const TWELFTH_HOUR_VALUE = 12;

type BaseProps = CalendarItemBaseStorage & CalendarBase;

interface CalendarTimePickerProps extends BaseProps {
  onBackCalendarView?: CalendarCallbackWithTime;
  incrementDate?: CalendarCallback;
  decrementDate?: CalendarCallback;
  dateState?: Date;
  onSubmitButton?: CalendarCallbackWithTime;
  submitButtonText?: string;
  onChangeTime?: (newTime: any) => void;
  showScheduleButton?: boolean;
}

export function CalendarTimePicker({
  hours = DEFAULT_HOUR_VALUE,
  minutes = 0,
  isPMChecked = false,
  onBackCalendarView = noop,
  incrementDate = noop,
  decrementDate = noop,
  dateState = new Date(),
  onSubmitButton = noop,
  minDate = new Date(),
  submitButtonText = window.i18next.t('pmwjs_schedule'),
  className = '',
  showBoxShadow = true,
  isFullWidth = false,
  onChangeTime = noop,
  showScheduleButton = true,
}: CalendarTimePickerProps): ReactElement {
  const [time, setTime] = useState<CalendarItemBaseStorage>({
    hours,
    minutes,
    isPMChecked,
  });

  const isHourValueNotAllowed = (hourVal: number): boolean => {
    return hourVal > TWELFTH_HOUR_VALUE || hourVal < 1;
  };

  const setHour = (hour: string): void => {
    let hourVal = parseInt(hour, 10);

    if (isHourValueNotAllowed(hourVal)) {
      hourVal = 1;
    }

    setTime((previousState) => {
      return {...previousState, hours: hourVal};
    });
    onChangeTime({...time, hours: hourVal});
  };

  const setMinutes = (newMinutes: string): void => {
    let minutesVal = parseInt(newMinutes, 10);
    if (Number.isNaN(minutesVal) || minutesVal > 59) {
      minutesVal = 0;
    }
    setTime((previousState) => {
      return {...previousState, minutes: minutesVal};
    });
    onChangeTime({...time, minutes: minutesVal});
  };

  const incrementHour = (): void => {
    let newHour = time.hours + 1;
    if (newHour > TWELFTH_HOUR_VALUE) {
      newHour = 1;
    }
    setTime((previousState) => {
      return {...previousState, hours: newHour};
    });
    onChangeTime({...time, hours: newHour});
  };

  const decrementHour = (): void => {
    let newHour = time.hours - 1;

    if (newHour < 1) {
      newHour = TWELFTH_HOUR_VALUE;
    }
    setTime((previousState) => {
      return {...previousState, hours: newHour};
    });
    onChangeTime({...time, hours: newHour});
  };

  const incrementMinutes = (): void => {
    const newMinutes = time.minutes + 15;
    if (newMinutes >= 60) {
      incrementHour();
      setTime((previousState) => {
        return {...previousState, minutes: 0};
      });
    } else {
      setTime((previousState) => {
        return {...previousState, minutes: newMinutes};
      });
    }

    onChangeTime({...time, minutes: newMinutes});
  };

  const decrementMinutes = (): void => {
    const newMinutes = time.minutes - 15;
    if (newMinutes < 0) {
      decrementHour();
      setTime((previousState) => {
        return {...previousState, minutes: 45};
      });
    } else {
      setTime((previousState) => {
        return {...previousState, minutes: newMinutes};
      });
    }

    onChangeTime({...time, minutes: newMinutes});
  };

  const getFormattedValues = (value: number): string => {
    if (value < 10) {
      return `0${value}`;
    }
    return value.toString();
  };

  const getOutputDate = (): string => {
    const month = dateState.toLocaleString('default', {month: 'short'});
    return `${dateState?.getDate().toString()} ${month} ${dateState?.getFullYear().toString()}`;
  };

  const onAMPMToggle = (newisPMChecked: boolean): void => {
    setTime((previousState) => {
      return {...previousState, isPMChecked: newisPMChecked};
    });

    onChangeTime({...time, isPMChecked: newisPMChecked});
  };

  const isPreviousDateDisabled = (): boolean => {
    return !(dateState >= minDate);
  };

  const get24HourFormatTime = (date: Date): Date => {
    const currDate = new Date(date);
    let formatTimeHours = time.hours;

    if (formatTimeHours === TWELFTH_HOUR_VALUE) {
      formatTimeHours = 0;
    }
    if (time.isPMChecked) {
      formatTimeHours += TWELFTH_HOUR_VALUE;
    }

    currDate.setHours(formatTimeHours, time.minutes);
    return currDate;
  };

  const isTimeNotAllowed = (): boolean => {
    const selectedDateTime = get24HourFormatTime(dateState);
    return !(selectedDateTime >= minDate);
  };

  const renderWarningMessage = () => {
    if (isTimeNotAllowed()) {
      return (
        <span className={styles.errorMessage}>
          <i className={`${styles.errorIcon} icon-exclamation-triangle size-icon-16 error-icon`} />
          {window.i18next.t('pmwjs_time_passed')}
        </span>
      );
    }
    return null;
  };

  const handleBlur = (): void => {
    if (Number.isNaN(time.hours) || time.hours > TWELFTH_HOUR_VALUE || time.hours < 1) {
      setTime((previousState) => {
        return {...previousState, hours: 1};
      });

      onChangeTime({...time, hours: 1});
    }
  };

  const renderTimeInput = (): ReactElement => {
    return (
      <div className={styles.timeInput}>
        <input
          className={`${styles.hoursInput} border-s-darker content-body`}
          value={getFormattedValues(time.hours)}
          type="number"
          onBlur={handleBlur}
          onChange={(e): void => {
            setHour(e.target.value);
          }}
        />
        <Text className={`${styles.timeInterval} body-s-bold`} val=":" />
        <input
          className={`${styles.minutesInput} border-s-darker content-body`}
          value={getFormattedValues(time.minutes)}
          type="number"
          onChange={(e): void => {
            setMinutes(e.target.value);
          }}
        />
        <ToggleButton isChecked={time.isPMChecked} onChangeHandler={onAMPMToggle} leftOptionValue={window.i18next.t('pmwjs_am')} rightOptionValue={window.i18next.t('pmwjs_pm')} />
      </div>
    );
  };

  const renderScheduleCta = (): ReactElement => {
    if (!showScheduleButton) {
      return <></>;
    }

    if (isUserPremium()) {
      return (
        <Button
          type={Type.PRIMARY}
          size={Size.MEDIUM}
          customClasses={`${styles.footerButton} -fullwidth`}
          text={submitButtonText}
          onClick={(): void => {
            onSubmitButton(time);
          }}
          disabled={isTimeNotAllowed()}
        />
      );
    }
    return (
      <Button
        type={Type.PREMIUM}
        size={Size.MEDIUM}
        icon="icon-crown"
        customClasses={`${styles.footerButton} -fullwidth`}
        text={submitButtonText}
        onClick={(): void => {
          onSubmitButton(time);
        }}
      />
    );
  };

  return (
    <div className={`${styles.calendarTime} react-calendar border-s-standard ${className} ${!showBoxShadow ? styles.noShadow : ''} ${isFullWidth ? styles.fullWidth : ''}`}>
      <div className={styles.calendarTimeNavigation}>
        <Icon
          icon="icon-caret-left"
          shape={IconShape.SQUARE}
          type={IconType.SECONDARY}
          size={IconSize.SIZE_ICON_20}
          isDisabled={isPreviousDateDisabled()}
          onClick={decrementDate}
        />
        <Button
          customClasses={`${styles.dateButton} flex-1`}
          textClasses={styles.textButton}
          type={className ? Type.GHOST : Type.SECONDARY}
          size={Size.SMALL}
          text={getOutputDate()}
          onClick={(): void => {
            onBackCalendarView(time);
          }}
        />
        <Icon icon="icon-caret-right" shape={IconShape.SQUARE} type={IconType.SECONDARY} size={IconSize.SIZE_ICON_20} onClick={incrementDate} />
      </div>

      <div className={`${styles.timeContainer} ${styles.increase}`}>
        <div className={`flex-v-row _full-width ${styles.timeInputsContainer}`}>
          <div className={styles.timeChange}>
            <Icon
              icon="icon-caret-up"
              shape={IconShape.SQUARE}
              type={IconType.TRANSPARENT}
              size={IconSize.SIZE_ICON_20}
              onClick={incrementHour}
              className={styles.hoursChange}
            />
            <Icon icon="icon-caret-up" shape={IconShape.SQUARE} type={IconType.TRANSPARENT} size={IconSize.SIZE_ICON_20} onClick={incrementMinutes} />
          </div>

          {renderTimeInput()}

          <div className={`${styles.timeChange} ${styles.decrease}`}>
            <Icon
              icon="icon-caret-down"
              shape={IconShape.SQUARE}
              type={IconType.TRANSPARENT}
              size={IconSize.SIZE_ICON_20}
              onClick={decrementHour}
              className={styles.hoursChange}
            />
            <Icon icon="icon-caret-down" shape={IconShape.SQUARE} type={IconType.TRANSPARENT} size={IconSize.SIZE_ICON_20} onClick={decrementMinutes} />
          </div>
        </div>

        {renderWarningMessage()}
        {renderScheduleCta()}
      </div>
    </div>
  );
}
