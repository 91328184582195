import React from "react";
import './modal-header-actions.scss';
import {ModalHeaderCloseButton} from "../modal-header-close-button";
import PropTypes from "prop-types";

const ModalHeaderActions = ({dismissible = true, actions = []}) => {
    const rows = [];

    if (actions) {
        actions.map((action) => {
            rows.push(action);
        });
    }
    if (dismissible) {
        rows.push(<ModalHeaderCloseButton key={'btn-modal-header-close'}/>);
    }

    return <div className="modal-header-actions">{rows}</div>;
}

ModalHeaderActions.propTypes = {
    actions: PropTypes.array,
    dismissible: PropTypes.bool,
};

export default ModalHeaderActions;
