import {
  deleteEmail,
  deleteMailingList,
  getEmailsByMailingListId,
  updateAllContactFieldsForMailingListAudience,
  updateMailingListName,
  getUserMailingListsForSignUpForms,
} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-thunk';
import type {ActionReducerMapBuilder} from '@reduxjs/toolkit/src/mapBuilders';
import type {NoInfer} from '@reduxjs/toolkit/src/tsHelpers';
import type {AudienceMapDataPayload, AudienceState} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-reducer';
import {
  doUpdateCreateSignUpFormPanelLists,
  doUpdateAudienceDataForMailingListAudience,
  addAudience,
  addAudienceIdsToMailingList,
  addMailingList,
  changeMailingListName,
  removeAudience,
  removeMailingList,
} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-reducer';
import type {UserSignupFormMailingListData} from '@Panels/create-signup-form-panel/create-signup-form-panel.types';
import {getEmailStatesEnumValues} from '@Libraries/email-marketing-campaigns-audience-library';
import type {MailingList} from '@Components/mailing-list-wizard/mailing-list-wizard.types';

export const emailMarketingCampaignAudienceExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<AudienceState>>) => {
  builder.addCase(getEmailsByMailingListId.fulfilled, (state, {payload}) => {
    const audienceIds = [];
    for (let i = 0; i < payload.audiences.length; i++) {
      addAudience(state, payload.audiences[i]);
      audienceIds.push(payload.audiences[i].id);
    }

    if (!state.mailingLists[payload.idMailingList]) {
      const getEmailsByMailingListIdParams: MailingList = {
        emailIds: audienceIds,
        name: payload.name,
        totalEmailsCount: payload.count,
        filters: getEmailStatesEnumValues().reduce((obj, emailState) => {
          return {...obj, [emailState]: true};
        }, {}),
        searchTerm: '',
        isSpam: payload.isSpam,
        lastViewed: payload.lastViewed,
      };

      addMailingList(state, payload.idMailingList, getEmailsByMailingListIdParams);
    } else {
      addAudienceIdsToMailingList(state, payload.idMailingList, audienceIds);
    }
  });

  builder.addCase(deleteEmail.fulfilled, (state, {meta}) => {
    const {audience, idMailingList, index} = meta.arg;
    const idAudience = audience.id;

    removeAudience(state, idMailingList, idAudience, index);
  });

  builder.addCase(updateMailingListName.pending, (state, {meta}) => {
    const {idMailingList, newName} = meta.arg;
    changeMailingListName(state, idMailingList, newName);
  });

  builder.addCase(updateMailingListName.rejected, (state, {meta}) => {
    const {idMailingList, currentName} = meta.arg;
    changeMailingListName(state, idMailingList, currentName);
  });

  builder.addCase(deleteMailingList.fulfilled, (state, {meta}) => {
    const {idMailingList} = meta.arg;
    removeMailingList(state, idMailingList);
  });

  builder.addCase(updateAllContactFieldsForMailingListAudience.fulfilled, (state, {meta}) => {
    const {arg} = meta;
    doUpdateAudienceDataForMailingListAudience(state, arg as AudienceMapDataPayload);
  });

  builder.addCase(getUserMailingListsForSignUpForms.fulfilled, (state, {payload}) => {
    doUpdateCreateSignUpFormPanelLists(
      state,
      payload.mailingLists.map((mailingList: MailingList) => {
        return {
          id: mailingList.id ?? 0,
          name: mailingList.name,
        } as UserSignupFormMailingListData;
      })
    );
  });
};
