import type {ReactElement} from 'react';
import React from 'react';
import {LabelledCheckbox} from '@Components/labelled-checkbox';
import styles from './checkbox-filters.module.scss';
import type {Filter, AllFilter, FiltersHashmap, OnFiltersChangeHandler} from './checkbox-filters.types';

export interface CheckboxFiltersProps {
  filters: Array<Filter>;
  onChange: OnFiltersChangeHandler;
  /**
   * setting true will add a filter at the top as the 'all' option which will perform logic accordingly.
   * When 'all' gets selected, the rest get unselected. When some other option gets selected, 'all' gets unselected.
   */
  doFiltersIncludeAllOption?: boolean;
  allFilterConfig?: AllFilter;
  /**
   * specify the minimum number of checkboxes that need to be selected at all times.
   * the onChange handler will not fire if a checkbox is being de-selected if for example minSelectionNumber = 1
   * and that checkbox is the only one selected
   */
  minSelectionNumber?: number;
}

const DEFAULT_MIN_SELECTION_COUNT = 1;

export function CheckboxFilters({
  filters,
  onChange,
  allFilterConfig,
  doFiltersIncludeAllOption = false,
  minSelectionNumber = DEFAULT_MIN_SELECTION_COUNT,
}: CheckboxFiltersProps): ReactElement {
  if (minSelectionNumber > filters.length) {
    throw new Error('You cannot send a minSelectionNumber greater than the number of filters!!!!');
  }

  const getFiltersHashmapInCheckedState = (): FiltersHashmap => {
    const filtersHashmap: FiltersHashmap = {};
    filters.forEach((filter) => {
      filtersHashmap[filter.dataValue] = true;
    });

    return filtersHashmap;
  };

  const getFiltersHashmapInUnCheckedState = (): FiltersHashmap => {
    const filtersHashmap: FiltersHashmap = {};
    filters.forEach((filter) => {
      filtersHashmap[filter.dataValue] = false;
    });

    return filtersHashmap;
  };

  const getFiltersHashmap = (): FiltersHashmap => {
    const filtersHashmap: FiltersHashmap = {};
    filters.forEach((filter) => {
      filtersHashmap[filter.dataValue] = filter.isChecked;
    });

    return filtersHashmap;
  };

  const areAllFiltersChecked = (): boolean => {
    return (
      filters.filter((filter) => {
        return !filter.isChecked;
      }).length === 0
    );
  };

  const isCheckedCountGoingBelowMinThreshold = (filterDataValue: string): boolean => {
    const allCheckedFilters = filters.filter((filter) => {
      return filter.isChecked;
    });
    const isFilterChecked = !!allCheckedFilters.find((filter) => {
      return filter.dataValue === filterDataValue;
    });

    return allCheckedFilters.length === minSelectionNumber && isFilterChecked;
  };

  const filtersHashmap: FiltersHashmap = getFiltersHashmap();
  const areAllFiltersSelected = doFiltersIncludeAllOption && areAllFiltersChecked();

  const onFilterChange = (dataValue: string, isCheckedNew: boolean): void => {
    if (isCheckedCountGoingBelowMinThreshold(dataValue)) {
      return;
    }

    let newState: FiltersHashmap;

    if (areAllFiltersSelected) {
      newState = {
        ...getFiltersHashmapInUnCheckedState(),
        [dataValue]: true,
      };
    } else {
      newState = {
        ...filtersHashmap,
        [dataValue]: isCheckedNew,
      };
    }

    onChange(newState);
  };

  const onAllFilterChecked = (): void => {
    if (areAllFiltersSelected) {
      return;
    }

    onChange(getFiltersHashmapInCheckedState());
  };

  const getCheckboxes = (): Array<ReactElement> => {
    const checkboxes: Array<ReactElement> = [];

    if (doFiltersIncludeAllOption) {
      checkboxes.push(
        <LabelledCheckbox
          {...(allFilterConfig ?? {})}
          className={`${styles.marginBottom} ${allFilterConfig?.className}`}
          key="all-filter"
          label={allFilterConfig?.label ?? window.i18next.t('pmwjs_all')}
          isChecked={areAllFiltersSelected}
          onChange={onAllFilterChecked}
        />
      );
    }

    filters.forEach((filter) => {
      return checkboxes.push(
        <LabelledCheckbox
          {...filter}
          className={`${styles.marginBottom} ${filter.className ?? ''}`}
          key={filter.dataValue}
          isChecked={areAllFiltersSelected ? false : filter.isChecked}
          onChange={(newCheckedState: boolean): void => {
            onFilterChange(filter.dataValue, newCheckedState);
          }}
        />
      );
    });

    return checkboxes;
  };

  return <>{getCheckboxes()}</>;
}
