import type {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import type {NoInfer} from '@reduxjs/toolkit/dist/tsHelpers';
import type {TemplateInfo, TemplateSubmissionFlowReducerState, ImproveTemplatesSortType} from '@Components/template-submission-flow/template-submission-flow.types';
import {SubmissionFlowStep} from '@Components/template-submission-flow/template-submission-flow.types';
import {addDesignStylesToTemplate, skipTemplate, submitTemplate} from '@Components/template-submission-flow/template-submission-flow-thunk';
import {LoadingStates} from '@Utils/loading.util';
import {goToPosterViewPage} from '@Libraries/template-submission-library';

export const templateSubmissionFlowExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<TemplateSubmissionFlowReducerState>>): void => {
  builder.addCase(submitTemplate.fulfilled, (state) => {
    state.isSubmitting = false;
    state.currentStep = SubmissionFlowStep.IN_REVIEW;
    state.nextStep = SubmissionFlowStep.IN_REVIEW;
  });
  builder.addCase(submitTemplate.pending, (state) => {
    state.isSubmitting = true;
  });
  builder.addCase(submitTemplate.rejected, (state) => {
    state.isSubmitting = false;
  });
  builder.addCase(addDesignStylesToTemplate.fulfilled, (state, {payload}) => {
    state.isSubmitting = false;
    state.showSuccessBanner = true;
    decrementOffset(state);
    setTemplateDataFromPayload(state, payload?.templatesInfo);
    incrementNumTemplatesWithDesignStyles(state);
    removeDuplicateTemplates(state);
    redirectForPosterView(state);
  });
  builder.addCase(addDesignStylesToTemplate.pending, (state) => {
    state.isSubmitting = true;
    resetWindowScroll(state);
  });
  builder.addCase(addDesignStylesToTemplate.rejected, (state) => {
    state.isSubmitting = false;
    state.improveTemplatesLoadingState = LoadingStates.NOT_LOADED;
  });
  builder.addCase(skipTemplate.fulfilled, (state, {payload}) => {
    setTemplateDataFromPayload(state, payload?.templatesInfo);
    addToProcessedTemplates(state);
    removeDuplicateTemplates(state);
    if (state.templatesToProcess.length === 0) {
      return;
    }
    doSkipTemplate(state);
    state.improveTemplatesLoadingState = LoadingStates.LOADED;
  });
  builder.addCase(skipTemplate.pending, (state) => {
    state.improveTemplatesLoadingState = LoadingStates.LOADING;
  });
  builder.addCase(skipTemplate.rejected, (state) => {
    state.improveTemplatesLoadingState = LoadingStates.NOT_LOADED;
  });
};

const decrementOffset = (state: TemplateSubmissionFlowReducerState): void => {
  if (state.offset > 0) {
    state.offset -= 1;
  }
};

const setTemplateDataFromPayload = (state: TemplateSubmissionFlowReducerState, ajaxResponse?: TemplateInfo[]): void => {
  if (ajaxResponse) {
    state.templatesToProcess = state.templatesToProcess.concat(ajaxResponse);
    state.doLoadMoreTemplates = ajaxResponse.length > 0;
    state.offset += ajaxResponse.length;
  }
};

const removeDuplicateTemplates = (state: TemplateSubmissionFlowReducerState): void => {
  const processedTemplateIds = state.processedTemplates.map((item) => {
    return item.hashedId;
  });
  state.templatesToProcess = state.templatesToProcess.filter((item) => {
    return !processedTemplateIds.includes(item.hashedId);
  });
};

const redirectForPosterView = (state: TemplateSubmissionFlowReducerState): void => {
  if (state.isPosterViewFlow) {
    goToPosterViewPage(state.selectedPosterHashedId);
  }
};

const resetWindowScroll = (state: TemplateSubmissionFlowReducerState): void => {
  if (state.isImproveTemplatesFlow) {
    window.scrollTo(0, 0);
  }
};

export const resetSelectedDesignStyles = (state: TemplateSubmissionFlowReducerState): void => {
  state.selectedDesignStyles = {};
};

export const updateSelectedPosterData = (state: TemplateSubmissionFlowReducerState): void => {
  const currentTemplateInfo = state.templatesToProcess.shift();
  if (!currentTemplateInfo) {
    return;
  }

  state.selectedPosterHashedId = currentTemplateInfo.hashedId;
  state.selectedPosterPreviewUrl = currentTemplateInfo.previewUrl;
  state.templateName = currentTemplateInfo.displayName;
};

export const updateUrl = (hashedId: string, sortType: ImproveTemplatesSortType): void => {
  history.replaceState('', '', window.PMW.util.site_url(`designers/improveTemplates/${hashedId}?sortType=${sortType}`));
};

export const incrementNumTemplatesWithDesignStyles = (state: TemplateSubmissionFlowReducerState): void => {
  state.numTemplatesWithDesignStyles += 1;
};

export const addToProcessedTemplates = (state: TemplateSubmissionFlowReducerState): void => {
  state.processedTemplates.push({hashedId: state.selectedPosterHashedId, previewUrl: state.selectedPosterPreviewUrl, displayName: state.templateName});
};

export const doSkipTemplate = (state: TemplateSubmissionFlowReducerState): void => {
  resetSelectedDesignStyles(state);
  updateSelectedPosterData(state);
  updateUrl(state.selectedPosterHashedId, state.improveTemplatesSortType);
};
