import React from "react";
import PropTypes from 'prop-types';
import DataTable from "react-data-table-component";
import './detailed-list.scss';
import {ITEM_TYPES, METRIC_TYPES} from "../../../../libraries/email-marketing-campaigns-library";
import {MetricInsights} from "../metric-insights";
import {PMW_COLORS_NEUTRAL} from "@Utils/color.util";
import {Text, TextSize} from "@Components/text";

/**
 * Shows details and stats about all recipients of an email marketing campaign or of an audience list in a DataTable.
 * @author Basil <basil@250mils.com>
 */
class DetailedList extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'detailed-list'}>
                <p className={'body-l-bold'}>{i18next.t('pmwjs_detailed_list')}</p>
                <DataTable
                    columns={this.#getColumns()}
                    data={this.#getRowData()}
                    striped={true}
                    pagination={true}
                    paginationComponentOptions={this.#getPaginationComponentOptions()}
                    customStyles={this.#getCustomStyles()}
                    conditionalRowStyles={this.#getConditionalRowStyles()}
                    sortIcon={<i className={'icon-caret-down custom-sort-icon'}/>}
                />
            </div>
        );
    }

    /**
     *
     * @return {Object}
     */
    #getPaginationComponentOptions = () => {
        return {
            rowsPerPageText: i18next.t('pmwjs_results_per_page')
        }
    }

    /**
     *
     * @return {Object}
     */
    #getCustomStyles = () => {
        return {
            headRow: {
                style: {
                    borderTopWidth: '1px',
                    borderTopColor: PMW_COLORS_NEUTRAL.NEUTRAL_6,
                    borderTopStyle: 'solid',
                    borderBottomColor: PMW_COLORS_NEUTRAL.NEUTRAL_6
                }
            },
            rows: {
                style: {
                    fontSize: '14px',
                    '&:not(:last-of-type)': {
                        borderBottomWidth: '0'
                    }
                },
                stripedStyle: {
                    backgroundColor: PMW_COLORS_NEUTRAL.NEUTRAL_1
                }
            }
        }
    }

    /**
     * @return {*[]}
     */
    #getColumns = () => {
        let columns = [];
        if (this.#isCampaignItemType()) {
            columns = this.#getColumnsForCampaignDetailsTable();
        } else if (this.#isAudienceItemType()) {
            columns = this.#getColumnsForMailingListDetailsTable();
        }
        return columns;
    }

    /**
     * @return {*[]}
     */
    #getRowData = () => {
        let data = [];
        if (this.#isCampaignItemType()) {
            data = Object.values(this.props.data['details']);
        } else if (this.#isAudienceItemType()) {
            data = Object.values(this.props.data['insights']);
        }
        return data;
    }

    #getColumnsForCampaignDetailsTable = () => {
        return [
            {
                name: this.#getTableHeaderCell(i18next.t('pmwjs_recipient')),
                selector: row => row.email,
                sortable: true
            },
            {
                name: this.#getTableHeaderCell(i18next.t('pmwjs_reached'), this.props.data[METRIC_TYPES.DELIVERY]['positiveOutcomeTotal'], 'icon-envelope size-icon-16 content-link'),
                selector: row => row.isDelivered,
                conditionalCellStyles: [
                    {
                        when: row => row.isDelivered,
                        classNames: this.#getClassNamesForSuccessCheckIcon()
                    },
                    {
                        when: row => !row.isDelivered,
                        classNames: this.#getClassNamesForCloseIcon()
                    }
                ],
                center: true,
                format: () => '',
                sortable: true
            },
            {
                name: this.#getTableHeaderCell(i18next.t('pmwjs_opened'), this.props.data[METRIC_TYPES.OPEN]['positiveOutcomeTotal'], 'icon-inbox size-icon-16 color-success'),
                selector: row => row.numOpens,
                conditionalCellStyles: [
                    {
                        when: row => row.numOpens > 0,
                        classNames: this.#getClassNamesForSuccessCheckIcon()
                    }
                ],
                center: true,
                format: () => '',
                sortable: true
            },
            {
                name: this.#getTableHeaderCell(i18next.t('pmwjs_clicked'), this.props.data[METRIC_TYPES.CLICK]['positiveOutcomeTotal'], 'icon-mouse-click size-icon-16 color-success'),
                selector: row => row.numClicks,
                cell: (row) => {
                    const clickInsights = row.clickInsights;
                    let links = clickInsights['links'] !== undefined ? row.clickInsights['links'] : [],
                        linkToShow = '';
                    if (links.length > 0) {
                        linkToShow = links[0].name;
                        if (links.length === 1) {
                            links = [];
                        } else {
                            links = links.slice(1);
                        }
                    }
                    return row.numClicks > 0 ? (<MetricInsights value={linkToShow} isLink={true} isPopupMenuItemLink={true} showMoreLabel={row.clickInsights['showMoreLabel']} showMorePopupMenuData={links}/>) : '';
                },
                sortable: true
            },
            {
                name: this.#getTableHeaderCell(i18next.t('pmwjs_unsubscribed'), this.props.data[METRIC_TYPES.CLICK]['negativeOutcomeTotal'], 'icon-user-x size-icon-16 content-sub-text'),
                selector: row => row.isUnsubscribe,
                conditionalCellStyles: [
                    {
                        when: row => row.isUnsubscribe && !row.isInvalid && !row.isDeleted,
                        classNames: this.#getClassNamesForDangerCheckIcon()
                    }
                ],
                center: true,
                format: row => this.#getFormatForLastColumnCell(row),
                sortable: true
            }
        ];
    }

    /**
     * @param {String} label
     * @param {Number} [value]
     * @param {String} [iconClass]
     * @return {JSX.Element}
     */
    #getTableHeaderCell = (label, value, iconClass) => {
        return (
            <div className={'table-header-cell flex-row-align-center'}>
                {iconClass ? <i className={iconClass}/> : null}
                <span className={'table-header-label body-xs-bold'}>{label}</span>
                <span className={'table-header-value body-xs'}>{value !== undefined ? '(' + value + ')' : ''}</span>
            </div>
        );
    }

    #getColumnsForMailingListDetailsTable = () => {
        return [
            {
                name: this.#getTableHeaderCell(i18next.t('pmwjs_email')),
                selector: row => row.email,
                sortable: true
            },
            {
                name: this.#getTableHeaderCell(i18next.t('pmwjs_opens'), this.props.data['numOpens'], 'icon-inbox size-icon-16 color-success'),
                selector: row => row.numOpens,
                cell: row => row.numOpens > 0 ? (
                    <MetricInsights value={row.numOpens} isLink={false} isPopupMenuItemLink={false} showMoreLabel={i18next.t('pmwjs_show_campaigns')} showMorePopupMenuData={row.opens} itemType={ITEM_TYPES.CAMPAIGNS}/>) : '',
                sortable: true
            },
            {
                name: this.#getTableHeaderCell(i18next.t('pmwjs_clicks'), this.props.data['numClicks'], 'icon-mouse-click size-icon-16 color-success'),
                selector: row => row.numClicks,
                cell: row => row.numClicks > 0 ? (<MetricInsights value={row.numClicks} isLink={false} isPopupMenuItemLink={true} showMoreLabel={i18next.t('pmwjs_show_links')} showMorePopupMenuData={row.links}/>) : '',
                sortable: true
            },
            {
                name: this.#getTableHeaderCell(i18next.t('pmwjs_unsubscribed'), this.props.data['numUnsubscribes'], 'icon-user-x size-icon-16 content-sub-text'),
                selector: row => row.isUnsubscribe,
                center: true,
                conditionalCellStyles: [
                    {
                        when: row => row.isUnsubscribe && !row.isInvalid && !row.isDeleted,
                        classNames: this.#getClassNamesForDangerCheckIcon()
                    }
                ],
                format: row => this.#getFormatForLastColumnCell(row),
                sortable: true
            }
        ];
    }

    /**
     * @param {Object} row
     * @return {string|*}
     */
    #getFormatForLastColumnCell = (row) => {
        if (row.isInvalid && !row.isDeleted) {
            return this.#getRemoveInvalidEmailsMsg();
        } else if (row.isDeleted) {
            return i18next.t('pmwjs_this_email_was_removed');
        }
        return '';
    }

    #getRemoveInvalidEmailsMsg = () => {
        return (
            <div className={`flexbox flex-wrap flex-align-items-center`}>
                <Text className={`spacing-m-r-1`} size={TextSize.XSMALL} val={i18next.t('pmwjs_this_is_an_invalid_email')}/>
                <a className={'remove-audience'} onClick={PMW.openRemoveInvalidEmailsModal.bind(this, {data: this.#getRowData()})}>
                    {i18next.t('pmwjs_remove')}
                </a>
            </div>
        )
    }

    #getConditionalRowStyles = () => {
        return [
            {
                when: row => (row.isUnsubscribe || row.isSpam || row.isBounce || row.isInvalid || row.isComplaint),
                classNames: ['invalid', 'color-danger']
            },
            {
                when: row => (row.isDeleted),
                classNames: ['unlinked', 'content-disabled']
            }
        ]
    }

    /**
     * @return []
     */
    #getClassNamesForSuccessCheckIcon = () => {
        return this.#getClassNamesForIcon('icon-check', 'color-success');
    }

    /**
     *
     * @return []
     */
    #getClassNamesForDangerCheckIcon = () => {
        return this.#getClassNamesForIcon('icon-check', 'color-danger');
    }

    /**
     * @return []
     */
    #getClassNamesForCloseIcon = () => {
        return this.#getClassNamesForIcon('icon-close', 'color-danger');
    }

    /**
     * @param {String} iconName
     * @param {String} iconColor
     * @return []
     */
    #getClassNamesForIcon = (iconName, iconColor) => {
        return [iconName, 'size-icon-20', iconColor];
    }

    /**
     * @return {boolean}
     */
    #isCampaignItemType = () => {
        return this.props.itemType === ITEM_TYPES.CAMPAIGNS;
    }

    /**
     * @return {boolean}
     */
    #isAudienceItemType = () => {
        return this.props.itemType === ITEM_TYPES.AUDIENCES;
    }
}

DetailedList.propTypes = {
    /**
     * The type of item for which details are to be shown ('Campaigns' or 'Audiences')
     */
    itemType: PropTypes.string.isRequired,

    /**
     * The data that will be shown in the table which is rendered in this component.
     */
    data: PropTypes.object.isRequired
};

export default DetailedList;