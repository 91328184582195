import type {ReactElement, RefObject} from 'react';
import React, { useEffect, useRef} from 'react';
import styles from './divider.module.scss';

export interface DividerProps {
  color?: string;
  height?: number;
  width?: number;
  additionalClasses?: string;
}

function Divider({...props}: DividerProps): ReactElement {
  const containerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && containerRef.current.style) {
      if (props.color) {
        containerRef.current.style.backgroundColor = props.color;
      }
      if (props.height) {
        containerRef.current.style.height = `${props.height}px`;
      }
      if (props.width) {
        containerRef.current.style.width = `${props.width}px`;
      }
    }
  }, []);

  return <div ref={containerRef} className={`${styles.divider} ${props.additionalClasses ?? ''}`} />;
}

export default Divider;
