import type {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import type {
  BusinessHoursMap,
  BusinessProfileAjaxResponse,
  BusinessProfileResponse,
  BusinessProfilesWizardReducerState,
} from '@Components/business-profiles-wizard/business-profiles-wizard.types';
import {BusinessProfileState, BusinessProfilesWizardMode, BusinessProfileWizardStep} from '@Components/business-profiles-wizard/business-profiles-wizard.types';
import {createBusinessProfile, deleteBusinessProfile, getBusinessProfile, updateBusinessProfile} from '@Components/business-profiles-wizard/business-profiles-wizard-thunk';
import {LoadingStates} from '@Utils/loading.util';
import {cloneBusinessHoursMap, getDisplayableBusinessHours, getInitialBusinessProfile, initialState, trackBusinessProfileEvent} from '@Libraries/business-profiles-wizard-library';
import {GA4EventName} from '@Libraries/ga-events';

export const businessProfilesWizardExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<BusinessProfilesWizardReducerState>>): void => {
  builder.addCase(getBusinessProfile.fulfilled, (state, {payload}) => {
    state.isSavingChanges = false;
    state.loadingState = LoadingStates.LOADED;
    state.googleMapAPIKey = payload.googleMapAPIKey;
    setBusinessProfile(state, payload);
  });
  builder.addCase(getBusinessProfile.pending, (state) => {
    state.isSavingChanges = true;
    state.loadingState = LoadingStates.LOADING;
  });
  builder.addCase(getBusinessProfile.rejected, (state) => {
    state.isSavingChanges = false;
    state.loadingState = LoadingStates.NOT_LOADED;
  });
  builder.addCase(createBusinessProfile.fulfilled, (state, {payload}) => {
    setSaveSuccessState(state, payload);
  });
  builder.addCase(createBusinessProfile.pending, (state) => {
    state.isSavingChanges = true;
    if (isUserSavingCompleteProfile(state)) {
      state.loadingState = LoadingStates.LOADING;
    }
  });
  builder.addCase(createBusinessProfile.rejected, (state) => {
    state.isSavingChanges = false;
    state.loadingState = LoadingStates.NOT_LOADED;
    state.currentStep = BusinessProfileWizardStep.BASIC_INFO;
    state.mode = BusinessProfilesWizardMode.NONE;
  });
  builder.addCase(updateBusinessProfile.fulfilled, (state, {payload}) => {
    setSaveSuccessState(state, payload);
  });
  builder.addCase(updateBusinessProfile.pending, (state) => {
    state.isSavingChanges = true;
    if (isUserSavingCompleteProfile(state)) {
      state.loadingState = LoadingStates.LOADING;
    }
  });
  builder.addCase(updateBusinessProfile.rejected, (state) => {
    state.isSavingChanges = false;
    state.loadingState = LoadingStates.NOT_LOADED;
  });
  builder.addCase(deleteBusinessProfile.fulfilled, (state) => {
    const {allCategories} = state;
    const contactEmail = state.businessProfile?.autoFilledEmail;
    const initState = initialState();
    const businessProfile = {...getInitialBusinessProfile(), contactEmail};

    return {...initState, businessProfile, allCategories};
  });
  builder.addCase(deleteBusinessProfile.pending, (state) => {
    state.isSavingChanges = true;
    state.loadingState = LoadingStates.LOADING;
  });
  builder.addCase(deleteBusinessProfile.rejected, (state) => {
    state.isSavingChanges = false;
    state.loadingState = LoadingStates.NOT_LOADED;
  });
};

const setBusinessProfile = (state: BusinessProfilesWizardReducerState, ajaxResponse?: BusinessProfileResponse): void => {
  if (ajaxResponse) {
    state.allCategories = ajaxResponse.allCategories;
  }

  if (ajaxResponse?.profileExists) {
    state.businessProfile = ajaxResponse.businessProfile;

    if (ajaxResponse.businessProfile.businessHours) {
      state.businessProfile.displayableBusinessHours = getDisplayableBusinessHours(ajaxResponse.businessProfile.businessHours);
      state.businessProfile.businessHours = cloneBusinessHoursMap(ajaxResponse.businessProfile.businessHours as BusinessHoursMap);
    }
    if (state.businessProfile.state === BusinessProfileState.USER_CREATED_SAVED || state.businessProfile.state === BusinessProfileState.GOOGLE_IMPORTED_SAVED) {
      state.mode = BusinessProfilesWizardMode.SAVED;
    } else {
      state.mode = BusinessProfilesWizardMode.EDITING;
    }

    state.savedBusinessProfile = state.businessProfile;
  }

  if (state.businessProfile?.contactEmail === '') {
    state.businessProfile.contactEmail = ajaxResponse?.emailId;
    state.businessProfile.autoFilledEmail = ajaxResponse?.emailId;
  }
};

const setSaveSuccessState = (state: BusinessProfilesWizardReducerState, payload: BusinessProfileAjaxResponse): void => {
  state.isSavingChanges = false;

  if (state.businessProfile) {
    state.businessProfile.idBusinessProfile = payload.idBusinessProfile;
    state.businessProfile.hashedId = payload.hashedIdBusinessProfile;

    if (payload.idAddress) {
      const address = state.businessProfile?.address ?? {idAddress: -1};
      address.idAddress = payload.idAddress;
      state.businessProfile.address = address;
    }

    state.savedBusinessProfile = state.businessProfile;

    setStateForIncompleteSaveSuccess(state);
    setStateForCompleteSaveSuccess(state);
  }
};

const isUserSavingIncompleteProfile = (state: BusinessProfilesWizardReducerState): boolean => {
  return (
    state.mode === BusinessProfilesWizardMode.CREATING &&
    state.businessProfile !== undefined &&
    (state.businessProfile.state === BusinessProfileState.GOOGLE_IMPORTED_UNSAVED || state.businessProfile.state === BusinessProfileState.USER_CREATED_UNSAVED)
  );
};

const setStateForIncompleteSaveSuccess = (state: BusinessProfilesWizardReducerState): void => {
  if (isUserSavingIncompleteProfile(state)) {
    state.mode = BusinessProfilesWizardMode.EDITING;
  }
};

const isUserSavingCompleteProfile = (state: BusinessProfilesWizardReducerState): boolean => {
  return (
    state.mode === BusinessProfilesWizardMode.SAVED &&
    state.businessProfile !== undefined &&
    (state.businessProfile.state === BusinessProfileState.GOOGLE_IMPORTED_SAVED || state.businessProfile.state === BusinessProfileState.USER_CREATED_SAVED)
  );
};

const setStateForCompleteSaveSuccess = (state: BusinessProfilesWizardReducerState): void => {
  if (isUserSavingCompleteProfile(state)) {
    state.loadingState = LoadingStates.LOADED;
    state.currentStep = BusinessProfileWizardStep.LAST;
    state.mode = BusinessProfilesWizardMode.SAVED;
    trackBusinessProfileEvent(GA4EventName.BUSINESS_PROFILES_COMPLETE);
  }
};
