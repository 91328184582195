import React from 'react';
import styles from './toggle-button.module.scss';

/**
 * @author < Haseeb Chaudhry haseebch@250mils.com >
 */

interface ToggleButtonProps {
  isChecked: boolean;
  onChangeHandler: Function;
  extraClasses?: string;
  leftOptionValue?: string;
  rightOptionValue?: string;
}

export function ToggleButton({
  isChecked = false,
  onChangeHandler = $.noop,
  extraClasses = '',
  leftOptionValue = window.i18next.t('pmwjs_no'),
  rightOptionValue = window.i18next.t('pmwjs_yes'),
}: ToggleButtonProps) {
  const handleChange = (event: React.MouseEvent<HTMLElement>) => {
    isChecked = event.currentTarget.getAttribute('data-value') === '1';
    onChangeHandler(isChecked);
  };

  const getLeftToggleClassName = (): string => {
    let className = `${styles.toggleOption} ${styles.left}`;
    if (!isChecked) {
      className += ` ${styles.selected}`;
    }
    return className;
  };

  const getRightToggleClassName = (): string => {
    let className = `${styles.toggleOption} ${styles.right}`;
    if (isChecked) {
      className += ` ${styles.selected}`;
    }
    return className;
  };

  return (
    <div className={styles.toggleSwitchButton + extraClasses}>
      <span data-value="0" className={getLeftToggleClassName()} onClick={handleChange}>
        {leftOptionValue}
      </span>
      <span data-value="1" className={getRightToggleClassName()} onClick={handleChange}>
        {rightOptionValue}
      </span>
    </div>
  );
}
