import type {CSVLink} from 'react-csv';

export type CSVLinkRef = CSVLink & HTMLAnchorElement & {link: HTMLAnchorElement};

export const CSV_SUMMARY_HEADERS_CAMPAIGNS: {label: string; key: string}[] = [
  {label: 'pmwjs_total', key: 'total'},
  {label: 'pmwjs_reached', key: 'numReached'},
  {label: 'pmwjs_did_not_reach', key: 'numDidNotReach'},
  {label: 'pmwjs_opened', key: 'numOpens'},
  {label: 'pmwjs_did_not_open', key: 'numUnoppened'},
  {label: 'pmwjs_clicked', key: 'numClicked'},
  {label: 'pmwjs_unsubscribed', key: 'numUnsubscribe'},
];

export const CSV_SUMMARY_HEADERS_MAILING_LIST: {label: string; key: string}[] = [
  {label: 'pmwjs_emails_received', key: 'emailsRecieved'},
  {label: 'pmwjs_open_rate', key: 'openRate'},
  {label: 'pmwjs_click_rate', key: 'clickRate'},
  {label: 'pmwjs_unsubscribed', key: 'unsubscribed'},
  {label: 'pmwjs_invalid_emails', key: 'invalidEmails'},
];

export const CSV_NEGATIVE_OUTCOME_REASON_HEADERS: {label: string; key: string}[] = [
  {label: 'pmwjs_did_not_reach', key: 'numDidNotReach'},
  {label: 'pmwjs_already_unsubscribed', key: 'alreadyUnsub'},
  {label: 'pmwjs_marked_as_spam', key: 'spam'},
  {label: 'pmwjs_invalid_email_address', key: 'invalidEmail'},
];

export const CSV_DETAILS_CAMPAIGN_HEADERS: {label: string; key: string}[] = [
  {label: 'pmwjs_recipient', key: 'email'},
  {label: 'pmwjs_reached', key: 'isDelivered'},
  {label: 'pmwjs_opened', key: 'numOpens'},
  {label: 'pmwjs_clicked', key: 'numClicks'},
  {label: 'pmwjs_unsubscribed', key: 'isUnsubscribe'},
];

export const CSV_DETAILS_MAILING_LIST_HEADERS: {label: string; key: string}[] = [
  {label: 'pmwjs_emails_received', key: 'emails'},
  {label: 'pmwjs_opens', key: 'numOpens'},
  {label: 'pmwjs_clicks', key: 'numClicks'},
  {label: 'pmwjs_unsubscribed', key: 'numUnsubscribed'},
];

export const CSV_FILE_NAME_FIXED_PART_FOR_EMAIL_CAMPAIGNS = '_email_campaign_report_';
export const CSV_FILE_NAME_FIXED_PART_FOR_MAILING_LISTS = '_mailing_list_report_';

export const CSV_SEPERATOR_HEADINGS = {
  CAMPAIGN_SENT: 'pmwjs_campaign_sent',
  CSV_GENERATED: 'pmwjs_CSV_generated',
  MAILING_LIST_CREATED_ON: 'pmwjs_created_on',
};

export const CSV_EMPTY_LINE = [];

export const getPercentage = (metricValue: number, divisor: number): number | string => {
  let val: number | string = metricValue;
  if (divisor !== null) {
    if (divisor === 0) {
      val = 0;
    } else {
      val = ((metricValue / divisor) * 100).toFixed(0);
    }
  }

  return val;
};
