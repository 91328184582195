import React from "react";
import PropTypes from 'prop-types';
import './popup-menu-item.scss';

export const PopupMenuItemIconSize = {
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
}

export const PopupMenuItemTypeInModal = {
    BORDERED_CENTER_ALIGNED: 'bordered-center-aligned',
    UNBORDERED_HORIZONTALLY_ALIGNED_START: 'unbordered-horizontally-aligned-start'
}

/**
 * A simple list item that can be used inside the popup-menu component.
 * Accepts typography and color classes for text, icon and icon color classes for the icon so the component can be customized and styled as per needs.
 * pass the href prop to make the component act as a link
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 * @return {JSX.Element}
 */
const PopupMenuItem = ({
                           text,
                           iconClasses,
                           clickHandler,
                           itemClasses,
                           textClasses,
                           textTypographyClasses,
                           textColorClasses,
                           iconColorClasses,
                           href,
                           hrefClasses,
                           disabled,
                           target,
                           horizontalAlignStart,
                           iconSize,
                           popupMenuTypeInModal = PopupMenuItemTypeInModal.BORDERED_CENTER_ALIGNED
                       }) => {

    const getIconSizeClasses = () => {
        if (iconSize === PopupMenuItemIconSize.SMALL) {
            return 'popup-menu-item-icon-size-small';
        }

        return 'popup-menu-item-icon-size-medium';
    }

    /**
     * @return {string}
     */
    const getItemClassesForModal = () => {
        if (popupMenuTypeInModal === PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START) {
            return '-horizontal-padding-in-modal -no-border-in-modal -more-margin-bottom-modal';
        }

        return '';
    }

    const isLink = href && href !== '',
        canClick = !isLink && !disabled,
        listItemContent = (
            <>
                <i className={`popup-menu-item-icon pmw-icon ${getIconSizeClasses()} ${iconClasses} ${iconColorClasses}`}/>
                <p className={`popup-menu-item-text ${textClasses} ${textTypographyClasses} ${textColorClasses}`}> {text} </p>
            </>
        );

    let listItemChildren = listItemContent;
    if (isLink) {
        listItemChildren = (
            <a className={`popup-menu-item-link flex-row-align-center ${hrefClasses ?? ''} ${disabled ? '-disabled' : ''}`}
               href={href} target={target}>
                {listItemContent}
            </a>
        );
    }

    return (
        <li className={`popup-menu-item hover-transition-all ${getItemClassesForModal()} ${horizontalAlignStart ? '-aligned-start' : ''} ${disabled ? '-disabled' : ''} ${itemClasses}`} {...(canClick) && {onClick: clickHandler}} >
            {listItemChildren}
        </li>
    );
}

PopupMenuItem.propTypes = {
    /**
     * The text to display
     */
    text: PropTypes.string.isRequired,
    /**
     * the icon-* class for the appropriate icon and any other class. example: icon icon-copy
     */
    iconClasses: PropTypes.string.isRequired,
    /**
     * any handler to attach to the list item
     */
    clickHandler: PropTypes.func,
    /**
     * a color class from _typography-colors.scss to give to the icon. Default: $neutral-80 (.content-heading)
     */
    iconColorClasses: PropTypes.string,
    /**
     * any additional classes to give to the text element
     */
    textClasses: PropTypes.string,
    /**
     * the typography class to give to the text element. Default: body-xs: 14px
     */
    textTypographyClasses: PropTypes.string,
    /**
     * a color class from _typography-colors.scss to give to the icon. Default: $neutral-48 (.content-sub-text)
     */
    textColorClasses: PropTypes.string,
    /**
     * if the component is a link, then the site url of where the link points to
     */
    href: PropTypes.string,

    /**
     * if the component is a link, optional link classes
     */
    hrefClasses: PropTypes.string,
    /**
     * any classes to give to the item itself
     */
    itemClasses: PropTypes.string,
    /**
     * disables hover and clicks if true
     */
    disabled: PropTypes.bool,

    target: PropTypes.string,
    horizontalAlignStart: PropTypes.bool,
    iconSize: PropTypes.string,
    popupMenuTypeInModal: PropTypes.string
}

PopupMenuItem.defaultProps = {
    clickHandler: $.noop,
    href: '',
    hrefClasses: '',
    itemClasses: '',
    iconColorClasses: 'content-heading',
    textClasses: '',
    textTypographyClasses: 'body-xs',
    textColorClasses: 'content-sub-text',
    disabled: false,
    target: '_self',
    horizontalAlignStart: false,
    iconSize: PopupMenuItemIconSize.MEDIUM
}

export default PopupMenuItem;