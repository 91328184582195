import type {ReactElement} from 'react';
import React from 'react';
import './circular-progress-loader.scss';

export enum LOADER_SIZE {
  DEFAULT = 'default',
  SMALL = '-small',
  XSMALL = '-xsmall',
  MEDIUM = '-medium',
  LARGE = '-large',
}

export enum LOADER_COLOR {
  PRIMARY_DARK = '-primarydark',
  PRIMARY = '-primary',
  WHITE = '-white',
  DARK = '-dark',
  ERROR = '-error',
  SUCCESS = '-success',
  WARNING = '-warning',
}

interface CircularProgressLoaderProps {
  /**
   * adjusts the width/height of the loader
   */
  size?: LOADER_SIZE;
  /**
   * changes the stroke-color of the loader
   */
  color?: LOADER_COLOR;
  /**
   * extra classes
   */
  classes?: string;
}

export function CircularProgressLoader({size = LOADER_SIZE.DEFAULT, color = LOADER_COLOR.PRIMARY, classes = ''}: CircularProgressLoaderProps): ReactElement {
  return <progress className={`icon-circular-loader ${classes} ${size} ${color}`} />;
}
