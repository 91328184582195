import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {TextStylesObject} from '@PosterWhiteboard/classes/text-styles.class';

interface StateProps {
  isEditingPanelDisabled: boolean;
  maskedTextProps: Partial<TextStylesObject>;
}

const initialState: StateProps = {
  isEditingPanelDisabled: false,
  maskedTextProps: {
    isBold: false,
    isItalic: false,
    underLine: false,
    lineThrough: false,
    leading: 120,
    letterSpacing: 0,
    fontFamily: 'RalewayRegular',
  },
};

const textMaskingSlice = createSlice({
  name: 'textMasking',
  initialState,
  reducers: {
    setIsTextMaskEditingPanelDisabled(state, action: PayloadAction<boolean>) {
      state.isEditingPanelDisabled = action.payload;
    },
    setTextMaskEditingOptions(state, action: PayloadAction<Partial<TextStylesObject>>) {
      state.maskedTextProps = {
        ...state.maskedTextProps,
        ...action.payload,
      };
    },
  },
});

export const {setIsTextMaskEditingPanelDisabled, setTextMaskEditingOptions} = textMaskingSlice.actions;
export const textMaskingReducer = textMaskingSlice.reducer;
