import React from "react";
import PropTypes from "prop-types";
import "./dotted-icon-button.scss";
import { CircularProgressLoader } from "../circular-progress-loader";

/**
 * a button with an icon and text with a dotted/dashed border. Pass the href prop to use it as a link
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
const DottedIconButton = ({
                            btnIcon,
                            btnText,
                            direction,
                            className,
                            textClasses,
                            href,
                            onClick,
                            ariaLabel,
                            disabled,
                            isLoading,
                            btnRef
                          }) => {
  const btnMarkup = (
    <>
      {isLoading ? <CircularProgressLoader classes={"dotted-btn-loader"} /> : <i className={`icon ${btnIcon}`} />}
      <span className={`dotted-icon-btn-text ${textClasses} ${!btnText ? '_hidden' : ''}`}> {btnText}</span>
    </>
  );

  const label = ariaLabel !== "" ? ariaLabel : btnText,
    btnClasses = `-${direction} ${className} ${disabled ? "-disabled" : ""}`;

  if (href) {
    return (
      <a className={`dotted-icon-button ${btnClasses}`} href={href} aria-label={label} title={btnText}>
        {btnMarkup}
      </a>
    );
  } else {
    return (
      <button ref={btnRef} className={`dotted-icon-button ${btnClasses}`} aria-label={label} onClick={onClick}
              disabled={disabled}>
        {btnMarkup}
      </button>
    );
  }
};

DottedIconButton.propTypes = {
  /**
   * the icon-* class of the icon to display on the button
   */
  btnIcon: PropTypes.string.isRequired,
  /**
   * the text to display on the button
   */
  btnText: PropTypes.string.isRequired,
  /**
   * by default, the button displays its content vertically, but it can be changed to 'horizontal' via this prop
   */
  direction: PropTypes.string,
  /**
   * any optional classes to apply to the button
   */
  className: PropTypes.string,

  /**
   * optional classes to give to the button text
   */
  textClasses: PropTypes.string,
  /**
   * the URL to where the button points to if it's a link
   */
  href: PropTypes.string,

  /**
   * the aria label to give to the button
   */
  ariaLabel: PropTypes.string,
  /**
   * whether the button should be disabled or not
   */
  disabled: PropTypes.bool,

  /**
   * The click handler to attach to the button
   */
  onClick: PropTypes.func,

  /**
   * whether to show loader or not
   */
  isLoading: PropTypes.bool,
  /**
   * optioanl ref to attach to the button
   */
  btnRef: PropTypes.object
};

DottedIconButton.defaultProps = {
  direction: "",
  className: "",
  textClasses: "body-s",
  href: "",
  ariaLabel: "",
  disabled: false,
  isLoading: false,
  btnRef: null
};

export default DottedIconButton;