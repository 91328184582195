import type {ReactNode} from 'react';
import React, {useState} from 'react';
import {escapeHTML} from '@Utils/string.util';
import type {AuthFormSubmissionParams, BaseAuthFormProps, ForgotPasswordParams} from '@Components/login-page/login-page.types';
import {AuthFormSubmissionType} from '@Components/login-page/login-page.types';
import {executeThunk} from '@Utils/thunk.util';
import {submitAuthForm} from '@Components/login-page/login-page-thunk';
import {EMAIL_PLACEHOLDER, redirectToPage} from '@Libraries/login-signup-library';
import type {VoidFunction} from '@Utils/general.util';
import {noop} from '@Utils/general.util';
import {Text, TextSize} from '../text';
import {AuthInput} from '../auth-input';
import {INPUT_FIELD_TYPE} from '../input-field';
import {Type} from '../button';
import {AuthFormSubmitButton} from '../auth-form-submit-button';
import {useAppDispatch} from '@/hooks';
import {useUpdateErrorStates} from '@/hooks/login-signup/useUpdateErrorStates';

interface ForgotPasswordFormProps extends BaseAuthFormProps {
  onSubmitCallback?: VoidFunction;
  isLoggedInUser?: boolean;
}

export function ForgotPasswordForm({showSmallButton = false, onSubmitCallback = noop, isLoggedInUser = false}: ForgotPasswordFormProps) {
  const [email, setEmail] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);
  const dispatch = useAppDispatch();

  const {showErrors} = useUpdateErrorStates();

  const handleEmailInputChange = React.useCallback((txt: string) => {
    setEmail(txt.trim());
  }, []);

  const getTextForInformationContainer = (): string => {
    if (resetPasswordEmailSent) {
      return window.i18next.t('pmwjs_forgot_password_reset_email_sent_information', {
        email: escapeHTML(email),
      });
    }
    return window.i18next.t('pmwjs_forgot_password_email_information');
  };

  const onSubmit = async () => {
    if (email === '') {
      setIsEmailInvalid(true);
      return;
    }

    setIsEmailInvalid(false);
    await onForgotPasswordSubmit();
  };

  const getInfoTextSize = (): TextSize => {
    if (showSmallButton) {
      return TextSize.XSMALL;
    }
    return TextSize.SMALL;
  };

  const onForgotPasswordSubmit = async () => {
    const forgotPasswordParams: ForgotPasswordParams = {
      email,
    };

    const authFormSubmissionParams: AuthFormSubmissionParams = {
      type: isLoggedInUser ? AuthFormSubmissionType.LOGGEDINFORGOTPASSWORD : AuthFormSubmissionType.FORGOTPASSWORD,
      requestParams: forgotPasswordParams,
    };

    await executeThunk(
      () => {
        return dispatch(submitAuthForm(authFormSubmissionParams));
      },
      onForgotPasswordSubmitSuccess,
      onForgotPasswordError
    );
  };

  const onForgotPasswordSubmitSuccess = (response: any): void => {
    if (response !== null && response.redirectURL !== null) {
      redirectToPage(window.PMW.util.site_url(response.redirectURL));
      return;
    }

    setResetPasswordEmailSent(true);
    onSubmitCallback();
  };

  const onForgotPasswordError = (response: any): void => {
    if (response.payload.data.redirectUrl) {
      redirectToPage(window.PMW.util.site_url(response.payload.data.redirectUrl));
      return;
    }

    showErrors(response.payload.data.message);
    setResetPasswordEmailSent(false);
  };

  const getPasswordResetEmailSentHeading = (): ReactNode => {
    return (
      <div className="flex-row-align-center spacing-m-b-5">
        <Text size={TextSize.LARGE} bold val={window.i18next.t('pmwjs_password_reset_email_sent')} />
      </div>
    );
  };

  const getPasswordResetEmailSentContent = (): ReactNode => {
    return (
      <>
        {getPasswordResetEmailSentHeading()}
        <Text val={getTextForInformationContainer()} size={getInfoTextSize()} dangerouslySetInnerHTML />
      </>
    );
  };

  const getBaseForgotPasswordForm = (): ReactNode => {
    return (
      <div className="spacing-m-t-6">
        <AuthInput
          label={window.i18next.t('pmwjs_email')}
          placeholder={EMAIL_PLACEHOLDER}
          type={INPUT_FIELD_TYPE.EMAIL}
          handleInput={handleEmailInputChange}
          showError={isEmailInvalid}
          onInputSubmit={onSubmit}
          errorText={window.i18next.t('pmwjs_valid_email_address')}
        />

        <AuthFormSubmitButton
          btnText={window.i18next.t('pmwjs_reset_password')}
          type={Type.PRIMARY}
          onClick={onSubmit}
          showSmallButton={showSmallButton}
          containerClassName="spacing-m-t-4"
          customClasses="-fullwidth"
        />
      </div>
    );
  };

  return <>{resetPasswordEmailSent ? getPasswordResetEmailSentContent() : getBaseForgotPasswordForm()}</>;
}
