import type {ReactElement} from 'react';
import React, { useState} from 'react';
import {useContentPlannerDate, useCustomEventFormData} from '@Components/content-planner/content-planner.hooks';
import {Button, Size, Type} from '@Components/button';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {executeThunk} from '@Utils/thunk.util';
import {createCustomEvent} from '@Components/content-planner/content-planner-thunk';
import {getEntriesMapKey} from '@Libraries/content-planner-library';
import type {CustomEventsPanelInnerContentProps} from '@Components/content-planner/content-planner.types';
import {ContentPlannerCustomEventForm} from '@Components/content-planner-custom-event-form';
import {useIsMounted} from '@Hooks/useIsMounted';
import {noop} from '@Utils/general.util';
import {onAjaxError} from '@Utils/ajax.util';
import {useAppDispatch} from '@/hooks';

export function CreateEventContent({isInModal, onClose}: CustomEventsPanelInnerContentProps): ReactElement {
  const formData = useCustomEventFormData();
  const contentPlannerDate = useContentPlannerDate();
  const dispatch = useAppDispatch();
  const isComponentMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const spacingClasses = isInModal ? 'spacing-p-t-5 spacing-p-b-6 spacing-p-l-6 spacing-p-r-6' : 'spacing-p-5';

  const SAVE_EVENT_LOADING_KEY = 'savingEvent';
  const showLoaders = (): void => {
    showLoading(SAVE_EVENT_LOADING_KEY, {
      text: window.i18next.t('pmwjs_saving_event'),
    });
    setIsLoading(true);
  };

  const hideLoaders = (): void => {
    hideLoading(SAVE_EVENT_LOADING_KEY);

    if (isComponentMounted) {
      setIsLoading(false);
    }
  };

  const saveCustomEvent = async () => {
    return dispatch(
      createCustomEvent({
        entriesMapKey: getEntriesMapKey(contentPlannerDate),
        eventData: formData,
      })
    );
  };

  const onResponseFromServer = (): void => {
    hideLoaders();
    onClose();
  };

  const onErrorResponse = (error: Error): void => {
    onResponseFromServer();
    onAjaxError(error);
  };

  const onSave = (): void => {
    if (!formData.title || !formData.description) {
      return;
    }

    showLoaders();
    executeThunk(saveCustomEvent, onResponseFromServer, onErrorResponse).catch(noop);
  };

  return (
    <ContentPlannerCustomEventForm className={spacingClasses} openDatePickerInPanel={isInModal}>
      <div className={`_full-width ${isInModal ? 'flex-v-row flex-column-reverse spacing-m-t-10' : 'flexbox spacing-m-t-6'}`}>
        <Button
          onClick={onClose}
          type={Type.SECONDARY}
          size={Size.SMALL}
          text={window.i18next.t('pmwjs_cancel')}
          customClasses={isInModal ? 'spacing-m-t-2' : 'spacing-m-r-3'}
          isFullWidth
        />
        <Button onClick={onSave} type={Type.PRIMARY} size={Size.SMALL} text={window.i18next.t('pmwjs_save')} isLoading={isLoading} isFullWidth />
      </div>
    </ContentPlannerCustomEventForm>
  );
}
