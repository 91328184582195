import {floor} from 'lodash';

export const initFakeProgress = (estimatedTotalTime: number, onProgressUpdate: (progress: number, hasTimedOut: boolean) => void): NodeJS.Timeout => {
  const MAX_PROGRESS = 0.99;
  const startTime = performance.now();
  const maxTime = estimatedTotalTime * 5;

  const interval = setInterval(() => {
    const elapsedTime = performance.now() - startTime;
    const hasTimedOut = elapsedTime / startTime > 5;
    const x = elapsedTime / maxTime;
    // Equation formed from here: https://www.desmos.com/calculator/ecxnzrspun
    let progress = 0;
    if (elapsedTime <= estimatedTotalTime) {
      const m = 4.15;
      progress = m * x;
    } else {
      const m = 0.2;
      const c = 0.79;
      progress = m * x + c;
    }
    progress = floor(progress, 2);
    if (progress >= MAX_PROGRESS) {
      onProgressUpdate(MAX_PROGRESS, hasTimedOut);
      clearInterval(interval);
    } else {
      onProgressUpdate(progress, hasTimedOut);
    }
  }, 300);

  return interval;
};
