import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  audioGridItemPlaying: string;
}

const initialState: StateProps = {
  audioGridItemPlaying: '',
};

const gridAudioItemSlice = createSlice({
  name: 'gridAudioItem',
  initialState,
  reducers: {
    playAudioGridItem(state, action: PayloadAction<string>) {
      state.audioGridItemPlaying = action.payload;
    },
    pauseAudioGridItem(state, action: PayloadAction<string>) {
      if (state.audioGridItemPlaying === action.payload) {
        state.audioGridItemPlaying = '';
      }
    },
    pauseAllAudioGridItems(state) {
      state.audioGridItemPlaying = '';
    },
  },
});

export const {playAudioGridItem, pauseAudioGridItem, pauseAllAudioGridItems} = gridAudioItemSlice.actions;
export const gridAudioItemReducer = gridAudioItemSlice.reducer;
