import React, {Suspense} from "react";
import PropTypes from 'prop-types';
import './grid-section.scss';
import {CircularProgressLoader} from "../circular-progress-loader";

const Grid = React.lazy(() => import ('../grid-deprecated').then(({Grid}) => ({default: Grid})));

/**
 * A section that contains: A heading, a grid of items (can be anything). The component also accepts a loader component
 * in case grid items are being fetched via AJAX, send isLoading = true and it will add a simple Circular progress component.
 * A custom loader component can also be passed in.
 * the section will get a class of -loading if isLoading prop is true
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
class GridSection extends React.Component {

    /**
     * reference to grid component
     * @type {Grid}
     */
    #grid;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.#refreshGridItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isLoading && prevProps.gridItems !== this.props.gridItems) {
            this.#refreshGridItems();
        }
    }

    render() {
        return (
            <section className={`grid-section ${this.props.sectionClasses} ${this.props.isLoading ? '-loading' : ''}`}>
                {!this.props.hideHeading ? <h2 className={`grid-section-heading content-heading ${this.props.headingClasses}`}> {this.props.sectionHeading} </h2>: null}
                {this.props.isLoading ? this.props.loaderComponent :
                    <Suspense fallback={this.props.loaderComponent}>
                        <Grid gridItems={this.props.gridItems} gridClasses={this.props.gridClasses} onGridReady={this.#onGridReady} onGridUnmount={this.#onGridUnmount}/>
                    </Suspense>
                }
            </section>
        );
    }

    #onGridReady = (gridInstance) => {
        this.#grid = gridInstance;
        if (this.#grid.getItems() !== this.props.gridItems) {
            this.#refreshGridItems();
        }
    }

    #refreshGridItems = () => {
        if (this.#grid) {
            this.#grid.setGridItems(this.props.gridItems);
        }
    }

    /**
     * callback for when grid component unmounts. We don't want a case where we try to call setState on an unmounted component
     * set the grid object to null.
     */
    #onGridUnmount = () => {
        this.#grid = null;
    }

}

GridSection.propTypes = {
    /**
     * List of grid items to give to the Grid component
     */
    gridItems: PropTypes.array,
    /**
     * Heading text
     */
    sectionHeading: PropTypes.string.isRequired,
    /**
     * extra classes to give to the section
     */
    sectionClasses: PropTypes.string,
    /**
     * extra classes to give to the grid
     */
    gridClasses: PropTypes.string,
    /**
     * any classes to give to the heading such as typography classes. Default: 20px body-l
     */
    headingClasses: PropTypes.string,
    /**
     * whether there is some loading operation going on, so show the loader
     */
    isLoading: PropTypes.bool,
    /**
     * Loader to show instead of grid during loading state. Default is a circular spinner
     */
    loaderComponent: PropTypes.object,
    /**
     * hide heading or not
     */
    hideHeading: PropTypes.bool
}

GridSection.defaultProps = {
    isLoading: false,
    sectionClasses: '',
    gridClasses: '',
    headingClasses: 'body-l-bold',
    hideHeading: false,
    loaderComponent: (<CircularProgressLoader classes={'flex-vertical-center -no-margin'}/>)
}

export default GridSection;
