import type {ReactElement} from 'react';
import React, { useState} from 'react';
import type {Filter, FiltersHashmap, OnFiltersChangeHandler} from '@Components/checkbox-filters/checkbox-filters.types';
import {CHECKBOX_TYPE} from '@Components/checkbox';
import {SearchBar} from '@Components/search-bar';
import {CheckboxFilters} from '@Components/checkbox-filters';
import type {AreaFilterInfo} from '@Components/content-planner/content-planner.types';
import styles from '@Components/content-planner/content-planner-util.module.scss';

export interface MultiSelectEventTypeFiltersProps {
  filters: FiltersHashmap;
  onFilterChange: OnFiltersChangeHandler;
  filtersSource: AreaFilterInfo[];
  includeSearch?: boolean;
  searchBoxPrompt?: string;
}

export type MultiSelectEventTypeFilterSearchParams = Omit<MultiSelectEventTypeFiltersProps, 'filters' | 'onFilterChange' | 'filtersSource'>;

export function MultiSelectEventTypeFilters({filters, onFilterChange, filtersSource, includeSearch = false, searchBoxPrompt = ''}: MultiSelectEventTypeFiltersProps): ReactElement {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const checkboxes: Filter[] = [];
  const filteredFiltersSource = includeSearch
    ? filtersSource.filter((source) => {
        return source.name.toLowerCase().includes(searchTerm.toLowerCase());
      })
    : filtersSource;

  filteredFiltersSource.forEach((filterSource, index) => {
    checkboxes.push({
      label: filterSource.name,
      isChecked: filters[filterSource.id],
      dataValue: filterSource.id.toString(),
      className: `${index === filteredFiltersSource.length - 1 ? 'spacing-m-b-0' : 'spacing-m-b-3'} ${styles.filterItem} spacing-p-l-2`,
      textClassName: 'content-body',
      checkboxType: CHECKBOX_TYPE.DARKER,
    });
  });

  const onCheckboxesChange = (newFilterValues: FiltersHashmap): void => {
    onFilterChange({
      ...filters,
      ...newFilterValues,
    });
  };

  const onSearchInput = (newSearchTerm: string): void => {
    setSearchTerm(newSearchTerm);
  };

  return (
    <div className="flex-v-row">
      {includeSearch ? <SearchBar placeholder={searchBoxPrompt} onInput={onSearchInput} className="spacing-m-t-0 spacing-m-b-3" /> : null}
      <CheckboxFilters filters={checkboxes} onChange={onCheckboxesChange} minSelectionNumber={0} />
    </div>
  );
}
