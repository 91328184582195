import type {RGB} from '@Utils/color.util';
import {rgbToHex} from '@Utils/color.util';
import {isProduction} from '@Libraries/environment-library';
import type {FontData} from '@Libraries/font-library';
import {getFancyTextFontsFromStore} from '@Libraries/font-library';
import {isWebpSupported} from '@Utils/browser.util';
import {getWriteDistributionUrl} from '@Utils/s3.util';

export type FancyTextEffectHashmap = Record<string, FancyTextEffect>;

export interface FancyTextEffect {
  id: string;
  name: string;
  fontFamily: string;
  defaultFamilyName: string;
  sortOrder: number;
  defaultColors: Array<RGB>;
  isHidden: boolean;
}

export enum MorphType {
  NONE = 0,
  CURVE_UP = 1,
  CURVE_DOWN = 2,
  WAVE = 3,
  WEDGE_LEFT = 4,
  WEDGE_RIGHT = 5,
  ROOF = 6,
}

let fancyTextEffectHashmap: FancyTextEffectHashmap = {};

export const getFancyTextEffectsHashmap = async (): Promise<FancyTextEffectHashmap> => {
  if (Object.keys(fancyTextEffectHashmap).length === 0) {
    try {
      fancyTextEffectHashmap = await window.PMW.readLocal('fancytextcontroller/getFancyTextEffects', null, 'fancyTextEffects');
    } catch (e) {
      console.error(e);
    }
  }

  return fancyTextEffectHashmap;
};

export const getFancyTextEffectForId = async (idFancyTextEffect: string): Promise<FancyTextEffect> => {
  const fancyTextEffects = await getFancyTextEffectsHashmap();
  return fancyTextEffects[idFancyTextEffect];
};

export function getDefaultFontSizeForFontFamily(name: string) {
  switch (name) {
    case 'Agent Orange':
    case 'Berkshire Swash':
      return 150;

    case 'Airstream':
      return 140;

    case 'Boston Traffic':
      return 145;

    case 'BubbleBoy2':
      return 125;

    case 'Caeldera':
      return 160;

    case 'Diner':
    case 'Star Avenue':
      return 110;

    case 'JudasPriest':
      return 105;

    case 'Fontdinerdotcom Luvable':
    case 'TagsXtreme':
      return 160;

    case 'Planet Kosmos':
      return 120;

    case 'Wicked Queen BB':
      return 200;
  }

  return 170;
}

// @ts-ignore
export const getFancyTextUrl = (id, family, size, scale, text, colors, morph, morphAmount, version) => {
  const p = [
    getRenderFancyTextBaseUrl(),
    `?id=${encodeURIComponent(id)}`,
    `&fontFamily=${encodeURIComponent(family)}`,
    `&fontSize=${encodeURIComponent(size)}`,
    `&text=${encodeURIComponent(text)}`,
    `&colors=${encodeURIComponent(JSON.stringify(colors))}`,
    `&scale=${encodeURIComponent(scale)}`,
    `&morphType=${encodeURIComponent(morph)}`,
    `&morphAmount=${encodeURIComponent(morphAmount)}`,
    `&isWebpSupported=${encodeURIComponent(isWebpSupported() ? '1' : '0')}`,
    `&version=${encodeURIComponent(version)}`,
  ];
  return p.join('');
};

const getRenderFancyTextBaseUrl = () => {
  const uri = 'fancytextrender/outputRender';

  if (isProduction()) {
    return getWriteDistributionUrl(`${window.PMW.indexPage}/${uri}`);
  }

  return window.PMW.util.site_url(uri);
};

export const getSortedFancyTextArrayFromHashmap = (data: FancyTextEffectHashmap): Array<FancyTextEffect> => {
  const dataArray = Object.values(data);
  dataArray.sort((a, b) => {
    if (a.sortOrder >= b.sortOrder) {
      return -1;
    }
    return 1;
  });

  return dataArray;
};

export const getColorHexesFromRGB = (colors: Array<RGB>, addPadding = true): Array<string> => {
  const colorHexes = [];
  for (let index = 0; index < colors.length; index += 1) {
    colorHexes.push(rgbToHex(colors[index]));
  }

  if (!addPadding) {
    return colorHexes;
  }

  const lengthOfColorHexes = colorHexes.length;

  for (let index = 0; index < 3 - lengthOfColorHexes; index += 1) {
    colorHexes.push('ffffff');
  }

  return colorHexes;
};

export const getDefaultMorphAmountFromType = (type: MorphType): number => {
  if (type === MorphType.CURVE_UP) {
    return 60;
  }
  if (type === MorphType.CURVE_DOWN) {
    return -60;
  }

  if (type === MorphType.WAVE) {
    return 1;
  }

  return 350;
};

export const getFancyTextFontFamilyFromId = (id: string): string => {
  const fonts = getFancyTextFontsFromStore() as Array<FontData> | null;
  if (!fonts) {
    return '';
  }

  for (const eachFont of fonts) {
    if (eachFont.idfont === Number(id)) {
      return eachFont.fontFamily;
    }
  }

  return '';
};

export const getIdFromFancyTextFontFamily = (fontFamily: string): string => {
  const fonts = getFancyTextFontsFromStore() as Array<FontData> | null;
  if (!fonts) {
    return '';
  }
  for (const eachFont of fonts) {
    if (eachFont.fontFamily === fontFamily) {
      return String(eachFont.idfont);
    }
  }

  return '';
};
