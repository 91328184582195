import {useEffect} from "react";

/**
 * Reusable hook that checks whether a click has come from outside the given DOM container
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 * @param {React.Ref} containerToCheckRef the Ref that for the DOM element for which we need to check whether there has been a click outside it or not.
 *                           defaults to the document
 * @param {function} onClickedOutside callback to execute once a click outside has been registered
 * @param {Element|null} excludeFromCheck any dom element that is outside the container and should be excluded from the check
 * @param {function|null} setHandlerCondition Optional. A function if true, sets the handler. returns otherwise.
 * */
const useOutsideClick = (containerToCheckRef, onClickedOutside, excludeFromCheck = null, setHandlerCondition = null) => {
    const shouldSetHandler = typeof setHandlerCondition === 'function' ? setHandlerCondition(): true;
    /**
     *
     * @param {Event} e
     * @returns {boolean}
     */
    const isClickFromExcludedElement = (e) => {
        return excludeFromCheck !== null && excludeFromCheck.contains(e.target);
    }

    /**
     *
     * @param {Event} e
     * @returns {boolean}
     */
    const isClickFromOutsideContainer = (e) => {
        if (containerToCheckRef.current) {
            return (!containerToCheckRef.current.contains(e.target) && !isClickFromExcludedElement(e))
        }

        return false;
    }

    useEffect(() => {
        if (!shouldSetHandler) {
            return;
        }

        /**
         *
         * @param {Event} e
         */
        const handler = (e) => {
            if (isClickFromOutsideContainer(e) && typeof onClickedOutside === 'function') {
                onClickedOutside(e);
            }
        }

        document.addEventListener('click', handler);

        return () => {
            document.removeEventListener('click', handler);
        }
    }, [onClickedOutside, excludeFromCheck, shouldSetHandler]);
}

export default useOutsideClick;