import React from "react";
import './modal-footer.scss';
import {ModalFooterPrimaryActions} from "../modal-footer-primary-actions";
import {ModalFooterSecondaryActions} from "../modal-footer-secondary-actions";
import PropTypes from "prop-types";

export default class ModalFooter extends React.Component {
    render = () => {
        return <div className={`${this.props.className} footer -flex`}>
            <div className="action-wrapper">
                <ModalFooterSecondaryActions actions={this.props.secondaryActions}/>
                <ModalFooterPrimaryActions className={this.props.footerPrimaryClassName} actions={this.props.primaryActions}/>
            </div>
            <div className="info-wrapper body-xxs-italic">
                {this.props.info}
            </div>
        </div>

    }
}

ModalFooter.propTypes = {
    info: PropTypes.element,
    primaryActions: PropTypes.array,
    secondaryActions: PropTypes.array,
    className: PropTypes.string,
    footerPrimaryClassName: PropTypes.string,
};

ModalFooter.defaultProps = {
    info: null,
    className: '',
    footerPrimaryClassName: ''
}
