import type {ReactElement} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {ControlledList} from '@Components/controlled-list';
import {THUMBNAIL_TYPE} from '@Components/thumbnail';
import type {ControlledListItem2} from '@Components/controlled-list/controlled-list.types';
import {CONTROLLED_LIST_ITEM_TYPE} from '@Components/controlled-list/controlled-list.types';
import {FlatIconColor, FlatIconColorType, IconShape, IconType} from '@Components/icon-v2/icon.types';
import {connect} from 'react-redux';
import {getFirstSelectedItem, getFirstSelectedItemId, ITEM_TYPES, sanitizeFilename} from '@Libraries/email-marketing-campaigns-library';
import {CSVLink} from 'react-csv';
import type {
  CSVDetails,
  CSVMetrics,
  CurrentItemMetrics,
  EmailMarketingCampaign,
  MailingList,
  MailingListMetrics,
  SingleCampaignMetrics,
  SingleCampaignMetricsOverview,
  CurrentItemDetails,
  CurrentItemMetricsOverview,
} from '@Components/email-marketing-campaign-performance/email-marketing-campaign-performance.types';
import {openEmailMetricsDownloadPDFModal} from '@Modals/email-metrics-download-pdf-modal';
import {formatDateYYYYMMDD} from '@Utils/date.util';
import {GA4EventName} from '@Libraries/ga-events';
import type {CSVLinkRef} from '@Components/email-marketing-campaign-performance/components/download-reports-dropdown-options/email-marketing-campaign-performance-csv-export-library';
import {
  CSV_DETAILS_CAMPAIGN_HEADERS,
  CSV_DETAILS_MAILING_LIST_HEADERS,
  CSV_EMPTY_LINE,
  CSV_FILE_NAME_FIXED_PART_FOR_EMAIL_CAMPAIGNS,
  CSV_FILE_NAME_FIXED_PART_FOR_MAILING_LISTS,
  CSV_NEGATIVE_OUTCOME_REASON_HEADERS,
  CSV_SUMMARY_HEADERS_CAMPAIGNS,
  CSV_SUMMARY_HEADERS_MAILING_LIST,
  CSV_SEPERATOR_HEADINGS,
  getPercentage,
} from '@Components/email-marketing-campaign-performance/components/download-reports-dropdown-options/email-marketing-campaign-performance-csv-export-library';
import styles from './download-reports-dropdown-options.module.scss';
import type {RootState} from '@/store';

interface DownloadReportsDropdownOptionsPropTypes {
  campaignMetrics: Record<string, SingleCampaignMetrics>;
  selectedItemIds: Array<string>;
  selectedItemType: string;
  mailingListMetrics: Record<string, MailingListMetrics>;
}

function DownloadReportsOptionList({campaignMetrics, selectedItemIds, ...props}: DownloadReportsDropdownOptionsPropTypes): ReactElement {
  const csvLink = useRef<CSVLinkRef>(null);
  const [dataToDownload, setDataToDownload] = useState<string[][]>([]);
  const selectedCampaignOrMailingList: EmailMarketingCampaign | MailingList = getFirstSelectedItem() as EmailMarketingCampaign | MailingList;
  const createdOnOrPublishOnDate = isMailingList(selectedCampaignOrMailingList) ? selectedCampaignOrMailingList.createdOn : selectedCampaignOrMailingList.publishOn;

  useEffect(() => {
    window.PMW.initTracking();
  }, []);

  const getCSVFileName = (): string => {
    return `${sanitizeFilename(selectedCampaignOrMailingList.name) as string}${
      campaignViewSelected() ? window.i18next.t(CSV_FILE_NAME_FIXED_PART_FOR_EMAIL_CAMPAIGNS) : window.i18next.t(CSV_FILE_NAME_FIXED_PART_FOR_MAILING_LISTS)
    }${formatDateYYYYMMDD(new Date(createdOnOrPublishOnDate))}-${formatDateYYYYMMDD(new Date())}.csv`;
  };

  const getCSVSummaryHeaders = (): string[] => {
    if (campaignViewSelected()) {
      return CSV_SUMMARY_HEADERS_CAMPAIGNS.map((item) => {
        return window.i18next.t(item.label);
      });
    }

    return CSV_SUMMARY_HEADERS_MAILING_LIST.map((item) => {
      return window.i18next.t(item.label);
    });
  };

  const getCSVSummary = (metricsOverview: CSVMetrics): string[] => {
    if (campaignViewSelected()) {
      return [
        metricsOverview.delivery.total.toString(),
        metricsOverview.delivery.positiveOutcomeTotal.toString(),
        metricsOverview.delivery.negativeOutcomeTotal.toString(),
        metricsOverview.open.positiveOutcomeTotal.toString(),
        metricsOverview.open.negativeOutcomeTotal.toString(),
        metricsOverview.click.positiveOutcomeTotal.toString(),
        metricsOverview.click.negativeOutcomeTotal.toString(),
      ];
    }

    return [
      metricsOverview.numRecipients.toString(),
      `${getPercentage(metricsOverview.numOpens, metricsOverview.numDelivered).toString()}%`,
      `${getPercentage(metricsOverview.numClicks, metricsOverview.numOpens).toString()}%`,
      metricsOverview.numUnsubscribes.toString(),
      metricsOverview.numBounces.toString(),
    ];
  };

  const getNegativeOutcomeDetails = (metricsOverview: SingleCampaignMetricsOverview): string[][] => {
    return [
      [
        ...CSV_NEGATIVE_OUTCOME_REASON_HEADERS.map((item) => {
          return window.i18next.t(item.label);
        }),
      ],
      [
        metricsOverview.delivery.negativeOutcomeTotal.toString(),
        ...metricsOverview.delivery.negativeOutcomeDetails.map((item) => {
          return item.value.toString();
        }),
      ],
    ];
  };

  const getDetailsHeaders = (): string[] => {
    if (campaignViewSelected()) {
      return CSV_DETAILS_CAMPAIGN_HEADERS.map((item) => {
        return window.i18next.t(item.label);
      });
    }

    return CSV_DETAILS_MAILING_LIST_HEADERS.map((item) => {
      return window.i18next.t(item.label);
    });
  };

  const getDetails = (detailsCSVData: CSVDetails): string[][] => {
    if (campaignViewSelected()) {
      return detailsCSVData.map((row) => {
        return [row.email, row.isDelivered.toString(), row.numOpens.toString(), row.numClicks.toString(), row.isUnsubscribe.toString()];
      });
    }

    return detailsCSVData.map((row) => {
      return [row.email, row.numOpens.toString(), row.numClicks.toString(), row.isUnsubscribe.toString()];
    });
  };

  const formatCSVData = (metricsOverview: CurrentItemMetricsOverview, detailsCSVData: CurrentItemDetails): string[][] => {
    const csvData = [
      CSV_EMPTY_LINE,
      [
        window.i18next.t(campaignViewSelected() ? CSV_SEPERATOR_HEADINGS.CAMPAIGN_SENT : CSV_SEPERATOR_HEADINGS.MAILING_LIST_CREATED_ON),
        formatDateYYYYMMDD(new Date(createdOnOrPublishOnDate)),
      ],
      [window.i18next.t(CSV_SEPERATOR_HEADINGS.CSV_GENERATED), formatDateYYYYMMDD(new Date())],
      CSV_EMPTY_LINE,
      getCSVSummaryHeaders(),
      getCSVSummary(metricsOverview as CSVMetrics),
    ];

    if (campaignViewSelected()) {
      csvData.push(CSV_EMPTY_LINE, ...getNegativeOutcomeDetails(metricsOverview as CSVMetrics));
    }

    csvData.push(CSV_EMPTY_LINE, getDetailsHeaders(), ...getDetails(detailsCSVData as CSVDetails));

    return csvData;
  };

  const initPDFGeneratorWizard = (): void => {
    openEmailMetricsDownloadPDFModal();
  };

  const triggerCSVDownload = (): void => {
    const selectedItemId: string | null = getFirstSelectedItemId(selectedItemIds) as string | null;

    if (!selectedItemId) {
      return;
    }

    let metrics: CurrentItemMetrics;
    let details: CurrentItemDetails;
    let metricsOverview: CurrentItemMetricsOverview;

    if (campaignViewSelected()) {
      metrics = campaignMetrics[selectedItemId];
      details = [...Object.values(metrics.details)];
      metricsOverview = {delivery: metrics.delivery, open: metrics.open, click: metrics.click};
    } else if (audienceViewSelected()) {
      metrics = props.mailingListMetrics[selectedItemId];
      details = [...Object.values(metrics.insights)];
      metricsOverview = {
        numRecipients: metrics.numRecipients,
        numBounces: metrics.numBounces,
        numClicks: metrics.numClicks,
        numOpens: metrics.numOpens,
        numComplaints: metrics.numComplaints,
        numDelivered: metrics.numDelivered,
        numUnsubscribes: metrics.numUnsubscribes,
      };
    } else {
      throw new Error('Invalid item selected');
    }

    setDataToDownload(formatCSVData(metricsOverview, details));
  };

  useEffect(() => {
    if (!dataToDownload.length) {
      return;
    }

    if (!csvLink.current) {
      return;
    }

    csvLink.current.link.click(); // fires the csv download

    if (campaignViewSelected()) {
      window.PMW.gtm.trackGA4CustomEvent(GA4EventName.DOWNLOAD_CAMPAIGN_CSV);
    } else {
      window.PMW.gtm.trackGA4CustomEvent(GA4EventName.DOWNLOAD_MAILING_LIST_CSV);
    }
  }, [dataToDownload]);

  const getDropdownOptions = (): ControlledListItem2[] => {
    const options: ControlledListItem2[] = [
      {
        id: 'option-download-csv',
        onClick: triggerCSVDownload,
        subText: window.i18next.t('pmwjs_includes_numerical_data_only'),
        text: window.i18next.t('pmwjs_spreadsheet_csv'),
        thumbnail: {
          type: THUMBNAIL_TYPE.ICON,
          icon: 'icon-table',
          icon_type: IconType.FLAT,
          IconShape: IconShape.SQUARE,
          flat_icon_type: {
            color: FlatIconColor.PRIMARY,
            type: FlatIconColorType.DEFAULT,
          },
        },
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
        className: styles.childIconWithRadius8,
      },
    ];

    if (campaignViewSelected()) {
      options.push({
        id: 'option-download-pdf',
        onClick: initPDFGeneratorWizard,
        subText: window.i18next.t('pmwjs_includes_graphs_and_numerical_data'),
        text: window.i18next.t('pmwjs_document_pdf'),
        thumbnail: {
          type: THUMBNAIL_TYPE.ICON,
          icon: 'icon-doc',
          icon_type: IconType.FLAT,
          IconShape: IconShape.SQUARE,
          flat_icon_type: {
            color: FlatIconColor.PRIMARY,
            type: FlatIconColorType.DEFAULT,
          },
        },
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
        className: styles.childIconWithRadius8,
      });
    }

    return options;
  };

  const campaignViewSelected = (): boolean => {
    return props.selectedItemType === ITEM_TYPES.CAMPAIGNS;
  };

  const audienceViewSelected = (): boolean => {
    return props.selectedItemType === ITEM_TYPES.AUDIENCES;
  };

  return (
    <>
      <CSVLink uFEFF={false} ref={csvLink} data={dataToDownload} filename={getCSVFileName()} className="_hidden" />
      <ControlledList className="_full-width" items={getDropdownOptions()} />
    </>
  );
}

const mapEmailMarketingCampaignsPerformanceStateToProps = (state: RootState): DownloadReportsDropdownOptionsPropTypes => {
  return {
    // the type of campaignMetrics in redux store is never[], supressing this error here to prevent type conflict
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    campaignMetrics: state.emailMarketingCampaignsPerformance.campaignMetrics,
    // the type of mailingListMetrics in redux store is never[], supressing this error here to prevent type conflict
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mailingListMetrics: state.emailMarketingCampaignsPerformance.mailingListMetrics,
    selectedItemType: state.emailMarketingCampaignsPerformance.itemType,
    selectedItemIds: state.emailMarketingCampaignsPerformance.selectedItemIds,
  };
};

function isMailingList(item: EmailMarketingCampaign | MailingList): item is MailingList {
  return (item as MailingList).createdOn !== undefined;
}

export const DownloadReportsDropdownOptions = connect(mapEmailMarketingCampaignsPerformanceStateToProps)(DownloadReportsOptionList);
