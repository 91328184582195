export interface BaseConfig {
  [key: string]: any;
}

interface ChangedKey {
  old: any;
  new: any;
}

export type ChangedKeys = Record<string, ChangedKey>;

export class CommonMethods {
  protected ignoreKeysForCopy: Record<string, null> = {};
  public copyVals(obj: Record<string, any> = {}): void {
    for (const [key, value] of Object.entries(obj)) {
      if (this.ignoreKeysForCopy[key] === undefined && this[key as keyof typeof this] !== value) {
        this[key as keyof typeof this] = value;
      }
    }
  }
}
