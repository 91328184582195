export const doesPublicFileExistAsync = (url: string, timeout = 0): Promise<boolean> => {
  return new Promise((resolve) => {
    try {
      const xmlHttp = new XMLHttpRequest();

      xmlHttp.ontimeout = (_) => {
        return resolve(false);
      };
      xmlHttp.onerror = (_) => {
        return resolve(false);
      };

      xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState === 4) {
          resolve(xmlHttp.status === 200);
        }
      };

      xmlHttp.open('GET', url, true);
      xmlHttp.timeout = timeout;
      xmlHttp.send(null);
    } catch (err) {
      resolve(false);
    }
  });
};

export const isSvgFile = (file: File): boolean => {
  return file.type === 'image/svg+xml' || file.name.toLowerCase().endsWith('.svg');
};
