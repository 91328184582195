import React from 'react';
import './dropdown.scss';
import PropTypes from 'prop-types';
import {DropdownItem} from './components/index.js';
import ReactDOM from 'react-dom';
import {PopupModal} from '../popup-modal';
import {Button, Size, Type} from '@Components/button';
import {arraysEqual} from "@Utils/array.util";

/**
 *
 * @type {Object.<string>}
 */
export const DROPDOWN_CONTENT_ALIGNMENT = {
  /**
   * removes the slight offset from the top for the main dropdown content list box
   */
  NO_TOP_OFFSET: '-without-top-offset',
  /**
   * adds a slight offset from the left for the main dropdown content list box
   */
  LEFT_OFFSET: '-with-left-offset',
};

/**
 * Generic dropdown component that can be plugged into other React components.
 * Supports click handlers and custom classes if one chooses to apply their own styles
 * Custom React components can also be used as dropdown items by setting the 'label' property in the dropdownItems object array
 * See PropTypes for more info.
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */

class Dropdown extends React.Component {
  static DROPDOWN_TOP_MARGIN = 4;
  static DROPDOWN_BOTTOM_MARGIN = 24;

  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      dataValue: this.props.defaultDataValue,
      showMore: this.props.showMore,
      isShowMoreHandled: false,
    };
    this.dropdownRef = React.createRef();
    this.portalDomId = this.props.portalDomId;
    this.title = this.props.title;
    this.showDropdownAsModalForMobile = this.props.showDropdownAsModalForMobile;
    this.scrollOffset = 70;
  }

  /**
   * attaches a click handler on the document to detect and handles clicks outside the dropdown
   */

  componentDidMount() {
    document.addEventListener('click', this.#documentClickHandler);
  }

  /**
   * cleaning up and removing the handler before component unmounts
   */

  componentWillUnmount() {
    document.removeEventListener('click', this.#documentClickHandler);
  }

  #hasDropdownReachedEnd = (newScrollTop, clientHeight, scrollHeight) => {
    return scrollHeight - newScrollTop <= clientHeight;
  };

  #toggleShowMore = (parentDropdown, isDropdownEnding) => {
    const overlay = parentDropdown.querySelector('.shadowed-wrapper-after');
    const showMoreButton = parentDropdown.querySelector('.js-show-more-button-a');
    if (isDropdownEnding) {
      overlay.classList.add('hidden');
      showMoreButton.classList.add('hidden');
    } else {
      overlay.classList.remove('hidden');
      showMoreButton.classList.remove('hidden');
    }
  };

  #scrollListener = (e) => {
    const parentDropdown = e.target.closest('.dropdown-content');

    const newScrollTop = parentDropdown.querySelector('.overlayWrapper').scrollTop;
    const clientHeight = parentDropdown.querySelector('.overlayWrapper').clientHeight;
    const scrollHeight = parentDropdown.querySelector('.overlayWrapper').scrollHeight;
    const isDropdownEnding = this.#hasDropdownReachedEnd(newScrollTop, clientHeight, scrollHeight);

    this.#toggleShowMore(parentDropdown, isDropdownEnding);

    if (newScrollTop === 0) {
      parentDropdown.querySelector('.shadowed-wrapper-before').classList.add('hidden');
    }

    if (newScrollTop > 0) {
      parentDropdown.querySelector('.shadowed-wrapper-before').classList.remove('hidden');
    }
  };

  /**
   * If the dropdown has received different dropdown items as props,
   * then change the labels to the newly passed ones on component update
   * @param {Object} prevProps
   * @param {Object} prevState
   * @param {any} snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!arraysEqual(this.props.dropdownItems, prevProps.dropdownItems) || this.#hasDataValueChanged(prevProps)) {
      this.setState({
        dataValue: this.props.defaultDataValue,
      });
    }

    this.#scrollToSelectedItem();
    if (this.state.showDropdown && this.props.showMore) {
      this.dropdownRef?.current?.querySelector('.overlayWrapper')?.dispatchEvent(new Event('scroll'));
    }
  }

  render() {
    const {dropdownItems, dropdownClasses, dropdownTypographyClasses} = this.props,
      showDropdown = this.state.showDropdown;
    this.dropdownItems = dropdownItems;

    return (
      <div className={`pmw-dropdown-v2 ${dropdownClasses} ${dropdownTypographyClasses}`} ref={this.dropdownRef}>
        <label className={`dropdown-label flexbox header-label ${this.props.dropdownLabelClasses}`} onClick={this.#toggleDropdown}>
          <div className="flexbox flex-h-row flex-align-items-center flex-content-start label-text-container">
            <div className={this.props.dropdownPrefixClasses}>{this.#getLabelPrefix()}</div>
            <div className={'label-text'} title={this.#getDropDownLabel()}>{this.#getDropDownLabel()}</div>
          </div>
          <i className={'dropdown-icon ' + (this.state.showDropdown ? 'icon-caret-up' : 'icon-caret-down')} />
        </label>
        {showDropdown && this.#getDropdownContent(dropdownItems)}
      </div>
    );
  }

  /**
   * if props are different and the new value is different to that of the current state, then return true else false
   * @param {Object} prevProps
   * @return {boolean}
   */
  #hasDataValueChanged = (prevProps) => {
    return this.props.defaultDataValue !== prevProps.defaultDataValue && this.props.defaultDataValue !== this.state.dataValue;
  };

  #getDropDownLabel = () => {
    let selectedItem = this.props.dropdownItems.find((x) => x.dataValue === this.state.dataValue);
    return selectedItem ? selectedItem.label : '&nbsp;';
  };

  #getDropdownContentByVariant = (items) => {
    if (this.props.showMore) {
      return (
        <div className={`dropdown-content dropdown-wrapper-not-scrollable ${this.props.dropdownContentAlignment} ${this.props.toggleShowMore ? '-spec' : ''}`}>
          <div
            className={'overlayWrapper'}
            onScroll={(e) => {
              this.#scrollListener(e);
            }}
          >
            <div className={'shadowed-wrapper-before hidden'}></div>
            <ul
              className={`js-dropdown-content  ${this.props.dropdownContentAlignment} ${this.props.dropdownContentClasses} -show-more-enabled`}
              style={this.#showDropdownInPortal() ? this.#getPositionStylesForDropdown() : null}
            >
              {this.#prepareListForRender(items)}
            </ul>
            <div className={'shadowed-wrapper-after'}></div>
          </div>
          <Button
            text={i18next.t('pmwjs_show_more')}
            customClasses={'btn-show-more-button radius-round js-show-more-button-a'}
            type={Type.SECONDARY}
            size={Size.SMALL}
            icon={'icon-arrow-bottom'}
          />
        </div>
      );
    } else {
      return (
        <ul
          className={`dropdown-content js-dropdown-content ${this.props.toggleShowMore ? '-spec dropdown-wrapper-not-scrollable' : ''} ${this.props.dropdownContentAlignment} ${
            this.props.dropdownContentClasses
          }`}
          style={this.#showDropdownInPortal() ? this.#getPositionStylesForDropdown() : null}
        >
          {this.#prepareListForRender(items)}
        </ul>
      );
    }
  };

  /**
   * returns the dropdown list to show when the dropdown menu is open
   * A modal is shown if the viewport width is mobile width and the showDropdownAsModalForMobile prop is true
   * if a react-portal DOM Id is passed then the menu is rendered inside a portal and positioned accordingly
   * @param {Array.<Object>} items
   * @return {JSX.Element|*}
   */
  #getDropdownContent = (items) => {
    const portalDomElement = document.getElementById(this.portalDomId),
      dropdownContent = this.#getDropdownContentByVariant(items);

    if (this.#showDropdownAsModalOnMobile()) {
      return (
        <PopupModal title={this.title} closeOnBodyClick={true} afterCloseCleanup={this.#closeDropdown} extraClasses={'js-dropdown-modal'}>
          {dropdownContent}
        </PopupModal>
      );
    } else if (portalDomElement) {
      return ReactDOM.createPortal(dropdownContent, portalDomElement);
    } else {
      return dropdownContent;
    }
  };

  /**
   * Returns exact position and size to apply on dropdown list
   * Used when dropdown list is created using portal
   * @returns {{top: number, left: number, width: number}}
   */
  #getPositionStylesForDropdown = () => {
    const boundingBox = this.dropdownRef ? this.dropdownRef.current.getBoundingClientRect() : null;
    return boundingBox
      ? {
          top: boundingBox.top + boundingBox.height + Dropdown.DROPDOWN_TOP_MARGIN,
          left: boundingBox.left,
          width: boundingBox.width,
          maxHeight: Math.round(window.innerHeight - boundingBox.bottom) - Dropdown.DROPDOWN_BOTTOM_MARGIN,
        }
      : {};
  };

  /**
   * scrolls to the selected item if its not in the viewport
   * Handles React Portal case gracefully as well in case the dropdown content is mounted inside a React Portal
   */
  #scrollToSelectedItem = () => {
    if (!this.#doesContentNeedToScroll()) {
      return;
    }

    const dropdown = this.#getDropdownContentDOMElement(),
      dropdownContent = dropdown.querySelector('.js-dropdown-content'),
      selectedItem = dropdownContent.querySelector('.js-selected'),
      distanceFromMenuTopForSelectedItem = selectedItem.getBoundingClientRect().y - dropdownContent.getBoundingClientRect().y;

    if (this.#isSelectedItemVisible(dropdownContent?.offsetHeight, selectedItem, distanceFromMenuTopForSelectedItem)) {
      dropdownContent.scrollTop = distanceFromMenuTopForSelectedItem - Dropdown.DROPDOWN_TOP_MARGIN;
    }
  };

  /**
   * Returns true if at least half of the item is visible in the viewport (the dropdown menu <ul>)
   * @param {number|undefined} dropdownContentHeight The height of the dropdown menu <ul>
   * @param selectedItem The selected dropdown item
   * @param {number} selectedItemVeritcalOffsetFromMenuTop the Y position offset of the selected dropdown item from the top of the dropdown menu <ul>
   * @return {boolean}
   */
  #isSelectedItemVisible = (dropdownContentHeight, selectedItem, selectedItemVeritcalOffsetFromMenuTop) => {
    return dropdownContentHeight !== undefined ? dropdownContentHeight < selectedItemVeritcalOffsetFromMenuTop + selectedItem.offsetHeight / 2 : false;
  };

  #isScrollAvailable = (clientHeight, newScrollTop) => {
    const isScrollAvailable = !(clientHeight - newScrollTop < this.scrollOffset);
    return isScrollAvailable;
  };

  #onShowMoreClick = (parentDropdown) => {
    const oldScrollTop = parentDropdown.querySelector('.overlayWrapper').scrollTop;
    const clientHeight = parentDropdown.querySelector('.overlayWrapper').clientHeight;
    const newScrollTop = oldScrollTop + this.scrollOffset;

    if (this.#isScrollAvailable(clientHeight, newScrollTop)) {
      this.#toggleShowMore(parentDropdown, true);
    }

    if (oldScrollTop == 0) {
      parentDropdown.querySelector('.shadowed-wrapper-before').classList.remove('hidden');
    }

    const moveScroll = parentDropdown.querySelector('.overlayWrapper').scrollBy({
      top: parentDropdown.querySelector('.overlayWrapper').scrollTop + this.scrollOffset,
      behavior: 'smooth',
    });
  };

  #getDropdownDetails = () => {
    const allDropdownHeaders = document.getElementsByClassName('pmw-dropdown-v2');
    let dropdownContentChild = null;
    let dropdownParent = null;
    const dropdownDetails = [];

    for (const parentElement of allDropdownHeaders) {
      dropdownContentChild = parentElement.querySelector('.dropdown-content');

      if (dropdownContentChild) {
        dropdownParent = parentElement;
        dropdownDetails.push(dropdownParent);
        dropdownDetails.push(dropdownContentChild);
        break;
      }
    }

    return dropdownDetails;
  };

  #getClickedDropdown = (eventTarget) => {
    const dropdownContent = document.getElementsByClassName('dropdown-content');
    let trueParentDropdown = null;
    for (let i = 0; i < dropdownContent.length; i = i + 1) {
      if (dropdownContent[i].contains(eventTarget)) {
        trueParentDropdown = dropdownContent[i];
        break;
      }
    }

    return trueParentDropdown;
  };

  /**
   * handler for when a click is recorded outside the dropdown.
   * Closes the dropdown if the click comes from outside the dropdown.
   * @param {Event} e
   */

  #documentClickHandler = (e) => {
    if (!this.dropdownRef.current.contains(e.target) && this.props.toggleShowMore) {
      this.props.toggleShowMore(false);
    }

    const buttonElements = document.getElementsByClassName('js-show-more-button-a');
    const dropdownDetails = this.#getDropdownDetails();

    let dropdownContentChild = null;
    if (dropdownDetails.length > 0) {
      dropdownContentChild = dropdownDetails[1];
      if (dropdownContentChild.scrollHeight > 500) {
        if (this.props.toggleShowMore) {
          this.props.toggleShowMore(true);
        }
      }
    }

    if (buttonElements[0] && buttonElements[0].contains(e.target)) {
      this.#onShowMoreClick(this.#getClickedDropdown(e.target));
      return;
    }

    if (!this.dropdownRef.current.contains(e.target)) {
      this.#closeDropdown();
    }
  };
  /**
   * toggles open/close state of dropdown
   */

  #toggleDropdown = () => {
    this.setState((prevState) => ({
      showDropdown: !prevState.showDropdown,
    }));
  };

  /**
   * closes the dropdown
   */

  #closeDropdown = () => {
    this.setState({
      showDropdown: false,
    });
  };

  /**
   * click handler for when a dropdown item is clicked.
   * Selects the clicked dropdown item and invokes the click handler passed down from props if any.
   * @param {Event} e
   * @param {Object} option
   */
  #onItemClick = (e, option) => {
    e.stopPropagation();
    if (!option.disabled) {
      this.setState(
        {
          dropdownLabel: option.label,
          dataValue: option.dataValue,
          showDropdown: false,
        },
        this.props.clickHandler.bind(null, option.dataValue)
      );
    }
  };

  /**
   * Creates the markup for dropdown list
   * @param {Array.<Object>} listItems the dropdown list item objects
   * @return {Array.<JSX.Element>}
   */
  #prepareListForRender = (listItems) => {
    const totalItems = listItems.length;
    return listItems.map((item, index) => {
      return (
        <DropdownItem
          key={`dropdown-item-${item.dataValue}`}
          dataValue={item.dataValue}
          label={this.props.renderCustomLabel ? this.props.renderCustomLabel(item.label, this.state.dataValue === item.dataValue, totalItems, index) : item.label}
          isSelected={this.state.dataValue === item.dataValue}
          onDropdownItemClick={(e) => this.#onItemClick(e, item)}
          isLink={item.isLink}
          href={item.href}
          disabled={item.disabled}
          dropdownItemClasses={this.#getDropdownItemClasses(this.state.dataValue === item.dataValue, index)}
          title={item.showTitle ? item.label : ''}
          showNotification={item.showNotification}
        />
      );
    });
  };

  #getDropdownItemClasses = (isItemSelected, itemIndex) => {
    let showMoreClasses = '';

    if (this.props.toggleShowMore) {
      showMoreClasses = '-large-dropdown-item ';
    }

    if (this.props.toggleShowMore && isItemSelected) {
      showMoreClasses = showMoreClasses + 'body-xs-bold';
    }
    return `${this.props.dropdownItemClasses} ${this.props.dropdownItemVariant} ${showMoreClasses}`;
  };

  /**
   * Builds a prefix to the dropdown label which can have an optional icon as well.
   * The prefix stays fixed/does not change with as the selected item changes.
   * @return {JSX.Element|null}
   */
  #getLabelPrefix = () => {
    let labelPrefixIcon = null;
    if (this.props.labelPrefixIconClass !== undefined) {
      labelPrefixIcon = <i className={'icon ' + this.props.labelPrefixIconClass} />;
    }

    if (this.props.labelPrefix !== undefined) {
      return (
        <span className={'label-prefix flex-row-align-center'}>
          {labelPrefixIcon}
          {this.props.labelPrefix}
        </span>
      );
    }

    return null;
  };

  /**
   * Determines whether we even need scrolling depending on the current state and props
   * @return {boolean}
   */
  #doesContentNeedToScroll = () => {
    return this.state.showDropdown && this.props.scrollToSelectedItem && this.dropdownRef.current !== null;
  };

  /**
   *
   * @return {HTMLElement|Element}
   */
  #getDropdownContentDOMElement = () => {
    if (this.#showDropdownAsModalOnMobile()) {
      return document.querySelector('.js-dropdown-modal');
    }

    if (this.props.portalDomId) {
      return document.getElementById(this.props.portalDomId);
    }

    return this.dropdownRef.current;
  };

  /**
   *
   * @return {boolean}
   */
  #showDropdownAsModalOnMobile = () => {
    return PMW.isMobileScreenWidth() && this.showDropdownAsModalForMobile;
  };

  /**
   *
   * @return {boolean}
   */
  #showDropdownInPortal = () => {
    return this.props.portalDomId && !this.#showDropdownAsModalOnMobile();
  };
}

Dropdown.propTypes = {
  /**
   * the associated data-value with the default item we are showing
   */
  defaultDataValue: PropTypes.any.isRequired,

  /**
   * an array of dropdown item objects that contain information about the dropdown item.
   * The dropdown item accepts:
   *                  label: the label associated with the dropdown item,
   *                  dataValue: a unique data-value you assign to the dropdown item,
   *                  isLink: an optional item that determines whether the dropdown item is a link or button,
   *                  href: the associated href if the item is a link
   *                  disabled: whether the item is disabled or not
   *
   */
  dropdownItems: PropTypes.array.isRequired,

  /**
   * optional string that is used to define your own custom classes to the dropdown root element
   */
  dropdownClasses: PropTypes.string,

  /**
   * classes to give to the dropdown content box
   */
  dropdownContentClasses: PropTypes.string,
  /**
   * typography class to give to the root level container. Default: body-xs
   */
  dropdownTypographyClasses: PropTypes.string,

  /**
   * optional string to show with leading label
   */
  leadingLabelText: PropTypes.string,
  /**
   * optional string that is used to define your own custom classes to each dropdown item
   */
  dropdownItemClasses: PropTypes.string,
  /**
   * One of DROPDOWN_ITEM_VARIANTS.*
   * pass in the 'default' variant to get a gray background for a dropdown item's selected tile
   */
  dropdownItemVariant: PropTypes.string,

  /**
   * optional string that is used to define your own custom classes to the dropdown label
   */
  dropdownLabelClasses: PropTypes.string,

  /**
   * How the dropdown content should be aligned with respect to the dropdown label box
   * By default, we show the dropdown content list with a bit of an offset from the left
   * pass in DROPDOWN_CONTENT_ALIGNMENT.LEFT to align it with where the label starts from the left
   */
  dropdownContentAlignment: PropTypes.string,

  /**
   * an optional handler than can be passed down and will be invoked when a dropdown item is clicked
   */
  clickHandler: PropTypes.func,

  /**
   * Title/Name for the dropdown, will be used to show popup modal on mobile device
   */
  title: PropTypes.string,

  /**
   * Optional parameter to be passed if you want dropdown list to be rendered as react portal,
   * portalDomId is the id of the dom element which should be created before rendering Dropdown component
   *
   * This is used to solve a common problem where you want to build some tooltip or dropdown,
   * but it’s cut by the parent element overflow: hidden styling:
   * More info: https://blog.logrocket.com/learn-react-portals-by-example/
   */
  portalDomId: PropTypes.string,

  /**
   * Set true if you want to show dropdown list as a modal on mobile devices
   */
  showDropdownAsModalForMobile: PropTypes.bool,

  /**
   * Optional fixed prefix for the label of the dropdown.
   */
  labelPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Optional icon prefix for the label of the dropdown.
   */
  labelPrefixIconClass: PropTypes.string,

  /**
   * Use this flag to scroll to the selected item in case the dropdown list is overflowing and has a scrollbar
   */
  scrollToSelectedItem: PropTypes.bool,

  /**
   * Optional function that renders a custom label for the dropdown
   */
  renderCustomLabel: PropTypes.func,

  /**
   * optional string that is used to define your own custom classes to the dropdown label prefix
   */
  dropdownPrefixClasses: PropTypes.string,
};

Dropdown.defaultProps = {
  portalDomId: null,
  title: '',
  showDropdownAsModalForMobile: false,
  dropdownClasses: '',
  dropdownContentClasses: '',
  dropdownTypographyClasses: 'body-xs',
  dropdownItemClasses: '',
  dropdownItemVariant: '',
  dropdownLabelClasses: '',
  dropdownContentAlignment: '',
  dropdownPrefixClasses: '',
  leadingLabelText: '',
  clickHandler: $.noop,
  scrollToSelectedItem: true,
  renderCustomLabel: null,
};

export default Dropdown;
