import React from "react";
import PropTypes from 'prop-types';
import './campaign-delivery-negative-outcome-details.scss';
import {Button, Size, Type} from "../../../button";

/**
 * Component that shows details about emails from an email marketing campaign that were not delivered
 * @author Basil <basil@250mils.com>
 */
class CampaignDeliveryNegativeOutcomeDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let showRemoveInvalidEmailsCTA = false;
        return (
            <div className={'campaign-delivery-negative-outcome-details spacing-p-3 radius-4'}>
                {this.props.negativeOutcomeDetails.map((item, index) => {
                    showRemoveInvalidEmailsCTA = item.value > 0;
                    return (
                        <div className={'detail flexbox'} key={index}>
                            <span>{item.label}</span>
                            <span className={'-text-bold'}>{item.value}</span>
                        </div>
                    )
                })}
                {showRemoveInvalidEmailsCTA ?
                    <Button
                        type={Type.PRIMARY}
                        size={Size.SMALL}
                        onClick={this.props.onRemoveInvalidEmails}
                        text={i18next.t('pmwjs_remove_invalid_emails')}
                        customClasses={'-fullwidth btn-remove-invalid-emails'}/>
                    : null
                }
            </div>
        );
    }
}


CampaignDeliveryNegativeOutcomeDetails.propTypes = {
    /**
     * An array containing details of the emails that were not delivered
     */
    negativeOutcomeDetails: PropTypes.array.isRequired,

    /**
     * Handler for the 'Remove Invalid Emails' CTA
     */
    onRemoveInvalidEmails: PropTypes.func.isRequired
};

export default CampaignDeliveryNegativeOutcomeDetails;