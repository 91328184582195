import type {ReactElement} from 'react';
import React from 'react';
import {ALIGNMENT_TYPE, DROPDOWN_SIZE} from '@Components/dropdown-v2/dropdown.types';
import {DropdownDefaultSelectBox} from '@Components/dropdown-v2/components/dropdown-default-select-box';
import {Dropdown} from '@Components/dropdown-v2';
import type {Brand} from '@Components/mystuff-brands/brand-kits.types';
import { EMPTY_SELECTABLE_COLORS_STATE} from '@Components/mystuff-brands/brand-kits.types';
import {getActiveBrandId} from '@Libraries/brands-library';
import {changeActiveBrandId} from '@Components/mystuff-brands/brands-thunk';
import {useDispatch} from 'react-redux';
import type {AnyAction} from 'redux';
import {ControlledList} from '@Components/controlled-list';
import {CONTROLLED_LIST_ITEM_TYPE} from '@Components/controlled-list/controlled-list.types';
import {setSelectedColorsInSelectableState, setSelectedLogoInSelectableState} from '@Components/mystuff-brands/brands-reducer';
import {useIsSmallerThanBreakpointWidth} from '@Hooks/useIsSmallerThanBreakpoint';
import {SCREEN_BREAKPOINTS} from '@Libraries/responsiveness-library';
import styles from './download-pdf-report-brand-selector-dropdown.module.scss';

interface BrandSelectorDropDownProps {
  brands: Brand[];
}

/**
 * Dropdown selector for selecting brands from the brands kits that can be used to customize the design in the PDF config wizard
 * @author Moeed Ahmad <moeeed@250mils.com>
 */
function DownloadPDFReportBrandSelectorDropdown({...props}: BrandSelectorDropDownProps): ReactElement {
  const activeBrandId = getActiveBrandId();
  const dispatchReduxThunk = useDispatch();
  const isMobileScreen = useIsSmallerThanBreakpointWidth(SCREEN_BREAKPOINTS.MOBILE_MODAL_ACTIVATION_BREAKPONT);

  const getActiveBrandDetails = (): Brand | undefined => {
    return props.brands.find((brand) => {
      return brand.brandId === activeBrandId;
    });
  };

  const changeBrandKitAndResetSelections = (brand: Brand): void => {
    dispatchReduxThunk(
      // wierd ts config shows error when passing the newActiveBrandId as an arg - disabling
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      changeActiveBrandId({newActiveBrandId: brand.brandId}) as unknown as AnyAction
    );
    dispatchReduxThunk(setSelectedColorsInSelectableState({ids: EMPTY_SELECTABLE_COLORS_STATE, defaultSelected: false}));
    dispatchReduxThunk(setSelectedLogoInSelectableState({id: -1, defaultSelected: false}));
  };

  const getDropDownBoxText = (): string => {
    return getActiveBrandDetails()?.name || window.i18next.t('pmwjs_select_brand_kit');
  };

  return (
    <section className={`grid-section spacing-m ${isMobileScreen ? 'spacing-m-b-4' : 'spacing-m-b-6'}`}>
      <h2 className="grid-section-heading content-heading body-l-bold">{window.i18next.t('pmwjs_brand_kits')}</h2>
      <Dropdown
        id="download-pdf-report-brand-selector-dropdown"
        alignment={ALIGNMENT_TYPE.BOTTOM_START}
        popup={
          <ControlledList
            id="dropdown-list"
            items={props.brands.map((brand) => {
              return {
                id: brand.brandId,
                text: brand.name,
                subText: '',
                type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_3,
                isSelected: brand.brandId === activeBrandId,
                onClick: (): void => {
                  changeBrandKitAndResetSelections(brand);
                },
              };
            })}
          />
        }
        selector={
          <DropdownDefaultSelectBox
            icon="icon-brands"
            id="brand-selector-email-metrics-export"
            size={isMobileScreen ? DROPDOWN_SIZE.SMALL : DROPDOWN_SIZE.MEDIUM}
            text={getDropDownBoxText()}
            className={styles.brandSelectorEmailMetricsExport}
          />
        }
        className={`${isMobileScreen ? '_full-width' : '_fit-width'}`}
      />
    </section>
  );
}

export default DownloadPDFReportBrandSelectorDropdown;
