import React from "react";
import PropTypes from 'prop-types';
import './modal.scss';
import {ModalContent} from "./components/modal-content";
import {ModalFooter} from "./components/modal-footer";
import {closeModal} from "../modal-container-deprecated/modal-container";
import {showLoading, hideLoading} from "../../libraries/loading-toast-library";

/**
 * Cache item to keep track of whether modals are disabled or not
 * @type {boolean}
 */
let disableModal = false;

export default class Modal extends React.Component {
    /**
     * @return {boolean}
     */
    static areModalsDisabled() {
        return disableModal;
    }

    /**
     * Disable modals from opening
     */
    static disableModals() {
        disableModal = true;
    }


    #isOpening = false;
    /**
     * The caches jquery element of modal
     * {jQuery}
     * @protected
     */
    el_;
    /**
     * Whether the modal has been initialized or not
     * @type {boolean}
     * @private
     */
    init_ = false;

    /**
     * jQuery selector for the body element. the -open-modal class is applied to the modal when opened to prevent scroll
     * @type {JQuery<HTMLElement>}
     */
    #bodyElement = $('body');

    /**
     * Render the modal component
     * @return {JSX.Element}
     */
    render = () => {
        return <div id={this.props.id} className={'modal -flex ' + this.props.classes} tabIndex="0">
            <div className={"modal-content " + this.props.modalClassName}>
                {this.props.header}
                {<ModalContent content={this.props.content} className={this.props.contentClassName}/>}
                {this.props.footerPrimaryActions.length > 0 && <ModalFooter primaryActions={this.props.footerPrimaryActions}
                                                                            secondaryActions={this.props.footerSecondaryActions}
                                                                            info={this.props.footerInfo}
                                                                            infoClassName={this.props.footerInfoClassName}
                                                                            className={this.props.footerClassName}
                                                                            footerPrimaryClassName={this.props.footerPrimaryClassName}
                />}
            </div>
        </div>;
    }

    /**
     * Open the modal when added to dom if wanted
     */
    componentDidMount = () => {
        this.el_ = $('#' + this.props.id);
        if (!this.init_) {
            this.init_ = true;
            showLoading('initModal' + this.props.id);
            this.#initialize().then(() => {
                if (this.props.open) {
                    this.#open();
                }
            }).catch(() => {
                hideLoading('initModal' + this.props.id);
            });
        } else if (this.props.open) {
            this.#open();
        }
    }


    /**
     * Open/close modal on modification
     */
    componentDidUpdate = () => {
        if (this.props.open) {
            this.#open();
        } else {
            this.#close();
        }
    }

    /**
     * Initialize and open the modal
     * @return {Promise<void>}
     */
    #open = async () => {
        if (Modal.areModalsDisabled() || this.el_.hasClass('modal-open') || this.#isOpening || !this.props.shouldOpen()) {
            return;
        }

        this.#isOpening = true;
        await this.props.beforeOpen();
        this.#bringModalToFront();
        this.#disablePageScroll();
        await this.#animateInModal();
        this.#isOpening = false;
        await this.#onModalOpen();
        hideLoading('initModal' + this.props.id);
    }

    /**
     * Close the modal
     * @return {Promise<void>}
     */
    #close = async () => {
        if (!this.props.isDismissible) {
            return;
        }
        if (!this.el_.hasClass('modal-open')) {
            return;
        }
        this.#isOpening = false;
        this.el_.children().removeClass('animate-modal-dialog-open');

        this.el_.addClass('-closing');
        this.props.beforeClose();
        await this.#animateClose();
        this.el_.removeClass('-closing');
        this.#onModalClose();
    }

    /**
     * Animate the modal to close
     * @return {Promise<unknown>}
     * @private
     */
    #animateClose = async () => {
        return new Promise((resolve) => {
            let mc = this.el_.children('.modal-content');
            if (mc.hasClass('animated') || (!this.#isOpening && this.props.animateModalClose)) {

                if (mc.hasClass('animated')) {
                    mc.removeClass('fadeInDown').addClass('fadeOutUp')
                        .one(PMW.util.animationEndEvents, resolve.bind(this));

                } else {
                    mc.addClass('animate-modal-dialog-close')
                        .one(PMW.util.animationEndEvents, resolve.bind(this));
                }
            } else {
                resolve();
            }

        });
    }

    /**
     * Callback for when the close animation has completed
     */
    #onModalClose = () => {
        this.el_.removeClass('modal-open')
            .children().removeClass('animate-modal-dialog-close').off();

        this.#enablePageScrollForModalClose();
        this.props.onClose();
        PMW.disconnectTopActionObserver(this.el_);
    }

    /**
     * Animates the modal in
     * @return {Promise<unknown>}
     * @private
     */
    #animateInModal = async () => {
        return new Promise((resolve) => {
            this.el_.addClass('modal-open')
                .children().addClass('animate-modal-dialog-open')
                .one(PMW.util.animationEndEvents, () => {
                    this.el_.children().removeClass('animate-modal-dialog-open');
                    resolve();
                })
        });
    }

    /**
     * Initialize modal handlers the first time it opens up
     */
    #initialize = async () => {
        PMW.bindModalCloseEvents(this.props.id, closeModal.bind(null, this.props.id));
        PMW.monitorAndUpdateModalTopActionChange(this.el_);
        PMW.onClick(this.el_, '.close', closeModal.bind(null, this.props.id));
        await this.props.initialize();
    }

    /**
     * Handler for when modal opens
     * @private
     */
    #onModalOpen = async () => {
        if (this.el_.find('.header .modal-header-actions .modal-header-action:visible').length > 1) {
            this.el_.find('.header .modal-header-actions').addClass('-multiple-actions');
        }

        this.#loadModalImages();
        this.#loadModalSprites();
        await this.props.onModalOpen();
    }

    /**
     * Prevents scrolling the page, hides the scroll bar for the page,
     * and adds a padding to the right to avoid any jerk when the scroll bar is moved.
     */
    #disablePageScroll = () => {
        this.#bodyElement.css('padding-right', `${window.innerWidth - this.#bodyElement.width()}px`);
        this.#bodyElement.addClass('-open-modal');
    }

    /**
     * enables scrolling for a page if all modal dialogs have been closed
     */
    #enablePageScrollForModalClose = () => {
        if (this.#haveAllModalsClosed()) {
            this.#bodyElement.removeAttr('style');
            this.#bodyElement.removeClass('-open-modal');
        }
    }

    /**
     * Loads all the images on modal using the data-url attribute
     * @private
     */
    #loadModalImages = () => {
        this.el_.find('img[data-url]').each(() => {
            this.src = this.getAttribute('data-url');
            this.removeAttribute('data-url');
        });
    }


    /**
     * Loads all the sprites on the modal using the data-sprite attribute
     * @private
     */
    #loadModalSprites = () => {
        this.el_.find('div[data-sprite]').each(() => {
            this.style.backgroundImage = 'url(' + this.getAttribute('data-sprite') + ')';
            this.removeAttribute('data-sprite');
        });
    }

    /**
     * Brings the modal to the front of all the modals
     * @private
     */
    #bringModalToFront = () => {
        let modals = $('.modal, .ReactModal__Overlay'),
            maxZIndex = 0;

        for (let i = 0; i < modals.length; i++) {
            let modalZIndex = parseInt($(modals[i]).css('z-index'));

            if (modalZIndex > maxZIndex) {
                maxZIndex = modalZIndex
            }
        }

        if (maxZIndex > this.el_.css('z-index')) {
            this.el_.css('z-index', maxZIndex + 1);
        }
    }

    /**
     *
     * @return {boolean}
     */
    #haveAllModalsClosed = () => {
        return $('.modal.modal-open').length === 0;
    }
}

Modal.propTypes = {
    id: PropTypes.string.isRequired,
    headerTitle: PropTypes.string,
    content: PropTypes.element.isRequired,
    headerContent: PropTypes.element,
    footerInfo: PropTypes.element,
    footerInfoClassName: PropTypes.string,
    footerPrimaryActions: PropTypes.array,
    footerSecondaryActions: PropTypes.array,
    open: PropTypes.bool,
    beforeOpen: PropTypes.func,
    beforeClose: PropTypes.func,
    onModalOpen: PropTypes.func,
    shouldOpen: PropTypes.func,
    classes: PropTypes.string,
    contentClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    animateModalClose: PropTypes.bool,
    onClose: PropTypes.func,
    initialize: PropTypes.func,
    isDismissible: PropTypes.bool,
    footerClassName: PropTypes.string,
    footerPrimaryClassName: PropTypes.string,
};

Modal.defaultProps = {
    beforeOpen: $.noop,
    onModalOpen: $.noop,
    initialize: $.noop,
    footerPrimaryActions: [],
    classes: "",
    contentClassName: "",
    modalClassName: "",
    animateModalClose: true,
    onClose: $.noop,
    beforeClose: $.noop,
    shouldOpen: () => {
        return true;
    },
    isDismissible: true,
    open: true,
    footerClassName: '',
    footerPrimaryClassName: '',
};
