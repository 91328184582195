// CodeReviewDanyDone: Change to ts
import type {ActionReducerMapBuilder} from '@reduxjs/toolkit/src/mapBuilders';
import type {NoInfer} from '@reduxjs/toolkit/src/tsHelpers';
import {getPMWLangsVersion, getSupportedLanguages} from '@Components/language-selector/language-selector-thunk';
import type {StateProps} from './language-selector.types';

export const langSelectorExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<StateProps>>) => {
  builder.addCase(getPMWLangsVersion.fulfilled, (state, {payload}) => {
    state.pmwLangsVersion = payload.version;
  });

  builder.addCase(getSupportedLanguages.fulfilled, (state, {payload}) => {
    state.pmwLangs = payload.pmwLangs;
    state.pmwLangsVersion = payload.version;
  });
};
