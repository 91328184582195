import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  hasInsideMasking: boolean;
  hasColorPop: boolean;
  shapeId?: string;
  gridID?: string;
}

const initialState: StateProps = {
  hasInsideMasking: false,
  hasColorPop: false,
  shapeId: undefined,
};

const shapeMaskingSlice = createSlice({
  name: 'shapeMasking',
  initialState,
  reducers: {
    setShapeId(state, action: PayloadAction<string | undefined>) {
      state.shapeId = action.payload;
    },
    setHasInsideMasking(state, action: PayloadAction<boolean>) {
      state.hasInsideMasking = action.payload;
    },
    setHasColorPop(state, action: PayloadAction<boolean>) {
      state.hasColorPop = action.payload;
    },
    setGridID(state, action: PayloadAction<string>) {
      state.gridID = action.payload;
    },
  },
});

export const {setHasInsideMasking, setHasColorPop, setShapeId, setGridID} = shapeMaskingSlice.actions;
export const shapeMaskingReducer = shapeMaskingSlice.reducer;
