import {onAjaxError} from '@Utils/ajax.util';

const THUNK_REQUEST_STATUS_REJECTED = 'rejected';

/**
 * Executes the thunk callback that dispatches a redux thunk and does error handling.
 * The error callback is executed if there is an error in the returned promise, or if the request status is 'rejected',
 * redux thunks execute a 'rejected' promise on failure
 * A default callback that opens an error modal displaying a generic error message is shown if no error callback is specified
 * @param thunkDispatchCallback Function that dispatches a redux thunk
 * @param onSuccessCallback Callback function to be executed on success
 * @param onErrorCallback Callback function to be executed on error
 */
export const executeThunk = async (thunkDispatchCallback: any, onSuccessCallback: any = null, onErrorCallback: any = null): Promise<void> => {
  const response = await thunkDispatchCallback();
  if (hasThunkRequestFailed(response)) {
    if (isValidCallback(onErrorCallback)) {
      onErrorCallback!(response);
    } else {
      onAjaxError(response.error, response.error.message);
    }
  } else if (isValidCallback(onSuccessCallback)) {
    onSuccessCallback!(response.payload);
  }
};

/**
 * Checks if the redux thunk request has failed
 * @param response Response object from thunk dispatch
 * @returns {boolean}
 */
const hasThunkRequestFailed = (response: any): boolean => {
  return response.error || response.meta?.requestStatus === THUNK_REQUEST_STATUS_REJECTED;
};

/**
 * Checks if a callback function is valid
 * @param callback Callback function to be checked
 * @returns {boolean}
 */
const isValidCallback = (callback: any | null): boolean => {
  return !!(callback && typeof callback === 'function');
};
