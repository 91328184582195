import type {TrimVideoReduxState} from '@Panels/trim-panels/trim-video-panel/trim-video-panel.types';
import type {TrimAudioReduxState} from '@Panels/trim-panels/trim-audio-panel/trim-audio-panel.types';

export interface TrimPanelBaseProps {
  duration: number;
  start: number;
  end: number;
  source: string;
  maxDuration: number;
  panelId?: string;
  showMaxDurationError?: boolean;
  forceTrim?: boolean;

  onTrim(trimData: TrimData): void | Promise<void>;
}

export enum TIME_BLOCK_ALIGNMENT {
  ALIGNLEFT = 'ALIGNLEFT',
  ALIGNRIGHT = 'ALIGNRIGHT',
}

export interface BaseTrimReduxState {
  playing: boolean;
  startTime?: number;
  endTime?: number;
}

export const enum REDUX_STATE {
  TYPE_AUDIO = 'AUDIO',
  TYPE_VIDEO = 'VIDEO',
}

export interface ReduxTimeSetterProps {
  key: REDUX_STATE;
  value: number | undefined;
}

export type TrimReduxStateValue = TrimAudioReduxState | TrimVideoReduxState;

export interface ReduxStateSetterProps {
  key: REDUX_STATE;
  value: TrimReduxStateValue;
}

export interface ReduxPlayingSetterProps {
  key: REDUX_STATE;
  value: boolean;
}

export interface TrimData {
  startTime: number;
  endTime: number;
}
