import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
  TemplateDesignStylesData,
  TemplateSubmissionData,
  TemplatesInfoAjaxResponse,
  ProcessTemplatesData,
} from '@Components/template-submission-flow/template-submission-flow.types';

export const submitTemplate = createAsyncThunk('submitTemplate', async (data: TemplateSubmissionData) => {
  return window.PMW.writeLocal('sell/savesubmission', data);
});

export const addDesignStylesToTemplate = createAsyncThunk<TemplatesInfoAjaxResponse, TemplateDesignStylesData>(
  'addDesignStylesToTemplate',
  async (data: TemplateDesignStylesData) => {
    return window.PMW.writeLocal('designers/addDesignStylesToTemplate', data);
  }
);

export const skipTemplate = createAsyncThunk<TemplatesInfoAjaxResponse, ProcessTemplatesData>('skipTemplate', async (data: ProcessTemplatesData) => {
  return window.PMW.writeLocal('designers/skipTemplate', data);
});
