import React from "react";
import './modal-header.scss';
import {ModalHeaderActions} from "../modal-header-actions";
import PropTypes from "prop-types";
import {ModalTabs} from "../modal-tabs";

export default class ModalHeader extends React.Component {
    render = () => {
        if (this.props.children) {
            return <div className={"header -flex " + (this.#hasTabs() ? 'has-tabs ' : '')}>
                {this.props.children}
            </div>;
        }

        return <div className={"header -flex " + (this.#hasTabs() ? 'has-tabs ' : '')}>
            {this.#hasTabs() && <ModalTabs tabs={this.props.tabs} selectedTabId={this.props.selectedTabId} updateSelectedTab={this.props.updateSelectedTab}/>}
            <div className={`title ${this.props.showBackButton ? '-has-back' : ''}`}>
                {this.props.showBackButton && <div className="modal-header-action back" title={i18next.t('pmwjs_back')} onClick={this.props.onBackButton}><i className="icon icon-back action-icon"/></div>}
                <h2 className={'heading ' + this.props.titleClass}>{this.props.title}</h2>
            </div>
            <div className="top-actions-bar">
                <ModalHeaderActions
                    actions={this.props.actions}
                    dismissible={this.props.dismissible}
                />
            </div>
        </div>;
    }

    #hasTabs = () => {
        return this.props.showTabs && this.props.tabs.length > 0;
    }
}

ModalHeader.propTypes = {
    tabs: PropTypes.array,
    selectedTabId: PropTypes.string,
    updateSelectedTab: PropTypes.func,
    title: PropTypes.string,
    titleClass: PropTypes.string,
    content: PropTypes.element,
    actions: PropTypes.array,
    showBackButton: PropTypes.bool,
    showTabs: PropTypes.bool,
    onBackButton: PropTypes.func,
    dismissible: PropTypes.bool,
};

ModalHeader.defaultProps = {
    tabs: [],
    selectedTabId: '',
    titleClass: '',
    updateSelectedTab: $.noop,
    showBackButton: false,
    showTabs: true,
    onBackButton: $.noop,
    dismissible: true,
}
