/**
 * Library with constants and utitlity functions based on screen realestate
 * @author Moeed Ahmad <moeeed@250mils.com>
 */

export enum SCREEN_BREAKPOINTS {
  XS = '480',
  SMALL = '576',
  MEDIUM = '768',
  TABLET = '896',
  LARGE = '992',
  XL = '1400',
  MOBILE_MODAL_ACTIVATION_BREAKPONT = '550',
}
