import React, {ReactElement} from 'react';
import {GridEmailSignupFormItemStorage} from '@Components/email-signup-form-grid/components/email-signup-form-grid';
import {Icon} from '@Components/icon-v2';
import {FlatIconColor, FlatIconColorType, IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from "@Components/text";
import {SignupFormGridItemOptions} from "@Components/base-grid/components/grid-email-signup-form-item/components/signup-form-grid-item-options";

interface MobileSignupFormGridItemProps {
  gridItem: GridEmailSignupFormItemStorage;
}

export function MobileSignupFormGridItem(
    {
        gridItem
    }: MobileSignupFormGridItemProps ): ReactElement {

    return (
        <div className="_full-width flex-row-align-center">
            <Icon
                icon="icon-signup-form"
                className="spacing-m-r-2"
                size={IconSize.SIZE_ICON_24}
                type={IconType.FLAT}
                shape={IconShape.SQUARE}
                flatIconType={{type: FlatIconColorType.DEFAULT, color: FlatIconColor.NEUTRAL}}
            />
            <a className="flex-v-row _full-height flex-1 _overflow-hidden" aria-label={gridItem.name} href={window.PMW.util.site_url(`formmaker/load/${gridItem.id}`)}>
                <Text val={gridItem.name} bold className="content-body spacing-m-b-1 -truncated-text" size={TextSize.SMALL} />
                <Text val={window.i18next.t('pmwjs_emails_collected', {emailsCount: gridItem.subscribedEmailsCount})} className="content-body" size={TextSize.XXSMALL} />
            </a>
            <SignupFormGridItemOptions gridItem={gridItem} />
        </div>
    );
}