import type {SocialMediaAccountId} from '@Components/social-media/post.vo';
import type {PosterHashedId} from '@Components/social-media/publish-params.types';

export enum ResizeOptionsConfig {
  FILL = 1,
  BLUR,
  STRETCH,
  CROP_TOP,
  CROP_CENTER,
  CROP_BOTTOM,
}

export enum ResizeOption {
  FILL = 1,
  BLUR,
  STRETCH,
  CROP,
}

export enum CropOption {
  CROP_TOP = 1,
  CROP_CENTER,
  CROP_BOTTOM,
}

export enum AccountResizeStatus {
  RESIZING = 'RESIZING',
  RESIZE_ERROR = 'RESIZE_ERROR',
  NOT_RESIZED = 'NOT_RESIZED', // The design has not been resized yet, nor has resizing started either
  RESIZE_DONE = 'RESIZE_DONE',
}

export interface AccountResizeStatusForDesignPayload {
  postResizeStatus?: AccountResizeStatus;
  reelResizeStatus?: AccountResizeStatus;
  storyResizeStatus?: AccountResizeStatus;
  idSocialMediaAccount: SocialMediaAccountId;
  idPoster: PosterHashedId;
}
