import React from "react";
import './modal-tabs.scss';
import PropTypes from "prop-types";

export default class ModalTabs extends React.Component {
    render = () => {
        if (!this.props.tabs) {
            return null
        }

        let tabs = [];
        for (const tab of this.props.tabs) {
            tabs.push(React.cloneElement(
                tab,
                {
                    isSelected: this.props.selectedTabId === tab.props.id,
                    key: tab.props.id,
                    onClick: this.#onClicked.bind(null, tab),
                }
            ));
        }

        return <div className="modal-header-tabs">
            {tabs}
        </div>
    }

    /**
     * Select the tab clicked on
     * @param {ModalTab} tab
     */
    #onClicked = (tab) => {
        this.props.updateSelectedTab(tab.props.id);
    }
}

ModalTabs.propTypes = {
    tabs: PropTypes.array,
    selectedTabId: PropTypes.string,
    updateSelectedTab: PropTypes.func,
};

ModalTabs.defaultProps = {
    tabs: [],
    selectedTabId: '',
    updateSelectedTab: $.noop,
}
