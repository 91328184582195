import type {ReactElement} from 'react';
import React from 'react';
import {IconShape, IconSize} from '@Components/icon-v2/icon.types';
import type {VoidFunction} from '@Components/login-page/login-page.types';
import {ClickableDiv} from '@Components/clickable-div';
import {noop} from '@Utils/general.util';
import {Icon} from '../icon-v2';
import styles from './checkbox.module.scss';

interface CheckboxProps {
  className?: string;
  isChecked?: boolean;
  disabled?: boolean;
  onClick?: VoidFunction;
  size?: IconSize;
  type?: CHECKBOX_TYPE;
}

export enum CHECKBOX_TYPE {
  DARKER = 'darker',
  LIGHT = 'light',
  DEFAULT = 'default',
  LIGHTER = 'lighter',
  PRIMARY = 'primary',
  SUCCESS = 'success',
}

export function Checkbox({
  className = '',
  isChecked = false,
  disabled = false,
  onClick = noop,
  size = IconSize.SIZE_ICON_12,
  type = CHECKBOX_TYPE.DEFAULT,
}: CheckboxProps): ReactElement | null {
  const isDefaultType = type === CHECKBOX_TYPE.DEFAULT;
  const getCheckboxColorClass = (): string => {
    switch (type) {
      case CHECKBOX_TYPE.DARKER:
        return styles.darker;

      case CHECKBOX_TYPE.LIGHT:
        return styles.light;

      case CHECKBOX_TYPE.PRIMARY:
        return styles.primary;

      case CHECKBOX_TYPE.LIGHTER:
        return styles.lighter;

      case CHECKBOX_TYPE.SUCCESS:
        return styles.success;

      case CHECKBOX_TYPE.DEFAULT:
      default:
        return '';
    }
  };

  return (
    <ClickableDiv
      onClick={onClick}
      role="checkbox"
      aria-checked={isChecked}
      className={`radius-4 ${styles.checkboxContainer} ${!isDefaultType ? getCheckboxColorClass() : ''} ${isChecked ? styles.selected : ''} ${className}`}
    >
      <Icon icon="icon-check" size={size} isDisabled={disabled} shape={IconShape.NONE} className={!isChecked ? '_invisible' : ''} />
    </ClickableDiv>
  );
}
