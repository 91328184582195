import React from "react";
import './brand-assets-progress-and-illustration.scss';
import {BrandsIllustration} from "../brands-illustration";
import {BrandsProgressBar} from "../brands-progress-bar";

/**
 * Displays the Brand Preview Illustration and
 * a Progress bar showing how many types of brand assets the user has added in My Stuff
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 * @returns {JSX.Element}
 */
const BrandAssetsProgressAndIllustration = () => {
    return (
        <section className={'brands-illustration-and-progress-section'}>
            {<h2 className={'brands-preview-heading content-heading body-xs-bold spacing-p-0'} >{i18next.t('pmwjs_brand_preview')}</h2>}
            <div className={'brands-illustration-and-progress-container'}>
                <BrandsProgressBar />
                <BrandsIllustration />
            </div>
        </section>
    );
}

export default BrandAssetsProgressAndIllustration;