import type {ReactElement} from 'react';
import React from 'react';
import {Text, TextSize} from '@Components/text';
import type {GridAudienceItemStorage} from "@Components/email-marketing-campaign-audience/components/audience-grid";
import {AudienceGridItemOptions} from "@Components/base-grid/components/grid-email-campaign-audience-item/components/audience-grid-item-options";
import styles from './audience-grid-item-footer.module.scss';

interface AudienceGridItemFooterProps {
  gridItem: GridAudienceItemStorage;
}

export function AudienceGridItemFooter({gridItem}: AudienceGridItemFooterProps): ReactElement {
  return (
    <div className={`flex-row-align-center spacing-m-t-2 spacing-p-r-2 ${styles.footer}`}>
      <Text val={gridItem.name} size={TextSize.XSMALL} className={`${styles.audienceName} flex-1`} />
      <AudienceGridItemOptions gridItem={gridItem} isSpam={!!gridItem.isSpam} />
    </div>
  );
}
