export interface AudioItemProps {
  uid: string;
  title: string;
  duration: number;
  width?: number;
  height?: number;
  source: string;
  onTitleRenamed?: (newTitle: string) => Promise<void>;
  showThreeDots?: boolean;
  categories?: string[];
}

export enum AudioItemDropdownItemId {
  RENAME = 'rename',
  MOVE = 'move',
  DELETE = 'delete',
}
