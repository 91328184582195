import type {ReactElement} from 'react';
import React from 'react';
import {Text, TextSize} from '@Components/text';
import {DROPDOWN_SIZE} from '@Components/dropdown-v2/dropdown.types';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import styles from './dropdown-text.module.scss';

interface DropdownTextProps {
  text: string;
  className?: string;
  type?: DROPDOWN_TEXT_TYPE;
  size?: DROPDOWN_SIZE;
  isBold?: boolean;
  isDisabled?: boolean;
  icon?: string;
  iconClassName?: string;
  textClassName?: string;
  /**
   * can use svg instead of icon but icon gets preference over it
   */
  svg?: ReactElement;
}

export enum DROPDOWN_TEXT_TYPE {
  SELECTOR_TEXT = 'SELECTOR_TEXT',
  INFORMATION_TEXT = 'INFORMATION_TEXT',
  LABEL_TEXT = 'LABEL_TEXT',
  DESCRIPTON_TEXT = 'DESCRIPTON_TEXT',
}

export const DropdownText = ({
  type = DROPDOWN_TEXT_TYPE.SELECTOR_TEXT,
  size = DROPDOWN_SIZE.SMALL,
  className = '',
  isBold = false,
  isDisabled = false,
  icon = '',
  iconClassName = '',
  svg = undefined,
  ...props
}: DropdownTextProps) => {
  const getClasses = () => {
    if (isDisabled) {
      if (type === DROPDOWN_TEXT_TYPE.SELECTOR_TEXT) {
        return 'content-disabled';
      }
      return 'content-sub-text';
    }

    if (type === DROPDOWN_TEXT_TYPE.INFORMATION_TEXT) {
      return 'content-sub-text';
    }

    if (type === DROPDOWN_TEXT_TYPE.DESCRIPTON_TEXT) {
      return 'content-disabled';
    }

    return 'content-body';
  };

  const getTextSizeFromDropdownSize = () => {
    switch (type) {
      case DROPDOWN_TEXT_TYPE.SELECTOR_TEXT:
        if (size === DROPDOWN_SIZE.XSMALL) {
          return TextSize.XXSMALL;
        }
        if (size === DROPDOWN_SIZE.SMALL) {
          return TextSize.XSMALL;
        }
        return TextSize.SMALL;

      case DROPDOWN_TEXT_TYPE.INFORMATION_TEXT:
        return TextSize.XXSMALL;
      case DROPDOWN_TEXT_TYPE.LABEL_TEXT:
        if (size === DROPDOWN_SIZE.SMALL) {
          return TextSize.XXSMALL;
        }
        return TextSize.XSMALL;

      case DROPDOWN_TEXT_TYPE.DESCRIPTON_TEXT:
        if (size === DROPDOWN_SIZE.SMALL) {
          return TextSize.XXSMALL;
        }
        return TextSize.XSMALL;

      default:
        return TextSize.XSMALL;
    }
  };

  const getIconSizeFromDropdownSize = () => {
    if (type === DROPDOWN_TEXT_TYPE.INFORMATION_TEXT) {
      return IconSize.SIZE_ICON_16;
    }
    switch (size) {
      case DROPDOWN_SIZE.XSMALL:
        return IconSize.SIZE_ICON_16;
      case DROPDOWN_SIZE.SMALL:
        return IconSize.SIZE_ICON_20;
      case DROPDOWN_SIZE.MEDIUM:
        return IconSize.SIZE_ICON_24;
      default:
        return IconSize.SIZE_ICON_20;
    }
  };

  const getTextMargin = (): string => {
    if (type === DROPDOWN_TEXT_TYPE.SELECTOR_TEXT) {
      return 'spacing-m-l-2';
    }
    if (type === DROPDOWN_TEXT_TYPE.INFORMATION_TEXT) {
      return 'spacing-m-l-1';
    }
    return '';
  };

  const getSvgSizeFromDropdownSize = (): string => {
    switch (size) {
      case DROPDOWN_SIZE.MEDIUM:
        return styles.mediumSvg;
      case DROPDOWN_SIZE.SMALL:
      case DROPDOWN_SIZE.XSMALL:
      default:
        return styles.smallSvg;
    }
  };

  const showContent = (): ReactElement => {
    if (icon) {
      return (
        <div className={`${className} ${styles.content} flex-row-align-center`}>
          <div className="flexbox">
            <Icon icon={icon} size={getIconSizeFromDropdownSize()} className={`${styles.icon} ${iconClassName}`} />
          </div>
          <Text className={`${getClasses()} ${getTextMargin()} ${styles.content} ${props.textClassName}`} val={props.text} size={getTextSizeFromDropdownSize()} bold={isBold} />
        </div>
      );
    }
    if (svg) {
      return (
        <div className={`${className} ${styles.content} flex-row-align-center`}>
          <div className={`flex-center ${getSvgSizeFromDropdownSize()}`}>{svg}</div>
          <Text className={`${getClasses()} ${getTextMargin()} ${styles.content} ${props.textClassName}`} val={props.text} size={getTextSizeFromDropdownSize()} bold={isBold} />
        </div>
      );
    }
    return showText();
  };

  const showText = (): ReactElement => {
    return <Text className={`${className} ${styles.content} ${getClasses()} ${props.textClassName}`} val={props.text} size={getTextSizeFromDropdownSize()} bold={isBold} />;
  };

  return showContent();
};
