import React from 'react';
import {Modal} from "../../components/modal-deprecated";
import './error-modal.scss';
import PropTypes from "prop-types";
import {ModalFooterPrimaryAction} from "../modal-deprecated/components/modal-footer-primary-action";
import {ModalHeader} from "../modal-deprecated/components/modal-header";
import {getStackTrace} from "@Utils/debug.util";

export const ErrorModal = (props) => {
    let message = props.message === undefined ? i18next.t('pmwjs_default_error') : props.message;
    let title = props.title === undefined ? i18next.t('pmwjs_error_title') : props.title;

    /**
     * @return {JSX.Element}
     */
    const getContent = () => {
        return (
            <React.Fragment>
                <div className="icon-wrapper">
                    <i className="modal-icon icon-exclamation-triangle"/>
                </div>
                <p className="message" dangerouslySetInnerHTML={{__html: message}}/>
            </React.Fragment>
        );
    }

    /**
     * Initialize the modal
     * @return {Promise<void>}
     */
    const beforeOpenModal = () => {
        if (props.log) {
            let debugData = 'Url: ' + window.location.href + ' ';

            if (props.debugDetails) {
                debugData += props.debugDetails;
            }

            debugData = debugData + '\nStacktrace:\n ' + getStackTrace();

            PMW.log(message + " " + debugData);
        }
    }

    /**
     * Handle Enter to close the dialog
     * @param {object} e EventData
     */
    const onKeyUp = async e => {
        if (e.which === 13) {
            await this.close();
        }
    }

    return <Modal
        {...props}
        onKeyUp={onKeyUp}
        beforeOpen={beforeOpenModal}
        content={getContent()}
        header={<ModalHeader title={title}/>}
        footerPrimaryActions={[
            <ModalFooterPrimaryAction key="close" customClasses={"close"} text={i18next.t('pmwjs_close')}/>,
        ]}
    />;

}

ErrorModal.propTypes = {
    /**
     * Error message to show in the modal
     */
    message: PropTypes.string,
    /**
     * Whether to log the error at back end or not
     * @default true
     */
    log: PropTypes.bool,
    /**
     * Debug data to add the log if log value is true
     * @default ''
     */
    debugDetails: PropTypes.string,
};

ErrorModal.defaultProps = {
    log: true,
    debugDetails: ''
};
