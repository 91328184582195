import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface Accordions {
  accordions: Record<string, AccordionStorage>;
  groups: Record<string, AccordionGroupStorage>;
}

const initialState: Accordions = {
  accordions: {},
  groups: {},
};

interface AccordionStorage {
  open: boolean;
}

interface AccordionGroupStorage {
  accordionIds: Record<string, null>;
}

export interface AccordionInitializeProps {
  id: string;
  groupId: string;
}

export interface AccordionActionProps {
  accordionId: string;
}

export const accordionSlice = createSlice({
  name: 'accordion',
  initialState,
  reducers: {
    initializeAccordionState: (state, action: PayloadAction<AccordionInitializeProps>) => {
      state.accordions[action.payload.id] = {
        open: false,
      };
      if (action.payload.groupId) {
        const groupsHashmap = state.groups[action.payload.groupId];
        if (groupsHashmap) {
          const groupHashmap = groupsHashmap.accordionIds;
          groupHashmap[action.payload.id] = null;
          state.groups[action.payload.groupId] = groupsHashmap;
        } else {
          const temp: Record<string, null> = {};
          temp[action.payload.id] = null;
          state.groups[action.payload.groupId] = {
            accordionIds: temp,
          };
        }
      }
    },
    toggleAccordionState: (state, action: PayloadAction<AccordionActionProps>) => {
      const accordionIdsInGroup = getAccordionIdsForGroup(state, action.payload.accordionId);
      if (!state.accordions[action.payload.accordionId].open && accordionIdsInGroup.length > 0) {
        toggleAccordionStatesOff(state, accordionIdsInGroup);
      }
      toggleState(state, action.payload.accordionId);
    },
  },
});

/**
 * Returns all the accordion IDs if the accordionId passed as a param has a group
 * @param state
 * @param accordionId
 */
const getAccordionIdsForGroup = (state: Accordions, accordionId: string) => {
  const groupIds = Object.keys(state.groups);
  for (let a = 0; a < groupIds.length; a++) {
    const accordionIdsInGroup = Object.keys(state.groups[groupIds[a]].accordionIds);
    if (accordionIdsInGroup.indexOf(accordionId) !== -1) {
      return accordionIdsInGroup;
    }
  }
  return [];
};

const toggleState = (state: Accordions, accordionId: string) => {
  state.accordions[accordionId].open = !state.accordions[accordionId].open;
};

const toggleAccordionStatesOff = (state: Accordions, accordionIds: Array<string>) => {
  accordionIds.forEach((accordionId, index) => {
    state.accordions[accordionId].open = false;
  });
};

export const {initializeAccordionState, toggleAccordionState} = accordionSlice.actions;
export const accordionReducer = accordionSlice.reducer;
