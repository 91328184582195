import type {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import type {NoInfer} from '@reduxjs/toolkit/dist/tsHelpers';
import {LoadingStates} from '@Utils/loading.util';
import type {
  DesignerPayout,
  DesignerRoyaltiesAjaxParams,
  DesignerRoyalty,
  DesignersLazyData,
  DesignerTemplateLifetimeEarning,
  DesignerTemplateLifetimeEarnings,
  RoyaltyPaymentStatus,
  DesignerTemplateEarningsAjaxParams} from '@Libraries/my-stuff/designer-dashboard-library';
import {
  getAppropriateRoyaltiesState,
  getPaymentStatusesForFetchingRoyalties,
  LAZY_PAYOUTS_LIMIT,
  LAZY_ROYALTIES_LIMIT,
  PAYOUTS_ID_NAME,
  ROYALTIES_ID_NAME,
  TEMPLATES_LIFETIME_EARNINGS_ID_NAME,
  shouldTemplateEarningsBeSortedByPublishTime,
} from '@Libraries/my-stuff/designer-dashboard-library';
import {arraysEqual, objectArrayToHashmap} from '@Utils/array.util';
import {fetchDesignerEarningsStats, fetchDesignerPayouts, fetchDesignerRoyalties, fetchTemplateLifeTimeEarnings} from './designer-earnings-report-thunk';
import type {DesignerEarningsReducerState} from './designer-earnings-report.types';

export const designerEarningsExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<DesignerEarningsReducerState>>): void => {
  builder.addCase(fetchDesignerEarningsStats.fulfilled, (state: DesignerEarningsReducerState, {payload}) => {
    state.designerEarnings = {
      ...payload,
      lastFetchedOn: new Date(),
    };
    state.earningsSummaryLoadingState = LoadingStates.LOADED;
  });

  builder.addCase(fetchDesignerEarningsStats.pending, (state): void => {
    state.earningsSummaryLoadingState = LoadingStates.LOADING;
  });

  builder.addCase(fetchDesignerEarningsStats.rejected, (state): void => {
    state.earningsSummaryLoadingState = LoadingStates.NOT_LOADED;
  });

  builder.addCase(fetchDesignerPayouts.pending, (state): void => {
    setLoadingStateForLazyData(state.payouts, LoadingStates.LOADING);
  });

  builder.addCase(fetchDesignerPayouts.fulfilled, (state, {payload}): void => {
    setLoadingStateForLazyData(state.payouts, LoadingStates.LOADED);
    state.payouts.loadMore = payload.length > LAZY_PAYOUTS_LIMIT;
    const payouts: DesignerPayout[] = payload.slice(0, LAZY_PAYOUTS_LIMIT);
    addIds(
      state.payouts,
      payouts.map((payout) => {
        return payout.payoutId;
      })
    );
    state.allPayouts = {
      ...state.allPayouts,
      ...objectArrayToHashmap(payouts, PAYOUTS_ID_NAME),
    };
  });

  builder.addCase(fetchDesignerRoyalties.pending, (state): void => {
    setLoadingStateForRoyalties(state, LoadingStates.LOADING);
  });

  builder.addCase(fetchDesignerRoyalties.fulfilled, (state, {meta, payload}): void => {
    setFilteredRoyaltiesForCurrentlySetFilters(state, meta.arg, payload);
  });

  builder.addCase(fetchTemplateLifeTimeEarnings.pending, (state): void => {
    setLoadingStateForLazyData(state.templateLifetimeEarnings, LoadingStates.LOADING);
  });

  builder.addCase(fetchTemplateLifeTimeEarnings.fulfilled, (state, {meta, payload}): void => {
    if (areTemplateEarningAjaxParamsSameAsCurrentState(state, meta.arg)) {
      setLoadingStateForLazyData(state.templateLifetimeEarnings, LoadingStates.LOADED);
      setTemplateEarningsInStateFromPayload(state, meta.arg, payload);
    }
  });
};

const setLoadingStateForRoyalties = (state: DesignerEarningsReducerState, loadingState: LoadingStates): void => {
  const royalties = getAppropriateRoyaltiesState(state.royaltyFilters, state.royalties, state.filteredRoyalties);
  setLoadingStateForLazyData(royalties, loadingState);
};

const setLoadingStateForLazyData = (lazyData: DesignersLazyData, loadingState: LoadingStates): void => {
  lazyData.loadingState = loadingState;
};

const addIds = (lazyData: DesignersLazyData, newIds: number[]): void => {
  lazyData.ids = [...lazyData.ids, ...newIds];
};

const setFilteredRoyaltiesForCurrentlySetFilters = (state: DesignerEarningsReducerState, ajaxParams: DesignerRoyaltiesAjaxParams, payload: DesignerRoyalty[]): void => {
  const paymentStatusesFromCurrentFilters = getPaymentStatusesForFetchingRoyalties(state.royaltyFilters.purchaseType, state.royaltyFilters.paymentStatusType);
  if (areCurrentPaymentStatusesSameAsInAjax(paymentStatusesFromCurrentFilters, ajaxParams.paymentStatuses)) {
    setLoadingStateForRoyalties(state, LoadingStates.LOADED);
    setRoyaltiesInStateFromPayload(state, payload);
  }
};

const setRoyaltiesInStateFromPayload = (state: DesignerEarningsReducerState, payload: DesignerRoyalty[]): void => {
  const royaltiesToAlter = getAppropriateRoyaltiesState(state.royaltyFilters, state.royalties, state.filteredRoyalties);
  royaltiesToAlter.loadMore = payload.length > LAZY_ROYALTIES_LIMIT;
  addIds(
    royaltiesToAlter,
    getRoyaltiesFromPayload(payload).map((royalty) => {
      return royalty.royaltyId;
    })
  );
  state.allRoyalties = {
    ...state.allRoyalties,
    ...objectArrayToHashmap(getRoyaltiesFromPayload(payload), ROYALTIES_ID_NAME),
  };
};

const setTemplateEarningsInStateFromPayload = (
  state: DesignerEarningsReducerState,
  ajaxParams: DesignerTemplateEarningsAjaxParams,
  payload: DesignerTemplateLifetimeEarning[]
): void => {
  state.templateLifetimeEarnings.loadMore = payload.length > ajaxParams.limit;
  const earnings = payload.slice(0, ajaxParams.limit);
  const IdsFromPayload = earnings.map((templateEarnings) => {
    return templateEarnings.posterId;
  });

  const allLifetimeTemplateEarningsFromPayload = objectArrayToHashmap(earnings, TEMPLATES_LIFETIME_EARNINGS_ID_NAME) as DesignerTemplateLifetimeEarnings;
  addIds(state.templateLifetimeEarnings, IdsFromPayload);

  state.allLifetimeTemplateEarnings = {
    ...state.allLifetimeTemplateEarnings,
    ...allLifetimeTemplateEarningsFromPayload,
  };
};

const areCurrentPaymentStatusesSameAsInAjax = (paymentStatusesCurrent?: RoyaltyPaymentStatus[], paymentStatusFromRequest?: RoyaltyPaymentStatus[]): boolean => {
  return paymentStatusesCurrent === paymentStatusFromRequest || (paymentStatusesCurrent !== undefined && arraysEqual(paymentStatusesCurrent, paymentStatusFromRequest ?? []));
};

const getRoyaltiesFromPayload = (payload: DesignerRoyalty[]): DesignerRoyalty[] => {
  return payload.slice(0, LAZY_ROYALTIES_LIMIT);
};

export const areTemplateEarningAjaxParamsSameAsCurrentState = (state: DesignerEarningsReducerState, ajaxParams: DesignerTemplateEarningsAjaxParams): boolean => {
  return (
    state.templateLifetimeEarnings.sortDirection === ajaxParams.sortDirection &&
    shouldTemplateEarningsBeSortedByPublishTime(state.templateLifetimeEarnings) === ajaxParams.isSortingByPublishTime
  );
};
