import type {GridItemStorage} from '@Components/base-grid/components/grid-item';
import type {GridStorageTypes} from '@Components/base-grid';
import type {GridEmailSignupFormItemStorage} from '@Components/email-signup-form-grid/components/email-signup-form-grid';

export const SIGNUP_GRID_ID = 'signupGrid';
export const deleteEmailSignupFormLoading = 'deleteEmailSignupForm';
export const duplicateEmailSignupFormLoading = 'duplicateEmailSignupForm';
export const updateEmailSignupFormLoadingNotifications = 'updateEmailSignupFormLoadingNotifications';

export const findGridItem = (grid: GridStorageTypes, gridItemId: string) => {
  return grid.items.find((item: GridItemStorage) => {
    return item.id === gridItemId;
  }) as GridEmailSignupFormItemStorage;
};
