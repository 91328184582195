import {alphabeticallySortFonts, FONT_ITEM_SELECTION_STATES, setDefaultSelectedFont, setPublicFontsInState} from "@Libraries/font-library.ts";

export const fontSelectorReducers = {
    /**
     * sets the selected font to the ID provided and sets the loading directly to the loaded state.
     * For uses ourside the editor where we may want to select a font for displaying purposes only.
     * @param state
     * @param action
     */
    selectFontWithoutLoading: (state, action) => {
        const font = action.payload;
        state.selectedFont = {...font, fontItemSelectedState: FONT_ITEM_SELECTION_STATES.SELECTED_FONT_LOADED};
    },

    setPmwFonts: (state, action) => {
        state.pmwFonts = action.payload;
        setDefaultSelectedFont(state, state.pmwFonts);
    },

    setPublicFonts: {
        reducer: (state, action) => {
            setPublicFontsInState(state, action.payload.pmwFonts, action.payload.fancyTextFonts);
        },
        prepare: (pmwFonts, fancyTextFonts) => {
            return {payload: {pmwFonts, fancyTextFonts}};
        }
    },

    setUserFonts: (state, action) => {
        const userFonts = action.payload;
        state.userFonts = userFonts.sort(alphabeticallySortFonts);
    },

    setLanguageScripts: (state, action) => {
        state.languageScripts = action.payload;
    },

    removeUserFont: (state, action) => {
        const fontFamily = action.payload;
        state.userFonts = state.userFonts.filter(userFont => userFont.fontFamily !== fontFamily);
        state.userFonts.sort(alphabeticallySortFonts);
        if (state.selectedFont.fontFamily === fontFamily) {
            setDefaultSelectedFont(state, state.pmwFonts);
        }

    },

    addUserFont: (state, action) => {
        state.userFonts.push(action.payload);
        state.userFonts.sort(alphabeticallySortFonts);
    },

    addUploadedFontToUserFonts: (state, action) => {
        const font = action.payload;
        state.userFonts.push(font);
        state.userFonts.sort(alphabeticallySortFonts);
        selectUserFont(state, font);
    },

    setDefaultFontAsSelectedFont: (state, action) => {
        setDefaultSelectedFont(state, state.pmwFonts);
    },

    setPMWFontsVersion: (state, action) => {
        state.pmwFontsVersion = action.payload;
    },

    setFontsLoading: (state, action) => {
        state.areFontsLoading = action.payload;
    }
}


const selectUserFont = (state, font) => {
    state.selectedFont.idfont = font.idfont;
    state.selectedFont.fontFamily = font.fontFamily;
    state.selectedFont.name = font.name;
    state.selectedFont.isInternalFont = false;
    state.selectedFont.fontItemSelectedState =  FONT_ITEM_SELECTION_STATES.SELECTED_FONT_LOADED;
}
