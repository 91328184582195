import type {CellData, DateData, TimeData} from '@Components/table/table.types';
import {DateFormat, DefaultTime, TimeFormat, TimeMeridiem} from '@Components/table/table.types';
import {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';

export function getCellDataForCellType(type: CellType, timeFormat: TimeFormat = TimeFormat.STANDARD, dateFormat: DateFormat = DateFormat.FORMAT_1) {
  switch (type) {
    case CellType.DATE:
      return {
        timestamp: new Date().getTime().toString(),
        dateFormat,
        type: CellType.DATE,
      } as DateData;
    case CellType.TIME:
      return {
        hours: DefaultTime.HOURS,
        minutes: DefaultTime.MINUTES,
        meridiem: TimeMeridiem.AM,
        timeFormat,
        type: CellType.TIME,
      } as TimeData;
    case CellType.TEAM:
      return {
        value: window.i18next.t('pmwjs_event_team'),
        type: CellType.TEAM,
      } as CellData;
    case CellType.VENUE:
      return {
        value: window.i18next.t('pmwjs_venue'),
        type: CellType.VENUE,
      } as CellData;
    default:
      return window.i18next.t('pmwjs_add_text');
  }
}

const CALENDER_DATA = {
  weekdays: {
    shorthand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  },
  months: {
    shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    longhand: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  },
  daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
  firstDayOfWeek: 0,
};

export const getFormattedDate = (dateFormat: string, time?: number) => {
  const milliseconds = time ?? new Date().getTime();

  let formattedDate = '';
  const dateObj = new Date(milliseconds);
  const formats = {
    d() {
      const day = formats.j();
      return day < 10 ? `0${day}` : day;
    },
    D() {
      return CALENDER_DATA.weekdays.shorthand[formats.w()];
    },
    j() {
      return dateObj.getUTCDate();
    },
    l() {
      return CALENDER_DATA.weekdays.longhand[formats.w()];
    },
    w() {
      return dateObj.getUTCDay();
    },
    F() {
      return monthToStr(formats.n() - 1, false);
    },
    m() {
      const month = formats.n();
      return month < 10 ? `0${month}` : month;
    },
    M() {
      return monthToStr(formats.n() - 1, true);
    },
    n() {
      return dateObj.getUTCMonth() + 1;
    },
    U() {
      return dateObj.getTime() / 1000;
    },
    y() {
      return String(formats.Y()).substring(2);
    },
    Y() {
      return dateObj.getUTCFullYear();
    },
    h() {
      let hours = dateObj.getHours();
      if (hours === 0) {
        hours = 12;
      }

      if (hours > 12) {
        hours -= 12;
      }

      return hours;
    },
    i() {
      const minutes = dateObj.getMinutes().toString();
      return minutes.length === 1 ? `0${minutes}` : minutes;
    },
    A() {
      const hours = dateObj.getHours();
      let meridiem = 'AM';

      if (hours >= 12) {
        meridiem = 'PM';
      }

      return meridiem;
    },
  } as any;
  const formatPieces = dateFormat.split('');

  formatPieces.map((formatPiece, index) => {
    if (formats[formatPiece] && formatPieces[index - 1] !== '\\') {
      formattedDate += formats[formatPiece]();
    } else if (formatPiece !== '\\') {
      formattedDate += formatPiece;
    }
  });

  return formattedDate;
};

export const monthToStr = (date: number, shorthand: boolean) => {
  if (shorthand) {
    return CALENDER_DATA.months.shorthand[date];
  }
  return CALENDER_DATA.months.longhand[date];
};
