import React from "react";
import PropTypes from 'prop-types';
import './empty-state-container.scss';
import {MessageBoxWithImage} from "../message-box-with-image";

/**
 * Generic component for displaying empty states.
 * An empty state container generally contains an image with some text
 * CTAs and other elements can be passed as children which will be shown after the image and text
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
const EmptyStateContainer = ({emptyStateClasses, children, messageBoxProps, reducePaddingOnSmallLaptopHeight}) => {
    return (
        <div
            className={`empty-state-container-box flex-column-justify-center flex-items-center ${emptyStateClasses} ${reducePaddingOnSmallLaptopHeight ? '-less-padding-small-laptop' : ''}`}>
            <MessageBoxWithImage {...messageBoxProps} />
            {children}
        </div>
    );
}

EmptyStateContainer.propTypes = {
    /**
     * Object containing props for the MessageBoxWithImage component.
     * See PropTypes for: MessageBoxWithImage for more details
     */
    messageBoxProps: PropTypes.object.isRequired,
    /**
     * any additional classes to give to the root element
     */
    emptyStateClasses: PropTypes.string,

    /**
     * whether to reduce padding on small laptop heights (768px) to make sure most of the content is shown above the fold
     */
    reducePaddingOnSmallLaptopHeight: PropTypes.bool
}

EmptyStateContainer.defaultProps = {
    emptyStateClasses: '',
    reducePaddingOnSmallLaptopHeight: false

}

export default EmptyStateContainer;