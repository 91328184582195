import React from 'react';
import PropTypes from 'prop-types';
import './brand-color-grid-item.scss';
import {connect} from 'react-redux';
import {isClickFromPopup, PopupMenu} from '../../../popup-menu';
import {PopupMenuItem, PopupToggleButton} from '../../../popup-menu/components';
import {deleteBrandAsset} from '../../brands-thunk';
import {BRAND_ASSETS, BRAND_COLOR_TYPES} from '@Libraries/brands-library';
import {colorToHexString} from '@Utils/color.util';
import {executeThunk} from '@Utils/thunk.util';
import {PopupMenuItemIconSize, PopupMenuItemTypeInModal} from "@Components/popup-menu/components/popup-menu-item/popup-menu-item";

/**
 * Grid Item component for the Brand Colors section
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
class BrandColorGridItem extends React.Component {
    render() {
        return (
            <li className={`brand-grid-item brand-color-grid-item brand-asset -has-cursor ${this.#isSelected() ? '-selected' : ''}`} onClick={this.#onBrandColorClick}>
                <div className={'color-container brand-asset-preview-container radius-4 hover-transition-all'} style={{background: colorToHexString(this.props.hexCode)}}/>
                <div className={'brand-asset-meta'}>
                    <span className={'brand-asset-name'}>{this.#getBrandColorTypeName()}</span>
                    {this.#getPopupMenuMarkup()}
                </div>
                {this.#getSelectionNumberIndicator()}
            </li>
        );
    }

    #isSelected = () => {
        return this.#isSelectionOfColorsAllowed() && this.props.isSelected;
    };

    #isSelectionOfColorsAllowed = () => {
        return this.props.isReadOnly && this.props.isSelectable;
    };

    /**
     *
     * @param {Event} e
     */
    #onBrandColorClick = (e) => {
        if (this.#isSelectionOfColorsAllowed()) {
            this.props.onClick(e);
            return;
        }

        if (this.props.isReadOnly) {
            return;
        }

        if (!isClickFromPopup(e)) {
            this.#openBrandColorsModal();
        }
    };

    /**
     *
     * @return {ReactElement | null}
     */
    #getPopupMenuMarkup = () => {
        if (this.props.isReadOnly) {
            return null;
        }

        return (
            <PopupMenu openModalOnPhone={true} modalTitle={i18next.t('pmwjs_color_options')} menuToggleButton={<PopupToggleButton className="-neutral -smaller-icon"/>}>
                <PopupMenuItem itemClasses="-smaller-height radius-4 -more-margin-horizontal" textColorClasses="content-body" text={i18next.t('pmwjs_edit_item')} iconClasses={'icon icon-pencil'} textClasses="spacing-m-l-2" clickHandler={this.#openBrandColorsModal} iconSize={PopupMenuItemIconSize.SMALL} horizontalAlignStart popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START} />
                <PopupMenuItem itemClasses="-smaller-height -danger radius-4 -more-margin-horizontal" textColorClasses="content-body" text={i18next.t('pmwjs_delete')} iconClasses={'icon icon-delete'} textClasses="spacing-m-l-2" clickHandler={this.#onDelete} iconSize={PopupMenuItemIconSize.SMALL} horizontalAlignStart popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START} />
            </PopupMenu>
        );
    };

    /**
     *
     * @return {ReactElement | null}
     */
    #getSelectionNumberIndicator = () => {
        if (!(this.props.isSelectable && this.props.isSelected && this.props.selectionIndex)) {
            return null;
        }

        return <div className={'brand-asset-color selection-number'}>{this.props.selectionIndex}</div>;
    };

    #openBrandColorsModal = () => {
        PMW.openBrandColorsModal({
            brandId: this.props.brandId,
            brandColorData: this.props,
        });
    };

    #onDelete = () => {
        PMW.openConfirmDeleteModal({
            title: i18next.t('pmwjs_are_you_sure'),
            text: i18next.t('pmwjs_delete_brand_asset_confirmation_message', {
                brandAsset: i18next.t('pmwjs_color_lowercase'),
                name: this.#getBrandColorTypeName(),
            }),
            onDeleteConfirmation: this.#deleteBrandColor,
        });
    };

    #deleteBrandColor = async () => {
        await executeThunk(() => this.props.deleteBrandColor(this.props.idbrandColor, this.props.brandId));
    };

    /**
     *
     * @returns {string}
     */
    #getBrandColorTypeName = () => {
        const colorType = this.props.type;
        if (colorType === BRAND_COLOR_TYPES.PRIMARY) {
            return i18next.t('pmwjs_primary');
        }

        if (colorType === BRAND_COLOR_TYPES.SECONDARY) {
            return i18next.t('pmwjs_secondary');
        }

        if (colorType === BRAND_COLOR_TYPES.OTHER) {
            return i18next.t('pmwjs_other');
        }

        return '';
    };
}

BrandColorGridItem.propTypes = {
    /**
     * the ID of this color item
     */
    idbrandColor: PropTypes.number.isRequired,
    /**
     * the ID of the brand the color belongs to
     */
    brandId: PropTypes.string.isRequired,
    /**
     * the hex code for the color for this item
     */
    hexCode: PropTypes.string.isRequired,
    /**
     * The number ENUM value specifying the type of the color
     * Accepted values: 1 (primary), 2 (secondary), 3 (other)
     */
    type: PropTypes.number.isRequired,
    /**
     * Disables all actions on click for every grid item
     */
    isReadOnly: PropTypes.bool,
    /**
     * If 'true', changes cursor to pointer on hovers
     */
    isSelectable: PropTypes.bool,
    /**
     * If 'true', adds a blue outline around the grid item
     */
    isSelected: PropTypes.bool,
    /**
     * onClickHandler for selectable buttons
     */
    onClick: PropTypes.func,
    /**
     * shows the number passed as prop in the top right corner of the color, only works if selectable && isSelected
     */
    selectionIndex: PropTypes.number,
};

const mapColorGridItemDispatchToProps = (dispatch) => {
    return {
        deleteBrandColor: (idbrandColor, brandId) => dispatch(deleteBrandAsset({brandAssetType: BRAND_ASSETS.BRAND_COLORS, idbrandAsset: idbrandColor, brandId: brandId})),
    };
};

BrandColorGridItem.defaultProps = {
    isReadOnly: false,
    isSelectable: false,
    isSelected: false,
    selectionIndex: 0,
};
export default connect(null, mapColorGridItemDispatchToProps)(BrandColorGridItem);
