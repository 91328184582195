import React from "react";
import './modal-footer-secondary-actions.scss';
import PropTypes from "prop-types";

export default class ModalFooterSecondaryActions extends React.Component {
    render = () => {
        return <div className="secondary-actions">{this.props.actions.length ? this.props.actions : null}</div>;
    }
}


ModalFooterSecondaryActions.propTypes = {
    actions: PropTypes.array
};

ModalFooterSecondaryActions.defaultProps = {
    actions: []
};