import type {RefObject} from 'react';
import { useEffect, useRef, useState} from 'react';
import {isEqual} from 'lodash';

export type queryKeys = string;

interface ContainerQueryType {
  minHeight?: number;
  minWidth?: number;
  maxHeight?: number;
  maxWidth?: number;
}
export type ContainerQueryCondition = ContainerQueryType;
export type ContainerQueryResult = Record<queryKeys, boolean>;
export type ContainerQueryObject = Record<queryKeys, ContainerQueryCondition>;

export const useContainerQuery = (ref: RefObject<HTMLElement>, query: ContainerQueryObject): ContainerQueryResult => {
  const [result, setResult] = useState<ContainerQueryResult>({});
  const updatedResult = useRef<ContainerQueryResult>({});

  const checkMaxCondition = (conditionalValue: number, refValue: number): boolean => {
    return refValue <= conditionalValue;
  };
  const checkMinCondition = (conditionalValue: number, refValue: number): boolean => {
    return refValue >= conditionalValue;
  };

  const areChecksTrue = (key: string): boolean => {
    if (!ref.current) {
      return false;
    }
    const object = query[key];
    if (object.maxHeight && !checkMaxCondition(object.maxHeight, ref.current.clientHeight)) {
      return false;
    }
    if (object.maxWidth && !checkMaxCondition(object.maxWidth, ref.current.clientWidth)) {
      return false;
    }
    if (object.minHeight && !checkMinCondition(object.minHeight, ref.current.clientHeight)) {
      return false;
    }
    if (object.minWidth && !checkMinCondition(object.minWidth, ref.current.clientWidth)) {
      return false;
    }

    return true;
  };

  const validateKeysForParam = (key: string): boolean => {
    if (!query[key]) {
      return false;
    }
    return areChecksTrue(key);
  };

  const updateResult = (): void => {
    const keys = Object.keys(query);
    const tempRes: ContainerQueryResult = {};
    for (const eachKey of keys) {
      tempRes[eachKey] = validateKeysForParam(eachKey);
    }

    if (isEqual(tempRes, updatedResult.current)) {
      return;
    }
    setResult(tempRes);
    updatedResult.current = tempRes;
  };

  useEffect(() => {
    const element = ref.current;
    const observer = new ResizeObserver(() => {
      updateResult();
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  return result;
};
