/**
 * Inserts items into an array and returns a new array without mutating the original array
 * @param {T[]} arr - The original array
 * @param {number} index - The index at which to insert the new items
 * @param {T | T[]} newItems - The new item or items to insert
 * @returns {T[]} A new array with the new items inserted
 */
export const insert = <T>(arr: T[], index: number, newItems: T | T[]): T[] => {
  const newItemArr = Array.isArray(newItems) ? newItems : [newItems];

  return [...arr.slice(0, index), ...newItemArr, ...arr.slice(index)];
};

/**
 * Moves an item in an array to a new index and returns a new array without mutating the original array
 * @param {T[]} array - The original array
 * @param {T} value - The value to move
 * @param {number} newIndex - The index to move the value to
 * @returns {T[]} A new array with the value moved to the new index
 */
export const moveArrayItemToIndex = <T>(array: T[], value: T, newIndex: number): T[] => {
  const newArray = [...array];
  const currentIndex = newArray.indexOf(value);

  if (currentIndex === -1) {
    return newArray;
  }

  newArray.splice(currentIndex, 1);
  newArray.splice(newIndex, 0, value);

  return newArray;
};

/**
 * Finds the value less than the specified value in a sorted array and returns it
 * @param {T[]} array - The sorted array
 * @param {T} val - The value to compare
 * @returns {T | null} The value less than the specified value, or null if not found
 */
export const getValueLessThanFromSortedArray = <T>(array: T[], val: T): T | null => {
  if (array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if (val < array[i]) {
        return i === 0 ? array[i] : array[i - 1];
      }
    }
    return array[array.length - 1];
  }
  return null;
};

/**
 * Sorts an array in ascending or descending order
 * @param {T[]} array - The array to sort
 * @param {string} order - The sorting order, either 'asc' (ascending) or 'desc' (descending)
 * @returns {T[]} The sorted array
 */
export const sortArray = <T>(array: T[], order: 'asc' | 'desc' = 'asc'): T[] => {
  switch (order) {
    case 'asc':
      return array.slice().sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    case 'desc':
      return array.slice().sort((a, b) => {
        if (a > b) {
          return -1;
        }
        if (a < b) {
          return 1;
        }
        return 0;
      });
    default:
      console.error('Invalid order value: ', order);
      return array;
  }
};

/**
 * Inserts an item into an array if it doesn't exist and returns a new array without mutating the original array.
 * @param {T[]} arr - The original array
 * @param {number} index - The index at which to insert the new item
 * @param {T} newItem - The new item to insert
 * @returns {T[]} A new array with the item inserted, if it doesn't already exist; otherwise, returns a copy of the original array
 */
export const insertIfNotExist = <T>(arr: T[], index: number, newItem: T): T[] => {
  const indexOfItem = arr.indexOf(newItem);
  return indexOfItem > -1 ? [...arr] : insert(arr, index, newItem);
};

/**
 * Gets the elements that exist in either ArrayA or ArrayB but not both.
 * @param {T[]} arrayA - The first array
 * @param {T[]} arrayB - The second array
 * @returns {T[]} An array containing the symmetric difference of ArrayA and ArrayB
 */
export const getSymmetricDifferenceBetweenArrays = <T>(arrayA: T[], arrayB: T[]): T[] => {
  return arrayA
    .filter((x) => {
      return !arrayB.includes(x);
    })
    .concat(
      arrayB.filter((x) => {
        return !arrayA.includes(x);
      })
    );
};

/**
 * Removes an item from an array and returns a new array without mutating the original array.
 * @param {T[]} arr - The original array
 * @param {number} indexToRemove - The index of the item to remove
 * @returns {T[]} A new array with the item removed
 */
export const remove = <T>(arr: T[], indexToRemove: number): T[] => {
  return [...arr.slice(0, indexToRemove), ...arr.slice(indexToRemove + 1)];
};

/**
 * Removes an item from an array and returns a new array without mutating the original array.
 * @param {T[]} arr - The original array
 * @param {T} item - The item to remove
 * @returns {T[]} A new array with the item removed, or a copy of the original array if the item doesn't exist
 */
export const removeItem = <T>(arr: T[], item: T): T[] => {
  const indexToRemove = arr.indexOf(item);

  if (indexToRemove > -1) {
    return remove(arr, indexToRemove);
  }

  return [...arr];
};

/**
 * Removes items from an array and returns a new array without mutating the original array.
 * @param {T[]} arr - The original array
 * @param {T[]} itemsToRemove - The items to remove
 * @returns {T[]} A new array with the items removed
 */
export const removeItems = <T>(arr: T[], itemsToRemove: T[]): T[] => {
  let remainingItems = [...arr];
  for (const itemToRemove of itemsToRemove) {
    remainingItems = removeItem(remainingItems, itemToRemove);
  }

  return remainingItems;
};

/**
 * Returns a hashmap for an array with values equal to the index they are located at.
 * @param {T[]} arr - The input array
 * @returns {Record<string, number>} A hashmap with array values as keys and their indices as values
 */
export const arrayToHashmap = <T>(arr: T[]): Record<string, number> => {
  const hashmap: Record<string, number> = {};
  for (let i = 0; i < arr.length; i++) {
    hashmap[String(arr[i])] = i;
  }
  return hashmap;
};

/**
 * Converts an array of objects to a hashmap.
 * Accepts an optional key that extracts a key value from each object and sets the object as a value of that key in the new hashmap.
 * By default, the index of the array element is used as the key for the object in the hashmap
 * For example: arrayToConvert = [ {id: 12, ...}, {id: '1234x', ...} ], keyToUse = 'id', hashmap = {'12': {...}, '1234x': {...}}
 * @param {T[]} arrayToConvert - The array of objects to convert to a hashmap
 * @param {K} [keyToUse=null] - The key to use from each object. If not provided, the index of the array element is used as the key
 * @returns {Record<string, T>} A hashmap with keys extracted from the objects and their corresponding objects as values
 */
export const objectArrayToHashmap = <T, K extends keyof T>(arrayToConvert: T[], keyToUse: K | null = null): Record<string, T> => {
  const hashmap: Record<string, T> = {};
  let arrElement: T | null = null;

  for (let i = 0; i < arrayToConvert.length; i++) {
    arrElement = arrayToConvert[i];
    const key = keyToUse !== null ? String(arrElement[keyToUse]) : String(i);
    hashmap[key] = arrElement;
  }

  return hashmap;
};

/**
 * Checks if two arrays are equal
 * @param {T[]} x - The first array
 * @param {T[]} y - The second array
 * @returns {boolean} True if the arrays are equal, false otherwise
 */
export const arraysEqual = <T>(x: T[] | string, y: T[] | string): boolean => {
  if (x.length !== y.length) return false;
  for (let i = 0; i < x.length; i++) {
    if (x[i] !== y[i]) {
      return false;
    }
  }
  return true;
};

/**
 * Checks if two arrays are equal, performing deep comparison of objects
 * @param {T[]} x - The first array
 * @param {T[]} y - The second array
 * @returns {boolean} True if the arrays are equal, false otherwise
 */
export const objectArraysEqual = <T extends Record<string, any>>(x: T[], y: T[]): boolean => {
  if (x === y) return true;
  if (x == null || y == null) return false;
  if (x.length !== y.length) return false;
  if (x.length === 0 && y.length === 0) return true;
  for (let i = 0; i < x.length; ++i) {
    if (typeof x[i] === 'object') {
      if (!objectsEqual(x[i], y[i])) {
        return false;
      }
    } else if (x[i] !== y[i]) return false;
  }
  return true;
};

/**
 * Checks if two objects are equal, given both objects have the same exact list of properties
 * @param {Record<string, any>} x - The first object
 * @param {Record<string, any>} y - The second object
 * @returns {boolean} True if the objects are equal, false otherwise
 */
export const objectsEqual = (x: Record<string, any>, y: Record<string, any>): boolean => {
  const keysX = Object.keys(x);
  const keysY = Object.keys(y);

  if (keysX.length !== keysY.length) return false;

  for (const key of keysX) {
    if (x[key] !== y[key]) {
      return false;
    }
  }

  return true;
};

/**
 * Moves an item to the beginning of the array.
 * Example: arr = [1,2,3,4,5], index = 3
 * Returns: [4,1,2,3,5]
 * Returns a new array.
 * @param {T[]} array - The original array
 * @param {number} indexOfItem - The index of the item to move
 * @returns {T[]} The new array with the item moved to the beginning
 */
export const moveArrayItemToStart = <T>(array: T[], indexOfItem: number): T[] => {
  const newArray = [...array];
  newArray.unshift(newArray.splice(indexOfItem, 1)[0]);
  return newArray;
};

/**
 * Replaces an item in the array at the specified index with one or more new items.
 * @param {T[]} arr - The original array
 * @param {number} indexToReplace - The index of the item to replace
 * @param {T | T[]} replaceWith - The new item(s) to replace with
 * @returns {T[]} A new array with the item replaced
 */
export const replace = <T>(arr: T[], indexToReplace: number, replaceWith: T | T[]): T[] => {
  const newItemArr = Array.isArray(replaceWith) ? replaceWith : [replaceWith];
  return [...arr.slice(0, indexToReplace), ...newItemArr, ...arr.slice(indexToReplace + 1)];
};

/**
 * Swaps the elements present at the provided indexes in the array.
 * @param {T[]} arr - The original array
 * @param {number} firstIndex - The index of the first element to swap
 * @param {number} secondIndex - The index of the second element to swap
 * @returns {T[]} A new array with the elements swapped
 */
export const swap = <T>(arr: T[], firstIndex: number, secondIndex: number): T[] => {
  const result = [...arr];
  [result[firstIndex], result[secondIndex]] = [result[secondIndex], result[firstIndex]];
  return result;
};

/**
 * Returns an array with unique values from the given array.
 * @param {T[]} array - The original array
 * @returns {T[]} An array with unique values
 */
export const uniqueArray = <T>(array: T[]): T[] => {
  return array.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};
