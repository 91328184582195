// All possible types of CTA stemming used within the dialog panel.
export enum SocialPostDialogCTA {
  SCHEDULE_DATE_TIME = 'SCHEDULE_DATE_TIME',
  CONTINUE_PUBLISHING = 'CONTINUE_PUBLISHING',
  EDIT_RESCHEDULE = 'EDIT_RESCHEDULE',
  DUPLICATE_DRAFT = 'DUPLICATE_DRAFT',
  REFRESH = 'REFRESH',
  CANCEL = 'CANCEL',
  SCHEDULE = 'SCHEDULE',
  DELETE = 'DELETE',
  VIEW_POST = 'VIEW_POST',
  DELETE_SCHEDULE = 'DELETE_SCHEDULE',
  BOOST_POST = 'BOOST_POST',
}
