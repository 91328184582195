import type {ReactElement} from 'react';
import React from 'react';
import {ContentPlannerDateTimeTile} from '@Components/content-planner-date-time-tile';
import {getDateFromUnixTimestamp} from '@Utils/date.util';
import {Button, IconPlacement, Size, Type} from '@Components/button';
import type {EditEventPanelInnerContentProps} from '@Panels/edit-content-planner-event-panel/edit-content-planner-event-panel.types';
import type {BaseEvent, CustomEventPanelCallback} from '@Components/content-planner/content-planner.types';
import {Text, TextSize} from '@Components/text';
import {noop} from '@Utils/general.util';
import styles from './default-panel.module.scss';

interface DefaultPanelProps extends Omit<EditEventPanelInnerContentProps, 'onClose'> {
  isLoading: boolean;
  onConfirmDelete: CustomEventPanelCallback;
  switchToDefaultMode: CustomEventPanelCallback;
  eventFormData: BaseEvent;
}

export function DefaultPanel({
  isInEditMode,
  isInDeleteMode,
  isInModal,
  isLoading,
  onConfirmDelete,
  switchToDeleteMode,
  switchToEditMode,
  switchToDefaultMode,
  eventFormData,
}: DefaultPanelProps): ReactElement {
  const date = getDateFromUnixTimestamp(eventFormData.timestamp);

  const getDeleteCTAs = (): ReactElement | null => {
    if (!isInDeleteMode) {
      return null;
    }

    return (
      <div className="flex-row-align-center flex-content-end spacing-m-t-4">
        <Button onClick={switchToDefaultMode} type={Type.SECONDARY} text={window.i18next.t('pmwjs_cancel')} size={Size.SMALL} customClasses="spacing-m-r-2" disabled={isLoading} />
        <Button onClick={onConfirmDelete} isLoading={isLoading} type={Type.DANGER} text={window.i18next.t('pmwjs_delete')} size={Size.SMALL} icon="icon-delete" />
      </div>
    );
  };

  const getModalCTAs = (): ReactElement | null => {
    if (!isInModal) {
      return null;
    }

    return (
      <div className={`_full-width flex-v-row spacing-m-t-8 ${styles.stickToBottom}`}>
        <Button
          customClasses="spacing-m-b-2"
          text={window.i18next.t('pmwjs_edit_item')}
          onClick={switchToEditMode}
          isFullWidth
          icon="icon-pencil"
          iconPlacement={IconPlacement.LEFT}
          size={Size.SMALL}
          type={Type.PRIMARY}
        />
        <Button
          text={window.i18next.t('pmwjs_delete')}
          onClick={switchToDeleteMode}
          isFullWidth
          icon="icon-delete"
          iconPlacement={IconPlacement.LEFT}
          size={Size.SMALL}
          type={Type.SECONDARY}
        />
      </div>
    );
  };

  return (
    <>
      <div className="flex-row-align-center">
        <div className={`${styles.swatch} radius-4 spacing-m-r-3`} />
        <Text val={eventFormData.title} bold size={TextSize.MEDIUM} className="content-heading" />
      </div>

      <Text val={eventFormData.description} className={`spacing-m-t-3 content-body ${styles.description}`} size={TextSize.SMALL} />
      <ContentPlannerDateTimeTile
        className={isInModal ? `${styles.bringToStart} spacing-m-b-4` : 'spacing-m-t-4'}
        date={date}
        onClick={noop}
        showEditIcon={false}
        btnSpacingClassName="spacing-p-l-0 spacing-p-r-1 spacing-p-t-1 spacing-p-b-1"
        showHover={isInEditMode}
        showExtraSmallText
      />
      {getDeleteCTAs()}
      {getModalCTAs()}
    </>
  );
}
