import React from 'react';
import PropTypes from 'prop-types';
import './item-switcher.scss';
import {ITEM_TYPES} from '@Libraries/email-marketing-campaigns-library';
import {EmailCampaignDropdownItem} from '@Components/email-marketing-campaign-performance/components/email-campaign-dropdown-item';
import {Dropdown} from '../../../dropdown';

/**
 * Parent component for the email campaigns performance view
 */
class ItemSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMore: props.showMore,
    };
  }

  render() {
    return (
      <div className={this.#getContainerClass()}>
        <Dropdown
          dropdownClasses={this.#getDropdownClass()}
          dropdownTypographyClasses={this.props.itemType === ITEM_TYPES.VIEWS ? 'body-xs-bold' : 'body-xs'}
          defaultLabel={this.props.defaultLabel}
          defaultDataValue={String(this.props.defaultValue)}
          dropdownItems={this.#getListItems()}
          dropdownItemClasses={'body-xs'}
          clickHandler={this.props.clickHandler}
          labelPrefix={this.props.labelPrefix}
          labelPrefixIconClass={this.#getLabelPrefixIconClass()}
          dropdownLabelClasses={`${this.props.isComparisonDropdown ? 'body-xs-bold' : ''}`}
          isValidComparison={this.props.isValidComparison}
          showMore={this.#isShowMoreEnabled()}
          toggleShowMore={this.#toggleShowMore}
          renderCustomLabel={this.#getCustomLabel}
        />
      </div>
    );
  }

  #getCustomLabel = (label, isSelected, totalItems, currentIndex) => {
    return <EmailCampaignDropdownItem item={label} isSelected={isSelected} totalItems={totalItems} currentIndex={currentIndex} />;
  };

  #getContainerClass = () => {
    let containerClass = 'item-switcher';

    if (this.props.itemType === ITEM_TYPES.VIEWS) {
      containerClass += ' -view-switcher';
      containerClass += ' -rounded';
    }

    if (this.props.isComparisonDropdown) {
      containerClass += ' -comparison-dropdown';
    }

    if (!this.props.isEmailPerformanceHeader && !this.props.isValidComparison) {
      containerClass += ' -toggle-campaigns';
    }

    if (this.props.isValidComparison) {
      containerClass += ' -valid-comparison';
    }

    return containerClass;
  };

  #toggleShowMore = (newValue) => {
    this.setState({
      isShowMore: newValue,
    });
  };

  #isShowMoreEnabled = () => {
    if (this.props.showMore) {
      return this.state.isShowMore;
    }
    return false;
  };

  #getDropdownClass = () => {
    let dropdownClass = ' -with-dark-border ';

    if (this.props.isComparisonDropdown) {
      if (this.props.isValidComparison) {
        dropdownClass += ' -highlight-secondary -comparison';
      } else {
        dropdownClass += ' -highlight-secondary';
      }
    } else {
      if (this.props.isValidComparison) {
        dropdownClass += ' -highlight-primary -main-campaign';
      } else {
        dropdownClass += ' -highlight-primary';
      }
    }

    if (this.props.isValidComparison && !this.props.isComparisonDropdown) {
      dropdownClass += ' -email-performance-campaign-name ';
    }

    dropdownClass+= ` ${this.props.dropdownClassName}`;

    return dropdownClass;
  };

  #getListItems = () => {
    let listItems = [];
    if (this.props.itemType === ITEM_TYPES.CAMPAIGNS) {
      listItems = this.#prepareDropdownPropsForCampaigns(this.props.listItems);
    } else if (this.props.itemType === ITEM_TYPES.AUDIENCES) {
      listItems = this.#prepareDropdownPropsForAudiences(this.props.listItems);
    } else if (this.props.itemType === ITEM_TYPES.VIEWS) {
      listItems = this.#prepareDropdownPropsForViews();
    }

    return listItems;
  };

  /**
   * @param {Array.<Object>} listItems
   * @return {Array.<Object>}
   */
  #prepareDropdownPropsForCampaigns = (listItems) => {
    return listItems.map((item) => {
      if (item !== undefined) {
        return {
          label: item.name,
          dataValue: item.id,
          showTitle: true,
        };
      }
    });
  };

  /**
   * @param {Array.<Object>} listItems
   * @return {Array.<Object>}
   */
  #prepareDropdownPropsForAudiences = (listItems) => {
    return listItems.map((item) => {
      if (item !== undefined) {
        return {
          label: item.name,
          dataValue: String(item.id),
          showTitle: true,
        };
      }
    });
  };

  /**
   * @return {Array.<Object>}
   */
  #prepareDropdownPropsForViews = () => {
    return [
      {
        label: i18next.t('pmwjs_campaign'),
        dataValue: ITEM_TYPES.CAMPAIGNS,
        isLink: false,
      },
      {
        label: i18next.t('pmwjs_mailing_list'),
        dataValue: ITEM_TYPES.AUDIENCES,
        isLink: false,
      },
    ];
  };

  /**
   * @return {string}
   */
  #getLabelPrefixIconClass = () => {
    let labelPrefixIconClass = this.props.labelPrefixIconClass;
    if (labelPrefixIconClass !== undefined) {
      labelPrefixIconClass += ' size-icon-16';
    }

    return labelPrefixIconClass;
  };
}

ItemSwitcher.propTypes = {
  /**
   * An array of list items that this item switcher will be used to switch between. Not required for 'view-switcher' variant.
   */
  listItems: PropTypes.array,

  /**
   * The type of item relevant to this item switcher.
   */
  itemType: PropTypes.string.isRequired,

  /**
   * The default label of this item switcher.
   */
  defaultLabel: PropTypes.string.isRequired,

  /**
   * The default value of this item switcher.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /**
   * Click handler for when a new item is selected.
   */
  clickHandler: PropTypes.func.isRequired,

  /**
   * Flag representing whether this is the item switcher for the second item, i.e. whether this dropdown
   * triggers the comparison view.
   */
  isComparisonDropdown: PropTypes.bool,

  /**
   * Optional prefix to add to the default label of this item switcher.
   */
  labelPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Optional icon prefix to add to the default label of this item switcher.
   */
  labelPrefixIconClass: PropTypes.string,
  dropdownClassName: PropTypes.string,
};

ItemSwitcher.defaultProps = {
  isComparisonDropdown: false,
  labelPrefix: undefined,
  labelPrefixIconClass: undefined,
  dropdownClassName: ''
};

export default ItemSwitcher;
