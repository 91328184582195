import type {ReactElement} from 'react';
import React from 'react';
import {HexPicker} from '@Components/color-picker-v2/components/hex-picker';
import {PickerInputField} from '@Components/color-picker-v2/components/picker-input-field';
import {OnMyDesignSection} from '@Components/color-picker-v2/components/on-my-design-section';
import styles from './color-picker-popup.module.scss';

interface ColorPickerPopupProps {
  className?: string;
  color: string;
  onMyDesign?: Array<string>;

  onChange(color: string): void;
  onClickAdd?(): void;

  onChangeEnded(color: string): void;
  hideHexPicker?: boolean;
  hideHexInputField?: boolean;
  isMobileVersion?: boolean;
}

export function ColorPickerPopup({
  className = '',
  onClickAdd = (): void => {},
  hideHexPicker = false,
  isMobileVersion = false,
  hideHexInputField = false,
  ...props
}: ColorPickerPopupProps): ReactElement | null {
  return (
    <div className={className}>
      {!hideHexPicker ? (
        <HexPicker
          className="spacing-m-b-3"
          color={props.color}
          onChange={(val: string): void => {
            props.onChange(val);
          }}
          onChangeEnded={(val: string): void => {
            props.onChangeEnded(val);
          }}
        />
      ) : null}
      {!hideHexInputField ? (
        <div className={styles.inputFieldWrapper}>
          <PickerInputField
            color={props.color}
            onChangeColor={(val: string): void => {
              props.onChange(val);
            }}
            onChangeEnded={(val: string): void => {
              props.onChangeEnded(val);
            }}
            isMobileVersion={isMobileVersion}
          />
        </div>
      ) : null}
      {props.onMyDesign ? (
        <OnMyDesignSection
          onColorSelect={(color): void => {
            props.onChange(color);
            props.onChangeEnded(color);
          }}
          colorsOnDesign={props.onMyDesign}
          isMobileVersion={isMobileVersion}
          onClickAdd={(): void => {
            onClickAdd();
          }}
        />
      ) : null}
    </div>
  );
}
