import type {ReactElement} from 'react';
import React from 'react';
import styles from './skeleton-brick.module.scss';

export interface SkeletonBrickProps {
  className?: string;
  width?: number;
  height?: number;
  radius?: string;
  theme?: SkeletonBrickTheme;
  isPulsating?: boolean;
}

export enum SkeletonBrickTheme {
  LIGHT = 'light',
  DARK = 'dark',
  LIGHT_TWO = 'light_two',
}

export function SkeletonBrick({className = '', theme = SkeletonBrickTheme.LIGHT, isPulsating = true, ...props}: SkeletonBrickProps): ReactElement {
  const getAnimationClassName = (): string => {
    switch (theme) {
      case SkeletonBrickTheme.DARK:
        return styles.dark;
      case SkeletonBrickTheme.LIGHT_TWO:
        return styles.lightTwo;
      case SkeletonBrickTheme.LIGHT:
      default:
        return styles.light;
    }
  };

  const getStaticBrickClassName = (): string => {
    switch (theme) {
      case SkeletonBrickTheme.DARK:
        return styles.staticDark;
      case SkeletonBrickTheme.LIGHT_TWO:
        return styles.staticLightTwo;
      case SkeletonBrickTheme.LIGHT:
      default:
        return styles.staticLight;
    }
  };

  return (
    <div
      style={{
        width: props.width,
        height: props.height,
        borderRadius: props.radius,
      }}
      className={`${className} ${styles.skeletonContainer} ${isPulsating === true ? getAnimationClassName() : getStaticBrickClassName()} ${styles.dimensions}`}
    />
  );
}
