import type {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import type {NoInfer} from '@reduxjs/toolkit/dist/tsHelpers';
import type {LoginType, TOTPVerificationFailResponse, TwoFactorAuthenticationState} from '@Components/two-factor-authentication/two-factor-authentication.types';
import {isTOTPLoginType} from '@Libraries/two-factor-authentication-library';
import {disableUserTwoFactorVerification, submit2FACodeForVerification, verifyTOTPForRegistration} from '@Components/two-factor-authentication/two-factor-authentication-thunk';

const TOTP_INVALID = 'totp_invalid';

export const twoFactorAuthenticationExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<TwoFactorAuthenticationState>>): void => {
  builder.addCase(verifyTOTPForRegistration.pending, (state) => {
    enableLoadingState(state);
  });

  builder.addCase(verifyTOTPForRegistration.fulfilled, (state) => {
    disableLoadingState(state);
  });

  builder.addCase(verifyTOTPForRegistration.rejected, (state, {payload}) => {
    disableLoadingState(state);
    handleManage2FAPanelError(state, payload as TOTPVerificationFailResponse);
  });

  builder.addCase(disableUserTwoFactorVerification.pending, (state) => {
    enableLoadingState(state);
  });

  builder.addCase(disableUserTwoFactorVerification.fulfilled, (state) => {
    disableLoadingState(state);
  });

  builder.addCase(disableUserTwoFactorVerification.rejected, (state) => {
    disableLoadingState(state);
  });

  builder.addCase(submit2FACodeForVerification.pending, (state) => {
    enableLoadingState(state);
  });

  builder.addCase(submit2FACodeForVerification.fulfilled, (state) => {
    onSuccessfulTOTPVerification(state);
    if (state.redirectUrl) {
      window.location.href = state.redirectUrl;
    }
  });

  builder.addCase(submit2FACodeForVerification.rejected, (state, {payload}) => {
    disableLoadingState(state);
    handle2FAVerificationPanelError(state, payload as TOTPVerificationFailResponse);
  });
};

const enableLoadingState = (state: TwoFactorAuthenticationState): void => {
  state.isLoading = true;
};

const disableLoadingState = (state: TwoFactorAuthenticationState): void => {
  state.isLoading = false;
};

const onSuccessfulTOTPVerification = (state: TwoFactorAuthenticationState): void => {
  state.isTOTPValidated = true;
};
const handle2FAVerificationPanelError = (state: TwoFactorAuthenticationState, payload: TOTPVerificationFailResponse): void => {
  if (payload.data.reason === TOTP_INVALID) {
    state.errorMessage = getInvalidTOTPMessageForLoginType(state.loginType);
  } else {
    state.errorMessage = window.i18next.t('pmwjs_something_wrong_reload_page');
  }
};

const handleManage2FAPanelError = (state: TwoFactorAuthenticationState, payload: TOTPVerificationFailResponse): void => {
  if (payload.data.reason === TOTP_INVALID) {
    state.errorMessage = window.i18next.t('pmwjs_two_factor_authentication_totp_error_message');
  }
};

const getInvalidTOTPMessageForLoginType = (loginType: LoginType): string => {
  if (isTOTPLoginType(loginType)) {
    return window.i18next.t('pmwjs_two_factor_authentication_totp_error_message');
  }
  return window.i18next.t('pmwjs_two_factor_authentication_backup_code_error_message', {url: window.PMW.util.site_url('user/feedbackform')});
};
