import {initUpload, onUnableToUploadError} from './upload-library.js';
import {default as Emitter, EVENTS} from '../services/event.js';
import {addBrandImage} from '@Components/mystuff-brands/brands-reducer.js';
import {filterValidImageFiles} from './user-image-library.ts';
import {getActiveBrandId} from './brands-library.js';
import {onAjaxError} from '@Utils/ajax.util.ts';
import {checkUploadStatus, UserMediaType} from '@Libraries/user-media-library';

/**
 * This file contains helper functions related to uploading brand assets such as brand logos
 * and brand videos, audios etc
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */


/**
 *
 * @param {jQuery} fileInput
 */
export const initBrandLogoUpload = (fileInput) => {
  initUpload(fileInput, uploadBrandLogos, onUnableToUploadError);
};

/**
 *
 * @param {Array} files
 * @returns {Promise<void>}
 */
export const uploadBrandLogos = async (files = null) => {
  let formData = new FormData(),
    filteredFiles = filterValidImageFiles(files),
    serverResponse = null;

  formData.append('Filedata', filteredFiles[0]);
  formData.append('brandId', getActiveBrandId());

  try {
    Emitter.emit(EVENTS.BRAND_LOGO_UPLOAD_START);
    serverResponse = await window.PMW.pollingAjaxCallAsync(async () => {
      return await PMW.writeLocal('posterimage/brandLogoUpload', formData, 'json', {
        timeout: 7200000,
        cache: false,
        processData: false, // Don't process the files
        contentType: false, // Set content type to false as jQuery will tell the server its a query string request
      });
    }, checkUploadStatus.bind(null, UserMediaType.IMAGE));
    PMW.redux.store.dispatch(addBrandImage(serverResponse));
    // Tracking temporarily disabled on 8th Nov 2023
    // trackGAEventForNewBrandLogo();
  } catch (error) {
    onAjaxError(error);
    Emitter.emit(EVENTS.BRAND_LOGO_UPLOAD_FAIL);
    return;

  }
  Emitter.emit(EVENTS.BRAND_LOGO_UPLOAD_SUCCESS, serverResponse);
};
