import type {DropdownProps} from '@Components/dropdown-v2';
import type {ListItemType8Storage} from '@Components/list/components/list-item-type-8';

// SelfCodeReviewMoeedLater: Delete this file after porting over auto captions to the new FE component
export enum AutoCaptionOptionType {
  LENGTH = 'length',
  TONE = 'tone',
  HASHTAGS = 'hashtags',
}

export enum Tier {
  DEFAULT = 'default',
}

export enum BlacklistStatus {
  BLACKLISTED = 'blacklisted',
  WHITELISTED = 'whitelisted',
}

export enum LengthCategory {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
}

export enum Tone {
  NEUTRAL = 'neutral',
  PROFESSIONAL = 'professional',
  EXCITED = 'excited',
  CONFIDENT = 'confident',
  WITTY = 'witty',
  EMPOWERING = 'empowering',
  FUNNY = 'funny',
}

export enum HashtagUsage {
  USE_HASHTAGS = 'use_hashtags',
  NO_HASHTAGS = 'no_hashtags',
}

export interface PromptConfigOptions {
  lengthCategory: LengthCategory;
  hasHashtags: HashtagUsage;
  tone: Tone;
}

export type OneOf<T> = {
  key: keyof T;
  value: T[keyof T];
};

export const DEFAULT_GENERATION_CONFIG_OPTIONS: PromptConfigOptions = {
  lengthCategory: LengthCategory.SHORT,
  hasHashtags: HashtagUsage.USE_HASHTAGS,
  tone: Tone.NEUTRAL,
};

export type CommonListItemPropsType = Pick<ListItemType8Storage, 'type' | 'onClick' | 'className'>;

export type CommonDropDownPropsType = Pick<DropdownProps, 'popUpHasCustomWidth' | 'popupSpacingClass' | 'topOffset' | 'className' | 'alignment' | 'onClick'>;

export type ErrorType = {
  status: string;
  message: string;
  code: string;
  data: null;
};

export type AutoCaptionReduxStateType = {
  inAutoCaptionMode: InAutoCaptionModeType;
  prompt: string;
  selectedCaption: string;
  isError: boolean;
  errorMessage: string;
  loadingStates: AutoCaptionLoadingStates;
  recentCaptions: RecentCaptionType[];
  generatedCaptions: CaptionType[];
  hasSeenIntroPopup: boolean;
  hasSeenReminderPopup: boolean;
  alterCaptionOptions: OneOf<PromptConfigOptions>[];
  previouslyUsedOptionsForAlteringCaption: PromptConfigOptions | null;
};

export type SetSelectedCaptionActionType = {
  payload: {
    caption: string;
  };
};

export type InAutoCaptionModeType = {accountId: number | null};

export type CaptionType = string;

export type ReuseCaptionResponse = {
  prompt: string;
  options: PromptConfigOptions;
};

export type RecentCaptionType = {
  captionid: string;
  generatedOn: string;
  caption: string;
  lengthCategory: LengthCategory;
  tone: Tone;
};

export type AutoCaptionLoadingStates = ComposedAutoCaptionLoadingStates;

type GenerateLoadingState = {
  isLoadingGenerate: boolean;
};

type UseCaptionLoadingState = {
  isLoadingUseCaption: boolean;
};

type AlterCaptionLoadingState = {
  isLoadingAlterCaption: boolean;
};

type RecentsLoadingState = {
  isLoadingRecents: boolean;
};

export type ComposedAutoCaptionLoadingStates = GenerateLoadingState & UseCaptionLoadingState & AlterCaptionLoadingState & RecentsLoadingState;
export const GENERATION_OPTIONS_COMPONENT_ID = 'generation-options';
export const ALTERATION_OPTIONS_COMPONENT_ID = 'alter-caption-options';

export type ConfigOptionListIDsType = {ID_HASHTAG_USAGE_LIST: string; ID_TONE_LIST: string; ID_LENGTH_LIST: string};

export const NUMBER_OF_CAPTIONS_PER_GENERATION = 4;

/**
 * @description Mirrors Backend \AutoCaption\ErrorTypes enum
 */
export enum AutoCaptionErrorType {
  PromptNotValidSentence = 'ac-prompt-not-valid-sentence',
  InvalidPrompt = 'ac-invalid-prompt',
  LimitReached = 'ac-limit-reached',
  BadReq = 'ac-bad-req',
  GeneralError = 'ac-general-error',
  DbError = 'ac-db-error',
  NoPrompt = 'ac-no-prompt',
  NoCaption = 'ac-no-caption',
  NoUserId = 'ac-no-user-id',
  SpammyPrompt = 'ac-spammy-prompt',
  PromptHasSuspiciousWords = 'ac-prompt-has-suspicious-words',
  OpenAiException = 'open-ai-exception',
  UserBlacklisted = 'ac-user-blacklist',
  OpenAiRateLimitHit = 'ac-open-ai-rate-limit-exception',
  TooManyConcurrentRequests = 'too-many-concurrent-reqs',
}

export type PromptSuggestion = {
  title: string;
  subtitle: string;
};

export const SMP_AI_CAPTION_PROMPT_SUGGESTIONS: PromptSuggestion[] = [
  {title: 'pmwjs_online_invite', subtitle: 'pmwjs_to_my_first_book_launch'},
  {title: 'pmwjs_book_signing', subtitle: 'pmwjs_announcement_for_my_readers'},
  {title: 'pmwjs_fight_for_animal_rights', subtitle: 'pmwjs_with_our_local_shelter'},
  {title: 'pmwjs_waste_no_water', subtitle: 'pmwjs_campaign_announcement'},
  {title: 'pmwjs_community_cleanup_drive', subtitle: 'pmwjs_hosted_by_our_small_business'},
  {title: 'pmwjs_recycle_for_the_planet', subtitle: 'pmwjs_announcement_for_our_community'},
  {title: 'pmwjs_save_the_date', subtitle: 'pmwjs_for_our_engagement'},
  {title: 'pmwjs_baby_announcement', subtitle: 'pmwjs_for_our_family'},
  {title: 'pmwjs_in_loving_memory', subtitle: 'pmwjs_of_a_loved_one'},
  {title: 'pmwjs_invitation', subtitle: 'pmwjs_to_our_wedding'},
  {title: 'pmwjs_volunteer_program', subtitle: 'pmwjs_for_our_charity_drive'},
  {title: 'pmwjs_sign_up_sheet', subtitle: 'pmwjs_for_an_annual_marathon'},
  {title: 'pmwjs_coat_drive', subtitle: 'pmwjs_announcement'},
  {title: 'pmwjs_food_drive', subtitle: 'pmwjs_announcement'},
  {title: 'pmwjs_toy_drive', subtitle: 'pmwjs_at_local_children_s_shelter'},
  {title: 'pmwjs_blood_drive', subtitle: 'pmwjs_for_our_local_health_clinic'},
  {title: 'pmwjs_bake_sale', subtitle: 'pmwjs_to_raise_funds'},
  {title: 'pmwjs_school_supply_drive', subtitle: 'pmwjs_for_the_local_adoption_center'},
  {title: 'pmwjs_earthquake_relief_fund', subtitle: 'pmwjs_for_our_community'},
  {title: 'pmwjs_new_album', subtitle: 'pmwjs_announcement'},
  {title: 'pmwjs_ticket_on_sale', subtitle: 'pmwjs_for_our_live_performance'},
  {title: 'pmwjs_happy_weekend', subtitle: 'pmwjs_to_our_employees'},
  {title: 'pmwjs_family_appreciation', subtitle: 'pmwjs_for_our_loved_ones'},
  {title: 'pmwjs_a_heartfelt_confession', subtitle: 'pmwjs_to_my_beloved'},
  {title: 'pmwjs_announcement', subtitle: 'pmwjs_for_our_summer_camp'},
  {title: 'pmwjs_recruitment', subtitle: 'pmwjs_for_an_after_school_program'},
  {title: 'pmwjs_online_invitation', subtitle: 'pmwjs_for_our_college_fair'},
  {title: 'pmwjs_sign_up_announcement', subtitle: 'pmwjs_for_our_annual_school_contest'},
  {title: 'pmwjs_announcement', subtitle: 'pmwjs_for_extra_online_classes'},
  {title: 'pmwjs_campaign_posters', subtitle: 'pmwjs_for_my_student_council_campaign'},
  {title: 'pmwjs_invitation', subtitle: 'pmwjs_for_our_school_pto_meeting'},
  {title: 'pmwjs_weekly_update', subtitle: 'pmwjs_for_our_sunday_service'},
  {title: 'pmwjs_invitation', subtitle: 'pmwjs_to_join_our_bible_study_group'},
  {title: 'pmwjs_sunday_service', subtitle: 'pmwjs_announcement_for_our_church'},
  {title: 'pmwjs_youth_ministry_meeting', subtitle: 'pmwjs_at_our_local_church'},
  {title: 'pmwjs_sign_up_announcement', subtitle: 'pmwjs_for_our_community_church_event'},
  {title: 'pmwjs_monthly_conference', subtitle: 'pmwjs_announcement_for_our_church'},
  {title: 'pmwjs_new_menu', subtitle: 'pmwjs_for_my_italian_restaurant'},
  {title: 'pmwjs_delivery_hours_announcement', subtitle: 'pmwjs_for_my_fast_food_restaurant'},
  {title: 'pmwjs_daily_specials', subtitle: 'pmwjs_announcement_for_my_restaurant'},
  {title: 'pmwjs_opening_time', subtitle: 'pmwjs_update_for_my_restaurant'},
  {title: 'pmwjs_happy_hour_menu', subtitle: 'pmwjs_for_my_new_bar'},
  {title: 'pmwjs_a_fun_tastic_pun', subtitle: 'pmwjs_to_promote_my_new_cafe'},
  {title: 'pmwjs_birthday_wish', subtitle: 'pmwjs_for_a_loved_one'},
  {title: 'pmwjs_get_well_soon_wish', subtitle: 'pmwjs_for_a_loved_one'},
  {title: 'pmwjs_good_luck_wish', subtitle: 'pmwjs_for_a_colleague'},
  {title: 'pmwjs_thank_you', subtitle: 'pmwjs_to_our_customers'},
  {title: 'pmwjs_farewell', subtitle: 'pmwjs_to_a_cherished_employee'},
  {title: 'pmwjs_congratulations', subtitle: 'pmwjs_on_a_new_venture'},
  {title: 'pmwjs_happy_anniversary', subtitle: 'pmwjs_to_someone_special'},
  {title: 'pmwjs_in_memory_of', subtitle: 'pmwjs_a_loved_one'},
  {title: 'pmwjs_business_hours', subtitle: 'pmwjs_for_my_retail_store'},
  {title: 'pmwjs_new_arrivals', subtitle: 'pmwjs_in_my_clothing_store'},
  {title: 'pmwjs_job_openings', subtitle: 'pmwjs_for_my_small_business'},
  {title: 'pmwjs_coming_soon_announcement', subtitle: 'pmwjs_for_my_new_product'},
  {title: 'pmwjs_online_invite', subtitle: 'pmwjs_for_a_business_conference'},
  {title: 'pmwjs_online_invite', subtitle: 'pmwjs_for_a_virtual_conference'},
  {title: 'pmwjs_event_announcement', subtitle: 'pmwjs_for_a_grand_opening'},
  {title: 'pmwjs_event_page', subtitle: 'pmwjs_for_my_upcoming_concert'},
  {title: 'pmwjs_upcoming_event', subtitle: 'pmwjs_for_my_boutique_sale'},
  {title: 'pmwjs_online_invitation', subtitle: 'pmwjs_for_my_charity_fundraiser'},
  {title: 'pmwjs_event_schedule', subtitle: 'pmwjs_for_my_music_festival'},
  {title: 'pmwjs_grand_opening', subtitle: 'pmwjs_of_my_new_restaurant'},
];

export enum PopupType {
  INTRO = 'intro',
  REMINDER = 'reminder',
}

export type HasSeenReponse = {
  [PopupType.INTRO]: boolean;
  [PopupType.REMINDER]: boolean;
};
