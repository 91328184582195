import type {ReactElement} from 'react';
import React from 'react';

export interface PanelFooterSecondaryCustomActionProps {
  children?: ReactElement;
  className?: string;
}

export function PanelFooterSecondaryCustomAction({className = '', children = <div />}: PanelFooterSecondaryCustomActionProps) {
  return (
    <>
      {className
        ? React.cloneElement(children, {
            className: `${children.props.customClasses} ${className}`,
          })
        : children}
    </>
  );
}
