import React from "react";
import './modal-content.scss';
import PropTypes from "prop-types";

export default class ModalContent extends React.Component {
    render = () => {
        return <div className={"content " + this.props.className}>{this.props.content}</div>;
    }
}

ModalContent.propTypes = {
    className: PropTypes.string,
    content: PropTypes.element
};

ModalContent.defaultProps = {
    className: ''
}
