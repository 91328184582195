import type {ReactElement} from 'react';
import React from 'react';
import type {InputFieldControlItemData, InputFieldControlItemEvent} from '@Components/input-field-v2/input-field.types';
import {InputControlItemType, InputFieldSize} from '@Components/input-field-v2/input-field.types';
import {InputFieldControlItem} from '@Components/input-field-v2/components/input-field-control-item';
import styles from './input-field-control.module.scss';

interface InputFieldControlProps {
  showDefaultControls?: boolean;
  showSingleDefaultControlOnly?: boolean;
  isDisabled?: boolean;
  customControls?: Array<InputFieldControlItemData>;
  size?: InputFieldSize;
  className?: string;

  onClick?(id: string, e?: InputFieldControlItemEvent): void;
  doBlurInputOnClick?: boolean;
}

export function InputFieldControl({
  className = '',
  size = InputFieldSize.SMALL,
  showDefaultControls = true,
  showSingleDefaultControlOnly = false,
  customControls = [],
  isDisabled = false,
  onClick = (): void => {},
  doBlurInputOnClick = true,
}: InputFieldControlProps): ReactElement | null {
  const onClickHandler = (id: string, e?: InputFieldControlItemEvent): void => {
    if (onClick) {
      onClick(id, e);
    }
  };

  const getDefaultControlItems = () => {
    const defaultControlData: Array<InputFieldControlItemData> = [
      {
        id: 'close-icon',
        type: InputControlItemType.ICONS_ONLY,
        icon: 'icon-close',
      },
      {
        id: 'check-icon',
        type: InputControlItemType.ICONS_ONLY,
        icon: 'icon-check',
      },
    ];

    if (showSingleDefaultControlOnly) {
      return (
        <InputFieldControlItem
          doBlurInputOnClick={doBlurInputOnClick}
          key={defaultControlData[0].id}
          data={defaultControlData[1]}
          isDisabled={isDisabled}
          isRightMostIcon
          size={size}
          onClick={onClickHandler}
        />
      );
    }
    const defaultControls: Array<ReactElement> = [];
    for (let index = 0; index < defaultControlData.length; index++) {
      defaultControls.push(
        <InputFieldControlItem
          doBlurInputOnClick={doBlurInputOnClick}
          key={index}
          onClick={onClickHandler}
          data={defaultControlData[index]}
          isDisabled={isDisabled}
          size={size}
          isRightMostIcon={index === defaultControlData.length - 1}
        />
      );
    }
    return defaultControls;
  };

  const getControlItems = () => {
    if (showDefaultControls) {
      return getDefaultControlItems();
    }

    const controls: Array<ReactElement> = [];

    if (customControls) {
      customControls.map((data, index) => {
        controls.push(
          <InputFieldControlItem
            doBlurInputOnClick={doBlurInputOnClick}
            onClick={onClickHandler}
            data={data}
            key={data.id}
            isDisabled={isDisabled}
            size={size}
            isRightMostIcon={index === customControls.length - 1}
          />
        );
        return controls;
      });
    }

    return controls;
  };

  return <ul className={`flex-h-row spacing-m-0 ${className} ${styles.container}`}>{getControlItems()}</ul>;
}
