import React from 'react';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import styles from './tag.module.scss';

interface TagProps {
  text: string;
  /**
   * Includes the class that renders the icon and any additional classes too
   */
  iconClasses?: string;
  className?: string;
  type?: TagType;
  icon?: string;
  textClasses?: string;
}

export enum TagType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
}

export function Tag({type = TagType.DEFAULT, className = '', iconClasses = '', icon = '', textClasses = '', ...props}: TagProps) {
  const getContainerClasses = () => {
    const classes = [styles.tagContainer, className, 'flex-row-align-center'];
    if (type === TagType.PRIMARY) {
      classes.push(styles.primary);
    } else if (type === TagType.SECONDARY) {
      classes.push(styles.secondary);
    } else if (type === TagType.DEFAULT) {
      classes.push(styles.default);
    }

    return classes.join(' ');
  };

  const getTextColorClasses = () => {
    switch (type) {
      case TagType.PRIMARY:
        return 'content-primary';
      case TagType.SECONDARY:
        return 'content-body-white';
      case TagType.DEFAULT:
        return 'content-body';
      default:
        return '';
    }
  };

  return (
    <div className={getContainerClasses()}>
      <Icon icon={icon} className={`${iconClasses}`} size={IconSize.SIZE_ICON_16} />
      <Text val={props.text} size={TextSize.XXSMALL} className={`${getTextColorClasses()} ${icon ? styles.marginLeft : ''} ${textClasses}`} bold />
    </div>
  );
}
