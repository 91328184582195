import React, {MouseEventHandler} from 'react';

export enum SubFlowType {
  RAYDIANT_SUBDISPLAY = 'RAYDIANT_SUBDISPLAY',
  SMART_TV_SUBDISPLAY = 'SMART_TV_SUBDISPLAY',
  SCREEN_CLOUD_SUBDISPLAY = 'SCREEN_CLOUD_SUBDISPLAY',
  SIGNAGELIVE_WIDGET_SUBDISPLAY = 'SIGNAGELIVE_WIDGET_SUBDISPLAY',
  POSTERBOOKING_SUBDISPLAY = 'POSTERBOOKING_SUBDISPLAY',
  FUGO_SUBDISPLAY = 'FUGO_SUBDISPLAY',
  YODECK_SUBDISPLAY = 'YODECK_SUBDISPLAY',
  VIZROM_SUBDISPLAY = 'VIZROM_SUBDISPLAY',
  DRIPBOARDS_SUBDISPLAY = 'DRIPBOARDS_SUBDISPLAY',
  DEFAULT = 'DEFAULT',
}

export interface DigitalSignageCompiledSubPanelProps {
  subFlowType: SubFlowType;
  onClose: () => void | MouseEventHandler;
}
