import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

export interface StateProps {
  [userId: number]: VideoBgRemovalCreditsData;
}

interface VideoBgRemovalCreditsData {
  [hashedIdWithStartAndEndTimeString: string]: number;
}

interface VideoCreditsData {
  userId: number;
  videoKey: string;
  credits: number;
}

const initialState: StateProps = {};

const videoBgRemovalCreditsCostSlice = createSlice({
  name: 'videoBgRemovalCreditsCostReact',
  initialState,
  reducers: {
    setVideoBgRemovalCreditsCost: (state: StateProps, action: PayloadAction<VideoCreditsData>) => {
      if (action.payload.userId in state) {
        state[action.payload.userId][action.payload.videoKey] = action.payload.credits;
      } else {
        state[action.payload.userId] = {[action.payload.videoKey]: action.payload.credits};
      }
    },
  },
});

export const {setVideoBgRemovalCreditsCost} = videoBgRemovalCreditsCostSlice.actions;
export const videoBgRemovalCreditsCostReducer = videoBgRemovalCreditsCostSlice.reducer;
