import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import {ClickableDiv} from '@Components/clickable-div';
import styles from './add-color-button.module.scss';

interface AddColorButtonProps {
  onAddColor(): void;
}

export function AddColorButton({...props}: AddColorButtonProps): ReactElement {
  const getAddButton = (): ReactElement => {
    return (
      <ClickableDiv
        onClick={(): void => {
          props.onAddColor();
        }}
        className={`flex-center ${styles.container} border-s-darker radius-4`}
      >
        <Icon className={styles.icon} icon="icon-plus" size={IconSize.SIZE_ICON_24} />
      </ClickableDiv>
    );
  };
  return getAddButton();
}
