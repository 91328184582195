import type {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import type {
  AjaxResponseWithCampaign,
  ChangeScheduleDateTimeParams,
  EmailCampaign,
  EmailCampaignId,
  EmailCampaignStatusFilter,
  EmailMarketingCampaignsReduxState,
  FetchEmailCampaignsAjaxParams,
} from '@Components/email-marketing-campaigns/email-marketing-campaigns.types';
import {
  deleteEmailCampaign,
  duplicateEmailCampaign,
  fetchEmailCampaigns,
  pauseEmailCampaign,
  restoreEmailCampaign,
  resumeEmailCampaign,
  sendScheduledCampaignNow,
  unscheduleEmailCampaign,
  updateCampaignScheduleDateTime,
} from '@Components/email-marketing-campaigns/email-marketing-campaigns-thunk';
import {EMAIL_CAMPAIGNS_LAZY_BATCH_SIZE, getAppropriateEmailCampaignsLazyData} from '@Libraries/email-campaigns-library';
import {LoadingStates} from '@Utils/loading.util';
import {insert, objectArrayToHashmap, objectsEqual, removeItem} from '@Utils/array.util';
import {getUnixTimestamp} from '@Utils/date.util';

export const emailMarketingCampaignsExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<EmailMarketingCampaignsReduxState>>): void => {
  builder.addCase(fetchEmailCampaigns.pending, (state, {meta}): void => {
    const lazyData = getAppropriateEmailCampaignsLazyData(state, meta.arg.statusFilter, meta.arg.sortBy);
    lazyData.loadingState = LoadingStates.LOADING;
  });

  builder.addCase(fetchEmailCampaigns.fulfilled, (state, {meta, payload}): void => {
    if (isAjaxResponseOutdated(state, meta.arg)) {
      return;
    }

    const lazyData = getAppropriateEmailCampaignsLazyData(state, meta.arg.statusFilter, meta.arg.sortBy);
    lazyData.loadingState = LoadingStates.LOADED;

    const ids = payload.map((campaign) => {
      return campaign.id;
    });
    lazyData.ids = lazyData.ids.concat(ids);
    lazyData.loadMore = ids.length >= EMAIL_CAMPAIGNS_LAZY_BATCH_SIZE;
    state.allCampaigns = {...state.allCampaigns, ...objectArrayToHashmap(payload, 'id')};
  });

  builder.addCase(sendScheduledCampaignNow.fulfilled, (state, {payload}) => {
    setCampaignInStateIfReceivedInResponse(state, payload.campaign);
  });

  builder.addCase(unscheduleEmailCampaign.fulfilled, (state, {payload}) => {
    setCampaignInStateIfReceivedInResponse(state, payload.campaign);
  });

  builder.addCase(deleteEmailCampaign.pending, (state, {meta}): void => {
    doRemoveCampaignIdFromLazyData(state, meta.arg.campaignId);
  });

  builder.addCase(deleteEmailCampaign.fulfilled, (state, {meta}) => {
    doRemoveCampaignFromStore(state, meta.arg.campaignId);
  });

  builder.addCase(deleteEmailCampaign.rejected, (state, {meta}) => {
    doAddCampaignIdToLazyData(state, meta.arg.campaignId);
  });

  builder.addCase(restoreEmailCampaign.fulfilled, (state, {meta}) => {
    doRemoveCampaignFromStore(state, meta.arg);
  });

  builder.addCase(restoreEmailCampaign.rejected, (state, {meta}) => {
    doAddCampaignIdToLazyData(state, meta.arg);
  });

  builder.addCase(duplicateEmailCampaign.fulfilled, (state, {payload}) => {
    addDuplicatedCampaignFromResponse(state, payload);
  });

  builder.addCase(updateCampaignScheduleDateTime.fulfilled, (state, {meta}): void => {
    updateCampaignScheduleTimeInStore(state, meta.arg);
  });

  builder.addCase(pauseEmailCampaign.fulfilled, (state, {meta}) => {
    const campaign = getCampaignFromStore(state, meta.arg);
    if (campaign) {
      campaign.isPaused = true;
    }
  });

  builder.addCase(resumeEmailCampaign.fulfilled, (state, {meta}) => {
    const campaign = getCampaignFromStore(state, meta.arg);
    if (campaign) {
      campaign.isPaused = false;
    }
  });
};

const updateCampaignScheduleTimeInStore = (state: EmailMarketingCampaignsReduxState, ajaxParams: ChangeScheduleDateTimeParams): void => {
  const campaign = getCampaignFromStore(state, ajaxParams.campaignId);

  if (!campaign) {
    return;
  }

  campaign.isScheduled = true;
  campaign.publishOn = getUnixTimestamp(ajaxParams.newDate);
};

const addDuplicatedCampaignFromResponse = (state: EmailMarketingCampaignsReduxState, ajaxResponse: AjaxResponseWithCampaign): void => {
  if (!ajaxResponse.campaign) {
    return;
  }

  doAddCampaignIdToLazyData(state, ajaxResponse.campaign.id);
  setCampaignInStateIfReceivedInResponse(state, ajaxResponse.campaign);
};

const setCampaignInStateIfReceivedInResponse = (state: EmailMarketingCampaignsReduxState, campaignFromResponse?: EmailCampaign): void => {
  if (campaignFromResponse) {
    state.allCampaigns[campaignFromResponse.id] = campaignFromResponse;
  }
};

const doRemoveCampaignIdFromLazyData = (state: EmailMarketingCampaignsReduxState, campaignId: EmailCampaignId): void => {
  state.defaultCampaignsLazyData.ids = removeItem(state.defaultCampaignsLazyData.ids, campaignId);
  state.filteredCampaignsLazyData.ids = removeItem(state.filteredCampaignsLazyData.ids, campaignId);
};

const doAddCampaignIdToLazyData = (state: EmailMarketingCampaignsReduxState, campaignId: EmailCampaignId): void => {
  state.defaultCampaignsLazyData.ids = insert(state.defaultCampaignsLazyData.ids, 0, campaignId);
  state.filteredCampaignsLazyData.ids = insert(state.filteredCampaignsLazyData.ids, 0, campaignId);
};

const doRemoveCampaignFromStore = (state: EmailMarketingCampaignsReduxState, campaignId: EmailCampaignId): void => {
  delete state.allCampaigns[campaignId];
};

const getCampaignFromStore = (state: EmailMarketingCampaignsReduxState, campaignId: EmailCampaignId): EmailCampaign | undefined => {
  return state.allCampaigns[campaignId];
};

const isAjaxResponseOutdated = (state: EmailMarketingCampaignsReduxState, ajaxParams: FetchEmailCampaignsAjaxParams): boolean => {
  return state.viewType !== ajaxParams.viewType || !areStatusFiltersSame(state.statusFilter, ajaxParams.statusFilter) || ajaxParams.sortBy !== state.sortingFilter;
};

const areStatusFiltersSame = (statusFilters1: EmailCampaignStatusFilter, statusFilter2: EmailCampaignStatusFilter): boolean => {
  return objectsEqual(statusFilters1, statusFilter2);
};
