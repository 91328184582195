import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {LayoutData} from '@Panels/user-schedule-panel/user-schedule-panel.types';
import type {TableDataType} from '@Components/table/table.types';
import {DateFormat, TimeFormat} from '@Components/table/table.types';
import type {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';

interface InitializeDataProps {
  rows: number;
  columns: number;
  layout?: LayoutData;
  timeFormat?: TimeFormat;
  dateFormat?: DateFormat;
  highlightedRows: Array<number>;
  columnsData?: Array<CellType>;
  tableData: Array<Array<TableDataType>>;
}

interface UpdateDataProps {
  columns?: number;
  layout?: LayoutData;
  dateFormat?: DateFormat;
  columnsData?: Array<CellType>;
  tableData: Array<Array<TableDataType>>;
}

interface StateProps {
  rows: number;
  columns: number;
  layout: LayoutData;
  timeFormat: TimeFormat;
  dateFormat: DateFormat;
  highlightedRows: Array<number>;
  columnsData: Array<CellType>;
  tableData: Array<Array<TableDataType>>;
}

const initialState: StateProps = {
  rows: 0,
  columns: 0,
  tableData: [],
  columnsData: [],
  highlightedRows: [],
  timeFormat: TimeFormat.STANDARD,
  dateFormat: DateFormat.FORMAT_6,
  layout: LayoutData.SLANTED_SPORTS_LAYOUT,
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    initializeData: (state, action: PayloadAction<InitializeDataProps>) => {
      state.rows = action.payload.rows;
      state.columns = action.payload.columns;
      state.tableData = action.payload.tableData;
      state.highlightedRows = action.payload.highlightedRows;
      if (action.payload.columnsData) {
        state.columnsData = action.payload.columnsData;
      }
      if (action.payload.layout) {
        state.layout = action.payload.layout;
      }
      if (action.payload.timeFormat) {
        state.timeFormat = action.payload.timeFormat;
      }
      if (action.payload.dateFormat) {
        state.dateFormat = action.payload.dateFormat;
      }
    },
    updateData: (state, action: PayloadAction<UpdateDataProps>) => {
      state.tableData = action.payload.tableData;
      if (action.payload.columns) {
        state.columns = action.payload.columns;
      }
      if (action.payload.columnsData) {
        state.columnsData = action.payload.columnsData;
      }
      if (action.payload.layout) {
        state.layout = action.payload.layout;
      }
      if (action.payload.dateFormat) {
        state.dateFormat = action.payload.dateFormat;
      }
    },
    setTableData: (state, action: PayloadAction<Array<Array<TableDataType>>>) => {
      state.tableData = action.payload;
    },
    setRows: (state, action: PayloadAction<number>) => {
      state.rows = action.payload;
    },
    setColumns: (state, action: PayloadAction<number>) => {
      state.columns = action.payload;
    },
    setHighlightedRows: (state, action: PayloadAction<Array<number>>) => {
      state.highlightedRows = action.payload;
    },
    setLayoutStyle: (state, action: PayloadAction<LayoutData>) => {
      state.layout = action.payload;
    },
    setTimeFormat: (state, action: PayloadAction<TimeFormat>) => {
      state.timeFormat = action.payload;
    },
    setDateFormat: (state, action: PayloadAction<DateFormat>) => {
      state.dateFormat = action.payload;
    },
  },
});

export const {initializeData, updateData, setTableData, setRows, setColumns, setHighlightedRows, setLayoutStyle, setTimeFormat, setDateFormat} = tableSlice.actions;

export const tableReducer = tableSlice.reducer;
