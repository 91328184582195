import type {CSSProperties, ReactElement} from 'react';
import React from 'react';
import './tooltip.scss';

/**
 * @author Ahmed Ateeq <ahmedateeq@250mils.com>
 */

interface ToolTipProps {
  id: string;
  className?: string;
  position?: TOOLTIP_POSITION;
  text: ReactElement;
  type?: TOOLTIP_TYPE;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  style?: CSSProperties;
  hasRoundedArrow?: boolean;
}

export enum TOOLTIP_POSITION {
  TOP_MIDDLE = 'TOP_MIDDLE',
  TOP_RIGHT = 'TOP_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  BOTTOM_MIDDLE = 'BOTTOM_MIDDLE',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
}

export enum TOOLTIP_TYPE {
  DEFAULT = 'DEFAULT',
  MODERN = 'MODERN',
  DARK = 'DARK',
  ERROR = 'ERROR',
}

export function Tooltip({
  className = '',
  position = TOOLTIP_POSITION.BOTTOM_MIDDLE,
  type = TOOLTIP_TYPE.DEFAULT,
  onMouseEnter = $.noop,
  onMouseLeave = $.noop,
  style = {},
  hasRoundedArrow = true,
  ...props
}: ToolTipProps) {
  const getClassesForToolTipType = () => {
    switch (type) {
      case TOOLTIP_TYPE.MODERN:
        return '-modern';
      case TOOLTIP_TYPE.DARK:
        return '-darker';
      case TOOLTIP_TYPE.ERROR:
        return '-error-tooltip';
      case TOOLTIP_TYPE.DEFAULT:
        return '';
    }
  };

  const getClassesForPosition = () => {
    switch (position) {
      case TOOLTIP_POSITION.TOP_MIDDLE:
        return 'top';
      case TOOLTIP_POSITION.TOP_RIGHT:
        return 'top right';
      case TOOLTIP_POSITION.TOP_LEFT:
        return 'top left';
      case TOOLTIP_POSITION.BOTTOM_LEFT:
        return 'left';
      case TOOLTIP_POSITION.BOTTOM_RIGHT:
        return 'right';
      default:
        return '';
    }
  };

  return (
    <div
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`tooltip ${hasRoundedArrow ? '_rounded-arrow' : ''} ${getClassesForToolTipType()} ${getClassesForPosition()} ${className}`}
    >
      {props.text}
    </div>
  );
}
