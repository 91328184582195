import type {ReactElement} from 'react';
import React from 'react';
import {Calendar} from 'react-calendar';
import './calendar-date-picker.scss';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import type {CalendarBase} from '@Components/calendar-date-time-picker/calendar.types';
import {noop} from '@Utils/general.util';

/**
 * @author < Haseeb Chaudhry haseebch@250mils.com >
 *     React-Calendar library doc link: https://www.npmjs.com/package/react-calendar
 */
const CALENDAR_TYPE_US = 'US';

export enum MIN_LEVEL {
  MONTH = 'month',
  YEAR = 'year',
  DECADE = 'decade',
  CENTURY = 'century',
}

interface CalendarDatePickerProps extends CalendarBase {
  /**
   * Function which is triggered if a date is selected
   */
  onDateSelected?(value: Date): void;

  /**
   * This is the selected date of the calendar
   */
  selectedDate?: Date;

  onDateChange?(value: Date): void;
  /**
   * This is the minimum level of the calendar enabled for the user
   */
  minLevelToShow?: MIN_LEVEL;
}

export function CalendarDatePicker({
  onDateSelected = noop,
  selectedDate = new Date(),
  onDateChange = noop,
  minDate = new Date(),
  minLevelToShow = MIN_LEVEL.YEAR,
  className = '',
  showBoxShadow = true,
  isFullWidth = false,
}: CalendarDatePickerProps): ReactElement {
  const onChange = (value: Date): void => {
    onDateChange(value);
  };

  const onClickDayHandler = (value: Date): void => {
    onDateSelected(value);
  };

  const getShortFormatWeekday = (locale: any, date: {getDay: () => number}): string => {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()];
  };

  return (
    <Calendar
      value={selectedDate}
      calendarType={CALENDAR_TYPE_US}
      onChange={onChange}
      minDate={minDate}
      formatShortWeekday={getShortFormatWeekday}
      onClickDay={onClickDayHandler}
      nextLabel={<Icon icon="icon-caret-right" size={IconSize.SIZE_ICON_20} className="p-no-margin" />}
      prevLabel={<Icon icon="icon-caret-left" size={IconSize.SIZE_ICON_20} className="p-no-margin" />}
      minDetail={minLevelToShow}
      className={`${className} ${!showBoxShadow ? '-no-shadow' : ''} ${isFullWidth ? '-full-width' : ''}`}
    />
  );
}
