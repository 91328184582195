import React, {useRef} from "react";
import './brands-illustration.scss';
import {BrandsIllustrationMiddleCanvas} from "../brands-illustration-middle-canvas";
import {BrandsIllustrationFonts} from "../brands-illustration-fonts";
import {getUniqueString} from "../../../../utils/string.util";


/**
 * The SVG Illustration that previews how a one's brand may map onto a design based on the brand's colors, logos, and fonts
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
const BrandsIllustration = () => {
    /**
     * used to give unique ids to all the masks and filters
     * so that the SVG styles do not get disrupted if this component is rendered in the same DOM elsewhere
     */
    const uniqueStringForSvgIdsRef = useRef(getUniqueString()),
        uniqueStringForSvgIds = uniqueStringForSvgIdsRef.current;

    return (
        <div className={'brands-illustration-container'} >
            <svg className={'brands-illustration-svg js-brands-illustration'} width="100%" height="100%" viewBox="0 0 598 687" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter={`url(#filter0_d_1163_10865-${uniqueStringForSvgIds})`}>
                    <path d="M30.0447 18.1762C30.0447 14.0827 33.363 10.7644 37.4564 10.7644H538.175C545.907 10.7644 552.175 17.0324 552.175 24.7644V650.384C552.175 658.116 545.907 664.384 538.175 664.384H44.0447C36.3127 664.384 30.0447 658.116 30.0447 650.384V18.1762Z" fill="white"/>
                </g>
                <mask id={`mask0_1163_10865-${uniqueStringForSvgIds}`} style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="137" y="39" width="387" height="491">
                    <rect x="137.27" y="39.5883" width="386.066" height="490.267" rx="6.83311" fill="#C4C4C4"/>
                </mask>
                <BrandsIllustrationMiddleCanvas uniqueStringForSvgIds={uniqueStringForSvgIds} />
                <BrandsIllustrationFonts uniqueStringForSvgIds={uniqueStringForSvgIds} />
                <defs>
                    <filter id={`filter0_d_1163_10865-${uniqueStringForSvgIds}`} x="17.6917" y="0.0585284" width="555.071" height="686.56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="4.11764" dy="5.7647"/>
                        <feGaussianBlur stdDeviation="8.23529"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.219727 0 0 0 0 0.370823 0 0 0 0 0.49448 0 0 0 0.165182 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1163_10865"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1163_10865" result="shape"/>
                    </filter>
                    <filter id={`filter1_d_1163_10865-${uniqueStringForSvgIds}`} x="411.854" y="477.726" width="185.503" height="185.502" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="4.94117" dy="6.58823"/>
                        <feGaussianBlur stdDeviation="9.88235"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.219727 0 0 0 0 0.370823 0 0 0 0 0.49448 0 0 0 0.165182 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1163_10865"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1163_10865" result="shape"/>
                    </filter>
                    <filter id={`filter2_d_1163_10865-${uniqueStringForSvgIds}`} x="35.1068" y="544.173" width="183.333" height="121.75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="4.94117" dy="6.58823"/>
                        <feGaussianBlur stdDeviation="9.88235"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.219727 0 0 0 0 0.370823 0 0 0 0 0.49448 0 0 0 0.165182 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1163_10865"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1163_10865" result="shape"/>
                    </filter>
                    <filter id={`filter3_d_1163_10865-${uniqueStringForSvgIds}`} x="197.858" y="544.173" width="182.546" height="121.75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="4.94117" dy="6.58823"/>
                        <feGaussianBlur stdDeviation="9.88235"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.219727 0 0 0 0 0.370823 0 0 0 0 0.49448 0 0 0 0.165182 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1163_10865"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1163_10865" result="shape"/>
                    </filter>
                    <filter id={`filter4_d_1163_10865-${uniqueStringForSvgIds}`} x="0.396697" y="19.823" width="127.647" height="368.941" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="4.94117" dy="6.58823"/>
                        <feGaussianBlur stdDeviation="9.88235"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.219727 0 0 0 0 0.370823 0 0 0 0 0.49448 0 0 0 0.165182 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1163_10865"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1163_10865" result="shape"/>
                    </filter>
                    <filter id={`filter5_d_1163_10865-${uniqueStringForSvgIds}`} x="31.749" y="105.892" width="56.1078" height="56.2745" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4.11764"/>
                        <feGaussianBlur stdDeviation="3.70588"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.28464 0 0 0 0 0.339652 0 0 0 0 0.53943 0 0 0 0.178376 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1163_10865"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1163_10865" result="shape"/>
                    </filter>
                    <filter id={`filter6_d_1163_10865-${uniqueStringForSvgIds}`} x="31.749" y="224.849" width="56.1078" height="56.2745" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4.11764"/>
                        <feGaussianBlur stdDeviation="3.70588"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.28464 0 0 0 0 0.339652 0 0 0 0 0.53943 0 0 0 0.178376 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1163_10865"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1163_10865" result="shape"/>
                    </filter>
                    <filter id={`filter7_d_1163_10865-${uniqueStringForSvgIds}`} x="31.749" y="165.379" width="56.1078" height="56.2745" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4.11764"/>
                        <feGaussianBlur stdDeviation="3.70588"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.28464 0 0 0 0 0.339652 0 0 0 0 0.53943 0 0 0 0.178376 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1163_10865"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1163_10865" result="shape"/>
                    </filter>
                    <filter id={`filter8_d_1163_10865-${uniqueStringForSvgIds}`} x="31.749" y="284.353" width="56.1078" height="56.2745" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4.11764"/>
                        <feGaussianBlur stdDeviation="3.70588"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.28464 0 0 0 0 0.339652 0 0 0 0 0.53943 0 0 0 0.178376 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1163_10865"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1163_10865" result="shape"/>
                    </filter>
                    <filter id={`filter9_d_1163_10865-${uniqueStringForSvgIds}`} x="31.749" y="46.4052" width="56.1078" height="56.2745" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity={'0'} result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4.11764"/>
                        <feGaussianBlur stdDeviation="3.70588"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.28464 0 0 0 0 0.339652 0 0 0 0 0.53943 0 0 0 0.178376 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1163_10865"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1163_10865" result="shape"/>
                    </filter>
                </defs>
            </svg>


        </div>
    );
}

export default BrandsIllustration;
