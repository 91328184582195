import {createAsyncThunk} from "@reduxjs/toolkit";

export const initEmailMarketingCampaignPerformance = createAsyncThunk(
    'initEmailMarketingCampaignPerformance',
    async (data) => {
        return await PMW.readLocal('emailmarketing/initEmailMarketingCampaignPerformance', data);
    }
);

export const removeInvalidEmailsForCampaign = createAsyncThunk(
    'removeInvalidEmailsForCampaign',
    async (data) => {
        return await PMW.writeLocal('emailmarketing/removeInvalidEmailsForCampaign', data);
    }
);

export const removeInvalidEmailsForMailingList = createAsyncThunk(
    'removeInvalidEmailsForMailingList',
    async (data) => {
        return await PMW.writeLocal('emailmarketing/removeInvalidEmailsForMailingList', data);
    }
);