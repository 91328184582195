import type {ReactElement} from 'react';
import React, {useState} from 'react';
import type {RGB} from '@Utils/color.util';
import type {GridItemProps} from '@Components/base-grid/components/grid-item';
import type {MorphType} from '@Libraries/fancy-text-library';
import type {GridMediaItemStorage} from '../grid-media-item';
import {GridMediaItem} from '../grid-media-item';

export interface GridFancytextItemStorage extends GridMediaItemStorage {
  effect: string;
  font: string;
  colors: Array<RGB>;
  morphAmount: number;
  morphType: MorphType;
}

interface GridFancytextItemProps extends GridItemProps {
  thumbSrc: string;
  paddedThumb?: boolean;
}

export function GridFancytextItem({...props}: GridFancytextItemProps): ReactElement {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <GridMediaItem
      {...props}
      isImageLoaded={isImageLoaded}
      thumb={
        <img
          onLoad={(): void => {
            return setIsImageLoaded(true);
          }}
          src={props.thumbSrc}
          className="radius-4"
          alt={window.i18next.t('pmwjs_image')}
          draggable="false"
          loading="lazy"
        />
      }
    />
  );
}
