import React from 'react';
import './brand-section-loader.scss';
import {CircularProgressLoader} from "../../../circular-progress-loader";


/**
 * Displayed when the contents of a brand section are being fetched via AJAX
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 * @return {JSX.Element}
 * @constructor
 */
const BrandSectionLoader = () => {

    return (
        <div className={'brand-section-loader radius-4 flex-center '}>
            <CircularProgressLoader classes={'-no-margin '} />
        </div>
    );
}

export default BrandSectionLoader;