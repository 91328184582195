import React from 'react';
import ReactDom from 'react-dom';
import "./popup-modal.scss";
import {closeModal} from "./popup-modal-reducer";
import {connect} from "react-redux";
import PropTypes from 'prop-types';

/** A simple modal that supports any kind of children which are passed as a prop.
 * Note: if the children have a CTA, and you want the CTA to close the modal after clicking, then the cta should have
 * the popup-modal-cta class.
 */
class PopupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            animationClass: 'animate-modal-dialog-open',
            bodyElement: $('body')
        };

        this.modalHeaderRef = React.createRef();
        this.modalRef = React.createRef();
    }


    componentDidMount() {
        this.state.bodyElement.addClass('-open-modal');
    }

    setCloseAnimation = () => {
        this.setState({
            animationClass: 'animate-modal-dialog-close'
        });
    }

    render() {
        return ReactDom.createPortal(
            (<div className={`popup modal-popup open ${this.props.extraClasses}`} style={{zIndex: 100000}} onClick={this.#onModalClick}>
                <div className={"popup-modal-content " + this.state.animationClass} onAnimationEnd={this.#oncloseModal} ref={this.modalRef}>
                    <div className="popup-modal-header" ref={this.modalHeaderRef}>
                        <h2 className="title">{this.props.title}</h2>
                        <div className="close-popup-modal flex-center" onClick={this.#onCloseClick}>
                            <i className="icon icon-close"/>
                        </div>
                    </div>
                    <div className={'content'}>
                        {this.props.children}
                    </div>
                </div>
            </div>),
            document.body);
    }

    /**
     * handler for when the close button is clicked, triggers the closing animation for modal
     * @param {Event} event
     */
    #onCloseClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setCloseAnimation();
    }

    /**
     * callback that executes when modal animation ends
     * closes the modal if the close modal animation has executed
     */
    #oncloseModal = () => {
        const animationClass = this.state.animationClass;
        if (animationClass === 'animate-modal-dialog-close') {
            this.state.bodyElement.removeClass('-open-modal');
            this.props.closeModal();
            if (typeof this.props.afterCloseCleanup === 'function') {
                this.props.afterCloseCleanup();
            }

        }
    }

    /**
     * Event handler for when there is a click anywhere in the modal (including outside modal content)
     * if the click event is a modal closing event, then close the modal else do nothing
     * @param {Event} event
     */
    #onModalClick = (event) => {

        if (this.#isModalCloseEvent(event)) {
            this.setCloseAnimation();
        }
    }

    /**
     * Determines where click came from inside the modal body (main modal content underneath the header)
     * @param {Event} event
     * @return {boolean}
     */
    #isModalContentClicked = (event) => {
        return this.modalRef && (this.modalRef.current === event.target || this.modalRef.current.contains(event.target));
    }

    /**
     * determines whether the modal header was clicked or not
     * @param {Event} event
     * @return {boolean}
     */
    #isModalHeaderClicked = (event) => {
        return this.modalHeaderRef && (this.modalHeaderRef.current === event.target || this.modalHeaderRef.current.contains(event.target));
    }

    /**
     * determines whether the click event came from the CTA inside the modal
     * @param event
     * @return {boolean}
     */
    #isCtaClicked = (event) => {
        const clickedNode = $(event.target);
        return clickedNode.is('.popup-modal-cta') || clickedNode.parents('.popup-modal-cta').length;
    }

    /**
     * if either: The CTA (with class popup-modal-cta) has been clicked,
     *            The click did not come from the modal header
     *            The click came from within the modal content and the closeOnBodyClick prop is true
     *
     * if any of the above conditions is satisfied, then the given event is a modal closing event
     * @param {Event} event
     * @return {boolean}
     */
    #isModalCloseEvent = (event) => {
        const isModalContentClicked = this.#isModalContentClicked(event),
            isModalHeaderClicked = this.#isModalHeaderClicked(event),
            isCtaClicked = this.#isCtaClicked(event);

        return isCtaClicked || (this.props.closeOnBodyClick && !isModalHeaderClicked) || !isModalContentClicked;
    }


}


const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch(closeModal())
    }
}

PopupModal.propTypes = {
    /**
     *  the name of the modal, appears in modal header
     */
    title: PropTypes.string.isRequired,
    /**
     * the elements inside modal content
     */
    children: PropTypes.element.isRequired,

    /**
     * any extra classes to give to the popup modal root container
     */
    extraClasses: PropTypes.string,
    /**
     * global reducer function that closes the modal
     */
    closeModal: PropTypes.func,

    /**
     * Optional cleanup function to call once modal is closed.
     * Can be used to unmount component
     */
    afterCloseCleanup: PropTypes.func
};

PopupModal.defaultProps = {
    extraClasses: ''
}


export default connect(null, mapDispatchToProps)(PopupModal);