import React from "react";
import PropTypes from 'prop-types';
import './brand-font-grid-item.scss';
import {BRAND_ASSETS, getBrandFontPreviewImageUrl, getBrandName, getBrandsFromStore, hideAddOrUpdateFontLoading, showAddOrUpdateFontLoading} from "@Libraries/brands-library";
import {PopupMenuItem, PopupToggleButton} from "../../../popup-menu/components";
import {isClickFromPopup, PopupMenu} from "../../../popup-menu";
import {deleteBrandAsset} from "../../brands-thunk";
import {connect} from "react-redux";
import {executeThunk} from "@Utils/thunk.util";
import {PopupMenuItemIconSize, PopupMenuItemTypeInModal} from "@Components/popup-menu/components/popup-menu-item/popup-menu-item";

/**
 * Single grid item in the list inside the Brand Fonts section
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
class BrandFontGridItem extends React.Component {
    render() {
        return (
            <li className={'brand-grid-item -small brand-font-grid-item brand-asset -has-cursor -with-background flex-column-justify-center'} onClick={this.#onBrandFontItemClick} >
                <img className={'brand-font-preview hover-transition-all'} src={getBrandFontPreviewImageUrl(this.props.idbrandFont)} alt={this.#getFontPreviewAltText()} />
                <PopupMenu openModalOnPhone={true} modalTitle={i18next.t('pmwjs_font_options')} menuToggleButton={ <PopupToggleButton className={'border-s-dark floating-options-toggle -neutral -smaller-icon'} />}>
                    <PopupMenuItem itemClasses="-smaller-height radius-4 -more-margin-horizontal" textColorClasses="content-body" text={i18next.t('pmwjs_edit_item')} textClasses="spacing-m-l-2" iconClasses={'icon icon-pencil'} iconSize={PopupMenuItemIconSize.SMALL} clickHandler={this.#onEditBrandFont} horizontalAlignStart popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START} />
                    <PopupMenuItem itemClasses="-smaller-height -danger radius-4 -more-margin-horizontal" textColorClasses="content-body" text={i18next.t('pmwjs_delete')} textClasses="spacing-m-l-2" iconClasses={'icon icon-delete'} iconSize={PopupMenuItemIconSize.SMALL} clickHandler={this.#onDelete} horizontalAlignStart popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START} />
                </PopupMenu>
            </li>
        );
    }

    /**
     *
     * @return {string}
     */
    #getFontPreviewAltText = () => {
        const brandName = getBrandName(this.props.brandId, getBrandsFromStore());
        return i18next.t('pmwjs_brand_font_in_x_brand_with_y_font_family', {brandName: brandName, fontFamily: this.props.fontFamily});
    }

    /**
     *
     * @param {Event} e
     */
    #onBrandFontItemClick = (e) => {
        if (!isClickFromPopup(e)) {
            this.#onEditBrandFont();
        }
    }

    #onEditBrandFont = () => {
        PMW.openAddBrandFontModal({
            brandId: this.props.brandId,
            brandFontInfo: this.props,
            beforeBrandFontAddedOrUpdated: showAddOrUpdateFontLoading.bind(null, i18next.t('pmwjs_saving')),
            onBrandFontAddedOrUpdated: hideAddOrUpdateFontLoading,
            onBrandFontAddedOrUpdatedFailure: hideAddOrUpdateFontLoading
        })
    }

    #onDelete = () => {
        PMW.openConfirmDeleteModal({
            title: i18next.t('pmwjs_are_you_sure'),
            text: i18next.t('pmwjs_delete_brand_asset_confirmation_message', {
                brandAsset: i18next.t('pmwjs_font'),
                name: this.props.fontName
            }),
            onDeleteConfirmation: this.#deleteBrandFont
        });
    }

    #deleteBrandFont = async () => {
        await executeThunk(() => this.props.deleteBrandFont(this.props.idbrandFont, this.props.brandId));
    }
}

BrandFontGridItem.propTypes = {
    /**
     * the id of the brand
     */
    brandId: PropTypes.string.isRequired,
    /**
     * the ID of the brand font item
     */
    idbrandFont: PropTypes.number.isRequired,
    /**
     * the font family this font item belongs to, will be shown in preview
     */
    fontFamily: PropTypes.string.isRequired,
    /**
     * the default color to be applied to this font
     */
    defaultColorCode: PropTypes.string.isRequired,
    /**
     * boolean determining whether this is a PMW font or some other font
     */
    isInternalFont: PropTypes.bool.isRequired,

    /**
     * the ID of the underlying font
     */
    idfont: PropTypes.number,

    /**
     * the name assigned to the brand font
     */
    fontName: PropTypes.string
}

const mapFontGridItemDispatchToProps = (dispatch) => {
    return {
        deleteBrandFont: (idbrandFont, brandId) => dispatch(deleteBrandAsset({brandAssetType: BRAND_ASSETS.BRAND_FONTS, idbrandAsset: idbrandFont, brandId: brandId}))
    }
}

export default connect(null, mapFontGridItemDispatchToProps)(BrandFontGridItem);
