import {useEffect} from 'react';
import {hidePanel, initPanel, removePanel, triggerPanelClose} from '@Components/panel/panel-reducer';
import {noop} from '@Utils/general.util';
import {useAppDispatch, useAppSelector} from '@/hooks';

const useCustomPanelClose = (panelId: string, onClose: Function, hideOnClose = false, onHide = noop) => {
  const dispatch = useAppDispatch();
  const startClosing = useAppSelector((state) => {
    return state.panels.panelHashmap[panelId]?.startClosing ?? false;
  });
  const isHidden = useAppSelector((state) => {
    return state.panels.panelHashmap[panelId]?.isHidden ?? false;
  });

  useEffect(() => {
    dispatch(initPanel({id: panelId, hideOnClose}));

    return (): void => {
      dispatch(removePanel(panelId));
    };
  }, []);

  useEffect(() => {
    if (startClosing) {
      onClose();
    }
  }, [startClosing]);

  useEffect(() => {
    if (isHidden) {
      onHide();
    }
  }, [isHidden]);

  return (): void => {
    if (hideOnClose) {
      dispatch(hidePanel(panelId));
    } else {
      dispatch(triggerPanelClose(panelId));
    }
  };
};

export default useCustomPanelClose;
