import type {ReactElement} from 'react';
import React, { useState} from 'react';
import {Text, TextSize} from '@Components/text';
import {ForgotPasswordForm} from '@Components/forgot-password-form';
import styles from './connect-account-reset-password-form.module.scss';

export function ConnectAccountResetPasswordForm(): ReactElement {
  const [showEmailPrompt, setShowEmailPrompt] = useState(true);

  const onForgotPasswordSubmit = (): void => {
    setShowEmailPrompt(false);
  };

  return (
    <div className="spacing-m-t-10 spacing-m-b-17 spacing-m-r-14 spacing-m-l-14">
      {showEmailPrompt ? (
        <div className="spacing-m-b-2">
          <Text val={window.i18next.t('pmwjs_enter_email_to_reset_password')} size={TextSize.SMALL} className="content-body" />
        </div>
      ) : null}
      <ForgotPasswordForm onSubmitCallback={onForgotPasswordSubmit} />
    </div>
  );
}
