import type {CSSProperties, ReactElement} from 'react';
import React from 'react';
import type {GRID_ITEM_TYPE, GridItemBaseStorage} from '@Components/base-grid/components/grid-item';
import styles from './grid-skeleton-item.module.scss';

const ITEM_DEFAULT_HEIGHT = 110;

export interface GridSkeletonItemStorage extends GridItemBaseStorage {
  type: GRID_ITEM_TYPE.SKELETON;
  width: number;
  height: number;
  useFixedDimensions?: boolean;
}

interface GridSkeletonItemProps {
  key: string;
  styles: CSSProperties;
  width: number;
  height: number;
  useFixedDimensions?: boolean;
}
export function GridSkeletonItem({useFixedDimensions = false, ...props}: GridSkeletonItemProps): ReactElement {
  return (
    <li
      key={props.key}
      style={useFixedDimensions ? {...props.styles, width: `${props.width}px`, height: `${props.height}px`} : props.styles}
      className={`radius-4 ${styles.skeletonItem}`}
    >
      <div style={{height: ITEM_DEFAULT_HEIGHT}} />
    </li>
  );
}
