import type {ReactElement} from 'react';
import React, {useRef} from 'react';
import {PANEL_SMALL_BREAKING_POINT} from '@Components/panel/panel.types';
import type {ContainerQueryObject} from '@Hooks/useContainerQuery';
import {useContainerQuery} from '@Hooks/useContainerQuery';
import type {PanelBottomPill} from '@Components/panel/components/panel-bottom-pills';
import {PanelBottomPills} from '@Components/panel/components/panel-bottom-pills';
import styles from './panel-footer.module.scss';
import type {PanelFooterPrimaryActionType} from '../panel-footer-primary-actions';
import {PanelFooterPrimaryActions} from '../panel-footer-primary-actions';
import type {PanelFooterSecondaryActionType} from '../panel-footer-secondary-actions';
import {PanelFooterSecondaryActions} from '../panel-footer-secondary-actions';

export interface PanelFooterProps {
  className?: string;
  info?: string;
  infoPosition?: FooterInfoPosition;
  border?: boolean;
  fullWidthPrimaryActions?: boolean;
  actionsContainerClassName?: string;
  primaryActionsClassName?: string;
  secondaryActionsClassName?: string;
  primaryActions?: Array<PanelFooterPrimaryActionType>;
  secondaryActions?: Array<PanelFooterSecondaryActionType>;
  bottomPills?: Array<PanelBottomPill>;
  isScrollable?: boolean;
  hasFullWidthActions?: boolean;
  spacingBetweenActionsClassname?: string;
}

enum FooterInfoPosition {
  LEFT = 'flex-vertical-start',
  RIGHT = 'flex-vertical-end',
  CENTER = 'flex-vertical-center',
}

const containerQuery: ContainerQueryObject = {
  small: {
    minWidth: 0,
    maxWidth: PANEL_SMALL_BREAKING_POINT,
  },
  large: {
    minWidth: PANEL_SMALL_BREAKING_POINT + 1,
  },
};

export function PanelFooter({
  className = '',
  info = '',
  infoPosition = FooterInfoPosition.LEFT,
  primaryActionsClassName = '',
  secondaryActionsClassName = '',
  fullWidthPrimaryActions = false,
  actionsContainerClassName = '',
  border = !fullWidthPrimaryActions,
  primaryActions = [],
  secondaryActions = [],
  bottomPills = [],
  isScrollable = false,
  hasFullWidthActions = false,
  spacingBetweenActionsClassname = 'spacing-m-t-3',
}: PanelFooterProps): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const params = useContainerQuery(containerRef, containerQuery);

  const getInfoContainer = (): ReactElement => {
    return <div className={`body-xs-italic spacing-p-t-2 ${infoPosition}`}>{info}</div>;
  };

  const getPrimaryAction = (): ReactElement => {
    return (
      <PanelFooterPrimaryActions className={`${primaryActionsClassName} ${styles.actions}`} fullWidth={fullWidthPrimaryActions || hasFullWidthActions} actions={primaryActions} />
    );
  };

  const getSecondaryAction = (): ReactElement => {
    return <PanelFooterSecondaryActions className={`${secondaryActionsClassName} ${styles.actions}`} fullWidth={hasFullWidthActions} actions={secondaryActions} />;
  };

  const getPanelActions = (): ReactElement | null => {
    if (!hasFullWidthActions) {
      return (
        <>
          {fullWidthPrimaryActions ? null : getSecondaryAction()}
          {getPrimaryAction()}
        </>
      );
    }

    return (
      <div className="flex-v-row _full-width">
        {getSecondaryAction()}
        <div className={spacingBetweenActionsClassname} />
        {getPrimaryAction()}
      </div>
    );
  };

  return (
    <div ref={containerRef} className={`flex-center flex-v-row ${className} ${styles.footer} ${border ? styles.footerBorder : ''} ${params.small ? `${styles.small}` : ''}`}>
      {primaryActions?.length || secondaryActions?.length ? (
        <div className={`flex-justify-between ${styles.actionsContainer} ${params.small ? 'spacing-p-3' : ''} ${actionsContainerClassName}`}>{getPanelActions()}</div>
      ) : null}
      {bottomPills.length ? (
        <PanelBottomPills className={primaryActions?.length || secondaryActions?.length ? styles.footerBorder : ''} pills={bottomPills} isScrollable={isScrollable} />
      ) : null}
      {info !== '' ? getInfoContainer() : null}
    </div>
  );
}
