import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {RGB} from '@Utils/color.util';
import {rgbToHexString} from '@Utils/color.util';
import type * as Fabric from '@postermywall/fabricjs-2';
import {loadImageAsync, removeAlmostTranparentPixels} from '@Utils/image.util';
import type {Item} from '@PosterWhiteboard/items/item/item.class';
import {roundPrecision} from '@/utils/math.util';

export enum BorderType {
  NONE = 0,
  RECTANGLE_BORDER = 1,
  ROUNDED_BORDER = 2,
  STROKE_BORDER = 3,
}

export interface BorderData {
  stroke?: Fabric.TFiller | string;
  strokeWidth?: number;
  strokeLineJoin?: CanvasLineJoin;
  strokeUniform?: boolean;
}

export interface ItemBorderObject {
  solidBorderType: BorderType;
  solidBorderColor: RGB;
  solidBorderThickness: number;
}

export class ItemBorder extends CommonMethods {
  public item: Item;
  public solidBorderType = BorderType.NONE;
  public solidBorderColor: RGB = [0, 0, 0];
  public solidBorderThickness = 4;

  constructor(item: Item) {
    super();
    this.item = item;
  }

  public toObject(): ItemBorderObject {
    return {
      solidBorderType: this.solidBorderType,
      solidBorderColor: this.solidBorderColor,
      solidBorderThickness: this.solidBorderThickness,
    };
  }

  public getBorder(): BorderData {
    // Stroke border is applied on image element before its used in fabric image item
    if (!this.hasStrokeBorderType()) {
      return {
        stroke: rgbToHexString(this.solidBorderColor),
        strokeWidth: this.solidBorderType === BorderType.NONE ? 0 : this.solidBorderThickness,
        strokeLineJoin: this.solidBorderType === BorderType.ROUNDED_BORDER ? 'round' : 'miter',
        strokeUniform: true,
      };
    }

    return {};
  }

  public hasStrokeBorderType(): boolean {
    return this.solidBorderType === BorderType.STROKE_BORDER;
  }

  public hasBorder(): boolean {
    return this.solidBorderType !== BorderType.NONE;
  }

  /**
   * Stroke border is applied before an item is intialized as it needs to change the underlying image to add outline to it
   * Thus instead of using this.imageElement this function accepts img as param as image has not yet being intiialzied in the item
   */
  public async applyBorderBeforeInitToImageElement(img: HTMLImageElement, effectsScale = 1): Promise<HTMLImageElement> {
    if (this.solidBorderType === BorderType.STROKE_BORDER) {
      return this.applyStrokeBorderForImage(img, effectsScale);
    }
    return img;
  }

  private async applyStrokeBorderForImage(img: HTMLImageElement, effectsScale: number): Promise<HTMLImageElement> {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error(`Failed to get context of canvas`);
    }

    const borderThickness = this.solidBorderThickness * effectsScale;
    const offsetArr = [
      -0.5, -0.5, -0.85, -0.5, -0.95, -0.25, -1, 0, -0.95, 0.25, -0.85, 0.5, -0.5, 0.5, 0.5, -0.5, -0.5, -0.85, -0.25, -0.95, 0.5, -0.85, 0.25, -0.95, 0, -1, -0.25, -0.95, -0.5,
      -0.85, 0.5, 0.5, 0.85, -0.5, 0.95, -0.25, 1, 0, 0.95, 0.25, 0.85, 0.5, 0, 1, -0.25, 0.95, -0.5, 0.85, 0.25, 0.95, 0.5, 0.85, -0.65, -0.75, 0.65, -0.75, 0.65, 0.75, -0.65,
      0.75,
    ];
    const ratio = roundPrecision(img.width / img.height, 3);
    let width;
    let height;

    if (img.width < img.height) {
      width = img.width + borderThickness;
      height = width / ratio;
    } else {
      height = img.height + borderThickness;
      width = height * ratio;
    }
    canvas.width = roundPrecision(width, 3);
    canvas.height = roundPrecision(height, 3);
    const reformatedImage = await removeAlmostTranparentPixels(img);

    // draw images at offsets from the array scaled by strokeThickness
    for (let i = 0; i < offsetArr.length; i += 2) {
      ctx.drawImage(reformatedImage, offsetArr[i] * (borderThickness / 2) + borderThickness / 2, offsetArr[i + 1] * (borderThickness / 2) + borderThickness / 2);
    }

    ctx.globalCompositeOperation = 'source-in';
    ctx.fillStyle = rgbToHexString(this.solidBorderColor);
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // draw original image in normal mode
    ctx.globalCompositeOperation = 'source-over';
    ctx.drawImage(img, borderThickness / 2, borderThickness / 2);
    return loadImageAsync(canvas.toDataURL('image/png'));
  }

  public updateBorderColor(color: RGB, undoable = true): void {
    void this.item.updateFromObject(
      {
        border: {
          solidBorderColor: color,
        },
      },
      {
        undoable,
      }
    );
  }

  public updateBorderThickness(updatedValue: number, undoable = true): void {
    void this.item.updateFromObject(
      {
        border: {
          solidBorderThickness: updatedValue,
        },
      },
      {
        undoable,
      }
    );
  }
}
