import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {PanelMessageProp} from "@Components/panel/panel.types";

interface StateProps {
  isBackgroundRemoved: boolean;
  showLoading: boolean;
  panelMessage?: PanelMessageProp;
}

const initialState: StateProps = {
  isBackgroundRemoved: false,
  showLoading: true,
  panelMessage: undefined,
};

export const cropperPanelSlice = createSlice({
  name: 'trimPanel',
  initialState,
  reducers: {
    setIsBackgroundRemoved(state, action: PayloadAction<boolean>) {
      state.isBackgroundRemoved = action.payload;
    },
    setShowLoading(state, action: PayloadAction<boolean>) {
      state.showLoading = action.payload;
    },
    setPanelMessage(state, action: PayloadAction<PanelMessageProp | undefined>) {
      state.panelMessage = action.payload
    }
  },
});

export const {setIsBackgroundRemoved, setShowLoading, setPanelMessage} = cropperPanelSlice.actions;
export const copperPanelReducer = cropperPanelSlice.reducer;
