import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

export interface StateProps {
  [hashedFilename: string]: number;
}

const initialState: StateProps = {};

interface ImageCreditsData {
  imageKey: string;
  credits: number;
}

const imageBgRemovalCreditsCostSlice = createSlice({
  name: 'imageBgRemovalCreditsCostReact',
  initialState,
  reducers: {
    setImageBgRemovalCreditsCost: (state: StateProps, action: PayloadAction<ImageCreditsData>) => {
      state[action.payload.imageKey] = action.payload.credits;
    },
  },
});

export const {setImageBgRemovalCreditsCost} = imageBgRemovalCreditsCostSlice.actions;
export const imageBgRemovalCreditsCostReducer = imageBgRemovalCreditsCostSlice.reducer;
