import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  showRemoveMask: boolean;
}

const initialState: StateProps = {
  showRemoveMask: false,
};

const maskingSlice = createSlice({
  name: 'masking',
  initialState,
  reducers: {
    setShowRemoveMask(state, action: PayloadAction<boolean>) {
      state.showRemoveMask = action.payload;
    },
  },
});

export const {setShowRemoveMask} = maskingSlice.actions;
export const maskingReducer = maskingSlice.reducer;
