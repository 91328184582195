import {
    addPhysicalAddress,
    createSenderProfile,
    deleteSenderProfile,
    initEmailMarketingCampaignSettings,
    updateDefaultSenderProfile,
    updatePhysicalAddress,
    updateSenderProfile,
    isEmailVerifiedForSenderProfile,
    checkEmailVerificationForSenderProfile
} from "./email-marketing-campaign-settings-thunk.js";
import {LOADING_STATES} from "@Libraries/email-marketing-campaigns-library.js";

export const emailMarketingCampaignSettingsExtraReducers = {
    [initEmailMarketingCampaignSettings.pending]: (state) => {
        state.settingsLoadingState = LOADING_STATES.LOADING;
    },

    [initEmailMarketingCampaignSettings.fulfilled]: (state, {payload}) => {
        state.settingsLoadingState = LOADING_STATES.LOADED;
        state.senderProfiles = payload.senderProfiles;
        state.emailSends = payload.emailSends;
        state.credits = payload.credits;
        state.defaultSenderProfileId = payload.defaultSenderProfileId;
        state.isCustomDomainEnabledForPremiumPlus = payload.isCustomDomainEnabledForPremiumPlus;
    },

    [deleteSenderProfile.fulfilled]: (state, {payload}) => {
        if (payload.newDefaultSenderProfileId !== undefined) {
            state.senderProfiles[payload.newDefaultSenderProfileId].isDefault = true;
            state.defaultSenderProfileId = payload.newDefaultSenderProfileId;
        }
        delete state.senderProfiles[payload.deletedSenderProfileId];
    },

    [updateSenderProfile.fulfilled]: (state, {payload}) => {
        state.senderProfiles[payload.id] = payload;
    },

    [updateDefaultSenderProfile.fulfilled]: (state, {payload}) => {
        state.senderProfiles[payload.previousDefaultSenderProfileId].isDefault = false;
        state.senderProfiles[payload.newDefaultSenderProfileId].isDefault = true;
        state.defaultSenderProfileId = payload.newDefaultSenderProfileId;
    },

    [createSenderProfile.fulfilled]: (state, {payload}) => {
        state.senderProfiles[payload.id] = payload;
    },

    [updatePhysicalAddress.fulfilled]: (state, {payload}) => {
        state.physicalAddress = payload.address;
    },

    [addPhysicalAddress.fulfilled]: (state, {payload}) => {
        state.physicalAddress = payload.address;
    },

    [isEmailVerifiedForSenderProfile.fulfilled]: (state, {payload}) => {
        if (payload.isVerified) {
            state.senderProfiles[payload.idSenderProfile].isVerified = true;
        }
    },

    [checkEmailVerificationForSenderProfile.fulfilled]: (state, {payload}) => {
        if (payload.isAvailable) {
            state.senderProfiles[payload.data.idSenderProfile].isVerified = true;
            if (!state.defaultSenderProfileId) {
                state.senderProfiles[payload.data.idSenderProfile].isDefault = true;
                state.defaultSenderProfileId = parseInt(payload.data.idSenderProfile);
            }
        }
    }
};
