import {useEffect, useMemo, useState} from 'react';

export function useIsInViewport(ref: HTMLElement): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => {
    return new IntersectionObserver(([entry]) => {
      return setIsIntersecting(entry.isIntersecting);
    });
  }, []);

  useEffect(() => {
    if (ref) {
      observer.observe(ref);
    }

    return (): void => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
