import React from "react";
import './video-preview.scss';
import PropTypes from "prop-types";
import {playVideo} from "@Utils/video.util";
import {numberToMMSSFormat} from "@Utils/date.util";
import {GridMediaItemTagType} from "@Components/base-grid/components/grid-media-item";
import {CostTag} from "@Components/cost-tag";
import {SourceTag} from "@Components/source-tag";

/**
 * @class VideoPreview
 */
export default class VideoPreview extends React.Component {
    #videoElement = null;

    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            videoPreviewReady: true,
            onMouseOver: false,
            loadingVideo: false,
            muted: true,
            isVideoPlaying: false,
            seekTime: props.duration
        };
    }

    /**
     * @returns {JSX.Element|null}
     */
    render() {
        return <div className={"video-preview " + this.props.className} onMouseEnter={this.#onMouseEnter} onMouseLeave={this.#onMouseLeave}>
            <img className="img-thumb" src={this.props.imgPlaceHolderSrc} alt={i18next.t('pmwjs_image')} draggable="false" loading="lazy"/>
            <video className={"video " + (this.state.isVideoPlaying ? '' : '_hidden')}
                   onTimeUpdate={this.#handleSeekTimeUpdate}
                   onPlay={this.#onPlay}
                   onPause={this.#onPause}
                   onError={this.#onPlayError}
                   onAbort={this.#onPlayError}
                   onWaiting={this.#showLoading}
                   onPlaying={this.#hideLoading}
                   onCanPlayThrough={this.#hideLoading}
                   crossOrigin="anonymous"
                   draggable="false"
                   muted={this.state.muted}
                   loop
                   playsInline
                   src={this.props.videoSrc}
                   preload="none"
                   onContextMenu={(e) => {
                       return e.preventDefault();
                   }}
                   ref={el => {
                       this.#videoElement = el
                   }}
            />
            {this.props.showUnmute && <i className={"mute-video-button icon-sound " + (this.state.muted ? '-muted' : '')} title={i18next.t((this.state.muted ? 'pmwjs_unmute' : 'pmwjs_unmute'))} onClick={this.#toggleMute}/>}
            {!this.state.videoPreviewReady && this.state.onMouseOver && <div className="preview-not-ready body-xs-bold radius-4">{i18next.t('pmwjs_preview_not_ready')}</div>}
            {this.state.seekTime !== undefined && this.#getDurationPill()}
            {this.props.tag ? this.#getTag() : null}
        </div>
    }

    #getDurationPill = () => {
        return <span className={`stream-media-length ${this.props.isSmallItem ? 'body-xxs-bold' : 'body-s-bold'} ${this.state.loadingVideo ? '-blink' : ''}`}>{this.props.showUHDIcon ? `${numberToMMSSFormat(this.state.seekTime)} · ${i18next.t('pmwjs_4k')}` : numberToMMSSFormat(this.state.seekTime)}</span>;
    }

    #toggleMute = () => {
        this.setState(state => ({
            muted: !state.muted
        }));
    }
    #showLoading = () => {
        this.setState({
            loadingVideo: true
        })
    }
    #hideLoading = () => {
        this.setState({
            loadingVideo: false
        })
    }

    #onPlay = () => {
        this.setState({
            isVideoPlaying: true
        })
    }
    #onPause = () => {
        this.setState({
            isVideoPlaying: false
        })
    }
    #onPlayError = async () => {
        this.setState({
            isVideoPlaying: false,
            videoPreviewReady: false,
            loadingVideo: false
        })
    }
    #onMouseEnter = async () => {
        this.setState({
            onMouseOver: true
        })
        if (this.state.videoPreviewReady) {
            await playVideo(this.#videoElement);
        }
    }

    #onMouseLeave = () => {
        this.setState({
            onMouseOver: false,
            seekTime: this.props.duration
        });
        this.#videoElement.pause();
        this.#videoElement.currentTime = this.props.duration;
    }

    #getTag = () => {
        if (this.props.tag) {
            return (
                <div className={"tagContainer _full-width _full-height"}>
                    {this.props.tag?.type === GridMediaItemTagType.COST ? (
                        <CostTag containerStyle={this.props.tag.containerStyle} textStyle={this.props.tag.textStyle} icon={this.props.tag.icon} price={this.props.tag.price} />
                    ) : (
                        <SourceTag containerStyle={this.props.tag?.containerStyle} contentStyle={this.props.tag?.iconStyle} />
                    )}
                </div>
            );
        }
        return null;
    };

    #handleSeekTimeUpdate = () => {
        if (this.#videoElement && this.#videoElement.currentTime) {
            this.setState(
              {
                  seekTime: Math.floor(this.#videoElement.currentTime)
              }
            )
        }
    };
}

VideoPreview.propTypes = {
    imgPlaceHolderSrc: PropTypes.string.isRequired,
    videoSrc: PropTypes.string,
    className: PropTypes.string,
    duration: PropTypes.number,
    showUHDIcon: PropTypes.bool,
    muted: PropTypes.bool,
    loadingIcon: PropTypes.string,
    isSmallItem: PropTypes.bool,
    tag: PropTypes.any
};

VideoPreview.defaultProps = {
    showUHDIcon: false,
    className: '',
    LoadingIcon: 'icon-videocam',
    showUnmute: false,
    isSmallItem: false,
    tag: undefined
}
