import type {ReactElement} from 'react';
import React, { useEffect, useRef, useState} from 'react';
import './hex-picker.scss';
import {HexColorPicker} from 'react-colorful';

interface HexPickerProps {
  className?: string;
  color: string;

  onChange(color: string): void;

  onChangeEnded(color: string): void;
}

export function HexPicker({className = '', ...props}: HexPickerProps): ReactElement {
  const [isPointerDown, setIsPointerDown] = useState(false);
  const colorRef = useRef(props.color);

  const onMouseUp = (): void => {
    props.onChangeEnded(colorRef.current);
    window.removeEventListener('pointerup', onMouseUp);
    setIsPointerDown(false);
  };

  useEffect(() => {
    if (isPointerDown) {
      window.addEventListener('pointerup', onMouseUp);
    }
    return () => {
      window.removeEventListener('pointerup', onMouseUp);
    };
  }, [isPointerDown]);

  return (
    <div className={`${className} saturation`}>
      <HexColorPicker
        color={props.color}
        onChange={(value: string): void => {
          props.onChange(value);
          colorRef.current = value;
        }}
        onPointerDown={(): void => {
          setIsPointerDown(true);
        }}
      />
    </div>
  );
}
