import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isPopupModalOpen: false,
    popupModalType: ''
};

const popupModalSlice = createSlice({
    name: 'popupModal',
    initialState,
    reducers: {
        openModal: (state, action) => {
            state.isPopupModalOpen = true;
            state.popupModalType = action.payload;
        },
        closeModal: (state) => {
            state.isPopupModalOpen = false;
            state.popupModalType = '';
        }
    }
})

export const mapDispatchToProps = dispatch => {
    return {
        openModal: modalType => dispatch(openModal(modalType))
    }
}

export const mapStateToProps = state => {
    return {
        isPopupModalOpen: state.popupModal.isPopupModalOpen,
        popupModalType: state.popupModal.popupModalType
    }


}

export const {openModal, closeModal} = popupModalSlice.actions;
export const popupModalReducer =  popupModalSlice.reducer;
