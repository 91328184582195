import type {ReactElement} from 'react';
import React, {useState} from 'react';
import {AuthInputErrorNotification} from '@Components/auth-input/components/auth-input-error-notification';
import {PASSWORD_STATUS} from '@Components/signup-form/signup-form.types';
import {IconSize, IconType} from '@Components/icon-v2/icon.types';
import type {VoidFunction} from '@Components/login-page/login-page.types';
import {noop} from '@Utils/general.util';
import {INPUT_FIELD_TYPE, InputField} from '../input-field';
import styles from './auth-input.module.scss';

type HandleInputType = ((txt: string) => void) | VoidFunction;

interface AuthInputProps {
  label: string;
  placeholder: string;
  type: INPUT_FIELD_TYPE;
  onInputSubmit: VoidFunction;
  handleInput: HandleInputType;
  showError?: boolean;
  onPasswordIconEyeClick?: VoidFunction;
  passwordFieldIcon?: string;
  isInputInvalid?: boolean;
  errorText?: string;
  onFocusCallback?: VoidFunction;
  onFocusOutCallback?: VoidFunction;
  passwordStatus?: PASSWORD_STATUS;
  disabled?: boolean;
  enableFocusStyles?: boolean;
  isLabelResponsive?: boolean;
  id?: string;
}

function AuthInput({
  label,
  placeholder,
  type,
  handleInput,
  onInputSubmit,
  showError = false,
  onPasswordIconEyeClick = noop,
  passwordFieldIcon = '',
  errorText = '',
  onFocusCallback = noop,
  onFocusOutCallback = noop,
  passwordStatus = PASSWORD_STATUS.DEFAULT,
  disabled = false,
  enableFocusStyles = false,
  isLabelResponsive = true,
  ...props
}: AuthInputProps): ReactElement {
  const [hasFocus, setHasFocus] = useState(false);

  const getInputFieldClass = (): string => {
    if (type === INPUT_FIELD_TYPE.EMAIL) {
      return styles.emailField;
    }

    if (type === INPUT_FIELD_TYPE.PASSWORD) {
      return styles.passwordField;
    }
    return '';
  };

  const getClassNames = (): string => {
    let borderClass = 'border-s-dark';

    if (type === INPUT_FIELD_TYPE.PASSWORD) {
      switch (passwordStatus) {
        case PASSWORD_STATUS.SUCCESS:
          borderClass = styles.borderSuccess;
          break;
        case PASSWORD_STATUS.ERROR:
          borderClass = styles.borderDanger;
          break;
        default:
          borderClass = 'border-s-dark';
          break;
      }
    }

    if (showError) {
      borderClass = styles.borderDanger;
    }

    if (enableFocusStyles && hasFocus) {
      borderClass = styles.borderFocus;
    }

    return `content-body-white ${borderClass} ${styles.inputField} ${getInputFieldClass()} ${isLabelResponsive ? '' : 'spacing-m-t-1'}`;
  };

  const onInputChange = (txt: string): void => {
    handleInput(txt);
  };

  const onFocus = (): void => {
    setHasFocus(true);
  };

  const onBlur = (): void => {
    setHasFocus(false);
  };

  const onAuthInputSubmit = (): void => {
    if (enableFocusStyles) {
      const passwordFieldContainer = document.getElementById(props.id ? props.id : '');
      if (passwordFieldContainer) {
        const inputField = passwordFieldContainer.querySelector('input');
        if (inputField) {
          inputField.blur();
        }
      }
    }
    onInputSubmit();
  };

  return (
    <div className={styles.authInputContainer}>
      <label htmlFor={props.id} className={`content-body body-xs-bold ${isLabelResponsive ? styles.label : ''}`}>
        {label}
      </label>

      <div onFocus={onFocusCallback} onBlur={onFocusOutCallback} className={`flexbox ${styles.inputFieldContainer}`}>
        <InputField
          id={props.id}
          placeholder={placeholder}
          className={`${getClassNames()} spacing-p-0 ${styles.iconContainer}`}
          type={type}
          onInputChange={onInputChange}
          onInputIconClick={onPasswordIconEyeClick}
          inputIconSize={IconSize.SIZE_ICON_20}
          inputIconExtraClass={styles.icon}
          showInputIcon={type !== INPUT_FIELD_TYPE.EMAIL}
          aria-label={label}
          inputIconName={passwordFieldIcon}
          inputClassName="spacing-p-3"
          onInputSubmit={onAuthInputSubmit}
          inputIconType={IconType.NONE}
          disabled={disabled}
          onInputFocus={onFocus}
          onInputBlur={onBlur}
        />
      </div>

      {showError ? <AuthInputErrorNotification errorText={errorText} /> : null}
    </div>
  );
}

export default React.memo(AuthInput);
