import type {ReactElement} from 'react';
import React, {useState} from 'react';
import {InputField} from '@Components/input-field-v2';
import {InputControlItemType} from '@Components/input-field-v2/input-field.types';
import {Tooltip} from '@Components/tooltip';
import {Text, TextSize} from '@Components/text';
import {hasAlphaNumericsOnly} from '@Utils/string.util';
import {copyToClipboard} from '@Utils/clipboard.util';
import styles from './picker-input-field.module.scss';

interface PickerInputFieldProps {
  color: string;

  onChangeColor(color: string): void;

  onChangeEnded(color: string): void;

  tooltipClasses?: string;
  isMobileVersion?: boolean;
}

export function PickerInputField({tooltipClasses = '', isMobileVersion = false, ...props}: PickerInputFieldProps): ReactElement {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isColorChanging, setIsColorChanging] = useState(false);
  const [colorDuringChange, setColorDuringChange] = useState(props.color.slice(1).toUpperCase());

  const parseColorForInputField = (value: string): string => {
    if (isFirstCharacterHash(value)) {
      return value.slice(1).toUpperCase();
    }

    return value.toUpperCase();
  };

  const isFirstCharacterHash = (value: string): boolean => {
    return value.length > 0 && value[0] === '#';
  };

  const onClickCopy = async (): Promise<void> => {
    await copyToClipboard(parseColorForInputField(props.color).toUpperCase());
  };

  const showToolTip = (): void => {
    setShowTooltip(true);
    onClickCopy().finally(() => {
      setTimeout(() => {
        setShowTooltip(false);
      }, 1000);
    });
  };

  const isValidInput = (value: string): boolean => {
    if (value.length > 6) {
      return false;
    }
    return hasAlphaNumericsOnly(value);
  };

  const onChangeHandler = (value: string): void => {
    if (!isValidInput(value)) {
      return;
    }
    if (!isColorChanging) {
      setIsColorChanging(true);
    }
    setColorDuringChange(value);
    props.onChangeColor(value.toUpperCase());
  };

  const onChangeEnded = (value: string): void => {
    if (!isValidInput(value)) {
      return;
    }
    setColorDuringChange(value.toUpperCase());
    props.onChangeEnded(value.toUpperCase());
  };

  const getInputFieldText = (): string => {
    if (isColorChanging) {
      return colorDuringChange;
    }
    return parseColorForInputField(props.color);
  };

  return (
    <>
      <InputField
        input={getInputFieldText()}
        onInputChange={onChangeHandler}
        onInputChangeEnded={onChangeEnded}
        onInputBlur={(): void => {
          setIsColorChanging(false);
        }}
        hasIcon="icon-hash"
        elementTitle={window.i18next.t('pmwjs_add_hex_color')}
        controls={{
          showDefaultControls: false,
          customControls: [
            {
              id: 'icon-copy',
              type: isMobileVersion ? InputControlItemType.ICONS_ONLY : InputControlItemType.ICON_BTNS,
              icon: 'icon-copy',
            },
          ],
          onClick: showToolTip,
        }}
      />
      {showTooltip ? (
        <Tooltip
          className={`${styles.tooltip} ${tooltipClasses}`}
          id="copy-hex-tooltip"
          text={<Text val={window.i18next.t('pmwjs_insights_keywords_copied')} size={TextSize.XXSMALL} />}
        />
      ) : null}
    </>
  );
}
