import type {ReactElement} from 'react';
import React from 'react';
import type {PanelFooterPrimaryDefaultActionProps, PanelFooterPrimaryCustomActionProps} from '@Components/panel';
import styles from './panel-footer-primary-actions.module.scss';

export type PanelFooterPrimaryActionType = ReactElement<PanelFooterPrimaryDefaultActionProps> | ReactElement<PanelFooterPrimaryCustomActionProps>;

interface PanelFooterPrimaryActionsProps {
  actions?: Array<PanelFooterPrimaryActionType>;
  className?: string;
  fullWidth?: boolean;
}

export function PanelFooterPrimaryActions({actions = [], fullWidth = false, className = ''}: PanelFooterPrimaryActionsProps) {
  return actions.length ? <div className={`${className} ${fullWidth ? styles.fullWidth : ''}`}>{actions}</div> : null;
}
