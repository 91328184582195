export interface FlatIconType {
  color?: FlatIconColor;
  type?: FlatIconColorType;
}

export enum IconShape {
  NONE = 'NONE',
  SQUARE = 'SQUARE',
  CIRCLE = 'CIRCLE',
}

export enum IconType {
  NONE = 'NONE',
  GHOST = 'GHOST',
  GHOST_UNCOLORED = 'GHOST_UNCOLORED',
  TRANSPARENT = 'TRANSPARENT',
  FLAT = 'FLAT',
  SECONDARY = 'SECONDARY',
  SHADOW = 'SHADOW',
  PRIMARY = 'PRIMARY',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  PREMIUM = 'PREMIUM',
  PREMIUM_TWO = 'PREMIUM_TWO',
  PRIMARY_DARK = 'PRIMARY_DARK',
  DARK = 'DARK',
  DARK_SECONDARY = 'DARK_SECONDARY',
  DARK_GHOST = 'DARK_GHOST',
}

export enum IconSize {
  SIZE_ICON_12 = 'SIZE_ICON_12',
  SIZE_ICON_16 = 'SIZE_ICON_16',
  SIZE_ICON_20 = 'SIZE_ICON_20',
  SIZE_ICON_24 = 'SIZE_ICON_24',
  SIZE_ICON_32 = 'SIZE_ICON_32',
  SIZE_ICON_48 = 'SIZE_ICON_48',
  SIZE_ICON_64 = 'SIZE_ICON_64',
}

export enum FlatIconColor {
  NONE = 'NONE',
  NEUTRAL = 'NEUTRAL',
  PREMIUM = 'PREMIUM',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  SECONDARY = 'SECONDARY',
  PRIMARY = 'FLAT-PRIMARY',
}

export enum FlatIconColorType {
  DEFAULT = 'DEFAULT',
  DARK = 'DARK',
  DARKER = 'DARKER',
}
