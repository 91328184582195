import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {Item} from '@PosterWhiteboard/items/item/item.class';
import {degToRad} from '@Utils/math.util';
import {initFakeProgress} from '@Libraries/fake-loading.library';
import {moveObjectOneLayerAboveAnotherObject} from '@Utils/fabric.util';
import {Circle, FabricText, Group, Rect} from '@postermywall/fabricjs-2';
import type {FabricLoadingObjects, ShowItemLoadingOtps} from './item.types';
import {ItemLoadingProgressType} from './item.types';

const CIRCULAR_PROGRESS_START_ANGLE = 270;

export class ItemLoading extends CommonMethods {
  private fabricLoadingObjects?: FabricLoadingObjects;
  public item: Item;
  public loadingOpts?: ShowItemLoadingOtps;
  private currentProgress = 0;
  private currentTextLangKeyIndex = 0;
  private showText = true;
  private progressInterval: NodeJS.Timeout | undefined;
  private doShowLoading = true;
  private fitToScreenScale = 1;

  public constructor(item: Item) {
    super();
    this.item = item;
  }

  public startLoading(opts?: ShowItemLoadingOtps): void {
    this.resetValues();
    this.loadingOpts = opts;
    if (this.isProgressFake()) {
      this.startFakeLoadingCounter();
    }

    this.initFabricObjectForLoading();
    this.showLoading();
  }

  public removeLoading(): void {
    this.resetValues();
    if (this.fabricLoadingObjects) {
      this.item.page.fabricCanvas.remove(this.fabricLoadingObjects.group);
      this.fabricLoadingObjects = undefined;
    }
    this.item.page.fabricCanvas.requestRenderAll();
  }

  /**
   * Temporarily hides the current loading
   */
  public hideLoading(): void {
    if (!this.fabricLoadingObjects) {
      return;
    }
    this.doShowLoading = false;
    this.updateLoadingVisibility();
    this.item.page.fabricCanvas.requestRenderAll();
  }

  /**
   * Shows the current loading hidden temporarily using the hideLoading function
   */
  public showLoading(): void {
    if (!this.fabricLoadingObjects) {
      return;
    }

    this.doShowLoading = true;
    this.updateLoadingVisibility();
    this.updateZIndex();
    this.updateContent();
    this.updateLoadingPosition();
    this.item.page.fabricCanvas.requestRenderAll();
  }

  public updateZIndex(): void {
    if (this.fabricLoadingObjects && this.isLoadingVisible()) {
      if (this.item.isActive()) {
        this.item.page.fabricCanvas.bringObjectToFront(this.fabricLoadingObjects.group);
      } else {
        moveObjectOneLayerAboveAnotherObject(this.item.page.fabricCanvas, this.fabricLoadingObjects.group, this.item.getParentFabricObject());
      }
    }
  }

  public updateTextOnItemResizeAndRender(): void {
    if (this.isLoadingVisible()) {
      this.invalidate();
      this.item.page.fabricCanvas.requestRenderAll();
    }
  }

  public updateLoadingPositionAndRender(): void {
    if (this.isLoadingVisible()) {
      this.updateLoadingPosition();
      this.item.page.fabricCanvas.requestRenderAll();
    }
  }

  public invalidate(): void {
    if (this.isLoadingVisible()) {
      this.updateTextVersionIfTooLong();
      this.updateLoadingPosition();
    }
  }

  private resetValues(): void {
    this.doShowLoading = true;
    this.currentProgress = 0;
    this.currentTextLangKeyIndex = 0;
    this.showText = true;
    this.loadingOpts = undefined;
    clearInterval(this.progressInterval);
  }

  private initFabricObjectForLoading(): void {
    if (this.fabricLoadingObjects) {
      return;
    }

    const circularLoaderProgress = new Circle({
      fill: 'transparent',
      stroke: '#ffffff',
      originX: 'center',
      originY: 'center',
      startAngle: CIRCULAR_PROGRESS_START_ANGLE,
      endAngle: CIRCULAR_PROGRESS_START_ANGLE,
    });

    const circularLoaderBackground = new Circle({
      fill: 'transparent',
      stroke: '#ffffff',
      originX: 'center',
      originY: 'center',
      opacity: 0.4,
    });

    const DEFAULT_FONT_SIZE = 16;
    const text = new FabricText('tempText', {
      fontSize: DEFAULT_FONT_SIZE,
      originX: 'center',
      originY: 'center',
      fontFamily: '"Nunito Sans", Helvetica, Arial, sans-serif',
      fill: '#ffffff',
    });

    const background = new Rect({
      fill: '#141325',
      opacity: 0.8,
      originX: 'center',
      originY: 'center',
    });

    const group = new Group([background, circularLoaderProgress, circularLoaderBackground, text], {
      selectable: false,
      evented: false,
      visible: true,
      originX: 'left',
      originY: 'top',
    });

    group.bindedObjectPmwId = this.item.uid;
    this.fabricLoadingObjects = {
      group,
      text,
      background,
      circularLoaderProgress,
      circularLoaderBackground,
    };

    this.updateFitToScreenScaleVal();
    this.fabricLoadingObjects.text.set('left', this.showProgress() ? this.getCircularProgressDiameter() / 2 : 0);
    this.item.page.fabricCanvas.add(this.fabricLoadingObjects.group);
  }

  private updateFitToScreenScaleVal(): void {
    this.fitToScreenScale = this.item.page.poster.scaling.getFitToScreenScale();
  }

  private updateContent(): void {
    if (!this.isLoadingVisible()) {
      return;
    }
    this.updateFitToScreenScaleVal();
    this.updateSize();
    this.updateText();
    this.updateLoadingWidth();
    this.updateTextVersionIfTooLong();
    this.updateProgressCircleLoader();
    this.item.page.fabricCanvas.requestRenderAll();
  }

  private updateSize(): void {
    const TOTAL_HEIGHT = 32;
    const textScale = 1 / this.fitToScreenScale;

    this.fabricLoadingObjects?.text.set({
      scaleX: textScale,
      scaleY: textScale,
    });

    this.fabricLoadingObjects?.circularLoaderProgress.set({
      radius: this.getCircularProgressRadiusWithoutStroke(),
      strokeWidth: this.getCircularProgressStrokeWidth(),
    });
    this.fabricLoadingObjects?.circularLoaderBackground.set({
      radius: this.getCircularProgressRadiusWithoutStroke(),
      strokeWidth: this.getCircularProgressStrokeWidth(),
    });

    const totalHeight = TOTAL_HEIGHT / this.fitToScreenScale;
    this.fabricLoadingObjects?.background.set({
      height: totalHeight,
      rx: 20 / this.fitToScreenScale,
      ry: 20 / this.fitToScreenScale,
    });
    this.fabricLoadingObjects?.group.set('height', totalHeight);
  }

  private getCircularProgressRadiusWithoutStroke(): number {
    return (this.getCircularProgressDiameter() - this.getCircularProgressStrokeWidth()) / 2;
  }

  private getCircularProgressStrokeWidth(): number {
    const DEFAULT_CIRCULAR_PROGRESS_STROKE_WIDTH = 3;
    return DEFAULT_CIRCULAR_PROGRESS_STROKE_WIDTH / this.fitToScreenScale;
  }

  private getCircularProgressDiameter(): number {
    const DEFAULT_CIRCULAR_PROGRESS_DIAMETER = 20;
    return DEFAULT_CIRCULAR_PROGRESS_DIAMETER / this.fitToScreenScale;
  }

  private getLoadingRightLeftPadding(): number {
    const DEFAULT_LOADING_RIGHT_LEFT_PADDING = 12;
    return DEFAULT_LOADING_RIGHT_LEFT_PADDING / this.fitToScreenScale;
  }

  private getLoadingMarginFromItemEdge(): number {
    const DEFAULT_LOADING_MARGIN_FROM_POSTER_EDGE = 12;
    return DEFAULT_LOADING_MARGIN_FROM_POSTER_EDGE / this.fitToScreenScale;
  }

  private getCircularLoaderTextGap(): number {
    const DEFAULT_CIRCULAR_LOADER_TEXT_GAP = 8;
    return DEFAULT_CIRCULAR_LOADER_TEXT_GAP / this.fitToScreenScale;
  }

  private updateLoadingWidth(): void {
    if (this.fabricLoadingObjects) {
      const newWidth =
        this.getCircularProgressDiameter() + this.getCircularLoaderTextGap() + this.fabricLoadingObjects.text.getScaledWidth() + this.getLoadingRightLeftPadding() * 2;
      this.fabricLoadingObjects.group.set('width', newWidth);
      this.fabricLoadingObjects.background.set('width', newWidth);
      this.fabricLoadingObjects.background.set('left', 0);

      const circularLoaderProgressNewLeft = -this.fabricLoadingObjects.text.getScaledWidth() / 2 - this.getCircularLoaderTextGap();
      this.fabricLoadingObjects.circularLoaderBackground.set('left', circularLoaderProgressNewLeft);
      this.fabricLoadingObjects.circularLoaderProgress.set('left', circularLoaderProgressNewLeft);
    }
  }

  private updateTextVersionIfTooLong(): void {
    const BUFFER = 12 / this.fitToScreenScale;
    if (this.fabricLoadingObjects && this.loadingOpts?.langTextKeys) {
      if (this.fabricLoadingObjects.group.getScaledWidth() + this.getLoadingMarginFromItemEdge() + BUFFER > this.item.getFabricObjectAbsoluteScaledWidth()) {
        if (this.loadingOpts.langTextKeys[this.currentTextLangKeyIndex + 1] !== undefined) {
          this.currentTextLangKeyIndex += 1;
        } else {
          this.showText = false;
        }
        this.updateText();
        this.updateLoadingWidth();
      }
    }
  }

  private showProgress(): boolean {
    return this.loadingOpts?.progress !== undefined;
  }

  private getLoadingText(): string {
    if (!this.showText && this.showProgress()) {
      return window.i18next.t('pmwjs_percentage', {
        percentage: this.getCurrentPercentageProgress(),
      });
    }

    if (this.loadingOpts?.langTextKeys) {
      return window.i18next.t(this.loadingOpts.langTextKeys[this.currentTextLangKeyIndex], {
        percentage: this.getCurrentPercentageProgress(),
      });
    }

    return this.showProgress()
      ? window.i18next.t('pmwjs_loading_percentage', {
          percentage: this.getCurrentPercentageProgress(),
        })
      : window.i18next.t('pmwjs_loading');
  }

  private getCurrentPercentageProgress(): number {
    return Math.round(this.currentProgress * 100);
  }

  private updateLoadingVisibility(): void {
    this.fabricLoadingObjects?.group?.set('visible', this.doShowLoading);
  }

  private updateLoadingPosition(): void {
    if (this.fabricLoadingObjects && this.isLoadingVisible()) {
      const itemAbsLeft = this.item.getFabricObjectAbsoluteLeft();
      const itemAbsTop = this.item.getFabricObjectAbsoluteTop();
      const itemAbsoluteAngle = this.item.getFabricObjectAbsoluteAngle();
      const angleRadians = degToRad(itemAbsoluteAngle);

      let relativeX = this.item.getFabricObjectAbsoluteScaledWidth() - this.fabricLoadingObjects.group.getScaledWidth() - this.getLoadingMarginFromItemEdge();
      let relativeY = this.item.getFabricObjectAbsoluteScaledHeight() - this.fabricLoadingObjects.group.getScaledHeight() - this.getLoadingMarginFromItemEdge();
      let angleAdjustedRelativeX = relativeX * Math.cos(angleRadians) - relativeY * Math.sin(angleRadians);
      let angleAdjustedRelativeY = relativeX * Math.sin(angleRadians) + relativeY * Math.cos(angleRadians);

      let loadingAbsLeft = itemAbsLeft + angleAdjustedRelativeX;
      let loadingAbsTop = itemAbsTop + angleAdjustedRelativeY;

      // Show the loading at the top left if the bottom right is not on canvas
      const BUFFER = 15;
      if (loadingAbsLeft > this.item.page.getNonScaledCanvasScaleddWidth() - BUFFER || loadingAbsTop > this.item.page.getNonScaledCanvasScaleddHeight() - BUFFER) {
        relativeX = this.getLoadingMarginFromItemEdge();
        relativeY = this.getLoadingMarginFromItemEdge();
        angleAdjustedRelativeX = relativeX * Math.cos(angleRadians) - relativeY * Math.sin(angleRadians);
        angleAdjustedRelativeY = relativeX * Math.sin(angleRadians) + relativeY * Math.cos(angleRadians);
        loadingAbsLeft = itemAbsLeft + angleAdjustedRelativeX;
        loadingAbsTop = itemAbsTop + angleAdjustedRelativeY;
      }

      this.fabricLoadingObjects.group.set({
        left: loadingAbsLeft,
        top: loadingAbsTop,
        angle: itemAbsoluteAngle,
        zIndeX: this.item.getZIndex() + 1,
      });
    }
  }

  private isLoadingVisible(): boolean {
    return this.fabricLoadingObjects?.group?.get('visible') === true;
  }

  private isProgressFake(): boolean {
    return this.loadingOpts?.progress?.type === ItemLoadingProgressType.FAKE;
  }

  private onProgressUpdate(progress: number, hasTimedOut: boolean): void {
    if (hasTimedOut) {
      this.removeLoading();
      return;
    }
    this.currentProgress = progress;
    this.updateContent();
  }

  private updateProgressCircleLoader(): void {
    if (this.fabricLoadingObjects) {
      this.fabricLoadingObjects.circularLoaderProgress.set('endAngle', 360 * this.currentProgress + CIRCULAR_PROGRESS_START_ANGLE);
    }
  }

  private updateText(): void {
    if (this.fabricLoadingObjects) {
      this.fabricLoadingObjects.text.set('text', this.getLoadingText());
    }
  }

  private startFakeLoadingCounter(): void {
    if (this.loadingOpts?.progress?.type === ItemLoadingProgressType.FAKE) {
      this.progressInterval = initFakeProgress(this.loadingOpts.progress.expectedTotalTime * 1000, this.onProgressUpdate.bind(this));
    }
  }
}
