import React, {useMemo} from "react";
import PropTypes from 'prop-types';
import './progress-bar-with-stops.scss';
import {ProgressBarStop} from "./components";
import {ProgressBarFinalStop} from "./components/progress-bar-final-stop/index.js";
import {ProgressBar} from "../progress-bar/index.js";


/**
 * Progress bar that's divided into smaller bars based on a number of 'steps'/'stops'
 * The bar creates the 'stops' for its UI based on the numSteps prop provided and optionally also supports labels for each step
 * See PropTypes for more info.
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
const ProgressBarWithStops = ({numSteps, currentStep, labelsForSteps, iconForFinalStep, extraClasses, progressBarClasses, progressBarStopClasses, progressBarFinalStopClasses}) => {
    const labelsProvided = useMemo(() => labelsForSteps.length !== 0 && labelsForSteps.length === numSteps, [labelsForSteps]),
        areAllStepsComplete = Math.floor(currentStep) > numSteps;


    const isStepCompleted = (stepIndex) => Math.floor(currentStep) - stepIndex > 0;

    const getProgressStep = (stepIndex) => {
        const isStepComplete = isStepCompleted(stepIndex),
            label = labelsProvided ? labelsForSteps[stepIndex - 1] : null;

        return stepIndex === numSteps ?
            <ProgressBarFinalStop key={`final-stop`} icon={iconForFinalStep} label={label} haveAllStepsCompleted={areAllStepsComplete} classes={progressBarFinalStopClasses} /> :
            <ProgressBarStop key={`stop-${stepIndex}`} label={label} isStepComplete={isStepComplete} haveAllStepsCompleted={areAllStepsComplete} classes={progressBarStopClasses} />;
    }

    const getProgressItems = () => {
        const progressItems = [];
        let isStepComplete, barPercentage;

        for (let i = 1; i <= numSteps; i++) {
            isStepComplete = isStepCompleted(i);
            barPercentage = isStepComplete ? 100 : (currentStep - i) * 100;
            progressItems.push(
                <React.Fragment key={`progress-item-${i}`}>
                    <ProgressBar key={`minibar-${i}`} progressPercentage={barPercentage} classes={`${areAllStepsComplete ? '-green' : ''} ${progressBarClasses}`} />
                    {getProgressStep(i)}
                </React.Fragment>
            )
        }

        return progressItems;
    }

    const progressItems = useMemo(getProgressItems, [numSteps, currentStep, labelsForSteps, iconForFinalStep])
    return (
        <div className={`progress-bar-with-steps flex-row-align-center ${labelsProvided ? '-with-labels' : ''} ${extraClasses}`}>
            {progressItems}
        </div>
    );
}

ProgressBarWithStops.propTypes = {
    /**
     * the number of steps the progress bar will have
     */
    numSteps: PropTypes.number.isRequired,
    /**
     * the current step/stop, can be a float value too to display a partially filled bar
     */
    currentStep: PropTypes.number.isRequired,
    /**
     * the icon to give for the final stop
     */
    iconForFinalStep: PropTypes.string.isRequired,
    /**
     * any labels to give to each stop
     */
    labelsForSteps: PropTypes.arrayOf(PropTypes.string),

    /**
     * extra classes to give to the root container
     */
    extraClasses: PropTypes.string,

    /**
     * extra classes to give to the progress bars between the stops
     */
    progressBarClasses: PropTypes.string,
    /**
     * extra classes to give to the 'stops' or the circles between the bars
     */
    progressBarStopClasses: PropTypes.string,
    /**
     * extra classes to give to the final stop that comes at the end of the bar
     */
    progressBarFinalStopClasses: PropTypes.string
}

ProgressBarWithStops.defaultProps = {
    labelsForSteps: [],
    extraClasses: '',
    progressBarClasses: '',
    progressBarStopClasses: '',
    progressBarFinalStopClasses: ''
}

export default ProgressBarWithStops;
