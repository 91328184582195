import type {ReactElement} from 'react';
import React from 'react';
import type {CustomEventPanelCallback} from '@Components/content-planner/content-planner.types';
import {Text, TextSize} from '@Components/text';
import {Button, IconPlacement, Size, Type} from '@Components/button';
import helper from '@Components/content-planner/content-planner-util.module.scss';

interface DeletePanelProps {
  eventTitle: string;
  onDelete: CustomEventPanelCallback;
  onCancel: CustomEventPanelCallback;
  isLoading: boolean;
}

export function DeletePanel({eventTitle, onDelete, onCancel, isLoading}: DeletePanelProps): ReactElement {
  return (
    <div className={`flex-v-row _full-height _full-width ${helper.animated}`}>
      <Text className="content-body fadeIn" size={TextSize.SMALL} val={window.i18next.t('pmwjs_delete_event_warning', {eventName: eventTitle})} dangerouslySetInnerHTML />
      <div className="flex-v-row spacing-m-t-8">
        <Button
          onClick={onDelete}
          text={window.i18next.t('pmwjs_delete')}
          size={Size.SMALL}
          type={Type.DANGER}
          iconPlacement={IconPlacement.LEFT}
          icon="icon-delete"
          isLoading={isLoading}
          isFullWidth
        />
        <Button onClick={onCancel} customClasses="spacing-m-t-2" text={window.i18next.t('pmwjs_cancel')} size={Size.SMALL} type={Type.SECONDARY} disabled={isLoading} isFullWidth />
      </div>
    </div>
  );
}
