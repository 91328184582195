import React from "react";
import PropTypes from 'prop-types';
import './section-divider.scss';
import {Icon} from "../../../icon-v2";
import {IconShape} from "@Components/icon-v2/icon.types";
import {Text, TextSize, TextType} from "../../../text";

/**
 * A divider to be placed in between sections. Contains an icon and an optional text label + vertical dashed line,
 * stacked vertically in that order.
 * @author Basil <basil@250mils.com>
 */
class SectionDivider extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={this.props.containerClass}>
                <Icon icon={this.props.iconName} shape={IconShape.NONE} className={'divider-icon ' + this.props.iconClass}/>
                {this.props.hasDividerLine ? this.#getDividerLine() : null}
                {this.props.label ? this.#getTextLabel() : null}
            </div>
        );
    }

    /**
     * @return {JSX.Element}
     */
    #getDividerLine = () => {
        return (
            <hr className={'vr'}/>
        );
    }

    /**
     * @return {JSX.Element}
     */
    #getTextLabel = () => {
        return (
            <Text val={this.props.label} type={TextType.CAPTION} size={TextSize.XXSMALL} className={'divider-label'}/>
        )
    }
}

SectionDivider.propTypes = {
    /**
     * Icon name
     */
    iconName: PropTypes.string.isRequired,

    /**
     * Optional additional class for the icon
     */
    iconClass: PropTypes.string,

    /**
     * Optional container class
     */
    containerClass: PropTypes.string,

    /**
     * Optional additional class for the icon
     */
    hasDividerLine: PropTypes.bool,

    /**
     * Optional additional class for the icon
     */
    label: PropTypes.string
};

SectionDivider.defaultProps = {
    iconClass: '',
    containerClass: '',
    hasDividerLine: false,
    label: ''
};

export default SectionDivider;