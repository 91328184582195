import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
  TOTPVerificationResponse,
  VerifyTOTPForRegistrationRequestParams,
  VerifyTOTPForUserActionRequestParams,
  VerifyTOTPRequestParams,
} from '@Components/two-factor-authentication/two-factor-authentication.types';

export const verifyTOTPForRegistration = createAsyncThunk(
  'verifyTOTPForRegistration',
  async ({totp, logoutAllOtherDevices}: VerifyTOTPForRegistrationRequestParams, {rejectWithValue}) => {
    try {
      return (await window.PMW.writeLocal('authenticate/verifyTOTPForRegistration', {totp, logoutAllOtherDevices: logoutAllOtherDevices ? 1 : 0})) as TOTPVerificationResponse;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const disableUserTwoFactorVerification = createAsyncThunk('disableTwoFactorAuthentication', async (_, {rejectWithValue}) => {
  try {
    return (await window.PMW.writeLocal('authenticate/disableUserTwoFactorAuthentication')) as void;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const submit2FACodeForVerification = createAsyncThunk('twoFactorAuthentication', async ({code, url}: VerifyTOTPRequestParams, {rejectWithValue}) => {
  try {
    return (await window.PMW.writeLocal(url, {code})) as TOTPVerificationResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const submitInputCodeForUserActionVerification = createAsyncThunk(
  'twoFactorAuthentication',
  async ({code, type, url}: VerifyTOTPForUserActionRequestParams, {rejectWithValue}) => {
    try {
      return (await window.PMW.writeLocal(url, {code, type})) as TOTPVerificationResponse;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
