import React from "react";
import {EmptyStateContainer} from "../../../empty-state-container";
import './empty-state.scss';
import {getFirstSelectedItemId, ITEM_TYPES} from '@Libraries/email-marketing-campaigns-library.js';
import EmailMarketingCampaignPerformanceHeader from "../email-marketing-campaign-performance-header/email-marketing-campaign-performance-header.jsx";
import {connect} from "react-redux";
import {Button, Size, Type} from "../../../button";

/**
 * @return {JSX.Element}
 * @constructor
 */
class EmptyState extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const firstSelectedItemId = getFirstSelectedItemId(this.props.selectedItemIds),
            campaign = (this.#isCampaignItemType() && firstSelectedItemId !== null) ? this.props.campaigns[firstSelectedItemId] : null,
            isCollectingData = this.props.isCollectingData,
            isDraftCampaign = campaign === null ? false : campaign.isDraft,
            isNoCampaignPublished = this.#isCampaignItemType() && this.props.isNoCampaignPublished,
            imgFileName = isCollectingData ? 'email-marketing-campaign-performance-collecting-data.png' : 'empty-email-marketing-campaign-performance.png';

        let heading = i18next.t('pmwjs_you_have_not_created_any_campaigns'),
            message = i18next.t('pmwjs_create_campaign_and_measure_performance'),
            ctaLabel = i18next.t('pmwjs_create_a_campaign');

        if (isCollectingData) {
            if (this.#isCampaignItemType()) {
                heading = i18next.t('pmwjs_gathering_insights_for_email_campaign');
                message = i18next.t('pmwjs_we_are_collecting_insights_for_campaign_check_later');
                ctaLabel = i18next.t('pmwjs_create_a_new_campaign');
            } else {
                heading = i18next.t('pmwjs_gathering_insights_for_audience');
                message = i18next.t('pmwjs_we_are_collecting_insights_for_audience_check_later');
                ctaLabel = i18next.t('pmwjs_create_a_new_campaign');
            }
        } else if (isDraftCampaign) {
            heading = i18next.t('pmwjs_campaign_not_published_yet');
            message = i18next.t('pmwjs_publish_this_campaign_to_see_performance');
            ctaLabel = i18next.t('pmwjs_create_a_new_campaign');
        } else if (isNoCampaignPublished) {
            heading = i18next.t('pmwjs_you_have_not_published_any_campaigns');
            message = i18next.t('pmwjs_almost_there_publish_campaigns');
            ctaLabel = i18next.t('pmwjs_create_a_new_campaign');
        }

        const messageBoxProps = {
            imageSrc: PMW.util.asset_url('images/mine/' + imgFileName),
            imgVariant: 'medium',
            imgClasses: '-smaller-small-laptop -less-margin-small-laptop',
            heading: heading,
            message: message,
            messageClasses: 'body-s',
            headingClasses: 'body-xl-bold'
        };

        return (
            <>
                {(isCollectingData || isDraftCampaign) ? <EmailMarketingCampaignPerformanceHeader/> : null}
                <EmptyStateContainer emptyStateClasses={'email-campaigns-performance-empty-state'}
                                     messageBoxProps={messageBoxProps} reducePaddingOnSmallLaptopHeight={true}>
                    <Button type={Type.PRIMARY} size={Size.MEDIUM}
                            customClasses={'btn-create-campaign -full-width spacing-m-t-1'}
                            onClick={PMW.mystuff.onCreateNewCampaign} text={ctaLabel}
                            shortText={i18next.t('pmwjs_new_campaign')}/>
                </EmptyStateContainer>
            </>
        );
    }

    #isCampaignItemType = () => {
        return this.props.itemType === ITEM_TYPES.CAMPAIGNS;
    }
}

const mapEmailMarketingCampaignsPerformanceStateToProps = state => {
    return {
        itemType: state.emailMarketingCampaignsPerformance.itemType,
        campaigns: state.emailMarketingCampaignsPerformance.campaigns,
        selectedItemIds: state.emailMarketingCampaignsPerformance.selectedItemIds,
        isNoCampaignPublished: state.emailMarketingCampaignsPerformance.isNoCampaignPublished,
        isCollectingData: state.emailMarketingCampaignsPerformance.isCollectingData
    }
}

export default connect(mapEmailMarketingCampaignsPerformanceStateToProps, null)(EmptyState);
