import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

export interface RemoveBackgroundActionProps {
  credits: number;
}

interface RemoveBackgroundProps {
  credits: number | null;
}

const initialState: RemoveBackgroundProps = {
  credits: null,
};

export const removeBackgroundSlice = createSlice({
  name: 'removeBackgroundCredits',
  initialState,
  reducers: {
    updateBackgroundRemovalCredits: (state, action: PayloadAction<RemoveBackgroundActionProps>) => {
      state.credits = action.payload.credits;
    },
  },
});

export const {updateBackgroundRemovalCredits} = removeBackgroundSlice.actions;
export const removeBackgroundReducer = removeBackgroundSlice.reducer;
