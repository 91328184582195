import {THUMBNAIL_TYPE} from '@Components/thumbnail';
import type {ListItemStorage} from '@Components/list/list.types';
import {LIST_ITEM_TYPE} from '@Components/list/list.types';
import type {ListItemType1Storage} from '@Components/list/components/list-item-type-1';
import {setPosLoadingState, setIsSearchingWithTags, updatePosCategories} from '@Panels/user-menu-panel/user-menu-panel-reducer';
import {updateListsState} from '@Components/list/list-reducer';
import {openErrorModal} from '@Modals/error-modal';
import type {MenuItemData, MenuItemTag, MenuItemVariation, PosCatalogBackendData, PosMenuItem, PosMenuItemVariation, TABS} from './user-menu-panel.types';
import {IMAGE_SRC, MENU_ITEM_TYPE, POS_LOADING_STATES, POS_MENU_LIST_ID, USER_MENU_MODAL_TABS_ID} from './user-menu-panel.types';

export const cloneMenuItem = (item: MenuItemData): MenuItemData => {
  const menuItem = {
    id: item.id,
    name: item.name,
    price: item.price,
    description: item.description,
    type: item.type,
    variationsList: item.variationsList.map((variation: MenuItemVariation) => {
      return cloneitemVariation(variation);
    }),
    addOnsList: item.addOnsList.map((variation: MenuItemVariation) => {
      return cloneitemVariation(variation);
    }),
    iconsList: [...item.iconsList],
    categories: [...item.categories],
  } as MenuItemData;
  if (menuItem.type === MENU_ITEM_TYPE.POS_MENU_ITEM && item.type === MENU_ITEM_TYPE.POS_MENU_ITEM) {
    menuItem.source = item.source;
    menuItem.posItemId = item.posItemId;
  }
  return menuItem;
};

export const menuItemToListStorage = (item: MenuItemData, onEditItem?: Function, onSelectItem?: Function, isSelected?: boolean, isLoading?: boolean): ListItemType1Storage => {
  const menuItem = {
    id: item.id,
    text: item.name,
    subtext: item.price,
    loading: !!isLoading,
    showEditIcon: !!onEditItem,
    additionalData: {
      description: item.description,
      variationsList: item.variationsList.map((variation: MenuItemVariation) => {
        return cloneitemVariation(variation);
      }),
      addOnsList: item.addOnsList.map((variation: MenuItemVariation) => {
        return cloneitemVariation(variation);
      }),
      categories: item.categories,
      iconsList: [...item.iconsList],
    },
    isSelected,
    type: LIST_ITEM_TYPE.DEFAULT_1,
    onClickEditIcon: onEditItem
      ? () => {
          return onEditItem(item);
        }
      : null,
    onClick: onSelectItem
      ? () => {
          return onSelectItem(item);
        }
      : null,
  } as ListItemType1Storage;
  if (item.type === MENU_ITEM_TYPE.POS_MENU_ITEM) {
    menuItem.thumbnail = {
      type: THUMBNAIL_TYPE.IMAGE,
      imageSrc: window.PMW.util.asset_url(IMAGE_SRC.SQUARE),
    };
    menuItem.additionalData = {
      ...menuItem.additionalData,
      source: item.source,
      posItemId: item.posItemId,
    };
  }
  return menuItem;
};

export const cloneitemVariation = (variation: MenuItemVariation): MenuItemVariation => {
  const menuItemVariation = {
    id: variation.id,
    name: variation.name,
    price: variation.price,
    type: variation.type,
  } as MenuItemVariation;
  if (variation.type === MENU_ITEM_TYPE.POS_MENU_ITEM && menuItemVariation.type === MENU_ITEM_TYPE.POS_MENU_ITEM) {
    menuItemVariation.posVariationId = variation.posVariationId;
  }
  return menuItemVariation;
};

export const initMenuItem = (item?: MenuItemData): MenuItemData => {
  return item
    ? cloneMenuItem(item)
    : ({
        id: '',
        name: '',
        price: '',
        description: '',
        type: MENU_ITEM_TYPE.USER_MENU_ITEM,
        iconsList: [],
        variationsList: [],
        addOnsList: [],
        categories: [],
      } as MenuItemData);
};

export const onError = (message?: string): void => {
  openErrorModal({
    message: message || window.i18next.t('pmwjs_connectivity_error'),
  });
};

export const getPosLoadingState = (): POS_LOADING_STATES => {
  return window.PMW.redux.store.getState().menus.posLoadingState;
};

export const getActiveTab = (): TABS => {
  return window.PMW.redux.store.getState().tabs.selectedTabsHashmap[USER_MENU_MODAL_TABS_ID] as TABS;
};

export const getMenuItemsHash = (items: Array<string>, hashMap: Record<string, ListItemStorage>, type: MENU_ITEM_TYPE): Record<string, MenuItemData> => {
  const menuHashMap: Record<string, MenuItemData> = {};
  if (items) {
    items.map((itemId) => {
      const item = hashMap[itemId] as ListItemType1Storage;
      menuHashMap[itemId] = {
        id: item.id,
        name: item.text,
        price: item.subtext,
        description: item.additionalData?.description,
        type,
        iconsList: item.additionalData?.iconsList,
        variationsList: item.additionalData?.variationsList,
        addOnsList: item.additionalData?.addOnsList,
        categories: item.additionalData?.categories,
      } as MenuItemData;
      if (item.additionalData?.source) {
        menuHashMap[itemId] = {
          ...menuHashMap[itemId],
          type: MENU_ITEM_TYPE.POS_MENU_ITEM,
          source: item.additionalData?.source,
          posItemId: item.additionalData?.posItemId,
        } as PosMenuItem;
      }
    });
  }
  return menuHashMap;
};

export const getListData = (listId: string, itemIds?: Array<string>, items?: Array<ListItemType1Storage>) => {
  return {
    listId,
    listItemsIds: itemIds ?? [],
    listItems: items ?? [],
    draggable: true,
    multiSelect: true,
    keepAtleastOneItemSelected: false,
  };
};

export const getSelectedTagIds = (tags: Array<MenuItemTag>): Array<string> => {
  const selectedTags: Array<string> = [];
  tags.forEach((tag) => {
    if (tag.isSelected) {
      selectedTags.push(tag.id);
    }
  });
  return selectedTags;
};

export const getSelectedTagNames = (tags: Array<MenuItemTag>): Array<string> => {
  const selectedTags: Array<string> = [];
  tags.forEach((tag) => {
    if (tag.isSelected) {
      selectedTags.push(tag.name);
    }
  });
  return selectedTags;
};

export const getUpdatedLoadingToken = (): string => {
  return window.PMW.redux.store.getState().menus.loadingToken;
};

const updatePosItems = (items: Array<ListItemType1Storage>, itemIds: Array<string>, loadingToken: string, categories?: Array<MenuItemTag>): void => {
  if (loadingToken !== getUpdatedLoadingToken()) {
    return;
  }
  window.PMW.redux.store.dispatch(setPosLoadingState(POS_LOADING_STATES.LOADED_ITEMS));
  window.PMW.redux.store.dispatch(
    updateListsState([
      {
        listId: POS_MENU_LIST_ID,
        listItemsIds: itemIds,
        listItems: items,
        draggable: false,
        multiSelect: true,
        keepAtleastOneItemSelected: false,
      },
    ])
  );
  if (categories && categories.length) {
    window.PMW.redux.store.dispatch(updatePosCategories(categories));
  }
  window.PMW.redux.store.dispatch(setIsSearchingWithTags(false));
};

export const loadPosMenuItems = async (categoryFilter?: Array<string>) => {
  try {
    const {loadingToken} = window.PMW.redux.store.getState().menus;
    const response = (await window.PMW.readLocal('square/getMenuItems', {
      categories: categoryFilter,
    })) as PosCatalogBackendData;
    const {items, categories} = response;
    const itemIds: string[] = [];
    const menuItems: Array<ListItemType1Storage> = [];
    items.forEach((item: PosMenuItem): void => {
      const menuItem: PosMenuItem = {
        posItemId: item.posItemId,
        type: MENU_ITEM_TYPE.POS_MENU_ITEM,
        id: item.id,
        name: item.name,
        price: item.price,
        description: item.description,
        categories: item.categories,
        iconsList: [],
        variationsList: [],
        addOnsList: [],
        source: item.source,
      };
      for (const posItemVariation of item.variationsList) {
        menuItem.variationsList.push({
          name: posItemVariation.name,
          price: posItemVariation.price,
          posVariationId: posItemVariation.id,
          type: MENU_ITEM_TYPE.POS_MENU_ITEM,
        } as PosMenuItemVariation);
      }
      itemIds.push(menuItem.id);
      menuItems.push(menuItemToListStorage(menuItem));
    });
    updatePosItems(menuItems, itemIds, loadingToken, categories);
  } catch (e) {
    onError();
  }
};
