import type {ReactElement} from 'react';
import React, {useState} from 'react';
import type {AnimatedSprite} from '@Libraries/animated-sprite.library';
import type {GridItemProps} from '../grid-item';
import type {GridMediaItemStorage} from '../grid-media-item';
import {GridMediaItem} from '../grid-media-item';

export interface GridAnimatedStickerItemStorage extends GridMediaItemStorage {
  hashedFilename: string;
  duration: number;
  frameRate: number;
  highResAnimatedSprite: AnimatedSprite;
  screenAnimatedSprite: AnimatedSprite;
}

interface GridStickerItemProps extends GridItemProps {
  videoThumbSrc?: string;
}

export interface GridAnimatedStickerItemBackEndData {
  id: string;
  hidden: boolean;
  hashedFilename: string;
  duration: string;
  frameRate: string;
}

/**
 * @class GridAnimatedStickerItem
 */
export function GridAnimatedStickerItem({...props}: GridStickerItemProps): ReactElement {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const getThumbElement = (): ReactElement => {
    return (
      <img
        onLoad={(): void => {
          return setIsImageLoaded(true);
        }}
        className="radius-4"
        draggable="false"
        crossOrigin="anonymous"
        src={props.videoThumbSrc}
        alt={window.i18next.t('pmwjs_sticker')}
      />
    );
  };

  return <GridMediaItem {...props} isImageLoaded={isImageLoaded} thumb={getThumbElement()} />;
}
