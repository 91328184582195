import type {ReactElement} from 'react';
import React from 'react';
import {ColorFill} from '@Components/color-fill';
import {v4 as uuidv4} from 'uuid';
import {AddColorButton} from '@Components/color-picker-v2/components/add-color-button';
import styles from './swatches.module.scss';

interface SwatchesProps {
  className?: string;
  colors: Array<string>;

  onClick(color: string): void;
  showAddButton?: boolean;
  onClickAdd?(): void;
  hasCustomMargins?: boolean;
}

const DEFAULT_SWATCH_WIDTH = 40;
const DEFAULT_SWATCH_HEIGHT = 40;

export function Swatches({className = '', hasCustomMargins = false, showAddButton = false, onClickAdd = (): void => {}, ...props}: SwatchesProps): ReactElement | null {
  const prepareColorSwatches = (): Array<ReactElement> => {
    return props.colors.map((color) => {
      const id = uuidv4();
      return (
        <ColorFill
          key={id}
          onClick={(): void => {
            props.onClick(color);
          }}
          width={DEFAULT_SWATCH_WIDTH}
          height={DEFAULT_SWATCH_HEIGHT}
          color={color}
        />
      );
    });
  };

  const getAddButton = (): ReactElement => {
    return <AddColorButton onAddColor={onClickAdd} />;
  };

  return (
    <div className={`flex-h-row ${className} ${!hasCustomMargins ? 'spacing-m-t-4 spacing-m-b-3' : ''} ${styles.container}`}>
      {showAddButton ? getAddButton() : null}
      {prepareColorSwatches()}
    </div>
  );
}
