import React from "react";
import PropTypes from 'prop-types';
import './progress-bar-final-stop.scss';
import {ProgressBarStopLabel} from "../progress-bar-stop-label";

/**
 * The last stop in the progress bar, it includes an icon.
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
const ProgressBarFinalStop = ({label, haveAllStepsCompleted, icon, classes}) => {
    return (
        <div className={`progress-bar-stop progress-bar-final-stop flex-center ${haveAllStepsCompleted ? '-completed': ''} js-progress-bar-stop ${classes}`} >
            <i className={`final-stop-icon ${icon}`} />
            {label && <ProgressBarStopLabel label={label} colorClass={haveAllStepsCompleted ? 'color-success' : 'content-disabled'} /> }
        </div>
    );
}

ProgressBarFinalStop.propTypes = {
    /**
     * an icon-* class to give to the final stop
     */
    icon: PropTypes.string.isRequired,
    /**
     * any lavel to associate with the stop
     */
    label: PropTypes.string,

    /**
     * whether all the steps have been completed in which case, apply other classes to reflect on the UI
     */
    haveAllStepsCompleted: PropTypes.bool,

    /**
     * extra CSS classes applied to the root element
     */
    classes: PropTypes.string
}

ProgressBarFinalStop.defaultProps = {
    classes: ''
}


export default React.memo(ProgressBarFinalStop);