import React from "react";
import './brands-illustration-color-fills.scss';
import PropTypes from 'prop-types';
import {PMW_COLORS_NEUTRAL} from "@Utils/color.util";


const BrandsIllustrationColorFills = ({colors, uniqueStringForSvgIds}) => {
    return (
        <>
            <g filter={`url(#filter4_d_1163_10865-${uniqueStringForSvgIds})`}>
                <rect x="15.2202" y="32.9995" width="88.1176" height="329.411" rx="7.41176" fill="white"/>
            </g>
            <g filter={`url(#filter9_d_1163_10865-${uniqueStringForSvgIds})`}>
                <path fillRule="evenodd" clipRule="evenodd" d="M59.8029 89.9149C70.521 89.9149 79.2097 81.1889 79.2097 70.4247C79.2097 59.6606 70.521 50.9346 59.8029 50.9346C49.0848 50.9346 40.396 59.6606 40.396 70.4247C40.396 81.1889 49.0848 89.9149 59.8029 89.9149Z" fill={getColor(colors,0)}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M59.8029 89.9149C70.521 89.9149 79.2097 81.1889 79.2097 70.4247C79.2097 59.6606 70.521 50.9346 59.8029 50.9346C49.0848 50.9346 40.396 59.6606 40.396 70.4247C40.396 81.1889 49.0848 89.9149 59.8029 89.9149Z" stroke="white" strokeWidth="2.47059"/>
            </g>
            <g filter={`url(#filter5_d_1163_10865-${uniqueStringForSvgIds})`}>
                <path fillRule="evenodd" clipRule="evenodd" d="M59.8029 149.402C70.521 149.402 79.2097 140.676 79.2097 129.912C79.2097 119.148 70.521 110.422 59.8029 110.422C49.0848 110.422 40.396 119.148 40.396 129.912C40.396 140.676 49.0848 149.402 59.8029 149.402Z" fill={getColor(colors, 1)}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M59.8029 149.402C70.521 149.402 79.2097 140.676 79.2097 129.912C79.2097 119.148 70.521 110.422 59.8029 110.422C49.0848 110.422 40.396 119.148 40.396 129.912C40.396 140.676 49.0848 149.402 59.8029 149.402Z" stroke="white" strokeWidth="2.47059"/>
            </g>
            <g filter={`url(#filter7_d_1163_10865-${uniqueStringForSvgIds})`}>
                <path fillRule="evenodd" clipRule="evenodd" d="M59.8029 208.889C70.521 208.889 79.2097 200.163 79.2097 189.399C79.2097 178.635 70.521 169.909 59.8029 169.909C49.0848 169.909 40.396 178.635 40.396 189.399C40.396 200.163 49.0848 208.889 59.8029 208.889Z" fill={getColor(colors, 2)}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M59.8029 208.889C70.521 208.889 79.2097 200.163 79.2097 189.399C79.2097 178.635 70.521 169.909 59.8029 169.909C49.0848 169.909 40.396 178.635 40.396 189.399C40.396 200.163 49.0848 208.889 59.8029 208.889Z" stroke="white" strokeWidth="2.47059"/>
            </g>
            <g filter={`url(#filter6_d_1163_10865-${uniqueStringForSvgIds})`}>
                <path fillRule="evenodd" clipRule="evenodd" d="M59.8029 268.359C70.521 268.359 79.2097 259.633 79.2097 248.869C79.2097 238.105 70.521 229.379 59.8029 229.379C49.0848 229.379 40.396 238.105 40.396 248.869C40.396 259.633 49.0848 268.359 59.8029 268.359Z" fill={getColor(colors, 3)}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M59.8029 268.359C70.521 268.359 79.2097 259.633 79.2097 248.869C79.2097 238.105 70.521 229.379 59.8029 229.379C49.0848 229.379 40.396 238.105 40.396 248.869C40.396 259.633 49.0848 268.359 59.8029 268.359Z" stroke="white" strokeWidth="2.47059"/>
            </g>
            <g filter={`url(#filter8_d_1163_10865-${uniqueStringForSvgIds})`}>
                <path fillRule="evenodd" clipRule="evenodd" d="M59.8029 327.863C70.521 327.863 79.2097 319.137 79.2097 308.373C79.2097 297.609 70.521 288.883 59.8029 288.883C49.0848 288.883 40.396 297.609 40.396 308.373C40.396 319.137 49.0848 327.863 59.8029 327.863Z" fill={getColor(colors, 4)}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M59.8029 327.863C70.521 327.863 79.2097 319.137 79.2097 308.373C79.2097 297.609 70.521 288.883 59.8029 288.883C49.0848 288.883 40.396 297.609 40.396 308.373C40.396 319.137 49.0848 327.863 59.8029 327.863Z" stroke="white" strokeWidth="2.47059"/>
            </g>
        </>
    );
}


/**
 * @param {number} index
 * @returns {string}
 */
const getDefaultColorHexForIndex = (index) => {
    switch (index) {
        case 0:
            return PMW_COLORS_NEUTRAL.NEUTRAL_28;
        case 1:
            return PMW_COLORS_NEUTRAL.NEUTRAL_18;
        case 2:
            return PMW_COLORS_NEUTRAL.NEUTRAL_11;
        case 3:
            return PMW_COLORS_NEUTRAL.NEUTRAL_6;
        case 4:
            return PMW_COLORS_NEUTRAL.NEUTRAL_3;
        default:
            return '#A1A1AA';
    }
}

/**
 *
 * @param {Array|null} colors
 * @param {number} index
 * @returns {string}
 */
const getColor = (colors, index) => {
    return colors ? colors[index]: getDefaultColorHexForIndex(index);
}

BrandsIllustrationColorFills.propTypes = {
    /**
     * the color hexes to show in the circles
     */
    colors: PropTypes.arrayOf(PropTypes.string),
    /**
     * A unique string appended to the ID of all masks and filters which is then needed by all the svg elements that reference those mask/filter elements
     */
    uniqueStringForSvgIds: PropTypes.string
}

BrandsIllustrationColorFills.defaultProps = {
    colors: null
}


export default React.memo(BrandsIllustrationColorFills);