import type {ReactElement} from 'react';
import React, {useEffect, useRef} from 'react';
import {Panel, PanelFooter, PanelFooterPrimaryDefaultAction, PanelHeader} from '@Components/panel';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import {v4 as uuidv4} from 'uuid';
import type {BasePanel} from '@Components/panel/panel.types';
import {getStackTrace} from '@Utils/debug.util';
import useCustomPanelClose from '@Panels/hooks/useCustomPanelClose';
import {noop} from '@Utils/general.util';

export interface ErrorPanelProps extends BasePanel {
  title?: string;
  message?: string;
  log?: boolean;
  debugDetails?: string;
  customClass?: string;
  onClose?: VoidFunction;
  showCloseButton?: boolean;
}

function ErrorPanel({log = true, debugDetails = '', ...props}: ErrorPanelProps): ReactElement {
  const {customClass, onClose, showCloseButton} = props;
  const panelId = useRef(props.panelId ?? uuidv4());
  const customClose = useCustomPanelClose(panelId.current, onClose ?? noop);

  useEffect(() => {
    if (log) {
      let debugData = `Url: ${window.location.href} `;

      if (debugDetails) {
        debugData += debugDetails;
      }

      debugData = `${debugData} \nStacktrace:\n ${getStackTrace()}`;

      window.PMW.log(`${props.message ?? window.i18next.t('pmwjs_default_error')} ${debugData}`);
    }
  }, []);

  const getContent = (): ReactElement => {
    return (
      <div className={`${customClass} flex-column-align-center flex-content-center _full-width _full-height spacing-p-l-12 spacing-p-r-12`}>
        <div className="spacing-p-8 bg-danger-10 radius-round">
          <Icon icon="icon-exclamation-triangle" className="color-danger-100" size={IconSize.SIZE_ICON_48} />
        </div>
        <Text className="spacing-p-t-6 -text-align-center" size={TextSize.SMALL} val={props.message ?? window.i18next.t('pmwjs_default_error')} dangerouslySetInnerHTML />
      </div>
    );
  };

  return (
    <Panel
      panelHeader={<PanelHeader title={props.title ?? window.i18next.t('pmwjs_error_title')} onClose={customClose} showCloseButton={showCloseButton} />}
      panelContent={getContent()}
      panelFooter={
        <PanelFooter
          fullWidthPrimaryActions
          primaryActions={[<PanelFooterPrimaryDefaultAction key="close-error-modal" isFullWidth onClick={customClose} text={window.i18next.t('pmwjs_close')} />]}
        />
      }
    />
  );
}

export default ErrorPanel;
