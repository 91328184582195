import React from 'react';
import type {VoidFunction} from '@Components/login-page/login-page.types';
import type { Type} from '../button';
import {Button, Size} from '../button';
import {useAppSelector} from '@/hooks';

interface AuthFormSubmitButtonProps {
  btnText: string;
  type: Type;
  onClick: VoidFunction;
  showSmallButton: boolean;
  customClasses?: string;
  icon?: string;
  isDisabled?: boolean;
  containerClassName?: string;
}

export function AuthFormSubmitButton({
  btnText,
  type,
  onClick,
  showSmallButton,
  containerClassName = '',
  customClasses = '',
  icon = '',
  isDisabled = false,
}: AuthFormSubmitButtonProps) {
  const isLoading = useAppSelector((state) => {
    return state.loginPage.isLoading;
  });

  const getButtonSize = (): Size => {
    if (showSmallButton) {
      return Size.SMALL;
    }
    return Size.MEDIUM;
  };

  const getCustomClasses = (): string => {
    if (isLoading) {
      return `${customClasses} -loading`;
    }
    return customClasses;
  };

  return (
    <div className={containerClassName}>
      <Button text={btnText} type={type} size={getButtonSize()} customClasses={getCustomClasses()} icon={icon} disabled={isDisabled} onClick={onClick} />
    </div>
  );
}
