import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type { ReduxPlayingSetterProps, ReduxStateSetterProps, ReduxTimeSetterProps} from '@Panels/trim-panels/trim-panels.types';
import {REDUX_STATE} from '@Panels/trim-panels/trim-panels.types';
import type {TrimVideoReduxState} from '@Panels/trim-panels/trim-video-panel/trim-video-panel.types';
import type {TrimAudioReduxState} from '@Panels/trim-panels/trim-audio-panel/trim-audio-panel.types';

interface StateProps {
  VIDEO: TrimVideoReduxState;
  AUDIO: TrimAudioReduxState;
}

const initialState: StateProps = {
  AUDIO: {
    playing: false,
  },
  VIDEO: {
    playing: false,
  },
};

export const trimPanelSlice = createSlice({
  name: 'trimPanel',
  initialState,
  reducers: {
    togglePlaying(state, action: PayloadAction<REDUX_STATE>) {
      state[action.payload].playing = !state[action.payload].playing;
    },
    setStartTime(state, action: PayloadAction<ReduxTimeSetterProps>) {
      state[action.payload.key].startTime = action.payload.value;
    },
    setEndTime(state, action: PayloadAction<ReduxTimeSetterProps>) {
      state[action.payload.key].endTime = action.payload.value;
    },
    initializeState(state, action: PayloadAction<ReduxStateSetterProps>) {
      state[action.payload.key] = {...action.payload.value};
    },
    setPlaying(state, action: PayloadAction<ReduxPlayingSetterProps>) {
      state[action.payload.key].playing = action.payload.value;
    },
    setSeekingTime(state, action: PayloadAction<ReduxTimeSetterProps>) {
      if (action.payload.key === REDUX_STATE.TYPE_VIDEO) {
        state[action.payload.key].seekTime = action.payload.value;
      }
    },
  },
});

export const {togglePlaying, setStartTime, setEndTime, initializeState, setPlaying, setSeekingTime} = trimPanelSlice.actions;
export const trimReducer = trimPanelSlice.reducer;
