import React from 'react';
import PropTypes from 'prop-types';
import './email-campaign-metric-summary.scss';
import {connect} from 'react-redux';
import {IconShape} from '@Components/icon-v2/icon.types';
import {CampaignMetricOutcomeSummary} from '../campaign-metric-outcome-summary';
import {CampaignMetricGraph} from '../campaign-metric-graph';
import {Text, TextSize, TextType} from '../../../text';
import {Icon} from '../../../icon-v2';
import {METRIC_TYPES} from '../../../../libraries/email-marketing-campaigns-library';

/**
 * Shows a summary of how an email campaign performed against a particular metric
 */
class EmailCampaignMetricSummary extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const metricType = this.props.metricType,
      metricDetails = this.props.campaignMetrics[this.props.campaignId][metricType];

    return (
      <div className={'email-campaign-metric-summary'}>
        <div className={`flexbox flex-center icon-label-container ${this.props.isPerformanceComparison ? 'spacing-m-t-9' : ''}`}>
          <Icon icon={this.#getIconClass()} shape={IconShape.NONE} className={'color-extra-light'} />
          <Text val={this.#getTextValue()} type={TextType.CAPTION} size={TextSize.XXSMALL} className={'spacing-m-l-2 content-body'} />
        </div>
        <CampaignMetricGraph
          metricType={metricType}
          positiveOutcomeLabel={metricDetails.positiveOutcomeLabel}
          positiveOutcomePercentage={metricDetails.positiveOutcomePercentage}
          negativeOutcomeLabel={metricDetails.negativeOutcomeLabel}
          negativeOutcomePercentage={metricDetails.negativeOutcomePercentage}
          hasVerticalLabels={this.props.isNonInteractable}
          graphColor={this.props.graphColor}
        />

        <CampaignMetricOutcomeSummary
          metricType={this.props.metricType}
          campaignId={this.props.campaignId}
          outcomeExpandableSection={this.props.negativeOutcomeExpandableSection ?? null}
          isNonInteractable={this.props.isNonInteractable}
        />
      </div>
    );
  }

  /**
   * @returns {string}
   */
  #getIconClass = () => {
    if (this.props.metricType === METRIC_TYPES.DELIVERY) {
      return 'icon-inbox';
    } else if (this.props.metricType === METRIC_TYPES.OPEN) {
      return 'icon-email-open';
    } else if (this.props.metricType === METRIC_TYPES.CLICK) {
      return 'icon-mouse-click';
    }

    return '';
  };

  /**
   * @returns {string}
   */
  #getTextValue = () => {
    if (this.props.metricType === METRIC_TYPES.DELIVERY) {
      return i18next.t('pmwjs_reach');
    } else if (this.props.metricType === METRIC_TYPES.OPEN) {
      return i18next.t('pmwjs_open');
    } else if (this.props.metricType === METRIC_TYPES.CLICK) {
      return i18next.t('pmwjs_interactions');
    }
    return '';
  };
}

EmailCampaignMetricSummary.propTypes = {
  /**
   * The type of metric, one of the METRIC_TYPES.* constants
   */
  metricType: PropTypes.string.isRequired,

  /**
   * The color of the positive outcome graph bar
   */
  graphColor: PropTypes.string.isRequired,

  /**
   * The ID of the campaign for which this metric is to be shown.
   */
  campaignId: PropTypes.string.isRequired,

  /**
   * An optional component which is to be rendered as the expandable section of the negative outcome of this metric
   */
  negativeOutcomeExpandableSection: PropTypes.object,

  /**
   * Optional bool which is true if the comparison view is being rendered
   */
  isPerformanceComparison: PropTypes.bool,

  /**
   * provides a read only copy of the output with all details unhidden
   */
  isNonInteractable: PropTypes.bool,
};

EmailCampaignMetricSummary.defaultProps = {
  negativeOutcomeExpandableSection: null,
  isNonInteractable: false,
};

const mapCampaignMetricSummaryStateToProps = (state) => {
  return {
    campaignMetrics: state.emailMarketingCampaignsPerformance.campaignMetrics,
  };
};

export default connect(mapCampaignMetricSummaryStateToProps, null)(EmailCampaignMetricSummary);
