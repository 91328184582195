export const isLocal = (): boolean => {
  return window.PMW.isLocal();
};

export const isBeta = (): boolean => {
  return window.location.href.indexOf('https://beta') > -1;
};

export const isProduction = (): boolean => {
  return window.location.href.indexOf('https://www.postermywall.com/index.php') > -1;
};
