import {createAsyncThunk} from '@reduxjs/toolkit';
import type {ChangePlanCostArgs, ChangePlanArgs} from '@Panels/change-plan-modal/change-plan-modal.types';

export const initChangePlanModal = createAsyncThunk('initChangePlanModal', async () => {
  return window.PMW.readLocal('premium/getChangeSubscriptionPlanDialog');
});

export const getTeamMembers = createAsyncThunk('getTeamMembers', async () => {
  return window.PMW.readLocal('teams/getTeamMembersForAdmin');
});

export const getChangePlanCost = createAsyncThunk('getChangePlanCost', async (data: ChangePlanCostArgs) => {
  return window.PMW.readLocal('premium/getChangePlanCost', data);
});

export const changePlan = createAsyncThunk('changePlan', async (data: ChangePlanArgs) => {
  return window.PMW.writeLocal('premium/changeSubscriptionPlan', data);
});

export const cancelPlan = createAsyncThunk('cancelPlan', async () => {
  return window.PMW.writeLocal('premium/cancelSubscription');
});
